import { Link, useLocation } from 'react-router-dom';
import Pagination from '@F/Pagination';
import { Button } from '@material-ui/core';
import NoticesFilter from './NoticesFilter';
import NoticesTable from './NoticesTable';

export default function Notices(): JSX.Element {
  const { pathname } = useLocation();

  return (
    <>
      <NoticesFilter />
      <Link to={`${pathname}/create`} style={{ textDecoration: 'none' }}>
        <Button variant="contained" color="primary" style={{ marginBottom: '10px' }}>
          생성
        </Button>
      </Link>
      <NoticesTable />
      <Pagination />
    </>
  );
}
