import { Body } from 'loplat-ui';
import { AppModule } from '@P/max/campaigns/module';
import Tooltip from '@F/Tooltip';
import { Row } from './styles';
import type { Props } from './index';

type HeaderProps = Pick<Props, 'type' | 'title' | 'content'>;
const RowHeader = ({ type, content, title }: HeaderProps): JSX.Element => (
  <Row>
    {type ? (
      <AppModule name={title} type={type} />
    ) : title === '딥링크 URL' ? (
      <Tooltip title="메시지를 클릭했을 때, 브라우저 혹은 앱을 열어 특정 페이지로 연결되는 주소.">
        <span className="title">{title}</span>
      </Tooltip>
    ) : (
      <span className="title">{title}</span>
    )}
    {content && <Body className="body">{content}</Body>}
  </Row>
);

export default RowHeader;
