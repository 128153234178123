import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '@F/materialUI/Pagination';
import type { Props as PaginationPops } from '@F/materialUI/Pagination';
import styled from 'styled-components';
import { grayscale800 } from 'loplat-ui';
import { actions } from '../../redux/campaigns/slice';
import { selectPagination } from '../../redux/campaigns/selector';
import CompareModalButton from '../../compare/CompareModal';
import CampaignTable from './Table';

const List = (): JSX.Element => {
  const { page, size, totalCount } = useSelector(selectPagination);
  const dispatch = useDispatch();

  const changePage = useCallback<PaginationPops['changePage']>(
    (e, page) => {
      dispatch(actions.fetchStart({ type: 'campaigns' }));
      dispatch(actions.setPage(page));
      dispatch(actions.fetchCampaigns());
    },
    [dispatch]
  );

  return (
    <Wrapper>
      <Pagination page={page} perPage={Number(size)} totalCount={totalCount} changePage={changePage} />

      <TableCaption>
        <CompareModalButton />
        <small>
          {(page - 1) * Number(size) + 1}-{page * Number(size) < totalCount ? page * Number(size) : totalCount} of{' '}
          {totalCount}
        </small>
      </TableCaption>
      <CampaignTable />
      <Pagination page={page} perPage={Number(size)} totalCount={totalCount} changePage={changePage} />
    </Wrapper>
  );
};

export default List;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  small {
    color: ${grayscale800};
    text-align: end;
    margin-bottom: 4px;
  }
`;
const TableCaption = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;
