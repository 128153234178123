import { useState } from 'react';
import PropTypes from 'prop-types';
import DialogModal from '@F/DialogModal';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

function CustomizingColumnsDialog(props) {
  const { open, setOpen, selectedColumns, setSelectedColumns, columns } = props;
  const [willBeSelectedColumns, setWillBeSelectedColumns] = useState({ ...selectedColumns });
  const handleChange = (event) => {
    setWillBeSelectedColumns({ ...willBeSelectedColumns, [event.target.name]: event.target.checked });
  };
  const confirm = () => {
    setSelectedColumns(willBeSelectedColumns);
    setOpen(false);
  };
  const ColumnsCheckboxGroup = (
    <FormGroup>
      {columns.map((column) => (
        <FormControlLabel
          key={column.name}
          control={
            <Checkbox
              checked={willBeSelectedColumns[column.name]}
              onChange={handleChange}
              name={column.name}
              disabled={column.isImportant}
            />
          }
          label={column.label}
        />
      ))}
    </FormGroup>
  );

  return (
    <DialogModal
      title="Columns 보이기 / 숨기기"
      description="보고자 하는 Column을 선택해주세요. 확인을 누르셔야 적용됩니다."
      open={open}
      setOpen={setOpen}
      confirmButtonDidClicked={confirm}
    >
      {ColumnsCheckboxGroup}
    </DialogModal>
  );
}

export default CustomizingColumnsDialog;

CustomizingColumnsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selectedColumns: PropTypes.objectOf(PropTypes.bool).isRequired,
  setSelectedColumns: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired
};
