import CategoryModal from '@F/modal/CategoryModal';
import TextInputForm from '@F/materialUI/TextInputForm';

function CategoryInput({ categoryCode, setCategoryCode, isCategoryModalOpen, setIsCategoryModalOpen }) {
  const CategoryInputForm = (
    <TextInputForm
      name="CategoryInput"
      label="카테고리 (category)"
      value={categoryCode.korName}
      onClick={() => setIsCategoryModalOpen(!isCategoryModalOpen)}
      readOnly
      onClear={() => {
        setCategoryCode({ korName: '', code: '' });
      }}
    />
  );

  return (
    <>
      {CategoryInputForm}
      <CategoryModal open={isCategoryModalOpen} setOpen={setIsCategoryModalOpen} setValue={setCategoryCode} />
    </>
  );
}
export default CategoryInput;
