import '@lottiefiles/lottie-player';
import * as S from './styles';

function PageLoadingContainer() {
  return (
    <S.StyledLoading>
      <lottie-player
        autoplay
        loop
        speed="1.5"
        mode="normal"
        src="https://assets8.lottiefiles.com/packages/lf20_x62chJ.json"
        style={{ height: '300px', width: '300px', margin: '0 auto' }}
      />
    </S.StyledLoading>
  );
}
export default PageLoadingContainer;

PageLoadingContainer.propTypes = {};
