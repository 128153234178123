import { createReducer, createSetValueAction, setValueReducer } from '@/redux/common/helper-functions';

/** prefix */
const PREFIX = 'STORE_ERROR_REPORT';

/** initial state */
const INITIAL_STATE = {
  reports: [],
  count: 0,
  openedReportIds: [],
  page: 1,
  perPage: 15,
  params: {},
  isLoading: false
};

/** type */
export const types = {
  RESET: `${PREFIX}/RESET`,
  SET_VALUE: `${PREFIX}/SET_VALUE`,
  SET_LOADING: `${PREFIX}/SET_LOADING`,
  SET_PAGE: `${PREFIX}/SET_PAGE`,
  FETCH_REPORTS: `${PREFIX}/FETCH_REPORTS`,
  SET_NEW_REPORTS: `${PREFIX}/SET_NEW_REPORTS`,
  SET_NEW_REPORT: `${PREFIX}/SET_NEW_REPORT`,
  DELETE_REPORT: `${PREFIX}/DELETE_REPORT`,
  CONFIRM_REPORT: `${PREFIX}/CONFIRM_REPORT`,
  DISCARD_STORE: `${PREFIX}/DISCARD_STORE`,
  DELETE_STORE: `${PREFIX}/DELETE_STORE`
};

/** action */
export const actions = {
  reset: () => ({ type: types.RESET }),
  setValue: createSetValueAction(types.SET_VALUE),
  setLoading: (isLoading) => ({ type: types.SET_LOADING, isLoading }),
  setPage: (page) => ({ type: types.SET_PAGE, page }),
  fetchReports: () => ({ type: types.FETCH_REPORTS }),
  setNewReports: (reports) => ({ type: types.SET_NEW_REPORTS, reports }),
  setNewReport: (report) => ({ type: types.SET_NEW_REPORT, report }),
  deleteReport: (report) => ({ type: types.DELETE_REPORT, report }),
  confirmReport: (report, params) => ({ type: types.CONFIRM_REPORT, report, params }),
  discardStore: (report) => ({ type: types.DISCARD_STORE, report }),
  deleteStore: (report) => ({ type: types.DELETE_STORE, report })
};

/** reducer */
const reducer = createReducer(INITIAL_STATE, {
  [types.RESET]: (draft) => {
    draft.reports = [];
    draft.openedReportIds = [];
  },
  [types.SET_VALUE]: setValueReducer,
  [types.SET_LOADING]: (draft, action) => {
    draft.isLoading = action.isLoading;
  },
  [types.SET_PAGE]: (draft, action) => {
    draft.page = action.page;
  },
  [types.SET_NEW_REPORTS]: (draft, action) => {
    draft.reports = action.reports;
    draft.openedreportIds = [];
  },
  [types.SET_NEW_REPORT]: (draft, action) => {
    const index = draft.reports.findIndex((report) => report.report_id === action.report.report_id);
    if (index >= 0) {
      draft.reports[index] = action.report;
    }
  },
  [types.DELETE_REPORT]: (draft, action) => {
    const index = draft.reports.findIndex((report) => report.report_id === action.report.report_id);
    if (index >= 0) {
      draft.reports = [...draft.reports.slice(0, index), ...draft.reports.slice(index + 1)];
    }
  }
});
export default reducer;
