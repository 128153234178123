import PropTypes from 'prop-types';
import RedPin from '@I/map/pin-red.png';
import BluePin from '@I/map/pin-blue.png';
import NaverMap from '@F/map/NaverMap';
import * as S from './styles';

function MapSection({ fingerprint }) {
  // NOTE: https://loplat.atlassian.net/browse/MC-1055

  const fingerprintMarker = {
    lng: fingerprint.fp_c_lng,
    lat: fingerprint.fp_c_lat,
    image: RedPin,
    size: [13 * 2, 17 * 2]
  };

  const storeMarker = {
    lng: fingerprint.fp_lng,
    lat: fingerprint.fp_lat,
    image: BluePin,
    size: [13 * 2, 17 * 2]
  };

  return (
    <S.StyledMapSection>
      <NaverMap
        id={fingerprint.fp_fpid}
        center={{
          lng: fingerprint.fp_lng,
          lat: fingerprint.fp_lat
        }}
        markers={[storeMarker, fingerprintMarker]}
        zoom={17}
      />
    </S.StyledMapSection>
  );
}
export default MapSection;

MapSection.propTypes = {
  fingerprint: PropTypes.shape({
    fp_fpid: PropTypes.number,
    lng: PropTypes.number,
    lat: PropTypes.number,
    fp_c_lng: PropTypes.number,
    fp_c_lat: PropTypes.number,
    fp_lng: PropTypes.number,
    fp_lat: PropTypes.number
  }).isRequired
};
