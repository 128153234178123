import { all, call, put, takeLeading, select } from 'redux-saga/effects';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { actions, types } from './state';
import { fetchUsers, createUser } from './api';

export function* fetchUsersSaga() {
  const { page, perPage, params } = yield select((state) => ({
    page: state.userManager.page,
    perPage: state.userManager.perPage,
    params: state.userManager.params
  }));

  yield put(actions.setLoading(true));
  try {
    const data = yield call(fetchUsers, page, perPage, params);
    yield put(actions.setNewUsers(data.items));
    yield put(actions.setValue('count', data.count));
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* createUserSaga(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(createUser, action.params);
    yield put(actions.fetchUsers());

    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* sagaReducer() {
  yield all([takeLeading(types.FETCH_USERS, fetchUsersSaga), takeLeading(types.CREATE_USER, createUserSaga)]);
}
