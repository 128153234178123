import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useManageNotice } from '../useManageNotice';
import NoticeDetailToolbar from './Toolbar';
import NoticeContentViewer from './NoticeContentViewer';

export default function NoticeDetail(): JSX.Element | null {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { notices } = useManageNotice();

  if (id !== undefined) {
    const notice = notices.find((notice) => notice.id === +id);

    if (notice !== undefined) {
      return (
        <StyledNoticeDetail>
          <NoticeDetailToolbar notice={notice} />
          <NoticeContentViewer notice={notice} />
        </StyledNoticeDetail>
      );
    }
  }

  navigate('/x/notices');
  return null;
}

const StyledNoticeDetail = styled.div`
  padding: 10px;
`;
