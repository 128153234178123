import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Editor } from '@toast-ui/react-editor';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import TextInputForm from '@F/materialUI/TextInputForm';
import { toastErrorMessage } from '@FUNC/toast';
import { NoticeFormValues } from '@TS/max/manageNotice';
import MarkdownEditor from '@P/homepage/manageRecruits/recruitPositionForm/MarkdownEditor';

import { NOTICE_TYPE_TEXT } from './constant';

export default function NoticeForm({
  initialNoticeFormValues = { title: '', type: 'NOTICE', content: '' },
  onClickCancel,
  onClickSave,
  disabled
}: {
  initialNoticeFormValues?: NoticeFormValues;
  onClickCancel: (e: React.MouseEvent) => void;
  onClickSave: (formValues: NoticeFormValues) => Promise<void>;
  disabled: boolean;
}): JSX.Element | null {
  const editorRef = useRef<Editor>(null);
  const [formValues, setFormValues] = useState<NoticeFormValues>(initialNoticeFormValues);

  const onChangeField = (field: keyof NoticeFormValues, value: NoticeFormValues[typeof field]) =>
    setFormValues((prev) => ({ ...prev, [field]: value }));

  return (
    <StyledNoticeForm>
      <div className="title-type">
        <TextInputForm
          className="title"
          name="title"
          label="제목"
          onChange={(e) => onChangeField('title', e.target.value)}
          value={formValues.title}
        />
        <FormControl fullWidth variant="outlined" className="type">
          <InputLabel id="notice-type">카테고리</InputLabel>
          <Select
            name="status"
            label="카테고리"
            value={formValues.type}
            onChange={(e: any) => onChangeField('type', e.target.value)}
          >
            {Object.entries(NOTICE_TYPE_TEXT).map(([status, statusText]) => (
              <MenuItem value={status} key={status}>
                {statusText}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <MarkdownEditor
        editorRef={editorRef}
        initialValue={initialNoticeFormValues.content}
        firebaseFolderName="notices-images"
      />
      <div className="buttons">
        <Button onClick={onClickCancel} disabled={disabled} color="secondary" variant="contained">
          취소
        </Button>
        <Button
          onClick={() => {
            const content = editorRef.current?.getInstance().getHTML().trim() as string;
            const title = formValues.title.trim();

            const errors = [];
            if (title.length < 1) {
              errors.push('한 글자 이상의 제목을 입력해주세요.');
            }
            if (title.length > 255) {
              errors.push('제목은 255자까지 입력 가능합니다.');
            }

            // content가 ''일 때, editor에서 getHTML로 넘어오는 값
            if (content === '<p><br class="ProseMirror-trailingBreak"></p>') {
              errors.push('한 글자 이상의 본문을 입력해주세요.');
            }

            if (errors.length) {
              toastErrorMessage(`${errors.join('\n')}`);
              return;
            }

            onClickSave({ ...formValues, title, content });
          }}
          disabled={disabled}
          color="primary"
          variant="contained"
        >
          저장
        </Button>
      </div>
    </StyledNoticeForm>
  );
}

const StyledNoticeForm = styled.form`
  .title-type {
    display: flex;
    margin-bottom: 20px;

    .title {
      max-width: 600px;
      margin-right: 20px;
    }

    .type {
      width: 200px;
    }
  }

  .content {
    margin-bottom: 20px;
  }

  .buttons {
    display: flex;

    *:not(:first-of-type) {
      margin-left: 10px;
    }
  }
`;
