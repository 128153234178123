import { all, call, put, takeLeading, select } from 'redux-saga/effects';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { actions, types } from './state';
import { fetchEvents, updateEvent, createEvent } from './api.ts';

export function* fetchData() {
  const { page, perPage, params } = yield select((state) => ({
    page: state.event.page,
    perPage: state.event.perPage,
    params: state.event.params
  }));

  yield put(actions.setLoading(true));
  try {
    const data = yield call(fetchEvents, page, perPage, params);
    yield put(actions.setNewEvents(data.result));
    yield put(actions.setValue('count', data.total_count));
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* createEventSaga(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(createEvent, action.params);
    toastSuccessMessage();
    yield put(actions.fetchEvents());
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* updateEventSaga(action) {
  yield put(actions.setLoading(true));
  try {
    const data = yield call(updateEvent, action.event.event_id, action.params);
    const newAttributes = {
      desc: data.desc,
      updated_at: data.updated_at,
      event_url: data.event_url,
      btn_name: data.btn_name
    };
    yield put(actions.setNewEvent({ ...action.event, ...action.params, ...newAttributes }));

    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* sagaReducer() {
  yield all([
    takeLeading(types.FETCH_EVENTS, fetchData),
    takeLeading(types.CREATE_EVENT, createEventSaga),
    takeLeading(types.UPDATE_EVENT, updateEventSaga)
  ]);
}
