import styled from 'styled-components';

const NoResultWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NoResult = (): JSX.Element => (
  <NoResultWrapper>
    <h3>검색 결과가 없습니다.</h3>
  </NoResultWrapper>
);

export default NoResult;
