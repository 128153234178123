import { useState } from 'react';
import PropTypes from 'prop-types';
import DialogModal from '@F/DialogModal';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

function CustomizingColumnsDialog(props) {
  const { open, setOpen, selectedColumns, setSelectedColumns } = props;
  const [willBeSelectedColumns, setWillBeSelectedColumns] = useState({
    ...selectedColumns
  });
  const handleChange = (event) => {
    setWillBeSelectedColumns({
      ...willBeSelectedColumns,
      [event.target.name]: event.target.checked
    });
  };
  const confirmButtonDidClicked = () => {
    setSelectedColumns(() => willBeSelectedColumns);
    setOpen(false);
  };
  const columnsCheckboxGroup = (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={willBeSelectedColumns.id} onChange={handleChange} name="storeID" value disabled />}
        label="STORE ID (PID)"
      />
      <FormControlLabel
        control={<Checkbox checked={willBeSelectedColumns.complexID} onChange={handleChange} name="complexID" />}
        label="Complex ID (CID)"
      />
      <FormControlLabel
        control={<Checkbox checked={willBeSelectedColumns.brandID} onChange={handleChange} name="brandID" />}
        label="Brand ID (BID)"
      />
      <FormControlLabel
        control={<Checkbox checked={willBeSelectedColumns.name} onChange={handleChange} name="name" />}
        label="장소명 (name)"
      />
      <FormControlLabel
        control={<Checkbox checked={willBeSelectedColumns.branchName} onChange={handleChange} name="branchName" />}
        label="지점명 (branch_name)"
      />
      <FormControlLabel
        control={<Checkbox checked={willBeSelectedColumns.floor} onChange={handleChange} name="floor" />}
        label="층수 (floor)"
      />
      <FormControlLabel
        control={<Checkbox checked={willBeSelectedColumns.category} onChange={handleChange} name="category" />}
        label="카데고리 (category)"
      />
      <FormControlLabel
        control={
          <Checkbox checked={willBeSelectedColumns.categoryDetail} onChange={handleChange} name="categoryDetail" />
        }
        label="카테고리 (상세)"
      />
      <FormControlLabel
        control={<Checkbox checked={willBeSelectedColumns.categoryCode} onChange={handleChange} name="categoryCode" />}
        label="카테고리 코드 (category_code)"
      />
      <FormControlLabel
        control={<Checkbox checked={willBeSelectedColumns.collector} onChange={handleChange} name="collector" />}
        label="수집자 (collector)"
      />
      <FormControlLabel
        control={<Checkbox checked={willBeSelectedColumns.timestamp} onChange={handleChange} name="timestamp" />}
        label="생성 시간(timestamp)"
      />
      <FormControlLabel
        control={<Checkbox checked={willBeSelectedColumns.clientCode} onChange={handleChange} name="clientCode" />}
        label="Client Code"
      />
      <FormControlLabel
        control={<Checkbox checked={willBeSelectedColumns.inspector} onChange={handleChange} name="inspector" />}
        label="검수자 (inspector)"
      />
      <FormControlLabel
        control={<Checkbox checked={willBeSelectedColumns.phone} onChange={handleChange} name="phone" />}
        label="PHONE"
      />
    </FormGroup>
  );

  return (
    <DialogModal
      title="Columns 보이기 / 숨기기"
      description="보고자 하는 Column을 선택해주세요. 확인을 누르셔야 적용됩니다."
      open={open}
      setOpen={setOpen}
      confirmButtonDidClicked={confirmButtonDidClicked}
    >
      {columnsCheckboxGroup}
    </DialogModal>
  );
}

export default CustomizingColumnsDialog;

CustomizingColumnsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selectedColumns: PropTypes.objectOf(PropTypes.bool).isRequired,
  setSelectedColumns: PropTypes.func.isRequired
};
