import { all, call, put, takeLeading, select } from 'redux-saga/effects';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { actions, types } from './state';
import { fetchStores, fetchStore, combineStores, combineSingleStore } from './api';

export function* fetchData() {
  const { page, perPage, params } = yield select((state) => ({
    page: state.storeCombine.page,
    perPage: state.storeCombine.perPage,
    params: state.storeCombine.params
  }));

  yield put(actions.setLoading(true));
  try {
    if (params.pid) {
      const data = yield call(fetchStore, params.pid);
      yield put(actions.setNewStores(data ? [data] : []));
      yield put(actions.setValue('count', data ? 1 : 0));
    } else {
      const data = yield call(fetchStores, page, perPage, params);
      yield put(actions.setNewStores(data.items));
      yield put(actions.setValue('count', data.count));
    }
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* combineStoresSaga(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(combineStores, action.params);

    const store = yield call(fetchStore, action.params.masterPid);
    yield put(actions.setNewStore(store));

    for (let i = 0; i < action.params.slavePidList.length; i += 1) {
      yield put(actions.deleteStore(action.params.slavePidList[i]));
    }
    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* combineSingleStoreSaga(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(combineSingleStore, action.store.pid);
    yield put(actions.setNewStore({ ...action.store, qa_combined: 1 }));
    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* sagaReducer() {
  yield all([
    takeLeading(types.FETCH_STORES, fetchData),
    takeLeading(types.COMBINE_STORES, combineStoresSaga),
    takeLeading(types.COMBINE_SINGLE_STORE, combineSingleStoreSaga)
  ]);
}
