import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import { reversedStatusOptions, statusOptions } from '@P/cook/cookVersion/options';
import { useDispatch } from 'react-redux';
import DialogModal from '@F/DialogModal';
import { useInput } from '@HOOK/';
import { actions } from '@/redux/cook/cookVersion/state';
import * as S from './styles';

function Detail({ id, data }) {
  const version = useMemo(() => data.find((element) => element.version_id === Number(id)), [id, data]);
  const classes = S.useStyles();

  const [packageName, setPackageName] = useInput(version.package_name);
  const PackageNameInput = (
    <TextInputForm name="PackageName" label="패키지명" onChange={setPackageName} value={packageName} />
  );
  const [integerAppVersion, setIntegerAppVersion] = useInput(String(version.v));
  const IntegerAppVersionInput = (
    <TextInputForm
      type="number"
      name="IntegerAppVersion"
      label="앱 버전(정수)"
      onChange={setIntegerAppVersion}
      value={integerAppVersion}
    />
  );
  const [appVersion, setAppVersion] = useInput(version.v_str);
  const AppVersionInput = (
    <TextInputForm name="AppVersion" label="앱 버전" onChange={setAppVersion} value={appVersion} />
  );
  const [minimumAppVersion, setMinimumAppVersion] = useInput(String(version.minimum_v));
  const MinimumAppVersionInput = (
    <TextInputForm
      type="number"
      name="MinimumAppVersion"
      label="동작 가능 최소 버전(정수)"
      onChange={setMinimumAppVersion}
      value={minimumAppVersion}
    />
  );
  const [comment, setComment] = useInput(version.comment);
  const CommentInput = <TextInputForm name="Comment" label="비고" onChange={setComment} value={comment} />;
  const [downloadLink, setDownloadLink] = useInput(version.download_link);
  const DownloadLinkInput = (
    <TextInputForm name="DownloadLink" label="앱 다운로드 링크" onChange={setDownloadLink} value={downloadLink} />
  );

  const [status, setStatus] = useInput(reversedStatusOptions[version.status]);
  const StatusSelect = (
    <SelectForm
      label="상태"
      options={Object.keys(statusOptions).slice(1)}
      value={status}
      onSelect={setStatus}
      name="StatusSelect"
    />
  );

  const dispatch = useDispatch();
  const deleteVersion = useCallback(() => {
    dispatch(actions.deleteVersion(version));
  }, [dispatch, version]);

  const updateVersion = () => {
    dispatch(
      actions.updateVersion(version, {
        versionId: version.version_id,
        integerVersion: Number(integerAppVersion),
        version: appVersion,
        minimumVersion: Number(minimumAppVersion),
        comment,
        packageName,
        downloadLink,
        status: statusOptions[status]
      })
    );
  };

  // 재확인 모달
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [confirmFunction, setConfirmFunction] = useState(() => () => {});
  const reconfirmDeleteVersion = useCallback(() => {
    setIsModalOpen(true);
    setModalTitle('버전을 삭제할까요?');
    setConfirmFunction(() => deleteVersion);
  }, [deleteVersion]);

  const reconfirmUpdateVersion = () => {
    setIsModalOpen(true);
    setModalTitle('버전을 업데이트할까요?');
    setConfirmFunction(() => updateVersion);
  };

  return (
    <S.StyledDetail>
      <Grid container spacing={2} className={classes.wrapper}>
        <Grid item xs>
          {PackageNameInput}
        </Grid>
        <Grid item xs>
          {AppVersionInput}
        </Grid>
        <Grid item xs>
          {IntegerAppVersionInput}
        </Grid>
        <Grid item xs>
          {MinimumAppVersionInput}
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.wrapper}>
        <Grid item xs>
          {StatusSelect}
        </Grid>
        <Grid item xs>
          {CommentInput}
        </Grid>
        <Grid item xs>
          {DownloadLinkInput}
        </Grid>
      </Grid>

      <hr />
      <S.ButtonRow>
        <Button variant="contained" color="secondary" onClick={reconfirmDeleteVersion}>
          DELETE
        </Button>
        <Button variant="contained" color="primary" className={classes.button} onClick={reconfirmUpdateVersion}>
          UPDATE
        </Button>
      </S.ButtonRow>

      <DialogModal
        title={modalTitle}
        description="확인버튼을 누르시면 다시 돌이킬 수 없습니다. 신중히 선택하십시오."
        open={isModalOpen}
        setOpen={setIsModalOpen}
        confirmButtonDidClicked={confirmFunction}
      />
    </S.StyledDetail>
  );
}
export default Detail;

Detail.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired
};
