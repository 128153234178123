import { useState } from 'react';
import Wrapper from '@F/materialUI/Wrapper';
import { TogglersContextProvider } from '@HOOK/useTogglers';
import Toolbar from './Toolbar';
import TableSection from './tableSection/TableSection';
import Filter from './Filter';
import * as S from './styles';
import { FINGERPRINT_TABLE_HEAD_CELLS } from './data';

function FpManager() {
  // select custom columns
  const [selectedColumns, setSelectedColumns] = useState(() =>
    FINGERPRINT_TABLE_HEAD_CELLS.reduce((obj, column) => ({ ...obj, [column.name]: true }), {})
  );

  return (
    <TogglersContextProvider>
      <S.StyledFpManager>
        <Wrapper>
          <Filter />
        </Wrapper>
        <br />
        <Toolbar selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} />
        <TableSection selectedColumns={selectedColumns} />
      </S.StyledFpManager>
    </TogglersContextProvider>
  );
}
export default FpManager;

FpManager.propTypes = {};
