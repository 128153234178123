export const originOptions = [
  {
    key: 0,
    value: '미입력'
  },
  {
    key: 1,
    value: '네이버 지도'
  },
  {
    key: 2,
    value: '다음 지도'
  },
  {
    key: 3,
    value: '복합몰 홈페이지'
  },
  {
    key: 4,
    value: '현장 판단'
  }
];
