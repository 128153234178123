import { createReducer, createSetValueAction, setValueReducer } from '@/redux/common/helper-functions';

/** prefix */
const PREFIX = 'STORE_COMBINE';

/** initial state */
const INITIAL_STATE = {
  stores: [],
  openedStoreIds: [],
  count: 0,
  page: 1,
  perPage: 15,
  params: {},
  isLoading: false
};

/** type */
export const types = {
  RESET: `${PREFIX}/RESET`,
  SET_VALUE: `${PREFIX}/SET_VALUE`,
  SET_LOADING: `${PREFIX}/SET_LOADING`,
  SET_PAGE: `${PREFIX}/SET_PAGE`,
  SET_NEW_STORES: `${PREFIX}/SET_NEW_STORES`,
  SET_NEW_STORE: `${PREFIX}/SET_NEW_STORE`,
  DELETE_STORE: `${PREFIX}/DELETE_STORE`,
  FETCH_STORES: `${PREFIX}/FETCH_STORES`,
  COMBINE_STORES: `${PREFIX}/COMBINE_STORES`,
  COMBINE_SINGLE_STORE: `${PREFIX}/COMBINE_SINGLE_STORE`
};

/** action */
export const actions = {
  reset: () => ({ type: types.RESET }),
  setValue: createSetValueAction(types.SET_VALUE),
  setLoading: (isLoading) => ({ type: types.SET_LOADING, isLoading }),
  setPage: (page) => ({ type: types.SET_PAGE, page }),
  setNewStores: (stores) => ({ type: types.SET_NEW_STORES, stores }),
  setNewStore: (store) => ({ type: types.SET_NEW_STORE, store }),
  deleteStore: (pid) => ({ type: types.DELETE_STORE, pid }),
  fetchStores: () => ({ type: types.FETCH_STORES }),
  combineStores: (params) => ({ type: types.COMBINE_STORES, params }),
  combineSingleStore: (store) => ({ type: types.COMBINE_SINGLE_STORE, store })
};

/** reducer */
const reducer = createReducer(INITIAL_STATE, {
  [types.RESET]: (draft) => {
    draft.stores = [];
    draft.openedStoreIds = [];
  },
  [types.SET_VALUE]: setValueReducer,
  [types.SET_LOADING]: (draft, action) => {
    draft.isLoading = action.isLoading;
  },
  [types.SET_PAGE]: (draft, action) => {
    draft.page = action.page;
  },
  [types.SET_NEW_STORES]: (draft, action) => {
    draft.stores = action.stores;
    draft.openedStoreIds = [];
  },
  [types.SET_NEW_STORE]: (draft, action) => {
    const index = draft.stores.findIndex((store) => store.pid === action.store.pid);
    if (index >= 0) {
      draft.stores[index] = action.store;
    }
  },
  [types.DELETE_STORE]: (draft, action) => {
    const index = draft.stores.findIndex((store) => store.pid === action.pid);
    if (index >= 0) {
      draft.stores = [...draft.stores.slice(0, index), ...draft.stores.slice(index + 1)];
    }
  }
});
export default reducer;
