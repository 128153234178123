import styled from 'styled-components';

export const StyledTableSection = styled.div``;

export const CellWithAction = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;

  svg {
    margin-left: 10px;
  }
`;
