import styled from 'styled-components';

export const StyledInfoSection = styled.div`
  width: 80%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Table = styled.table`
  width: 100%;
  font-size: 0.8rem;

  td,
  th {
    padding: 0.5rem;
    border: 1px solid #ebebeb;
  }

  thead > tr {
    height: 50px;
  }

  .GrayCell {
    background-color: ${(props) => props.theme.palette.grey[300]};
  }
`;

export const Status = styled.p``;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > button {
    margin-left: 10px;
  }
`;
