import styled from 'styled-components';
import MuiPagination from '@material-ui/lab/Pagination';

export type Props = {
  page: number;
  perPage: number;
  totalCount: number;
  changePage: (event: React.ChangeEvent<unknown>, page: number) => void;
};
export default function Pagination({ page, perPage, totalCount, changePage }: Props): JSX.Element {
  return (
    <StyledPaginationWrapper>
      <MuiPagination defaultPage={1} page={page} count={Math.ceil(totalCount / perPage)} onChange={changePage} />
    </StyledPaginationWrapper>
  );
}

const StyledPaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`;
