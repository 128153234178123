import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox } from '@material-ui/core';
import EnhancedTable from '@F/table/Table';
import AssignModal from '@F/modal/assignModal/AssignModal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { format } from '@FUNC/math';
import PaginationRow from '@F/table/PaginationRow';
import FloorSiteModal from '@P/poi/SiteMap/SiteMapTableWrapper/FloorSiteModal';
import ResetAssignedModal from '@F/modal/assignModal/ResetAssignedsModal';
import * as S from './styles';
import { getComplexFullName } from '../functions';

function SiteMapTableWrapper({
  siteCounts,
  sites,
  sitesToLiftedUp,
  complexes,
  pages,
  isListType,
  onConfirm,
  onChangeSortOption
}) {
  const classes = S.useStyles();
  const [selectedSites, setSelectedSites] = useState([]);
  const [refinedSites, setRefinedSites] = useState([]);
  const [refinedSitesToLiftedUp, setRefinedSitesToLiftedUp] = useState([]);

  const [isAssignModalOpened, setIsAssignModalOpened] = useState(false);
  const [isResetAssignedModalOpened, setIsResetAssignedModalOpened] = useState(false);
  const [isFloorSiteModalOpened, setIsFloorSiteModalOpened] = useState(false);
  const [floorSiteToShow, setFloorSiteToShow] = useState(null);

  const getRefinedSite = useCallback(
    (site) => {
      const isFloorSite = !!site.sites;
      const idToShow = isFloorSite ? site.cid : site.site_id;
      const matchedComplex = site.cid ? complexes.find((complex) => complex.cid === site.cid) : null;

      return {
        id: {
          value: site.site_id,
          component: site.charge ? (
            <S.ChargedCountLabel>{idToShow}</S.ChargedCountLabel>
          ) : (
            <S.NonChargedCountLabel>{idToShow}</S.NonChargedCountLabel>
          )
        },
        pinTier: !isFloorSite ? site.pin_tier : null,
        name: !isFloorSite
          ? site.origin_name
          : {
              value: `${site.floor}층`,
              component: (
                <S.FloorButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsFloorSiteModalOpened(true);
                    setFloorSiteToShow(site);
                  }}
                >
                  {site.floor}층
                </S.FloorButton>
              )
            },
        ...(isListType ? { address: site.addr_road || site.addr || matchedComplex?.addr } : {}),
        category: site.last_category,
        complexName: matchedComplex ? getComplexFullName(matchedComplex) : null
      };
    },
    [complexes, isListType]
  );

  const rowsToShow = useMemo(() => {
    if (!refinedSitesToLiftedUp.length) {
      return refinedSites;
    }
    return refinedSites.sort((site) =>
      refinedSitesToLiftedUp.map((ele) => ele.id.value).includes(site.id.value) ? -1 : 0
    );
  }, [refinedSites, refinedSitesToLiftedUp]);

  // TODO: 최적화
  const [isShownCharged, setIsShownCharged] = useState(false);
  const [isShownNonCharged, setIsShownNonCharged] = useState(true);

  const chargedSites = useMemo(() => sites.filter((site) => !site.cid && site.charge), [sites]);
  const chargedComplexes = useMemo(() => sites.filter((site) => site.cid && site.charge), [sites]);
  const nonChargedSites = useMemo(() => sites.filter((site) => !site.cid && !site.charge), [sites]);
  const nonChargedComplexes = useMemo(() => sites.filter((site) => site.cid && !site.charge), [sites]);

  useEffect(() => {
    setSelectedSites([]);
  }, [sites]);

  useEffect(() => {
    let filteredRows = [];
    if (isShownNonCharged) {
      filteredRows = filteredRows.concat(nonChargedComplexes, nonChargedSites);
    }
    if (isShownCharged) {
      filteredRows = filteredRows.concat(chargedComplexes, chargedSites);
    }
    setRefinedSites(filteredRows.map(getRefinedSite));
  }, [
    isShownCharged,
    isShownNonCharged,
    chargedSites,
    chargedComplexes,
    nonChargedSites,
    nonChargedComplexes,
    getRefinedSite
  ]);

  useEffect(() => {
    setRefinedSitesToLiftedUp(sitesToLiftedUp.map(getRefinedSite));
  }, [sitesToLiftedUp, getRefinedSite]);

  const sitesToAssign = useMemo(() => {
    const sites = [];
    selectedSites.forEach((site) => {
      if (site.pinTier === null) {
        // 복합몰의 층을 나타내는 floorSite일 경우
        const floorSite = [...chargedComplexes, ...nonChargedComplexes].find((ele) => ele.site_id === site.id.value);
        sites.push(
          ...floorSite.sites.map((site) => ({
            ...getRefinedSite(site),
            name: `${site.origin_name}(${floorSite.origin_name} ${floorSite.floor}층)`
          }))
        );
      } else {
        sites.push(site);
      }
    });
    return sites;
  }, [selectedSites, chargedComplexes, nonChargedComplexes, getRefinedSite]);

  return (
    <S.StyledSiteMapTableWrapper>
      <S.Header>
        <CountCheckbox
          isShownCharged={isShownCharged}
          isShownNonCharged={isShownNonCharged}
          onChangeIsShownCharged={() => setIsShownCharged((prevState) => !prevState)}
          onChangeIsShownNonCharged={() => setIsShownNonCharged((prevState) => !prevState)}
          chargedSitesCount={siteCounts.chargedSites}
          chargedComplexesCount={siteCounts.chargedComplexes}
          nonChargedSitesCount={siteCounts.nonChargedSites}
          nonChargedComplexesCount={siteCounts.nonChargedComplexes}
        />
        <div>
          <Button variant="outlined" color="secondary" onClick={() => setSelectedSites([])}>
            선택 초기화
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => setIsResetAssignedModalOpened(true)}
            disabled={selectedSites.length === 0}
          >
            할당취소
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => setIsAssignModalOpened(true)}
            disabled={selectedSites.length === 0}
          >
            할당
          </Button>
        </div>
      </S.Header>
      <S.Content>
        <S.TableCountRow>
          {`Displaying ${(pages.page - 1) * pages.perPage + 1} to ${Math.min(
            pages.page * pages.perPage,
            siteCounts.total
          )} of ${format(siteCounts.total)} items`}
        </S.TableCountRow>
        <S.TableWrapper>
          <EnhancedTable
            titleText="할당할 site를 선택하세요"
            headCells={headCells.filter((headCell) => (isListType ? true : headCell.label !== '주소'))}
            rows={rowsToShow}
            selected={selectedSites}
            setSelected={setSelectedSites}
            isCheckable
            initialDense
            isApiSort
            onChangeSortOption={onChangeSortOption}
          >
            <div>complex 하위 sites</div>
          </EnhancedTable>

          <PaginationRow count={siteCounts.total} page={pages.page} setPage={pages.setPage} perPage={pages.perPage} />
        </S.TableWrapper>
      </S.Content>

      <AssignModal
        open={isAssignModalOpened}
        setOpen={setIsAssignModalOpened}
        sites={sitesToAssign}
        onConfirm={onConfirm}
      />
      <ResetAssignedModal
        open={isResetAssignedModalOpened}
        setOpen={setIsResetAssignedModalOpened}
        sites={sitesToAssign}
        onConfirm={onConfirm}
      />
      <FloorSiteModal open={isFloorSiteModalOpened} setOpen={setIsFloorSiteModalOpened} floorSite={floorSiteToShow} />
    </S.StyledSiteMapTableWrapper>
  );
}
export default SiteMapTableWrapper;

SiteMapTableWrapper.propTypes = {
  siteCounts: PropTypes.shape({
    total: PropTypes.number,
    chargedSites: PropTypes.number,
    chargedComplexes: PropTypes.number,
    nonChargedSites: PropTypes.number,
    nonChargedComplexes: PropTypes.number
  }),
  sites: PropTypes.arrayOf(PropTypes.object),
  sitesToLiftedUp: PropTypes.arrayOf(PropTypes.object),
  complexes: PropTypes.arrayOf(PropTypes.object),
  pages: PropTypes.shape({
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired
  }).isRequired,
  isListType: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onChangeSortOption: PropTypes.func.isRequired
};

SiteMapTableWrapper.defaultProps = {
  siteCounts: {
    total: 0,
    chargedSites: 0,
    chargedComplexes: 0,
    nonChargedSites: 0,
    nonChargedComplexes: 0
  },
  sites: [],
  sitesToLiftedUp: [],
  complexes: []
};

function CountCheckbox({
  isShownCharged,
  onChangeIsShownCharged,
  isShownNonCharged,
  onChangeIsShownNonCharged,
  chargedSitesCount,
  chargedComplexesCount,
  nonChargedSitesCount,
  nonChargedComplexesCount
}) {
  return (
    <S.CountContainer>
      <S.NonChargedCountLabel>
        <FormControlLabel
          control={<Checkbox color="primary" checked={isShownNonCharged} onChange={onChangeIsShownNonCharged} />}
        />
        미할당{' '}
        <S.FullCountLabel>{`Sites: ${nonChargedSitesCount}, Complex: ${nonChargedComplexesCount}`}</S.FullCountLabel>
      </S.NonChargedCountLabel>
      <S.ChargedCountLabel>
        <FormControlLabel
          control={<Checkbox color="primary" checked={isShownCharged} onChange={onChangeIsShownCharged} />}
        />
        할당 <S.FullCountLabel>{`Sites: ${chargedSitesCount}, Complex: ${chargedComplexesCount}`}</S.FullCountLabel>
      </S.ChargedCountLabel>
    </S.CountContainer>
  );
}

CountCheckbox.propTypes = {
  isShownCharged: PropTypes.bool.isRequired,
  onChangeIsShownCharged: PropTypes.func.isRequired,
  isShownNonCharged: PropTypes.bool.isRequired,
  onChangeIsShownNonCharged: PropTypes.func.isRequired,
  chargedSitesCount: PropTypes.number.isRequired,
  chargedComplexesCount: PropTypes.number.isRequired,
  nonChargedSitesCount: PropTypes.number.isRequired,
  nonChargedComplexesCount: PropTypes.number.isRequired
};

const headCells = [
  {
    id: 'site_id',
    numeric: true,
    disablePadding: false,
    label: 'ID'
  },
  {
    id: 'pin_tier',
    numeric: true,
    disablePadding: false,
    label: '우선순위'
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: '장소명'
  },
  {
    id: 'addr',
    numeric: false,
    disablePadding: false,
    label: '주소'
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: '카테고리'
  },
  {
    id: 'complex_name',
    numeric: false,
    isSortable: false,
    disablePadding: false,
    label: '복합몰명'
  }
];
