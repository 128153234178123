import { fingerprintsApi } from '@API/manager';

export function fetchFingerprints(page, perPage, params) {
  return fingerprintsApi.getAutomaticallyCollectedFingerprints(perPage, page, params).then((response) => response.data);
}

export function updateAutomaticallyCollectedFingerprintValidation(fpid, params) {
  return fingerprintsApi
    .patchAutomaticallyCollectedFingerprintValidation(fpid, params)
    .then((response) => response.data);
}

export function updateAutomaticallyCollectedFingerprint(fpid, params) {
  return fingerprintsApi.putAutomaticallyCollectedFingerprint(fpid, params).then((response) => response.data);
}
