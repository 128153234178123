import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';

import { Company } from '@TS/max/company/api';
import { maxCompaniesApi } from '@API/max';
import { toastErrorMessage } from '@FUNC/toast';
import { managerLocalStorage } from '@/utils/localStorageParser';

import { PrimitiveLogFilterValues } from '../data';

export default function MaxCompanies(): JSX.Element {
  const [status, setStatus] = useState<ApiStatus>('idle');
  const [primitiveLogCompanies, setPrimitiveLogCompanies] = useState<Company[]>([]);

  const { values, setValues, touched, setTouched } = useFormikContext<PrimitiveLogFilterValues>();
  const clientId = values.client_id;

  useEffect(() => {
    const cachedPrimitiveLogCompanies = managerLocalStorage.get<Company[]>(
      managerLocalStorage.keys['max/companies?type=2']
    );

    if (cachedPrimitiveLogCompanies) {
      setPrimitiveLogCompanies(cachedPrimitiveLogCompanies);
      return;
    }

    setStatus('loading');

    (async () => {
      try {
        const {
          data: { companies }
        } = await maxCompaniesApi.getCompanies({ type: 2 });

        setPrimitiveLogCompanies(companies);
        managerLocalStorage.set<Company[]>(managerLocalStorage.keys['max/companies?type=2'], companies);
      } catch (e) {
        toastErrorMessage(
          '회사 정보를 불러오는 요청에 실패했습니다. 요청을 다시 시도해주세요. \n문제가 지속될 경우 관리자(전창현)에게 문의 부탁드립니다.'
        );
      } finally {
        setStatus('idle');
      }
    })();
  }, []);

  return (
    <Autocomplete
      id="company"
      freeSolo
      autoHighlight
      clearOnBlur
      disabled={status === 'loading'}
      loading={status === 'loading'}
      options={primitiveLogCompanies}
      value={clientId}
      onChange={(e, newValue) => {
        setTouched({ ...touched, client_id: true }, false);
        setValues((prev) => ({ ...prev, client_id: newValue || '' }));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="회사명"
          name="company"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {status === 'loading' && <CircularProgress size={20} thickness={4} />}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}
