import { AxiosResponse } from 'axios';
import { axiosInstancesManager } from '@API/axios';
import { BrandItem, ListResponse, Parameters } from '@TS/store/brand';

class BrandsApi {
  get({ bid }: Pick<Parameters, 'bid'>): Promise<AxiosResponse<BrandItem>> {
    return axiosInstancesManager.manager.get(`/brands/${bid}`);
  }

  getByName({ searchTerm }: Pick<Parameters, 'searchTerm'>): Promise<AxiosResponse<ListResponse>> {
    return axiosInstancesManager.manager.get('/brands/search', { params: { search_term: searchTerm } });
  }
}

export default new BrandsApi();
