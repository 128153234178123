import { useLocation } from 'react-router-dom';
import FilterWrapper from './Filter/Wrapper';
import Filter from './Filter/Filter';
import TableSection from './tableSection/TableSection';
import * as S from './styles';

function ComplexManager() {
  const location = useLocation();

  const lastPath = location.pathname.split('/').slice(-1)[0];
  const id = Number.isNaN(+lastPath) ? '' : lastPath;

  return (
    <S.StyledComplexContainer>
      <FilterWrapper>
        <Filter initialCid={id} />
      </FilterWrapper>
      <TableSection />
    </S.StyledComplexContainer>
  );
}
export default ComplexManager;
