import { useCallback, useState } from 'react';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';

// TODO: ts로 마이그레이션 완료되면 useInput도 ts버젼으로 변경하기
export const useInput = (initialValue, clearedValue) => {
  const [value, setValue] = useState(initialValue ?? '');

  const onChangeInput = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const clearInput = useCallback(() => {
    if (clearedValue !== undefined) setValue(clearedValue);
    else setValue(initialValue);
  }, [clearedValue, initialValue]);

  return [value, onChangeInput, clearInput];
};

export const useFetch = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);

  const callApi = useCallback(
    async (apiFunction, params, toastOnSuccess = false, errorMessages = {}, hideLoading = false) => {
      setIsLoading(!hideLoading);
      let response = null;
      try {
        response = await apiFunction(params);
        setIsError(false);

        if (toastOnSuccess) {
          toastSuccessMessage();
        }
        return response;
      } catch (e) {
        const customErrorMessage = errorMessages[e.response.data?.type];
        const errorMessage = e.response.data;
        toastErrorMessage(customErrorMessage || errorMessage);
        setIsError(true);
        setError(e);
      } finally {
        setIsLoading(false);
      }
      return response;
    },
    []
  );

  return {
    callApi,
    isLoading,
    isError,
    error
  };
};
