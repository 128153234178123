import styled from 'styled-components';

export const StyledSendMessage = styled.div`
  & section {
    width: 100%;
    min-height: 50px;
    margin-bottom: 20px;

    p {
      margin-bottom: 15px;
    }
  }

  & textarea {
    width: 100%;
    height: 15rem;
    font-size: 1.2rem;
  }

  & br {
    height: 1rem;
  }

  & button {
    float: right;
    margin-top: 1rem;
  }
`;
