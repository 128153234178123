import { storesApi } from '@API/manager';

export function fetchStores(page, perPage, params) {
  return storesApi.getStoreData({ page, perPage, ...params }).then((response) => response.data);
}

export function fetchStoresByPID(pid) {
  return storesApi.getStoreDataByPID({ pid }).then((response) => response.data);
}

export function closeStore(pid) {
  return storesApi.patchStoreClose({ pid });
}

export function discardStore(pid) {
  return storesApi.patchStoreDiscard({ pid });
}

export function deleteStore(pid) {
  return storesApi.deleteStore({ pid });
}

export function completeStore(storeInfo) {
  return storesApi.putStore(storeInfo);
}
