import styled, { css } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: 10
  },
  margin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1)
  }
}));

export const Row = styled(Grid)`
  margin-bottom: 10px;
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-right: 20px;
  margin-bottom: 20px;
`;

export const Table = styled.div`
  display: flex;
  width: 100%;
  height: 70vh;
  margin-bottom: 20px;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
`;
