/* eslint-disable vars-on-top */
import styled from '@emotion/styled';

import { TogglersContextProvider } from '@HOOK/useTogglers';
import { PaginationContextProvider } from '@HOOK/usePagination';
import Pagination from '@F/Pagination';

import AlertBox from './AlertBox';
import PrimitiveLogsFilter from './PrimitiveLogsFilter';
import PrimitiveLogsTable from './PrimitiveLogsTable';
import { PrimitiveLogBlocProvider } from './usePrimitiveLogBloc';

function XPrimitiveLog(): JSX.Element {
  return (
    <>
      <AlertBox />
      <Box>
        <PrimitiveLogsFilter />
        <Pagination />
        <PrimitiveLogsTable />
        <Pagination />
      </Box>
    </>
  );
}

export default function Wrapped(): JSX.Element {
  return (
    <TogglersContextProvider>
      <PaginationContextProvider initialOption={{ page: 1, perPage: 1000, totalCount: 0 }}>
        <PrimitiveLogBlocProvider>
          <XPrimitiveLog />
        </PrimitiveLogBlocProvider>
      </PaginationContextProvider>
    </TogglersContextProvider>
  );
}

const Box = styled.div`
  padding: 20px;
  box-shadow: 0 0 0 1px #eee;
  background-color: #fff;
  border-radius: 4px;
`;
