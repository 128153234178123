import { Button, Grid } from '@material-ui/core';
import TextInputForm from '@F/materialUI/TextInputForm';
import { cookUsersApi } from '@API/manager/cook';
import Loading from '@F/Loading';
import { useDispatch } from 'react-redux';
import { toastErrorMessage } from '@FUNC/toast';
import { useFetch, useInput } from '@HOOK/';
import { actions } from '@/redux/cook/cookVersion/state';
import * as S from './styles';

function CreateVersion() {
  const classes = S.useStyles();

  const [packageName, setPackageName] = useInput('');
  const PackageNameInput = (
    <TextInputForm name="PackageName" label="패키지명" onChange={setPackageName} value={packageName} />
  );
  const [integerAppVersion, setIntegerAppVersion] = useInput('');
  const IntegerAppVersionInput = (
    <TextInputForm
      type="number"
      name="IntegerAppVersion"
      label="앱 버전(정수)"
      onChange={setIntegerAppVersion}
      value={integerAppVersion}
    />
  );
  const [appVersion, setAppVersion] = useInput('');
  const AppVersionInput = (
    <TextInputForm name="AppVersion" label="앱 버전" onChange={setAppVersion} value={appVersion} />
  );
  const [minimumAppVersion, setMinimumAppVersion] = useInput('');
  const MinimumAppVersionInput = (
    <TextInputForm
      type="number"
      name="MinimumAppVersion"
      label="동작 가능 최소 버전(정수)"
      onChange={setMinimumAppVersion}
      value={minimumAppVersion}
    />
  );
  const [comment, setComment] = useInput('');
  const CommentInput = <TextInputForm name="Comment" label="비고" onChange={setComment} value={comment} />;
  const [downloadLink, setDownloadLink] = useInput('');
  const DownloadLinkInput = (
    <TextInputForm name="DownloadLink" label="앱 다운로드 링크" onChange={setDownloadLink} value={downloadLink} />
  );

  const dispatch = useDispatch();
  const { callApi, isLoading } = useFetch();
  const createVersion = async () => {
    if (!packageName.trim()) {
      toastErrorMessage('패키지명은 필수로 입력되어야 합니다.');
      return;
    }

    const toastOnSuccess = true;
    const errorMessages = { InvalidUsage: '현재 앱 버전이 동작가능 앱 버전보다 낮습니다.' };
    await callApi(
      cookUsersApi.postVersion,
      {
        integerVersion: Number(integerAppVersion),
        version: appVersion,
        minimumVersion: Number(minimumAppVersion),
        comment,
        packageName,
        downloadLink
      },
      toastOnSuccess,
      errorMessages
    );
    dispatch(actions.fetchVersions());
  };

  return (
    <S.StyledCreateVersion>
      {isLoading && <Loading />}
      <div className={classes.title}>버전 등록</div>
      <Grid container spacing={2} className={classes.wrapper}>
        <Grid item xs>
          {PackageNameInput}
        </Grid>
        <Grid item xs>
          {IntegerAppVersionInput}
        </Grid>
        <Grid item xs>
          {AppVersionInput}
        </Grid>
        <Grid item xs>
          {MinimumAppVersionInput}
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.wrapper}>
        <Grid item xs={3}>
          {CommentInput}
        </Grid>
        <Grid item xs={3}>
          {DownloadLinkInput}
        </Grid>
      </Grid>

      <S.ButtonRow>
        <Button variant="contained" color="primary" onClick={createVersion}>
          등록
        </Button>
      </S.ButtonRow>
    </S.StyledCreateVersion>
  );
}
export default CreateVersion;

CreateVersion.propTypes = {};
