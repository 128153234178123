import { cashplaceRewardsApi } from '@API/manager/cashplace';
import { Reward, GivingRewardParams, FetchingRewardParams } from '@TS/cashplace/reward';

export function fetchRewards({ page, perPage, state, userId }: FetchingRewardParams): Promise<Reward[]> {
  return cashplaceRewardsApi
    .getRewards({
      page,
      perPage,
      state,
      userId
    })
    .then((response) => response.data);
}

export function updateRewards({ status, rewardIds, reason }: GivingRewardParams): Promise<Reward> {
  return cashplaceRewardsApi
    .giveRewards({
      status,
      rewardIds,
      reason
    })
    .then((response) => response.data);
}
