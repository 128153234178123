import { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { toJS } from 'mobx';
import { valuesStore } from '@/mobX/values';

function UserSelectForm({ emitUser }) {
  const [value, setValue] = useState('');
  const options = useMemo(() => toJS(valuesStore.collectors).map((user) => user.username), []);

  const onChange = useCallback((_, newValue) => {
    setValue(newValue);
  }, []);

  useEffect(() => {
    emitUser(value);
  }, [emitUser, value]);

  return (
    <Autocomplete
      options={options}
      onInputChange={onChange}
      fullWidth
      renderInput={(params) => <TextField {...params} label="유저명" variant="outlined" error={value.length <= 0} />}
    />
  );
}
export default UserSelectForm;

UserSelectForm.propTypes = {
  emitUser: PropTypes.func.isRequired
};
