import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(1)
  },
  paper: {
    margin: theme.spacing(5),
    padding: theme.spacing(5)
  }
}));
