import styled from 'styled-components';

export const Button = styled.button`
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 10px;

  width: 45px;
  height: 45px;

  background-color: ${(props) =>
    props.isDrawingMode ? props.theme.palette.grey[500] : props.theme.palette.common.white};
  border: 1px solid ${(props) => props.theme.palette.grey[400]};
  border-radius: 10px;
  outline: none;

  cursor: pointer;
`;
