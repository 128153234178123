import { useCallback } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { observer } from 'mobx-react';
import { toastStore } from '@/mobX/toast';

const Toast = observer(() => {
  const closeToast = useCallback((_, reason) => {
    if (reason !== 'clickaway') {
      toastStore.setIsOpen(false);
    }
  }, []);

  return (
    <Snackbar
      open={toastStore.isOpen}
      onClose={closeToast}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert
        onClose={closeToast}
        severity={toastStore.type}
        variant="filled"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <h2>{toastStore.message}</h2>
      </Alert>
    </Snackbar>
  );
});
export default Toast;

Toast.propTypes = {};
