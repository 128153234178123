import styled, { css } from 'styled-components';
import MTableCell from '@material-ui/core/TableCell';

export const StyledCombineTable = styled.div``;

export const TableCell = styled(MTableCell)`
  ${(props) =>
    props.isqa &&
    css`
      background-color: rgba(168, 255, 86, 0.2);
    `};
  ${(props) =>
    props.selected &&
    css`
      background-color: rgba(0, 169, 255, 0.2) !important;
    `};
`;
