import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Map from '@P/ClosingManager/tableSection/detail/Map';
import Detail from '@P/ClosingManager/tableSection/detail/Detail';
import * as S from './styles';

function ClosingManagerInnerContainer({ id, closingData, selectedColumns }) {
  const detail = useMemo(() => closingData.find((item) => item.site_id === id), [closingData, id]);

  return (
    <S.StyledClosingManagerInnerContainer>
      <Grid container spacing={1}>
        <Grid item xs>
          <Map
            detail={detail}
            location={{
              lat: detail.lat,
              lng: detail.lng
            }}
          />
        </Grid>
        <Grid item xs>
          <Detail detail={detail} detailProperties={selectedColumns} />
        </Grid>
      </Grid>
    </S.StyledClosingManagerInnerContainer>
  );
}
export default ClosingManagerInnerContainer;

ClosingManagerInnerContainer.propTypes = {
  id: PropTypes.number,
  closingData: PropTypes.arrayOf(PropTypes.any).isRequired,
  selectedColumns: PropTypes.objectOf(PropTypes.any).isRequired
};

ClosingManagerInnerContainer.defaultProps = {
  id: null
};
