import { GetNoticesResponse } from '@API/manager/notification';
import { bluescale300, grayscale700, grayscale800, green300, palette } from 'loplat-ui';
import {
  Notice,
  NoticeFilterValues,
  NoticeOrderBy,
  NoticeStatus,
  NoticeTableFields,
  NoticeType
} from '@TS/max/manageNotice';

export const mockNotice: Notice = {
  id: 23,
  title: 'X.ver 1.6.1 Release Note',
  content:
    '<p><br></p><p><br></p><p><br></p><p><img src="https://firebasestorage.googleapis.com/v0/b/loplat-x-admin-58046.appspot.com/o/images%2Fc74f6c13e40a555051fbee963fe3f3bc5bc04abb0d62dce903b783bf43fe57d4.test?alt=media&amp;token=200ed115-b244-48c2-bf13-a4e3fae487b5"></p><p><br></p><p><strong>## 캠페인 실행 타입 변경 업데이트 되었습니다.</strong></p><p><br></p><p>캠페인 생성시 광고ID를 추가하면, 메시지 설정 화면에 </p><p>‘테스트 모드’가 활성화 됩니다. 테스트 모드에서는 캠페인이 </p><p>횟수 및 시간과 관계 없이 항상 매칭됩니다.</p><p><br></p><p>마음껏 테스트를 실행해 보세요.</p><p><br></p><p>자세한 내용은 가이드를 참고 하세요. <a href="https://loplatx.gitbook.io/loplat-x-documents/#guide-update-history" rel="noopener noreferrer" target="_blank">[참고]</a></p>',
  status: 'PUBLISH',
  type: 'NOTICE',
  creator: '장천규',
  created_at: '2022-02-12',
  updated_at: '2022-02-14',
  published_at: '2022-02-22'
};
const mockNotice2: Notice = { ...mockNotice, status: 'PENDING', type: 'NOTICE_I' };
const mockNotice3: Notice = { ...mockNotice, status: 'DELETED', type: 'UPDATE' };

export const mockGetNoticesResponse: GetNoticesResponse = {
  items: Array(1000)
    .fill(0)
    .map((_, i) =>
      i % 3 === 0 ? { ...mockNotice, id: i } : i % 3 === 1 ? { ...mockNotice2, id: i } : { ...mockNotice3, id: i }
    ),
  count: 1000
};

// # view

// ## label

export const NOTICE_TYPE_TEXT: { [key in NoticeType]: string } = {
  UPDATE: 'POI Update',
  NOTICE: 'x Release Note',
  NOTICE_I: 'i Release Note'
};

export const NOTICE_STATUS_TEXT: { [key in NoticeStatus]: string } = {
  PUBLISH: '공고',
  PENDING: '대기',
  DELETED: '삭제'
};

export const NOTICE_TYPE_COLOR: { [key in NoticeType]: string } = {
  UPDATE: grayscale700,
  NOTICE: palette.primary,
  NOTICE_I: palette.primary
};

//  bluescale300
export const NOTICE_STATUS_COLOR: { [key in NoticeStatus]: string } = {
  PUBLISH: green300,
  PENDING: grayscale800,
  DELETED: bluescale300
};

// ## filter

export const NOTICE_FILTER_INITIAL_VALUES: NoticeFilterValues = {
  type: null,
  status: null
};

// ## table
export const NOTICE_TABLE_HEAD_CELLS: {
  name: NoticeTableFields;
  label: string;
  defaultOrderType: OrderType;
  initialIsActive?: boolean;
}[] = [
  {
    name: 'id',
    label: 'id',
    defaultOrderType: 'DESC',
    initialIsActive: true
  },
  {
    name: 'title',
    label: '제목',
    defaultOrderType: 'DESC'
  },
  {
    name: 'creator',
    label: '작성자',
    defaultOrderType: 'DESC'
  },
  {
    name: 'type',
    label: '카테고리',
    defaultOrderType: 'DESC'
  },
  {
    name: 'created_at',
    label: '생성일',
    defaultOrderType: 'DESC'
  },
  {
    name: 'published_at',
    label: '발행일',
    defaultOrderType: 'DESC'
  },
  {
    name: 'status',
    label: '상태',
    defaultOrderType: 'DESC'
  }
];

export const NOTICE_TABLE_INITIAL_ORDER_BY: NoticeOrderBy = {
  name: 'created_at',
  type: 'DESC'
};
