import Tag from '@F/Tag';
import { SelectedBrand } from '@TS/store/brandModal';
import * as S from './styles';

interface Props {
  isVisible: boolean;
  brands: SelectedBrand[];
  deleteSelectedBrandByID: (id: SelectedBrand['id']) => void;
}

const PreviousSelectedBrands = ({ isVisible, brands, deleteSelectedBrandByID }: Props): JSX.Element | null => {
  if (!isVisible) return null;

  return (
    <S.PreviousSelectedBrandsWrapper>
      <h2>현재 선택된 브랜드</h2>
      <ul>
        {brands.map(({ id, name }) => (
          <li key={id}>
            <Tag label={name} id={id} deleteTag={deleteSelectedBrandByID} />
          </li>
        ))}
      </ul>
    </S.PreviousSelectedBrandsWrapper>
  );
};

export default PreviousSelectedBrands;
