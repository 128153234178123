import { all, call, put, takeLeading, select } from 'redux-saga/effects';
import moment from 'moment';
import { toastErrorMessage } from '@FUNC/toast';
import { actions, types } from './state';
import { fetchStudies, updateStudy, fetchCDevices } from './api.ts';

export function* fetchCDevicesSaga() {
  try {
    const localStorageCDevices = window.localStorage.getItem('manager/cashplace/study/devices');

    if (localStorageCDevices) {
      const { savedAt, data } = JSON.parse(localStorageCDevices);
      const dayDiff = Math.floor(moment.duration(moment().diff(moment(savedAt))).asDays());

      if (dayDiff < 1) {
        yield put(actions.setValue('cDevices', data));

        return;
      }
    }

    const cDevices = yield call(fetchCDevices);

    window.localStorage.setItem(
      'manager/cashplace/study/devices',
      JSON.stringify({
        savedAt: new Date(),
        data: cDevices
      })
    );

    yield put(actions.setValue('cDevices', cDevices));
  } catch (e) {
    toastErrorMessage(e);
  }
}

export function* fetchStudiesSaga() {
  const { page, perPage, params } = yield select((state) => ({
    page: state.inspect.page,
    perPage: state.inspect.perPage,
    params: state.inspect.params
  }));

  yield put(actions.setLoading(true));
  try {
    const data = yield call(fetchStudies, { page, perPage, ...params });
    yield put(actions.setNewInspects(data.result));
    yield put(actions.setValue('count', data.total_count));
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* updateStudySaga(action) {
  yield put(actions.addLoadingInspect(action.params.studyId));
  yield put(actions.closeInspect(action.params.studyId));
  try {
    const response = yield call(updateStudy, action.params);
    yield put(actions.setNewInspect(response));
  } catch (e) {
    toastErrorMessage(e);
    yield put(actions.updateErrorInspect(action.params.studyId));
  } finally {
    yield put(actions.deleteLoadingInspect(action.params.studyId));
  }
}

export default function* sagaReducer() {
  yield all([
    takeLeading(types.FETCH_C_DEVICES, fetchCDevicesSaga),
    takeLeading(types.FETCH_STUDIES, fetchStudiesSaga),
    takeLeading(types.UPDATE_STUDY, updateStudySaga)
  ]);
}
