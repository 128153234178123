import { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import SearchModal from '@P/store/StoreCombine/combineModal/SearchModal';
import CombineModal from '@P/store/StoreCombine/combineModal/CombineModal';
import { Store } from '@TS/store/store';

function ManualCombine(): JSX.Element {
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isCombineModalOpen, setIsCombineModalOpen] = useState(false);

  const [storesToBeCombined, setStoresToBeCombined] = useState<Store[]>([]);
  const [warningMessage, setWarningMessage] = useState('');

  const openCombineModalWithSearchedStores = useCallback((pids: Store['pid'][], stores: Store[]) => {
    if (pids.length !== stores.length) {
      // 검색에 실패한 매장이 존재하여, 검색 대상과 검색 결과의 개수가 다른 경우
      const pidsFromStores = stores.map((store) => store.pid);
      setWarningMessage(`!WARNING! pid ${pids.filter((pid) => !pidsFromStores.includes(pid)).join(', ')} 검색 실패`);
    } else {
      setWarningMessage('');
    }

    setStoresToBeCombined(stores);
    setIsSearchModalOpen(false);
    setIsCombineModalOpen(true);
  }, []);

  return (
    <>
      <Button variant="outlined" color="primary" onClick={() => setIsSearchModalOpen(true)}>
        수동 병합
      </Button>
      <SearchModal
        isOpen={isSearchModalOpen}
        setIsOpen={setIsSearchModalOpen}
        onEmitResult={openCombineModalWithSearchedStores}
      />
      <CombineModal
        isOpen={isCombineModalOpen}
        setIsOpen={setIsCombineModalOpen}
        stores={storesToBeCombined}
        additionalMessage={warningMessage}
        isMasterPidChangeable
      />
    </>
  );
}
export default ManualCombine;
