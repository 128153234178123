import axios, { AxiosError } from 'axios';
import { toastErrorMessage } from '@FUNC/toast';

const errorMessagesByType = {
  Forbidden: '권한이 없습니다.',
  IntegrityError: '중복된 아이디이거나 잘못된 요청입니다.',
  NotFound: '검색결과가 없습니다.'
} as const;

const errorMessages = {
  InvalidSection: '해당 매장에 대한 203 mapping이 없으므로 section으로 변경할 수 없습니다.'
} as const;

interface CustomError extends Error {
  type: keyof typeof errorMessagesByType;
  message: keyof typeof errorMessages;
}

export function handleErrorWithToast(error: unknown): void {
  let message;

  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError<CustomError>;
    const axiosErrorData = axiosError.response?.data;
    if (axiosErrorData) {
      message =
        errorMessagesByType[axiosErrorData.type] || errorMessages[axiosErrorData.message] || axiosErrorData.message;
    }
  } else if (error instanceof Error) {
    message = error.message;
  }

  toastErrorMessage(message);
}
