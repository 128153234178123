import { GeoGroupIcon, PolygonIcon, CircleIcon } from 'loplat-ui';
import type { GeofenceBase, GeofenceSingle } from '@TS/max/campaigns/area';

import Tooltip from '@F/Tooltip';
import { Wrapper } from './index.styles';

type GeofenceModuleType = {
  type: 'single' | 'group';
  shape?: GeofenceSingle['shape_type'];
  count?: number;
} & GeofenceBase;
const GeofenceModule = ({ type, shape, name, active, count = 1 }: GeofenceModuleType): JSX.Element => (
  <Tooltip title={`${count.toLocaleString()}개 ${active === 0 ? '비활성화' : '활성화'}`}>
    <Wrapper>
      {type === 'group' ? (
        <GeoGroupIcon size={20} />
      ) : shape === 0 ? (
        <PolygonIcon size={20} />
      ) : (
        <CircleIcon size={20} />
      )}
      {name}
    </Wrapper>
  </Tooltip>
);

export default GeofenceModule;
