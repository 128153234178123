import { grayscale800, grayscale100 } from 'loplat-ui';
import styled from 'styled-components';
import { gridSpread, listMargin } from '@P/max/campaigns/utils/styles';

export const TreeWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  span {
    color: ${grayscale800};
    &.title {
      font-weight: bold;
    }
  }

  > li {
    padding: 12px 16px;
    border-bottom: 1px solid ${grayscale100};
  }

  li {
    &::marker {
      color: ${grayscale800};
    }
  }

  ul {
    margin: 8px 0 0 18px;
    li {
      padding-left: 8px;
    }

    &[data-depth='2'] {
      li {
        ${listMargin(3)};
        &::marker {
          content: '-';
        }
      }
    }
    &[data-depth='3'] {
      li::marker {
        content: '>';
      }
    }
  }
`;

export const Row = styled.div`
  ${gridSpread};

  > div,
  span {
    &:first-of-type {
      grid-column: 1/4;
    }
  }
  p.body {
    word-break: break-all;
    margin: 0;
    grid-column: 4/-1;
    text-align: end;
  }
`;
