import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MapSection from '@P/store/ErrorReport/tableSection/detail/MapSection';
import ImageSection from '@P/store/ErrorReport/tableSection/detail/ImageSection';
import ReportSection from '@P/store/ErrorReport/tableSection/detail/ReportSection';
import * as S from './styles';

function Detail({ id, data }) {
  const report = useMemo(() => data.find((element) => element.report_id === id), [id, data]);
  const [newReportLngLat, setNewReportLngLat] = useState(() => ({
    lng: report.report_lng,
    lat: report.report_lat
  }));

  return (
    <S.StyledDetail>
      <MapSection report={report} reportLngLat={newReportLngLat} onEmitReportLngLat={setNewReportLngLat} />
      <ImageSection report={report} />
      <ReportSection report={report} newReportLngLat={newReportLngLat} />
    </S.StyledDetail>
  );
}
export default Detail;

Detail.propTypes = {
  id: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.any).isRequired
};

Detail.defaultProps = {
  id: 0
};
