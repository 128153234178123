import styled, { keyframes } from 'styled-components';

import { TableCell } from '@material-ui/core';

type TableSkeletonCellProps = {
  width: number;
  height: number;
};

export default function TableSkeletonCell({ width, height }: TableSkeletonCellProps): JSX.Element {
  return (
    <TableCell align="center">
      <StyledWrapper>
        <StyledSkeleton width={width} height={height} />
      </StyledWrapper>
    </TableCell>
  );
}

const lineAnimation = keyframes`
0% {
  background-position: -1200px 0;
}
100% {
  background-position: 1200px 0;
}
`;

const StyledSkeleton = styled.span`
  border-radius: 4px;

  background-color: #ffffff;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.08) 0%,
    rgba(0, 0, 0, 0.15) 16.5%,
    rgba(0, 0, 0, 0.08) 33%
  );

  background-size: 1200px 100%;
  animation: ${lineAnimation} 3s infinite;

  ${({ width, height }: { width: number; height: number }) => `
    width: ${width}px;
    height: ${height}px;
`}
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
