import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { managerLocalStorage } from '@/utils/localStorageParser';
import { RouteNode } from '@/routes';

export type AddFavoriteRouteNode = (favoriteRouteNode: RouteNode) => void;
export type RemoveFavoriteRouteNode = (favoriteRouteNode: RouteNode) => void;
export type IsFavoriteRouteNode = (favoriteRouteNode: RouteNode) => boolean;
export type IsOpenedParentRouteNode = (parentRouteNode: RouteNode) => boolean;
export type ToggleOpenedRouteNode = (parentRouteNode: RouteNode) => void;

type SideMenuContextType = {
  favoriteRouteNodes: RouteNode[];
  addFavoriteRouteNode: AddFavoriteRouteNode;
  removeFavoriteRouteNode: RemoveFavoriteRouteNode;
  isFavoriteRouteNode: IsFavoriteRouteNode;
  isOpenedParentRouteNode: IsOpenedParentRouteNode;
  toggleOpenedRouteNode: ToggleOpenedRouteNode;
};

const SideMenuContext = createContext<SideMenuContextType | undefined>(undefined);

type SideMenuContextProviderProps = {
  children: React.ReactNode;
};

export function SideMenuContextProvider({ children }: SideMenuContextProviderProps): JSX.Element {
  const [favoriteRouteNodes, setFavoriteRouteNodes] = useState<RouteNode[]>(
    managerLocalStorage.get(managerLocalStorage.keys.favorite_route_nodes) ?? []
  );
  const [openedMenus, setOpenedMenus] = useState<RouteNode[]>(
    managerLocalStorage.get<RouteNode[]>(managerLocalStorage.keys.opened_menus) ?? []
  );

  const addFavoriteRouteNode: AddFavoriteRouteNode = useCallback((favoriteRouteNode) => {
    setFavoriteRouteNodes((prev) => [...prev, favoriteRouteNode]);
  }, []);

  const removeFavoriteRouteNode: RemoveFavoriteRouteNode = useCallback(
    (favoriteRouteNode) => setFavoriteRouteNodes((prev) => prev.filter((fr) => fr.path !== favoriteRouteNode.path)),
    []
  );

  const isFavoriteRouteNode: IsFavoriteRouteNode = (favoriteRouteNode) =>
    !!favoriteRouteNodes.find((fr) => fr.path === favoriteRouteNode.path);

  const isOpenedParentRouteNode: IsOpenedParentRouteNode = (parentRouteNode: RouteNode) =>
    openedMenus.some((openedParentRouteNode) => openedParentRouteNode.path === parentRouteNode.path);

  const toggleOpenedRouteNode: ToggleOpenedRouteNode = (parentRouteNode: RouteNode) => {
    openedMenus.some((openedParentRouteNode) => openedParentRouteNode.path === parentRouteNode.path)
      ? setOpenedMenus((prev) =>
          prev.filter((openedParentRouteNode) => openedParentRouteNode.path !== parentRouteNode.path)
        )
      : setOpenedMenus((prev) => [...prev, parentRouteNode]);
  };

  useEffect(() => {
    managerLocalStorage.set(managerLocalStorage.keys.favorite_route_nodes, favoriteRouteNodes);
    managerLocalStorage.set(managerLocalStorage.keys.opened_menus, openedMenus);
  }, [favoriteRouteNodes, openedMenus]);

  return (
    <SideMenuContext.Provider
      value={{
        isFavoriteRouteNode,
        addFavoriteRouteNode,
        removeFavoriteRouteNode,
        favoriteRouteNodes,
        isOpenedParentRouteNode,
        toggleOpenedRouteNode
      }}
    >
      {children}
    </SideMenuContext.Provider>
  );
}

export const useSideMenu = (): SideMenuContextType => {
  const context = useContext(SideMenuContext);

  if (context === undefined) {
    throw new Error('favorite routes context must be used in SideMenuContextProvider');
  }

  return context;
};
