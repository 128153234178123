import { useState } from 'react';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import { Button, Checkbox, Grid } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch } from 'react-redux';
import { toastErrorMessage } from '@FUNC/toast';
import { useInput } from '@HOOK/';
import { actions } from '@/redux/admin/userManager/state';
import { roleOptions } from '../options';
import * as S from './styles';

function CreateUser() {
  const classes = S.useStyles();

  const [userName, setUserName] = useInput('');
  const UserNameInput = <TextInputForm name="UserName" label="아이디" onChange={setUserName} value={userName} />;
  const [name, setName] = useInput('');
  const NameInput = <TextInputForm name="Name" label="이름" onChange={setName} value={name} />;
  const [password, setPassword] = useInput('');
  const PasswordInput = (
    <TextInputForm name="Password" label="비밀번호" onChange={setPassword} value={password} type="password" />
  );
  const [email, setEmail] = useInput('');
  const EmailInput = <TextInputForm name="Email" label="이메일" onChange={setEmail} value={email} />;

  const [role, setRole] = useInput('일반 사용자');
  const RoleSelect = (
    <SelectForm
      label="권한"
      options={Object.keys(roleOptions).slice(1)}
      value={role}
      onSelect={setRole}
      name="RoleSelect"
    />
  );
  const [isMember, setIsMember] = useState(false);
  const IsMemberOption = (
    <FormControlLabel
      control={<Checkbox color="primary" checked={isMember} onChange={() => setIsMember(!isMember)} />}
      label="로플랫 직원"
    />
  );

  const isValidInputs = () => {
    if (!userName.trim()) {
      toastErrorMessage('아이디는 필수로 입력되어야 합니다.');
      return false;
    }
    if (!name.trim()) {
      toastErrorMessage('이름은 필수로 입력되어야 합니다.');
      return false;
    }
    const passwordRegex = /^.{8,20}$/;
    if (!passwordRegex.test(password)) {
      toastErrorMessage('비밀번호는 8자 이상 입력되어야 합니다.');
      return false;
    }
    return true;
  };

  const dispatch = useDispatch();
  const createNewUser = () => {
    if (!isValidInputs()) return;

    dispatch(
      actions.createUser({
        id: userName,
        userName: name,
        password,
        role: roleOptions[role],
        isLoplat: isMember,
        email: email || null
      })
    );
  };

  return (
    <S.StyledCreateUser>
      <div className={classes.title}>사용자 생성</div>
      <Grid container spacing={2} className={classes.wrapper}>
        <Grid item xs={2}>
          {UserNameInput}
        </Grid>
        <Grid item xs={2}>
          {NameInput}
        </Grid>
        <Grid item xs={2}>
          {PasswordInput}
        </Grid>
        <Grid item xs={2}>
          {EmailInput}
        </Grid>
        <Grid item xs={2}>
          {RoleSelect}
        </Grid>
        <Grid item xs={2}>
          {IsMemberOption}
        </Grid>
      </Grid>
      <S.ButtonRow>
        <Button variant="contained" color="primary" onClick={createNewUser}>
          생성하기
        </Button>
      </S.ButtonRow>
    </S.StyledCreateUser>
  );
}
export default CreateUser;

CreateUser.propTypes = {};
