import { useCallback, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Checkbox } from 'loplat-ui';
import { cookSitesApi } from '@API/manager/cook';
import { useInput } from '@HOOK/';
import SelectForm from '@F/materialUI/SelectForm';
import { provinces, districts } from '@/static/data/areas';
import * as S from './styles';

export const PIN_TIERS = [1, 2, 3, 4];
const INITIAL_PIN_TIERS_STATE = PIN_TIERS.map((p) => ({ name: String(p), checked: true }));

function SiteMapToolbar({ onClickLoadListButton, onClickLoadMarkerButton, onSelectAddr3 }) {
  const [isLoading, setIsLoading] = useState(false);
  const [perPage, setPerPage] = useInput('500');

  // 우선순위 설정
  // TODO: LL-193. '전체'를 포함한 CheckboxGroup 컴포넌트 만들기
  const [pinTierChecks, setPinTierChecks] = useState(INITIAL_PIN_TIERS_STATE);
  const isAllChecked = pinTierChecks.every((data) => data.checked);
  const handleCheckPinTier = ({ target: { name, checked } }) => {
    setPinTierChecks((prev) => prev.map((pinTier) => (pinTier.name === name ? { name, checked } : pinTier)));
  };
  const handleCheckAllPinTiers = () => {
    setPinTierChecks((prev) =>
      isAllChecked ? prev.map((p) => ({ ...p, checked: false })) : prev.map((p) => ({ ...p, checked: true }))
    );
  };

  // 주소 설정
  const [addr1, setAddr1] = useInput('');
  const [addr2, setAddr2, clearAddr2] = useInput('');
  const [addr3, setAddr3] = useState('');

  const [stringifiedAddr3, setStringifiedAddr3] = useState('');
  const [addr3Options, setAddr3Options] = useState([]);
  const stringifiedAddr3Options = useMemo(
    () =>
      addr3Options.map(
        (option) => `${option.addr3}: ${option.sites} (${option.complex ?? option.cids}) (${option.pin_tier_1})`
      ),
    [addr3Options]
  );

  const setValueOfTAddr3 = useCallback((event) => {
    const { value } = event.target;
    setAddr3(value.split(':')[0]);
    setStringifiedAddr3(value);
  }, []);

  const getAddr3Options = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await cookSitesApi.getAddr3List({ addr1, addr2 });
      const { data } = response;
      setAddr3Options([{ addr3: '전체', ...data.counts }, ...data.items]);
    } finally {
      setIsLoading(false);
    }
  }, [addr1, addr2]);

  const resetAddr3 = useCallback(() => {
    setAddr3('');
    setStringifiedAddr3('');
    setAddr3Options([]);
  }, []);

  // addr1 선택시
  useEffect(() => {
    clearAddr2();
  }, [addr1, clearAddr2]);

  // addr2 선택시
  useEffect(() => {
    if (addr2) {
      resetAddr3();
      getAddr3Options();
    }
  }, [addr2, resetAddr3, getAddr3Options]);

  // addr3 선택시
  useEffect(() => {
    if (addr3) {
      onSelectAddr3(addr1, addr2, addr3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addr3, onSelectAddr3]);

  // Toolbar의 모든 params
  const toolbarParams = {
    perPage: Number(perPage),
    pinTiers: pinTierChecks.filter((pinTier) => pinTier.checked).map((pinTier) => Number(pinTier.name)),
    addresses: { addr1, addr2, addr3 }
  };

  return (
    <S.StyledSiteMapToolbar>
      <S.Row>
        <S.SelectFormGroup>
          <SelectForm label="광역시/도" options={provinces} value={addr1} onSelect={setAddr1} />
          <SelectForm label="시/군" options={districts[addr1]} value={addr2} disabled={!addr1} onSelect={setAddr2} />
          <SelectForm
            label={`행정구역${isLoading ? '(로딩 중...)' : ''}`}
            options={stringifiedAddr3Options}
            value={stringifiedAddr3}
            disabled={!addr2 || isLoading}
            onSelect={setValueOfTAddr3}
          />
        </S.SelectFormGroup>
        <fieldset>
          <legend>우선 순위</legend>
          <S.Row>
            <Checkbox label="전체" name="전체" checked={isAllChecked} onChange={handleCheckAllPinTiers} />
            {pinTierChecks.map(({ name, checked }) => (
              <Checkbox label={name} name={name} key={name} checked={checked} onChange={handleCheckPinTier} />
            ))}
          </S.Row>
        </fieldset>
        <SelectForm
          label="개수"
          options={['100', '500', '1000']}
          value={perPage}
          onSelect={setPerPage}
          name="CountSelect"
        />
      </S.Row>

      <S.Row>
        <Button variant="outlined" color="primary" onClick={() => onClickLoadListButton(toolbarParams)}>
          리스트 불러오기
        </Button>
        <Button variant="contained" color="primary" onClick={() => onClickLoadMarkerButton(toolbarParams)}>
          마커 불러오기
        </Button>
      </S.Row>
    </S.StyledSiteMapToolbar>
  );
}
export default SiteMapToolbar;

SiteMapToolbar.propTypes = {
  onClickLoadMarkerButton: PropTypes.func.isRequired,
  onSelectAddr3: PropTypes.func.isRequired
};
