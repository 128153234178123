import styled from 'styled-components';

export const StyledImageSection = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Images = styled.div`
  width: 100%;

  img {
    width: 45%;
    max-height: 300px;
    padding: 0 1px;
    cursor: pointer;
  }
`;
