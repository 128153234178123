import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as firebase from 'firebase/app';
import { GridList, GridListTile } from '@material-ui/core';
import Loading from '@F/Loading';
// TODO: ModalImage 삭제;
import Lightbox from 'react-image-lightbox';
import { toastErrorMessage } from '@FUNC/toast';
import { useFetch } from '@HOOK/';
import { cashplaceStudiesApi } from '@API/manager/cashplace';
import { FIREBASE_COOK_URL, FIREBASE_CASHPLACE_URL } from '@/config';
import 'react-image-lightbox/style.css';
import * as S from './styles';

function Images({ detail }) {
  const [images, setImages] = useState([]);
  const [viewingImages, setViewingImages] = useState([]);
  const { callApi, isLoading } = useFetch();
  const [imagesLoading, setImagesLoading] = useState(false);
  const [imageViewerOpen, setImageViewerOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    if (detail) {
      if (detail.fingerprint && detail.fingerprint.length > 0) {
        const tempLinks = new Set();

        const link = `${FIREBASE_COOK_URL}/loplatcook/images`;

        detail.fingerprint.forEach((item) => {
          if (detail.collector === 'cashplace' && item.study_id) {
            getCashPlaceImage(item.study_id);
          } else if (detail.collector !== 'cashplace') {
            const imgLink = `${link}/${moment(item.created_at).format('YYYY-MM-DD')}/${item.collector}/img_fp`;

            [1, 2, 3].forEach((i) => {
              tempLinks.add(`${imgLink}_${item.fpid}_${i}.jpg`);
            });
          }
        });
        getImages([...tempLinks]);
      }
    }
  }, [detail]);

  async function getCashPlaceImage(studyId) {
    const validImages = [];
    try {
      setImagesLoading(true);
      const result = await callApi(cashplaceStudiesApi.getStudyImageNames, { studyId });

      if (result) {
        result.data.image_names.map(async (imgName) => {
          const image = firebase.storage().refFromURL(`${FIREBASE_CASHPLACE_URL}/images/${imgName}`);
          const url = await image.getDownloadURL();
          if (url) {
            validImages.push(url);
            validImages.sort((a, b) => {
              const aDate = a.split('%2F')[2];
              const bDate = b.split('%2F')[2];
              return new Date(bDate) - new Date(aDate);
            });
            setImages(validImages);
            setViewingImages(validImages.slice(0, 3));
          }
        });
      }
    } catch (e) {
      toastErrorMessage(e.message);
    } finally {
      setImagesLoading(false);
    }
  }
  async function getImages(linkList) {
    let tempImage = [];
    const validImages = [];
    setImagesLoading(true);
    if (linkList && linkList.length > 0) {
      linkList.map(async (img) => {
        const image = firebase.storage().refFromURL(img);
        try {
          tempImage = await image.getDownloadURL();
        } catch (exception) {
          tempImage = undefined;
        }
        if (tempImage !== undefined) {
          validImages.push(tempImage);
          validImages.sort((a, b) => {
            const aDate = a.split('%2F')[2];
            const bDate = b.split('%2F')[2];
            return new Date(bDate) - new Date(aDate);
          });
          setImages(validImages);
          setViewingImages(validImages.slice(0, 3));
        }
      });
      setImagesLoading(false);
    }
  }
  function imageClicked(image) {
    setImageViewerOpen(true);
    setImageIndex(images.findIndex((item) => item === image));
  }

  return (
    <S.StyledStoreInnerImages>
      {(isLoading || imagesLoading) && <Loading />}
      {images && images.length > 0 ? (
        <GridList cellHeight={500} cols={images.length > 2 ? 3 : 2}>
          {viewingImages.map((image) => (
            <GridListTile key={image}>
              <img src={image} onClick={() => imageClicked(image)} alt="Image-Viewer" />
            </GridListTile>
          ))}
        </GridList>
      ) : (
        <h1>이미지가 없거나 불러오는 데에 실패했습니다.</h1>
      )}
      {imageViewerOpen && (
        <Lightbox
          reactModalStyle={{ overlay: { zIndex: 2000 } }}
          mainSrc={images[imageIndex]}
          nextSrc={images[(imageIndex + 1) % images.length]}
          prevSrc={images[(imageIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setImageViewerOpen(false)}
          onMovePrevRequest={() => setImageIndex((imageIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setImageIndex((imageIndex + images.length + 1) % images.length)}
          imageCaption={images[imageIndex]}
          enableZoom
          imageTitle={`${images.length}개 중 ${imageIndex + 1}번째 사진`}
        />
      )}
    </S.StyledStoreInnerImages>
  );
}
export default Images;

Images.propTypes = {
  detail: PropTypes.objectOf(PropTypes.any).isRequired
};
