import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Article, ArticleForm, ArticlesParams } from '@TS/homepage/article';

type ArticleStore = {
  status: ApiStatus;
  error: null | Error;
  data: Article[];
  totalCount: number;
  params: ArticlesParams;
};

const initialState: ArticleStore = {
  status: 'idle',
  error: null,
  data: [],
  totalCount: 0,
  params: {
    page: 1,
    perPage: 20,
    order: ['updated_at DESC'],
    status: ['PENDING', 'PUBLISH']
  }
};

// slice

const slice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    setParams: (state, action: PayloadAction<ArticlesParams>) => {
      state.params = action.payload;
    },
    // create
    createArticleSuccess: (state, action: PayloadAction<Article>) => {
      const article = action.payload;

      state.data.unshift(article);
    },
    // read
    fetchArticlesStart: (state) => {
      state.status = 'loading';
    },
    fetchArticlesSuccess: (state, action: PayloadAction<{ data: Article[]; totalCount: number }>) => {
      const { data, totalCount } = action.payload;

      state.status = 'success';
      state.data = data;
      state.totalCount = totalCount;
    },
    fetchArticlesFailure: (state, action: PayloadAction<Error>) => {
      state.status = 'failure';
      state.error = action.payload;
    },
    // update
    updateArticleSuccess: (state, action: PayloadAction<{ id: number; data: Partial<Article> }>) => {
      const { id, data } = action.payload;

      const updatedIndex = state.data.findIndex((article) => article.id === id);
      if (updatedIndex === -1) return;

      const updated = { ...state.data[updatedIndex], ...data };

      state.data[updatedIndex] = updated;
    },
    // delete
    deleteArticleSuccess: (state, action: PayloadAction<number>) => {
      const id = action.payload;
      state.data = state.data.filter((article) => article.id !== id);
    },

    // createSlice에서 saga에서 사용할 action을 생성할 수 있도록 작성했습니다.
    createArticle: (state, action: PayloadAction<{ data: ArticleForm; callback: (isSuccess: boolean) => void }>) => {},
    fetchArticles: (state, action: PayloadAction<ArticlesParams | undefined>) => {},
    updateArticle: (
      state,
      action: PayloadAction<{
        id: number;
        data: Partial<Article>;
        callback: (isSuccess: boolean) => void;
      }>
    ) => {},
    deleteArticle: (state, action: PayloadAction<{ id: number; callback: (isSuccess: boolean) => void }>) => {},
    changePage: (state, action: PayloadAction<number>) => {}
  }
});

export const { actions } = slice;

export default slice;
