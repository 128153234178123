import Wrapper from '@F/materialUI/Wrapper';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useState } from 'react';
import styled from 'styled-components';
import { NoticeStatus, NoticeType } from '@TS/max/manageNotice';

import { NOTICE_STATUS_TEXT, NOTICE_TYPE_TEXT } from '../constant';
import { useManageNotice } from '../useManageNotice';

export default function NoticesFilter(): JSX.Element {
  const { fetchNotices, fetchApiStatus } = useManageNotice();
  const [type, setType] = useState<NoticeType | ''>('');
  const [status, setStatus] = useState<NoticeStatus | ''>('');

  const handleChangeType = (e: any) => setType(e.target.value);
  const handleChangeStatus = (e: any) => setStatus(e.target.value);
  const submit = () => fetchNotices({ type: type === '' ? null : type, status: status === '' ? null : status });

  return (
    <Wrapper>
      <StyledNoticeFilter container spacing={2}>
        <Grid item xs={6} sm={3} lg={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="notice-type">카테고리</InputLabel>
            <Select labelId="notice-type" name="type" label="카테고리" value={type} onChange={handleChangeType}>
              <MenuItem value="">전체</MenuItem>
              {Object.entries(NOTICE_TYPE_TEXT).map(([type, typeText]) => (
                <MenuItem value={type} key={type}>
                  {typeText}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3} lg={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="notice-status">상태</InputLabel>
            <Select name="status" label="상태" value={status} onChange={handleChangeStatus}>
              <MenuItem value="">전체</MenuItem>
              {Object.entries(NOTICE_STATUS_TEXT).map(([status, statusText]) => (
                <MenuItem value={status} key={status}>
                  {statusText}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2} lg={1}>
          <Button
            onClick={submit}
            color="primary"
            variant="contained"
            size="large"
            disabled={fetchApiStatus === 'loading'}
            style={{ height: '55px' }}
            fullWidth
          >
            조회
          </Button>
        </Grid>
      </StyledNoticeFilter>
    </Wrapper>
  );
}

const StyledNoticeFilter = styled(Grid)`
  margin-bottom: 20px;
  padding: 10px 20px;
`;
