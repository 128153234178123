import { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import ModalWrapper from '@F/modal/ModalWrapper';
import { useDispatch } from 'react-redux';
import useInput from '@HOOK/useInput';
import { useAppSelector } from '@HOOK/useSelector';
import { actions } from '@/redux/cashplace/user/state';
import { categories } from './options';
import * as S from './styles';

type DetailProps = {
  id?: number;
};

function Detail({ id }: DetailProps): JSX.Element {
  const { lastTitle, lastDescription } = useAppSelector((state) => ({
    lastTitle: state.user.title,
    lastDescription: state.user.description
  }));
  const classes = S.useStyles();

  const [title, setTitle] = useInput(lastTitle);
  const TitleInput = <TextInputForm name="TitleInput" label="제목" value={title} onChange={setTitle} />;

  const [description, setDescription] = useInput(lastDescription);
  const DescriptionInput = (
    <TextInputForm name="DescriptionInput" label="내용" value={description} onChange={setDescription} />
  );

  const [credit, setCredit] = useInput('');
  const CreditInput = (
    <TextInputForm
      name="CreditInput"
      label="지급 크레딧"
      value={credit}
      onChange={setCredit}
      error={credit.slice(-1) !== '0'}
      helperText="10원 단위만 입력가능합니다."
    />
  );

  const [category, setCategory] = useState<string>('이벤트');
  const CategorySelect = (
    <SelectForm
      label="종류"
      options={Object.keys(categories)}
      value={category}
      onSelect={(e): void =>
        setCategory((prevState) => {
          if (typeof e.target.value === 'string') return e.target.value;
          return prevState;
        })
      }
      name="CategorySelect"
    />
  );

  const [isModalOpened, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const updateUser = () => {
    dispatch(
      actions.updateUser({
        credit,
        title,
        message: description,
        kind: categories[category],
        userId: id
      })
    );
    dispatch(actions.setValue('title', title));
    dispatch(actions.setValue('description', description));
  };

  return (
    <S.StyledDetail>
      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {TitleInput}
        </Grid>
      </S.Row>
      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {DescriptionInput}
        </Grid>
      </S.Row>
      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {CreditInput}
        </Grid>
        <Grid item xs>
          {CategorySelect}
        </Grid>
      </S.Row>
      <S.ButtonRow>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (credit.slice(-1) !== '0') return;
            setIsModalOpen(true);
          }}
        >
          지급하기
        </Button>
      </S.ButtonRow>
      <ModalWrapper open={isModalOpened} setOpen={setIsModalOpen} confirmText="지급하기" confirm={updateUser}>
        <>
          <h2>아래 내용으로 리워드를 지급합니까?</h2>
          <p>
            제목: <strong>{title}</strong>
          </p>
          <p>
            내용: <strong>{description}</strong>
          </p>
          <p>
            지급 크레딧: <strong>{credit}</strong>
          </p>
          <p>
            종류: <strong>{category}</strong>
          </p>
        </>
      </ModalWrapper>
    </S.StyledDetail>
  );
}
export default Detail;
