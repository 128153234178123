import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import * as S from './styles';

function ResponseAlert({ open, handleClose, message, error }) {
  return (
    <S.StyledResponseAlert>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={error ? 'error' : 'success'}>
          {message}
        </Alert>
      </Snackbar>
    </S.StyledResponseAlert>
  );
}
export default ResponseAlert;

ResponseAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  error: PropTypes.bool
};

ResponseAlert.defaultProps = {
  message: '성공하였습니다.',
  error: true
};
