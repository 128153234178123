import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { PlaceTodo } from '@TS/cook/placetodo';
import { PlaceTodoSortableField } from '@TS/cook/api/placetodos';
import { useSelector, useDispatch } from 'react-redux';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, TableCell } from '@material-ui/core';
import { selectStatus, selectUncollectedComplexPlaceTodos } from '../redux/selector';
import { actions } from '../redux/slice';

import TableTitleCell from './TableTitleCell';
import TableItemComponent from './TableItem';
import TableSkeleton from './TableSkeleton';
/*
  TODO : 현재는 params.orders 필드에 대해서는 snake_case로 프론트에서 처리하는 중.
         이유는, camel params를 snake params로 변환해주는 camelObjToSnakeObj 유틸함수에서 필드명에 대해서만 변환과정이 이루어지기 때문.
         리펙토링 과정에서 camelObjToSnakeObj 함수를 deep하게 transform해주는 함수로 바꿀 필요가 있음.
*/

export type TableItemStatus = '미검수' | '승인' | '거부' | '폐점 처리 중';
export interface TableItem {
  id: number;
  collapsed: boolean;
  status: TableItemStatus;
  placetodo: PlaceTodo;
}

const TITLES: {
  label: string;
  orders?: {
    fields: CamelToSnake<PlaceTodoSortableField>[];
    defaultOrderType: OrderType;
  };
}[] = [
  { label: '토글' },
  {
    label: '상태',
    orders: {
      fields: ['report_status'],
      defaultOrderType: 'DESC'
    }
  },
  {
    label: 'Site ID',
    orders: {
      fields: ['site_id'],
      defaultOrderType: 'DESC'
    }
  },
  {
    label: '복합몰(지점)',
    orders: {
      fields: ['complex_name', 'complex_branch_name'],
      defaultOrderType: 'DESC'
    }
  },
  {
    label: '장소명(지점)',
    orders: {
      fields: ['name', 'branch_name'],
      defaultOrderType: 'DESC'
    }
  },
  {
    label: 'Store ID',
    orders: {
      fields: ['store_id'],
      defaultOrderType: 'DESC'
    }
  },
  {
    label: '층',
    orders: {
      fields: ['floor'],
      defaultOrderType: 'DESC'
    }
  },
  {
    label: '수집자',
    orders: {
      fields: ['charge'],
      defaultOrderType: 'DESC'
    }
  },
  {
    label: '할당일시',
    orders: {
      fields: ['created_at'],
      defaultOrderType: 'DESC'
    }
  },
  {
    label: '수집결과',
    orders: {
      fields: ['charge_result'],
      defaultOrderType: 'DESC'
    }
  },
  {
    label: '미수집사유',
    orders: {
      fields: ['report_reason'],
      defaultOrderType: 'DESC'
    }
  }
];

export default function UnCollectedComplexPlaceTodosTable(): JSX.Element {
  const [tableItems, setTableItems] = useState<TableItem[]>([]);
  const [isTableItemsCollapsed, setIsTableItemsCollapsed] = useState(true);

  const dispatch = useDispatch();
  const unCollectedComplexPlaceTodos = useSelector(selectUncollectedComplexPlaceTodos);
  const status = useSelector(selectStatus);

  const toggleTableItemsCollapsed = useCallback(() => setIsTableItemsCollapsed((prev) => !prev), []);
  const toggleTableItemCollapsed = useCallback((id: TableItem['id']) => {
    setTableItems((prev) => {
      const targetIndex = prev.findIndex((tableItem) => tableItem.id === id);

      const next = prev.map((item) => ({ ...item }));

      next[targetIndex].collapsed = !next[targetIndex].collapsed;

      return next;
    });
  }, []);

  const changeTableItemStatus = useCallback(
    (id: number) => (status: TableItemStatus) => {
      setTableItems((prev) => {
        const targetIndex = prev.findIndex((tableItem) => tableItem.id === id);

        const next = prev.map((item) => ({ ...item }));

        next[targetIndex].status = status;

        return next;
      });
    },
    []
  );

  useEffect(() => {
    dispatch(actions.reset());
    dispatch(actions.fetchUnCollectedComplexPlaceTodos());
  }, [dispatch]);

  useEffect(() => {
    setTableItems((prev) => prev.map((tableItem) => ({ ...tableItem, collapsed: isTableItemsCollapsed })));
  }, [isTableItemsCollapsed]);

  useEffect(() => {
    // MEMO : 타입스크립트 map 함수 관련 프론트 회의에서 논의 예정
    // const tableItems = unCollectedComplexPlaceTodos.map((placetodo) => ({
    //   id: placetodo.todoId,
    //   collapsed: true,
    //   status: '미검수',
    //   placetodo
    // }));

    setTableItems(
      unCollectedComplexPlaceTodos.map((placetodo) => ({
        id: placetodo.todoId,
        collapsed: true,
        status: '미검수',
        placetodo
      }))
    );
  }, [unCollectedComplexPlaceTodos]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {TITLES.map(({ label, orders }) => (
              <TableTitleCell
                key={label}
                title={label}
                orders={orders}
                isTableItemsCollapsed={isTableItemsCollapsed}
                toggleTableItemsCollapsed={toggleTableItemsCollapsed}
              />
            ))}
          </TableRow>
        </TableHead>

        {status === 'loading' ? (
          <TableSkeleton />
        ) : (
          <TableBody>
            {tableItems.length ? (
              tableItems.map((tableItem) => (
                <TableItemComponent
                  key={tableItem.id}
                  item={tableItem}
                  toggleCollapsed={toggleTableItemCollapsed}
                  changeStatus={changeTableItemStatus(tableItem.id)}
                />
              ))
            ) : (
              <TableRow>
                <TableCell align="center" component="td" scope="row" colSpan={11}>
                  <StyledNoResult>검색 결과가 없습니다.</StyledNoResult>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

const StyledNoResult = styled.p`
  font-size: 30px;
  font-weight: bold;
`;
