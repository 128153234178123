import { useCallback, useEffect, useMemo } from 'react';
import PaginationRow from '@F/table/PaginationRow/index';
import EnhancedTable from '@F/table/Table/index';
import { createHeadCells } from '@F/table/Table/functions';
import { shallowEqual, useDispatch } from 'react-redux';
import StatusLabel from '@F/StatusLabel';
import Blue from '@material-ui/core/colors/blue';
import Red from '@material-ui/core/colors/red';
import Grey from '@material-ui/core/colors/grey';
import Yellow from '@material-ui/core/colors/yellow';
import { Study } from '@TS/cashplace/study';
import { Column } from '@TS/columns';
import { useAppSelector } from '@HOOK/useSelector';
import { actions } from '@/redux/cashplace/inspect/state';
import Detail from '../Detail';
import * as S from './styles';

const columns: Column[] = [
  { name: 'state', label: '상태' },
  { name: 'id', label: 'STUDY ID', isImportant: true },
  { name: 'siteId', label: 'SITE ID' },
  { name: 'userId', label: 'USER ID' },
  { name: 'placeName', label: '장소명' },
  { name: 'floor', label: '층수' },
  { name: 'category', label: '카테고리' },
  { name: 'phone', label: '전화번호' },
  { name: 'address', label: '주소' },
  { name: 'lux', label: 'LUX' },
  { name: 'credit', label: '크레딧(적립금)' },
  { name: 'device', label: '기종' },
  { name: 'os', label: 'OS' },
  { name: 'isCompleted', label: '완료여부' },
  { name: 'reviewedDate', label: '검수일시' },
  { name: 'createdDate', label: '생성일시' }
];

function TableSection(): JSX.Element {
  const { page, perPage, count, studies, openedStudyIds, loadingStudyIds } = useAppSelector(
    (state) => ({
      page: state.inspect.page,
      perPage: state.inspect.perPage,
      count: state.inspect.count,
      studies: state.inspect.studies,
      openedStudyIds: state.inspect.openedStudyIds,
      loadingStudyIds: state.inspect.loadingStudyIds
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const setPage = useCallback(
    (newPage) => {
      if (page === newPage) dispatch(actions.fetchInspects());
      else dispatch(actions.setPage(newPage));
    },
    [dispatch, page]
  );
  useEffect(() => {
    dispatch(actions.fetchInspects());
  }, [dispatch, page]);

  const headCells = columns.map((column) => createHeadCells(column.name, column.label));

  const rows = useMemo(
    () =>
      studies?.map((item: Study) => ({
        state: {
          value: item.study_log.state,
          component: loadingStudyIds.includes(item.study_log.study_id)
            ? formatStateLabel(null)
            : formatStateLabel(item.study_log.state)
        },
        id: item.study_log.study_id,
        siteId: item.study_log.site_id,
        userId: item.study_log.user_id,
        placeName: item.site.name,
        floor: item.study_log.floor,
        category: item.site.refined_category,
        phone: item.site.phone,
        address: item.site.addr_road,
        lux: item.study_log.c_lux,
        credit: item.slot && item.credit_log ? `${item.slot.credit}(${item.credit_log.credit})` : '',
        device: item.device,
        os: item.os,
        isCompleted: item.slot?.state === 1 ? 'X' : 'O',
        reviewedDate: item.study_log.inspected_at,
        createdDate: item.study_log.created_at
      })),
    [studies, loadingStudyIds]
  );

  const setOpenedStudyIds = useCallback(
    (newOpenedStudyIds) => {
      dispatch(actions.setValue('openedStudyIds', newOpenedStudyIds));
    },
    [dispatch]
  );

  return (
    <S.StyledTableSection>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} />
      <EnhancedTable
        rows={rows || []}
        headCells={headCells}
        initialOrder="desc"
        initialOrderBy="carbs"
        isCheckable={false}
        isCollapsible
        opened={openedStudyIds}
        setOpened={setOpenedStudyIds}
      >
        <Detail data={studies} />
      </EnhancedTable>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} reverse />
    </S.StyledTableSection>
  );
}
export default TableSection;

function formatStateLabel(status: number | null | string): React.ReactNode {
  switch (status) {
    case -1:
      return (
        <StatusLabel color={Red[800]} backgroundColor={Red[200]}>
          승인취소
        </StatusLabel>
      );
    case 1:
      return (
        <StatusLabel color={Blue[800]} backgroundColor={Blue[200]}>
          승인
        </StatusLabel>
      );
    case 0:
      return (
        <StatusLabel color={Grey[800]} backgroundColor={Grey[200]}>
          미승인
        </StatusLabel>
      );
    case 'error':
      return (
        <StatusLabel color={Red[600]} backgroundColor={Yellow[600]}>
          오류
        </StatusLabel>
      );
    default:
      return (
        <StatusLabel color={Grey[800]} backgroundColor={Grey[200]}>
          <strong>처리중...</strong>
        </StatusLabel>
      );
  }
}
