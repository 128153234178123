import { useCallback, useEffect, useMemo, useState } from 'react';
import EnhancedTable from '@F/table/Table';
import Detail from '@P/store/StoreManager/tableSection/detail/Detail';
import Button from '@material-ui/core/Button';
import CustomizingColumnsDialog from '@P/store/StoreManager/dialogs/CustomizingColumnsDialog';
import { toJS } from 'mobx';
import getCategoryName from '@P/store/StoreManager/tableSection/TableSection/getCategoryName';
import moment from 'moment';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PaginationRow from '@F/table/PaginationRow';
import { actions } from '@/redux/store/storeManager/state';
import { valuesStore } from '@/mobX/values';
import * as S from './styles';

function TableSection() {
  const dispatch = useDispatch();

  const { page, perPage, count, stores, fetchType } = useSelector((state) => state.storeManager, shallowEqual);
  const { openedStoreIds } = useSelector((state) => state.storeManager);

  const setOpenedStoreIds = useCallback(
    (newOpenedStoreIds) => {
      dispatch(actions.setValue('openedStoreIds', newOpenedStoreIds));
    },
    [dispatch]
  );

  const setPage = useCallback(
    (newPage) => {
      if (page === newPage) dispatch(actions.fetchStores(fetchType));
      else dispatch(actions.setPage(newPage));
    },
    [dispatch, fetchType, page]
  );

  useEffect(() => {
    dispatch(actions.fetchStores(fetchType));
  }, [dispatch, page]); // eslint-disable-line react-hooks/exhaustive-deps

  const [open, setOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState({
    id: true,
    complexID: true,
    brandID: true,
    name: true,
    branchName: true,
    floor: true,
    category: true,
    categoryDetail: false,
    categoryCode: true,
    collector: true,
    timestamp: true,
    clientCode: false,
    inspector: true,
    phone: false
  });

  function createHeadCells(id, label, numeric = false, disablePadding = false) {
    return {
      id,
      numeric,
      disablePadding,
      label,
      hide: !selectedColumns[id]
    };
  }

  function createRows(
    id,
    complexID,
    brandID,
    name,
    branchName,
    floor,
    category,
    categoryDetail,
    categoryCode,
    collector,
    timestamp,
    clientCode,
    inspector,
    phone,
    fingerprint
  ) {
    return {
      id: {
        value: id,
        component: <S.IdText emphasize={fingerprint?.length >= 2}>{id}</S.IdText>
      },
      complexID: complexID || '',
      brandID: brandID || '',
      name: name || '',
      branchName: branchName || '',
      floor: floor || '',
      category: categoryCode ? getCategoryName(categoryCode, toJS(valuesStore.categories.manager)) : '',
      categoryDetail: categoryDetail || '',
      categoryCode: categoryCode || '',
      collector: collector || '',
      timestamp: timestamp ? moment(timestamp).format('YYYY-MM-DD HH:mm:ss') : '',
      clientCode: clientCode || '',
      inspector: inspector || '',
      phone: phone || ''
    };
  }

  const [headCells, setHeadCells] = useState([
    createHeadCells('id', 'STORE ID (PID)'),
    createHeadCells('complexID', 'Complex ID (CID)'),
    createHeadCells('brandID', 'Brand ID (BID)'),
    createHeadCells('name', '장소명 (name)'),
    createHeadCells('branchName', '지점명 (branch_name)'),
    createHeadCells('floor', '층수 (floor)'),
    createHeadCells('category', '카테고리 (category)'),
    createHeadCells('categoryDetail', '카테고리 (상세)'),
    createHeadCells('categoryCode', '카테고리 코드(category_code)'),
    createHeadCells('collector', '수집자 (collector)'),
    createHeadCells('timestamp', '생성 시간 (timestamp)'),
    createHeadCells('clientCode', 'CLIENT CODE)'),
    createHeadCells('inspector', '검수자 (inspector)'),
    createHeadCells('phone', 'PHONE')
  ]);

  const customizingColumnsButton = (
    <Button key="customizingColumnsButton" variant="outlined" color="primary" onClick={() => setOpen(true)}>
      column 보이기/숨기기
    </Button>
  );

  const rows = useMemo(
    () =>
      stores.map((item) =>
        createRows(
          item.pid,
          item.cids,
          item.bids,
          item.name,
          item.branch_name,
          item.floor,
          item.category,
          item.category_sub,
          item.category_code,
          item.collector,
          item.created_at,
          item.client_code,
          item.qa_by,
          item.phone,
          item.fingerprint
        )
      ),
    [stores]
  );

  const refinedRows = useMemo(() => {
    const updatedColumns = Object.entries(selectedColumns)
      .filter((element) => !!element[1])
      .map((element) => element[0]);

    return rows.map((row) => {
      const updatedRow = {};
      updatedColumns.forEach((column) => {
        updatedRow[column] = row[column];
      });
      return updatedRow;
    });
  }, [rows, selectedColumns]);

  useEffect(() => {
    const updatedHeadCells = headCells.map((cell) => ({
      ...cell,
      hide: !selectedColumns[cell.id]
    }));
    setHeadCells(updatedHeadCells);
  }, [selectedColumns]);

  return (
    <S.StyledStoreTable>
      <CustomizingColumnsDialog
        open={open}
        setOpen={setOpen}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
      />
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} />
      <EnhancedTable
        titleText={['', customizingColumnsButton]}
        headCells={headCells.filter((cell) => cell.hide === false)}
        // TODO: 성능 개선
        rows={refinedRows}
        initialOrder="desc"
        initialOrderBy="carbs"
        isCheckable={false}
        isCollapsible
        opened={openedStoreIds}
        setOpened={setOpenedStoreIds}
      >
        <Detail data={stores} opened={openedStoreIds} setOpened={setOpenedStoreIds} />
      </EnhancedTable>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} reverse />
    </S.StyledStoreTable>
  );
}
export default TableSection;
