import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { Button } from '@material-ui/core';
import TextInputForm from '@F/materialUI/TextInputForm';
import { ArticleForm } from '@TS/homepage/article';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { actions } from './redux/slice';

const formSchema = Yup.object().shape({
  url: Yup.string().url('입력하신 Url의 형식이 올바르지 않습니다.'),
  published_at: Yup.date()
});

const FIELDS: { label: string; name: keyof ArticleForm; fullWidth?: boolean; type?: string }[] = [
  {
    label: '기사 URL',
    name: 'url',
    fullWidth: true
  },
  {
    label: '기사 발행일',
    name: 'published_at',
    type: 'date'
  }
];

export default function ArticleFormComponent(): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const handleSubmitForm = (
    values: ArticleForm,
    { setSubmitting }: Pick<FormikHelpers<ArticleForm>, 'setSubmitting'>
  ) => {
    setIsLoading(true);

    dispatch(
      actions.createArticle({
        data: values,
        callback: (isSuccess) => {
          if (isSuccess) {
            toastSuccessMessage('입력하신 기사 정보가 성공적으로 등록되었습니다.');
          }

          setIsLoading(false);
          setSubmitting(false);
        }
      })
    );
  };

  const alertEmptyURL = (e: React.MouseEvent, values: ArticleForm) => {
    if (values.url.trim() === '') {
      toastErrorMessage('URL을 입력해주세요.');
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const initialFormValues = { url: '', published_at: moment().format('YYYY-MM-DD') };

  return (
    <Formik initialValues={initialFormValues} onSubmit={handleSubmitForm} validationSchema={formSchema}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid }) => (
        <StyledForm onSubmit={handleSubmit}>
          <StyledFields>
            {FIELDS.map((field) => (
              <TextInputForm
                key={field.name}
                error={(errors[field.name] && touched[field.name]) || (touched[field.name] && !values[field.name])}
                type={field.type}
                name={field.name}
                value={values[field.name]}
                label={field.label}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth={field.fullWidth}
              />
            ))}
          </StyledFields>
          <Button
            onClick={(e) => alertEmptyURL(e, values)}
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            disabled={isLoading || !isValid || (touched.url && !values.url)}
          >
            등록
          </Button>
        </StyledForm>
      )}
    </Formik>
  );
}

const StyledFields = styled.div`
  display: flex;
  width: 800px;

  input[type='date'] {
    width: 300px;
  }
`;

const StyledForm = styled.form`
  width: 100%;
  display: flex;
  align-items: center;

  & div {
    margin-right: 10px;
  }

  margin-bottom: 10px;

  input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }
`;
