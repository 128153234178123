import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableHead, TableRow } from '@material-ui/core';
import { ArticleSortableField } from '@TS/homepage/article';
import TableHeadCell from '@F/table/cells/TableHeadCell';
import { actions } from '../redux/slice';
import { selectParams } from '../redux/selector';

const TABLE_HEAD_CELLS: {
  value: string;
  name?: ArticleSortableField;
  defaultOrderType?: OrderType;
}[] = [
  {
    name: 'title',
    value: '제목',
    defaultOrderType: 'DESC'
  },
  {
    name: 'published_at',
    value: '발행일',
    defaultOrderType: 'ASC'
  },
  {
    name: 'creator',
    value: '작성자',
    defaultOrderType: 'DESC'
  },
  {
    name: 'created_at',
    value: '생성일',
    defaultOrderType: 'DESC'
  },
  {
    name: 'updated_at',
    value: '수정일',
    defaultOrderType: 'DESC'
  },
  {
    name: 'status',
    value: '발행',
    defaultOrderType: 'DESC'
  },
  {
    value: '삭제'
  }
];
const initialActiveHeadCell = TABLE_HEAD_CELLS.find((hc) => hc.name === 'updated_at')!;
const initialOrderBy = { name: initialActiveHeadCell.name, orderType: initialActiveHeadCell.defaultOrderType };

export default function ArticlesTableHead(): JSX.Element {
  const dispatch = useDispatch();
  const params = useSelector(selectParams);

  const [orderBy, setOrderBy] = useState(initialOrderBy);

  const handleClickTableHeadCell = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    _name?: string,
    orderType?: OrderType
  ) => {
    if (!_name && !orderType) return;

    const name = _name as ArticleSortableField;
    setOrderBy({ name, orderType });
  };

  const getNewOrder = (
    oldOrder: `${ArticleSortableField} ${OrderType}`[],
    newOrderName: ArticleSortableField,
    newOrderType: OrderType
  ) => {
    const newOrder = [...oldOrder];
    newOrder.pop();
    newOrder.push(`${newOrderName} ${newOrderType}`);
    return newOrder;
  };

  useEffect(() => {
    if (!orderBy.name || !orderBy.orderType) return;

    const newOrder = getNewOrder(params.order, orderBy.name, orderBy.orderType);

    dispatch(
      actions.fetchArticles({
        ...params,
        order: newOrder
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // params를 포함시킬 경우 무한루프 발생
  }, [orderBy, dispatch]);

  return (
    <TableHead>
      <TableRow>
        {TABLE_HEAD_CELLS.map(({ name, value, defaultOrderType }, i) => (
          <TableHeadCell
            key={i}
            name={name}
            label={value}
            defaultOrderType={defaultOrderType}
            onClick={handleClickTableHeadCell}
            isActive={name === orderBy.name}
          />
        ))}
      </TableRow>
    </TableHead>
  );
}
