import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import InnerDetail from '@P/fingerprint/manualMapping/tableSection/detail/InnerDetail';
import InnerMap from '@P/fingerprint/manualMapping/tableSection/detail/InnerMap';
import * as S from './styles';

function Detail({ id, data, keyword }) {
  const fingerprint = useMemo(() => data.find((element) => element.id === Number(id)), [id, data]);

  const [selectedPlace, setSelectedPlace] = useState(null);

  return (
    <S.StyledDetail>
      <Grid container spacing={1}>
        <Grid item xs>
          <InnerDetail detail={fingerprint} selectedPlace={selectedPlace} />
        </Grid>
        <Grid item xs>
          <InnerMap
            detail={fingerprint}
            setSelectedPlace={setSelectedPlace}
            recommendedKeyword={keyword.id === id ? keyword.value : ''}
          />
        </Grid>
      </Grid>
    </S.StyledDetail>
  );
}
export default Detail;

Detail.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  keyword: PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.string
  })
};

Detail.defaultProps = {
  id: 0,
  keyword: {
    id: 0,
    value: ''
  }
};
