import axios from 'axios';
import { paramsSerializer } from '@FUNC/paramsSerializer';
import { axiosInstancesManager } from './helper';

axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.paramsSerializer = paramsSerializer;

axiosInstancesManager.resetAxiosInstances();

export * from './helper';
