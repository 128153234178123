import Loading from '@F/Loading';
import { useAppSelector } from '@HOOK/useSelector';
import TableSection from './tableSection/TableSection';
import Filter from './Filter';
import SummarySection from './SummarySection';

import * as S from './styles';

function Inspect(): JSX.Element {
  const isLoading = useAppSelector((state) => state.inspect.isLoading);

  return (
    <S.StyledInspect>
      {isLoading && <Loading />}
      <SummarySection />
      <br />
      <Filter />
      <br />
      <TableSection />
    </S.StyledInspect>
  );
}
export default Inspect;
