// https://stackoverflow.com/questions/60269936/typescript-convert-generic-object-from-snake-to-camel-case

export const camelToSnake = (str: string): string => str.replace(/[A-Z]/g, (upper) => `_${upper.toLowerCase()}`);
export const snakeToCamel = (str: string): string => str.replace(/((_+)\w)/g, (n_w) => n_w.slice(-1).toUpperCase());

export const snakeObjToCamelObj = <T>(obj: T): SnakeToCamelObj<T> =>
  Object.entries(obj).reduce((acc, [key, value]) => ({ ...acc, [snakeToCamel(key)]: value }), {} as SnakeToCamelObj<T>);

export const camelObjToSnakeObj = <T>(obj: T): CamelToSnakeObj<T> =>
  Object.entries(obj).reduce((acc, [key, value]) => ({ ...acc, [camelToSnake(key)]: value }), {} as CamelToSnakeObj<T>);
