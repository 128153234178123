import * as S from './styles';

function Unauthorized() {
  return (
    <S.StyledUnauthorized>
      <h1>이 페이지에 접근할 권한이 없습니다.</h1>
      <lottie-player
        autoplay
        speed="2.0"
        mode="normal"
        src="https://assets1.lottiefiles.com/private_files/lf30_sbsvs4ki.json"
        style={{ height: '500px', width: '500px', margin: '0 auto' }}
      />
    </S.StyledUnauthorized>
  );
}
export default Unauthorized;

Unauthorized.propTypes = {};
