import { createReducer, createSetValueAction, setValueReducer } from '@/redux/common/helper-functions';

/** prefix */
const PREFIX = 'COOK_USER';

/** initial state */
const INITIAL_STATE = {
  users: [],
  count: 0,
  openedUserIds: [],
  page: 1,
  perPage: 15,
  params: {},
  isLoading: false
};

/** type */
export const types = {
  RESET: `${PREFIX}/RESET`,
  SET_VALUE: `${PREFIX}/SET_VALUE`,
  SET_LOADING: `${PREFIX}/SET_LOADING`,
  SET_PAGE: `${PREFIX}/SET_PAGE`,
  FETCH_USERS: `${PREFIX}/FETCH_USERS`,
  SET_NEW_USERS: `${PREFIX}/SET_NEW_USERS`,
  SET_NEW_USER: `${PREFIX}/SET_NEW_USER`,
  UPDATE_USER: `${PREFIX}/UPDATE_USER`
};

/** action */
export const actions = {
  reset: () => ({ type: types.RESET }),
  setValue: createSetValueAction(types.SET_VALUE),
  setLoading: (isLoading) => ({ type: types.SET_LOADING, isLoading }),
  setPage: (page) => ({ type: types.SET_PAGE, page }),
  fetchUsers: () => ({ type: types.FETCH_USERS }),
  setNewUsers: (users) => ({ type: types.SET_NEW_USERS, users }),
  setNewUser: (user) => ({ type: types.SET_NEW_USER, user }),
  updateUser: (user, params) => ({ type: types.UPDATE_USER, user, params })
};

/** reducer */
const reducer = createReducer(INITIAL_STATE, {
  [types.RESET]: (draft) => {
    draft.users = [];
    draft.openedUserIds = [];
  },
  [types.SET_VALUE]: setValueReducer,
  [types.SET_LOADING]: (draft, action) => {
    draft.isLoading = action.isLoading;
  },
  [types.SET_PAGE]: (draft, action) => {
    draft.page = action.page;
  },
  [types.SET_NEW_USERS]: (draft, action) => {
    draft.users = action.users;
    draft.openedUserIds = [];
  },
  [types.SET_NEW_USER]: (draft, action) => {
    const index = draft.users.findIndex((user) => user.user_id === action.user.user_id);
    if (index >= 0) {
      draft.users[index] = action.user;
    }
  }
});
export default reducer;
