import styled from 'styled-components';

export const StyledDetail = styled.div`
  p {
    margin-bottom: 10px;
  }
`;

export const ButtonRow = styled.div`
  button:first-of-type {
    margin-right: 10px;
  }
`;
