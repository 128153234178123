import { useEffect, useState } from 'react';
import Wrapper from '@F/materialUI/Wrapper';
import { Button, Grid } from '@material-ui/core';
import SelectForm from '@F/materialUI/SelectForm';
import TextInputForm from '@F/materialUI/TextInputForm';
import { useDispatch } from 'react-redux';
import useInput from '@HOOK/useInput';
import { actions } from '@/redux/cashplace/user/state';
import * as S from './styles';

function Filter(): JSX.Element {
  const classes = S.useStyles();

  const [userId, setUserId] = useInput('');
  const UserIdInput = <TextInputForm name="UserIdInput" label="User ID" value={userId} onChange={setUserId} />;

  const [count, setCount] = useState<string>('10');
  const CountSelect = (
    <SelectForm
      label="개수"
      options={['10', '50', '100', '200']}
      value={count}
      onSelect={(e): void =>
        setCount((prevState) => {
          if (typeof e.target.value === 'string') return e.target.value;
          return prevState;
        })
      }
      name="CountSelect"
    />
  );

  const dispatch = useDispatch();
  const fetchUsers = () => {
    dispatch(actions.setPage(1));
    dispatch(actions.setValue('perPage', Number(count)));
    dispatch(actions.setValue('params', { userId: userId ? Number(userId) : undefined }));
    dispatch(actions.fetchUsers());
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <S.StyledFilter
      onKeyDown={(event) => {
        if (event.key === 'Enter') fetchUsers();
      }}
    >
      <Wrapper>
        <S.Row container className={classes.margin} spacing={1}>
          <Grid item xs>
            {UserIdInput}
          </Grid>
          <Grid item xs>
            {CountSelect}
          </Grid>
        </S.Row>

        <S.ButtonRow>
          <Button variant="contained" color="primary" onClick={fetchUsers}>
            불러오기
          </Button>
        </S.ButtonRow>
      </Wrapper>
    </S.StyledFilter>
  );
}
export default Filter;
