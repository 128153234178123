import { cashplaceVersionsApi } from '@API/manager/cashplace';
import { Version } from '@TS/cashplace/version';

export function fetchVersion(page: number, perPage: number): Promise<Version[]> {
  return cashplaceVersionsApi.getVersions({ page, perPage }).then((response) => response.data);
}

export function createVersion({ version, versionStr, minimumVersion, comment }: Version): Promise<Version> {
  return cashplaceVersionsApi
    .createVersion({
      version,
      versionStr,
      minimumVersion,
      comment
    })
    .then((response) => response.data);
}

export function updateVersion({ versionId, version, versionStr, minimumVersion, comment }: Version): Promise<Version> {
  return cashplaceVersionsApi
    .updateVersion({
      versionId,
      version,
      versionStr,
      minimumVersion,
      comment
    })
    .then((response) => response.data);
}

export function deleteVersion({ versionId }: Version): Promise<Version> {
  return cashplaceVersionsApi.deleteVersion({ versionId }).then((response) => response.data);
}
