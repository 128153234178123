import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import TextInputForm from '@F/materialUI/TextInputForm';
import { useInput } from '@HOOK/';
import * as S from './styles';

function LoginForm({ submit, disabled = false }) {
  const classes = S.useStyles();
  const [username, setUsername] = useInput('');
  const [password, setPassword] = useInput('');

  return (
    <S.StyledLoginForm
      onSubmit={(e) => {
        e.preventDefault();
        submit(username, password);
      }}
    >
      <S.Title>Loplat Manager</S.Title>
      <TextInputForm
        label="아이디"
        name="username"
        autoComplete="username"
        value={username}
        onChange={setUsername}
        className={classes.textField}
      />
      <TextInputForm
        label="비밀번호"
        name="password"
        type="password"
        autoComplete="password"
        value={password}
        onChange={setPassword}
        className={classes.textField}
      />
      <Button variant="contained" size="large" disabled={disabled} className={classes.button} type="submit">
        로그인 하기
      </Button>
    </S.StyledLoginForm>
  );
}
export default LoginForm;

LoginForm.propTypes = {
  submit: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

LoginForm.defaultProps = {
  disabled: false
};
