import { axiosInstancesManager } from '@API/axios';
import { camelToSnake } from '@FUNC/word';
import { ORDER_BY_TO_BACKEND_CONVENTION } from '@FUNC/order';

/* eslint class-methods-use-this: 0 */

class FingerprintsApi {
  getFingerprint(fpid) {
    return axiosInstancesManager.manager.get(`/fingerprints/${fpid}`);
  }

  getFingerprints({
    startDate,
    endDate,
    name,
    branchName,
    perPage,
    page,
    order,
    orderBy,
    collector,
    categoryCode,
    imageInspected,
    imageUploadStatus,
    fpState
  }) {
    return axiosInstancesManager.manager.get('/fingerprints', {
      params: {
        per_page: perPage,
        page,
        orders:
          orderBy && order
            ? [`${camelToSnake(ORDER_BY_TO_BACKEND_CONVENTION.fpManager[orderBy] || orderBy)} ${order}`]
            : [],
        name,
        branch_name: branchName,
        start_date: startDate,
        end_date: endDate,
        category_code: categoryCode,
        collector,
        image_upload_status: imageUploadStatus,
        image_inspected: imageInspected,
        state: fpState
      }
    });
  }

  getFingerprintsCsv({
    startDate,
    endDate,
    name,
    branchName,
    perPage,
    page,
    order,
    orderBy,
    collector,
    categoryCode,
    imageInspected,
    imageUploadStatus,
    fpState
  }) {
    return axiosInstancesManager.manager.get('/fingerprints', {
      headers: {
        Accept: 'text/csv'
      },
      params: {
        per_page: perPage,
        page,
        orders:
          orderBy && order
            ? [`${camelToSnake(ORDER_BY_TO_BACKEND_CONVENTION.fpManager[orderBy] || orderBy)} ${order}`]
            : [],
        name,
        branch_name: branchName,
        start_date: startDate,
        end_date: endDate,
        category_code: categoryCode,
        collector,
        image_upload_status: imageUploadStatus,
        image_inspected: imageInspected,
        state: fpState
      }
    });
  }

  getStats() {
    return axiosInstancesManager.manager.get('/fingerprints/stats');
  }

  patchImageValidation(fpid, status) {
    return axiosInstancesManager.manager.patch(`/fingerprints/${fpid}/validate-image`, {
      image_validation_action: status
    });
  }

  getAutomaticallyCollectedFingerprints(perPage, page, { state, charge, priority }) {
    return axiosInstancesManager.manager.get('/auto-fps', {
      params: {
        per_page: perPage,
        page,
        state,
        charge,
        priority
      }
    });
  }

  getSearchNaverMap({ keyword, lat, lng }) {
    return axiosInstancesManager.manager.get('/auto-fps/search-naver-map', {
      params: {
        keyword,
        lat,
        lng
      }
    });
  }

  patchAutomaticallyCollectedFingerprintValidation(fpid, { state }) {
    return axiosInstancesManager.manager.patch(`/auto-fps/${fpid}/invalidate`, {
      state
    });
  }

  putAutomaticallyCollectedFingerprint(
    fpid,
    { charge, state, nid, name, branchName, categoryCode, lat, lng, addr, addrRoad, phone, section, cid, floor }
  ) {
    return axiosInstancesManager.manager.put(`/auto-fps/${fpid}`, {
      charge,
      state,
      nid,
      name,
      branch_name: branchName,
      category_code: categoryCode,
      s_lat: lat,
      s_lng: lng,
      addr,
      addr_road: addrRoad,
      phone,
      section,
      cid,
      floor
    });
  }
}

export default new FingerprintsApi();
