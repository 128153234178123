import { createReducer, createSetValueAction, setValueReducer } from '@/redux/common/helper-functions';

/** prefix */
const PREFIX = 'CONTACT';

/** initial state */
const INITIAL_STATE = {
  contacts: [],
  count: 0,
  openedContactIds: [],
  page: 1,
  perPage: 15,
  params: {},
  isLoading: false
};

/** type */
export const types = {
  RESET: `${PREFIX}/RESET`,
  SET_VALUE: `${PREFIX}/SET_VALUE`,
  SET_LOADING: `${PREFIX}/SET_LOADING`,
  SET_PAGE: `${PREFIX}/SET_PAGE`,
  FETCH_CONTACTS: `${PREFIX}/FETCH_CONTACTS`,
  SET_NEW_CONTACTS: `${PREFIX}/SET_NEW_CONTACTS`,
  SET_NEW_CONTACT: `${PREFIX}/SET_NEW_CONTACT`,
  UPDATE_CONTACT: `${PREFIX}/UPDATE_CONTACT`
};

/** action */
export const actions = {
  reset: () => ({ type: types.RESET }),
  setValue: createSetValueAction(types.SET_VALUE),
  setLoading: (isLoading) => ({ type: types.SET_LOADING, isLoading }),
  setPage: (page) => ({ type: types.SET_PAGE, page }),
  fetchContacts: () => ({ type: types.FETCH_CONTACTS }),
  setNewContacts: (contacts) => ({ type: types.SET_NEW_CONTACTS, contacts }),
  setNewContact: (contact) => ({ type: types.SET_NEW_CONTACT, contact }),
  updateContact: (contact, params) => ({ type: types.UPDATE_CONTACT, contact, params })
};

/** reducer */
const reducer = createReducer(INITIAL_STATE, {
  [types.RESET]: (draft) => {
    draft.contacts = [];
    draft.openedContactIds = [];
  },
  [types.SET_VALUE]: setValueReducer,
  [types.SET_LOADING]: (draft, action) => {
    draft.isLoading = action.isLoading;
  },
  [types.SET_PAGE]: (draft, action) => {
    draft.page = action.page;
  },
  [types.SET_NEW_CONTACTS]: (draft, action) => {
    draft.contacts = action.contacts;
    draft.openedContactIds = [];
  },
  [types.SET_NEW_CONTACT]: (draft, action) => {
    const index = draft.contacts.findIndex((contact) => contact.contact_id === action.contact.contact_id);
    if (index >= 0) {
      draft.contacts[index] = action.contact;
    }
  }
});
export default reducer;
