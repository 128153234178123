import { storesApi } from '@API/manager';

export function fetchStores(page, perPage, params) {
  return storesApi.getStoreData({ page, perPage, ...params }).then((response) => response.data);
}

export function fetchStore(pid) {
  return storesApi.getStoreDataByPID({ pid }).then((response) => {
    if (Object.keys(response.data).length === 0) return null;
    return response.data;
  });
}

export function combineStores(params) {
  return storesApi.patchCombineStores(params).then((response) => response);
}

export function combineSingleStore(pid) {
  return storesApi.patchCombineStore({ pid }).then((response) => response);
}
