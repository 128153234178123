import { all, call, put, takeLeading, select } from 'redux-saga/effects';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { downloadCsv } from '@FUNC/download';
import { fetchClosedSites, patchSite, fetchClosedSitesCsv } from './api';
import { actions, types } from './state';

export function* fetchData() {
  const { page, perPage, params } = yield select((state) => ({
    page: state.closingManager.page,
    perPage: state.closingManager.perPage,
    params: state.closingManager.params
  }));

  yield put(actions.setLoading(true));
  try {
    const data = yield call(fetchClosedSites, page, perPage, params);
    yield put(actions.setNewClosingDatas(data.items));
    yield put(actions.setValue('count', data.count));
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* fetchCsvData() {
  const { page, perPage, params } = yield select((state) => ({
    page: state.closingManager.page,
    perPage: state.closingManager.perPage,
    params: state.closingManager.params
  }));

  yield put(actions.setLoading(true));
  try {
    const { data, fileName } = yield call(fetchClosedSitesCsv, page, perPage, params);

    downloadCsv(data, fileName);
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* patchData(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(patchSite, { siteId: action.siteId, action: action.action });
    yield put(
      actions.setNewClosingData({
        site_id: action.siteId,
        report_status: generateCodeNumberByAction(action.action)
      })
    );
    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* sagaReducer() {
  yield all([
    takeLeading(types.FETCH_CLOSING_DATA, fetchData),
    takeLeading(types.FETCH_CLOSING_CSV_DATA, fetchCsvData),
    takeLeading(types.PATCH_CLOSING_DATA, patchData)
  ]);
}

function generateCodeNumberByAction(action) {
  switch (action) {
    case 'close_accept_pending':
      return 4;
    case 'close_accept':
      return 5;
    case 'close_reject_pending':
      return 2;
    case 'close_reject':
      return 0;
    case 'close_pending':
      return 3;
    case 'close_qa_cancel':
      return 1;
    default:
      return null;
  }
}
