import { Fragment } from 'react';
import { Body, Caption, grayscale800 } from 'loplat-ui';
import type { RegionCondition } from '@P/max/campaigns/utils/type';
import { CHIP_DETAIL } from '@P/max/campaigns/utils/data';
import { Separator, DetailSection } from './styles';

type RegionOptionProps = Omit<RegionCondition['condition'], 'lv1' | 'lv2'>;
const ExtraConditions = (props: RegionOptionProps): JSX.Element | null => {
  const detailOptions = (Object.entries(props) as Entries<RegionOptionProps>).filter(([, value]) => !!value.length);
  if (!detailOptions.length) return null;

  return (
    <>
      <Separator className="and">
        <span>AND</span>
      </Separator>

      <dt className="title">브랜드/카테고리/매장</dt>
      <DetailSection className="body">
        <dl>
          {detailOptions.map(([title, value]) => (
            <Fragment key={title}>
              <dt>{CHIP_DETAIL[title].label}</dt>
              <dd>
                <Body color={CHIP_DETAIL[title].color}>{value.join(', ')}</Body>
              </dd>
            </Fragment>
          ))}
        </dl>
        <Caption size="xs" color={grayscale800}>
          선택한 행정구역 내, 위의 조건에 방문할때 캠페인이 동작합니다.
        </Caption>
      </DetailSection>
    </>
  );
};

export default ExtraConditions;
