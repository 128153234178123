import { all, call, put, takeLeading, select } from 'redux-saga/effects';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { actions, types } from './state';
import { fetchUsers, updateUser, updateUsers } from './api.ts';

export function* fetchUsersSaga() {
  const { page, perPage, params } = yield select((state) => ({
    page: state.user.page,
    perPage: state.user.perPage,
    params: state.user.params
  }));

  yield put(actions.setLoading(true));
  try {
    const data = yield call(fetchUsers, { page, perPage, ...params });
    yield put(actions.setNewUsers(data.result));
    yield put(actions.setValue('count', data.total_count));
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* updateUserSaga(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(updateUser, action.params);

    yield fetchUsersSaga();

    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* updateUsersSaga(action) {
  yield put(actions.setLoading(true));
  yield put(actions.setValue('failed', null));
  try {
    const { fail } = yield call(updateUsers, action.params);
    yield fetchUsersSaga();

    yield put(actions.setValue('failed', fail));
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* sagaReducer() {
  yield all([
    takeLeading(types.FETCH_USERS, fetchUsersSaga),
    takeLeading(types.UPDATE_USER, updateUserSaga),
    takeLeading(types.UPDATE_USERS, updateUsersSaga)
  ]);
}
