import Wrapper from '@F/materialUI/Wrapper';
import Filter from './Filter';
import TableSection from './tableSection/TableSection';
import * as S from './styles';

function StoreCombine() {
  return (
    <S.StyledStoreCombine>
      <Wrapper>
        <Filter />
      </Wrapper>
      <br />
      <Wrapper>
        <TableSection />
      </Wrapper>
    </S.StyledStoreCombine>
  );
}
export default StoreCombine;

StoreCombine.propTypes = {};
