import { Button, styled, MarginSpacing } from 'loplat-ui';
import { useNavigate } from 'react-router-dom';

type Props = {
  go: string;
  children: string;
} & MarginSpacing;
const LinkButton = ({ go, children, ...props }: Props): JSX.Element => {
  const history = useNavigate();

  return (
    <StyledButton onClick={() => history(go)} size="xs" color="primary2" role="link" {...props}>
      {children}
    </StyledButton>
  );
};

export default LinkButton;

const StyledButton = styled(Button)`
  span {
    font-size: 0.9rem;
  }
`;
