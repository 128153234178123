import { Version } from '@TS/cashplace/version';
import { axiosInstancesManager } from '@API/axios';
/* eslint class-methods-use-this: 0 */

class CashplaceVersionsApi {
  getVersions({ perPage, page }: { perPage: number; page: number }) {
    return axiosInstancesManager.manager.get('/cashplace/versions', {
      params: {
        per_page: perPage,
        page
      }
    });
  }

  createVersion({ version, versionStr, minimumVersion, comment }: Version) {
    return axiosInstancesManager.manager.post('/cashplace/versions', {
      v: version,
      v_str: versionStr,
      minimum_v: minimumVersion,
      comment
    });
  }

  updateVersion({ versionId, version, versionStr, minimumVersion, comment }: Version) {
    return axiosInstancesManager.manager.put(`/cashplace/versions/${versionId}`, {
      v: version,
      v_str: versionStr,
      minimum_v: minimumVersion,
      comment
    });
  }

  deleteVersion({ versionId }: Version) {
    return axiosInstancesManager.manager.delete(`/cashplace/versions/${versionId}`);
  }
}
export default new CashplaceVersionsApi();
