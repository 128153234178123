import Filter from './Filter';
import TableSection from './tableSection/TableSection';
import * as S from './styles';

function Contact(): JSX.Element {
  return (
    <S.StyledContact>
      <Filter />
      <br />
      <TableSection />
    </S.StyledContact>
  );
}
export default Contact;
