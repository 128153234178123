import moment from 'moment';
import { createReducer, createSetValueAction, setValueReducer } from '@/redux/common/helper-functions';

/** prefix */
const PREFIX = 'COLLECT_MANAGER';

/** inital state */
const INITIAL_STATE = {
  closingData: [],
  count: 0,
  openedClosingIds: [],
  page: 1,
  perPage: 10,
  params: {
    order: 'desc',
    orderBy: 'charge_result_at',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  },
  isLoading: false
};

/** type */
export const types = {
  RESET: `${PREFIX}/RESET`,
  SET_VALUE: `${PREFIX}/SET_VALUE`,
  SET_LOADING: `${PREFIX}/SET_LOADING`,
  SET_PAGE: `${PREFIX}/SET_PAGE`,
  FETCH_CLOSING_DATA: `${PREFIX}/FETCH_CLOSING_DATA`,
  FETCH_CLOSING_CSV_DATA: `${PREFIX}/FETCH_CLOSING_CSV_DATA`,
  SET_NEW_CLOSING_DATAS: `${PREFIX}/SET_NEW_CLOSING_DATAS`,
  SET_NEW_CLOSING_DATA: `${PREFIX}/SET_NEW_CLOSING_DATA`,
  PATCH_CLOSING_DATA: `${PREFIX}/PATCH_CLOSING_DATA`
};

/** action */
export const actions = {
  reset: () => ({ type: types.RESET }),
  setValue: createSetValueAction(types.SET_VALUE),
  setLoading: (isLoading) => ({ type: types.SET_LOADING, isLoading }),
  setPage: (page) => ({ type: types.SET_PAGE, page }),
  fetchClosingData: () => ({ type: types.FETCH_CLOSING_DATA }),
  fetchClosingCsvData: () => ({ type: types.FETCH_CLOSING_CSV_DATA }),
  setNewClosingDatas: (closingData) => ({ type: types.SET_NEW_CLOSING_DATAS, closingData }),
  setNewClosingData: (closingData) => ({ type: types.SET_NEW_CLOSING_DATA, closingData }),
  patchClosingData: (siteId, action) => ({ type: types.PATCH_CLOSING_DATA, siteId, action })
};

/** reducer */

const reducer = createReducer(INITIAL_STATE, {
  [types.RESET]: (draft) => {
    draft.closingData = [];
    draft.openedClosingIds = [];
  },
  [types.SET_VALUE]: setValueReducer,
  [types.SET_NEW_CLOSING_DATAS]: (draft, action) => {
    draft.closingData = action.closingData;
    draft.openedClosingIds = [];
  },
  [types.SET_LOADING]: (draft, action) => {
    draft.isLoading = action.isLoading;
  },
  [types.SET_PAGE]: (draft, action) => {
    draft.page = action.page;
  },
  [types.SET_NEW_CLOSING_DATA]: (draft, action) => {
    const index = draft.closingData.findIndex((item) => item.site_id === action.closingData.site_id);
    if (index >= 0) {
      draft.closingData[index] = {
        ...draft.closingData[index],
        ...action.closingData
      };
    }
  }
});
export default reducer;
