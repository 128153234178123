import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import type { Company } from '@TS/max/company/api';

export type State = {
  status: ApiStatus;
  error: null | Error;
  companies: Company[];
};

const initialState: State = {
  status: 'idle',
  error: null,
  companies: []
};

const slice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    fetchStart: (state) => {
      state.status = 'loading';
    },
    fetchFailure: (state, action: PayloadAction<{ error: Error }>) => {
      state.status = 'failure';
      state.error = action.payload.error;
    },
    fetchSuccess: (state, action: PayloadAction<{ companies: State['companies'] }>) => {
      state.status = 'success';
      state.companies = action.payload.companies;
    },
    fetchCompanies: () => {}
  }
});

export const { actions } = slice;
export default slice.reducer;

export const companiesPersistConfig = {
  key: 'companies',
  storage,
  whitelist: ['companies']
};
