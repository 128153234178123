import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import { format } from '@FUNC/math';
import * as S from './styles';

function CountBox({ data, lastData, description, label, backgroundColor }) {
  return (
    <S.StyledCountBox>
      <S.Header backgroundColor={backgroundColor}>
        <div>Collected Places</div>
        <div>{label}</div>
      </S.Header>
      <S.Content>
        <div>
          <CountUp end={data} separator="," />
          <span>{lastData !== null && ` / ${format(lastData)}`}</span>
        </div>
        <div>{description}</div>
      </S.Content>
    </S.StyledCountBox>
  );
}
export default CountBox;

CountBox.propTypes = {
  data: PropTypes.number,
  lastData: PropTypes.number,
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string
};

CountBox.defaultProps = {
  data: 0,
  lastData: null,
  backgroundColor: 'red'
};
