import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import Wrapper from '@F/materialUI/Wrapper';
import { actions } from '@/redux/store/storeErrorReport/state';
import Filter from './Filter';
import TableSection from './tableSection/TableSection';

import * as S from './styles';

function ErrorReport() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.reset());
  }, []);
  return (
    <S.StyledErrorReport>
      <Wrapper>
        <Filter />
      </Wrapper>
      <br />
      <TableSection />
    </S.StyledErrorReport>
  );
}
export default ErrorReport;

ErrorReport.propTypes = {};
