import { useEffect, useState } from 'react';
import SingleDatePicker from '@F/materialUI/datePicker/SingleDatePicker';
import * as S from './styles';

type Props = {
  initialStartDate?: string;
  initialEndDate?: string;
  onEmitDate: ([]) => void; // eslint-disable-line no-empty-pattern
  disabled?: boolean;
  labels?: string[];
  names?: string[];
};

/** WARN: 기간 선택 컴포넌트가 필요하다면, 이파일 대신 `@F/Duration` 사용 권장 */
function MaterialDatePicker({
  initialStartDate,
  initialEndDate,
  onEmitDate,
  disabled = false,
  labels = ['', ''],
  names = ['', '']
}: Props): JSX.Element {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  useEffect(() => {
    onEmitDate([startDate, endDate]);
  }, [onEmitDate, startDate, endDate]);

  return (
    <S.Wrapper>
      <SingleDatePicker
        name={names[0]}
        label={labels[0]}
        defaultDate={initialStartDate}
        onEmitDate={setStartDate}
        disabled={disabled}
      />
      <p>~</p>
      <SingleDatePicker
        name={names[0]}
        label={labels[1]}
        defaultDate={initialEndDate}
        onEmitDate={setEndDate}
        disabled={disabled}
      />
    </S.Wrapper>
  );
}
export default MaterialDatePicker;
