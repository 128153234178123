import { all, call, put, takeLeading, select } from 'redux-saga/effects';
import moment from 'moment';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { actions, types } from './state';
import { fetchContacts, updateContact } from './api.ts';

export function* fetchData() {
  const { page, perPage, params } = yield select((state) => ({
    page: state.contact.page,
    perPage: state.contact.perPage,
    params: state.contact.params
  }));

  yield put(actions.setLoading(true));
  try {
    const data = yield call(fetchContacts, page, perPage, params);
    yield put(actions.setNewContacts(data.result));
    yield put(actions.setValue('count', data.total_count));
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* updateContactSaga(action) {
  yield put(actions.setLoading(true));
  try {
    const data = yield call(updateContact, action.contact.contact_id, action.params);
    const newAttributes = {
      checked_at: moment.unix(data.checked_at).format('YYYY-MM-DD HH:mm:ss'),
      state: data.state
    };
    yield put(actions.setNewContact({ ...action.contact, ...action.params, ...newAttributes }));

    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* sagaReducer() {
  yield all([takeLeading(types.FETCH_CONTACTS, fetchData), takeLeading(types.UPDATE_CONTACT, updateContactSaga)]);
}
