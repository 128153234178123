import { TableSortLabel } from '@material-ui/core';
import Tooltip from '@F/Tooltip';
import { nextOrderInKorean } from '@FUNC/order';

type Props = {
  value: string;
  isActive: boolean;
  direction: OrderType;
  onClick: () => void;
};

const TableHeadCell = ({ value, direction, onClick, isActive }: Props): JSX.Element => (
  <Tooltip title={sortingTip(value, direction, isActive)} enterDelay={300} style={{ transform: 'translateX(12px)' }}>
    <TableSortLabel active={isActive} direction={direction.toLowerCase() as Lowercase<OrderType>} onClick={onClick}>
      <b>{value}</b>
    </TableSortLabel>
  </Tooltip>
);

export default TableHeadCell;

const sortingTip = (value: string, direction: OrderType, isActive: boolean): string => {
  if (isActive) return `클릭하면 ${value}를 기준으로 ${nextOrderInKorean(direction)}으로 정렬합니다.`;
  return `클릭하면 ${value}를 기준으로 내림차순으로 정렬합니다.`;
};
