import moment from 'moment';
import { calculateDifferenceBetweenTwoDates } from '@FUNC/date';

interface Return {
  leftDaysBeforeDueDate: number;
  isOverdue: boolean;
}

const useDueDate = (dueDate: Date | string): Return => {
  const leftDaysBeforeDueDate = calculateDifferenceBetweenTwoDates(moment(dueDate).toString(), Date().toString());
  const isOverdue = leftDaysBeforeDueDate < 0;

  return {
    leftDaysBeforeDueDate,
    isOverdue
  };
};

export default useDueDate;
