import TextInputForm from '@F/materialUI/TextInputForm';
import BrandModal from '@F/modal/BrandModal';
import { SelectedBrand } from '@TS/store/brandModal';
import { Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { toastWarningMessage } from '@FUNC/toast';

type BrandsInputProps = {
  onChange: (brands: SelectedBrand[]) => void;
  initialState: SelectedBrand[];
  label?: string;
  required?: boolean;
};

/* 
  brand = { name: '', id: '' }
  검색 시 name으로
  수동 입력 시 id로
*/

type BrandsInputValueType = 'name' | 'id';

export default function BrandsInput({
  onChange,
  initialState = [],
  label = '브랜드',
  required = false
}: BrandsInputProps): JSX.Element {
  const [brands, setBrands] = useState<SelectedBrand[]>(initialState);
  const [showBrandsModal, setShowBrandsModal] = useState(false);
  const [valueType, setValueType] = useState<BrandsInputValueType>('id');

  useEffect(() => {
    onChange(brands);
  }, [brands]);

  const openModal = () => setShowBrandsModal(true);

  return (
    <div>
      <Tooltip
        title={
          brands.length
            ? `${Object.entries(brands[0])
                .filter((entry) => entry[1])
                .map((entry) => `${entry[0]}: ${entry[1]}`)
                .join(' , ')}`
            : ''
        }
        placement="top-start"
      >
        <TextInputForm
          name="BrandsInput"
          label={label}
          value={
            brands.length ? (valueType === 'id' ? String(brands[0].id) : `${brands[0].id}(${brands[0].name})`) : ''
          }
          onClear={required ? undefined : () => setBrands([])}
          onChange={(e) => {
            setValueType('id');

            if (e.target.value === '') {
              setBrands([]);
              return;
            }

            if (Number.isNaN(Number(e.target.value))) {
              toastWarningMessage('숫자만 입력할 수 없습니다.');
            } else {
              setBrands([{ name: '', id: Number(e.target.value) }]);
            }
          }}
          onSearch={() => {
            setValueType('name');
            openModal();
          }}
        />
      </Tooltip>
      <BrandModal multiSelectable={false} open={showBrandsModal} setOpen={setShowBrandsModal} setValue={setBrands} />
    </div>
  );
}
