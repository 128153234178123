/* eslint-disable react/no-unescaped-entities */
import { Body, grayscale800, primary } from 'loplat-ui';
import type { TimeCondition } from '@P/max/campaigns/utils/type';
import { Section, SectionRow } from '../section';

type Props = { condition: TimeCondition };
const TimeConditionSection = ({ condition }: Props): JSX.Element => (
  <Section title="시간 및 실행 조건">
    <SectionRow subTitle="캠페인 기간">
      <Body tag="span" mr={2}>
        {condition.period.value}
      </Body>
      <Body tag="span" color={grayscale800}>
        (총 {condition.period.count})
      </Body>
    </SectionRow>
    <SectionRow subTitle="실행 시간대">
      <ul>
        {condition.operation.map((option) => (
          <li key={option.days}>
            <Body tag="span" color={primary} mr={2} weight="extraBold">
              {option.days}
            </Body>
            <Body tag="span" weight="extraBold" mr={1}>
              {option.time.start}
            </Body>
            부터
            <Body tag="span" weight="extraBold" ml={2} mr={1}>
              {option.time.end}
            </Body>
            까지
          </li>
        ))}
      </ul>
    </SectionRow>
    <SectionRow subTitle="실행 조건">
      설정한
      <Body tag="span" weight="extraBold" mx={2}>
        '{condition.location}'
      </Body>
      에서 캠페인 실행
    </SectionRow>
  </Section>
);

export default TimeConditionSection;
