import { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import * as firebase from 'firebase';
import moment from 'moment';
import { GridList, GridListTile } from '@material-ui/core';
import Loading from '@F/Loading';

import { useFetch } from '@HOOK/';
import { FIREBASE_COOK_URL } from '@/config';
import * as S from './styles';

function Images({ detail }) {
  const [images, setImages] = useState([]);
  const [viewingImages, setViewingImages] = useState([]);
  const { isLoading } = useFetch();
  const [imagesLoading, setImagesLoading] = useState(false);
  const [imageViewerOpen, setImageViewerOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    if (detail) {
      const tempLinks = new Set();
      const link = `${FIREBASE_COOK_URL}/loplatcook/close_store`;

      [...Array(10).keys()].forEach((i) => {
        tempLinks.add(
          `${link}/${moment(detail.charge_result_at, 'YYYY-MM-DD').format('YYYY-MM-DD')}/${detail.charge}/${
            detail.site_id
          }_${i + 1}.jpg`
        );
      });
      getImages([...tempLinks]);
    }
  }, [detail]);

  async function getImages(linkList) {
    let tempImage = [];
    const validImages = [];
    setImagesLoading(true);
    if (linkList && linkList.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const imgUrl of linkList) {
        const image = firebase.storage().refFromURL(imgUrl);
        try {
          // eslint-disable-next-line no-await-in-loop
          tempImage = await image.getDownloadURL();
        } catch (exception) {
          tempImage = null;
          break;
        }

        if (tempImage !== null) {
          validImages.push(tempImage);
          validImages.sort((a, b) => {
            const aDate = a.split('%2F')[2];
            const bDate = b.split('%2F')[2];
            return new Date(bDate) - new Date(aDate);
          });
          setImages(validImages);
          setViewingImages(validImages.slice(0, 3));
        }
      }
      setImagesLoading(false);
    }
  }
  function imageClicked(image) {
    setImageViewerOpen(true);
    setImageIndex(images.findIndex((item) => item === image));
  }

  return (
    <S.StyledImages>
      {(isLoading || imagesLoading) && <Loading />}
      {images && images.length > 0 ? (
        <GridList cellHeight={200} cols={images.length > 2 ? 3 : 2}>
          {viewingImages.map((image) => (
            <GridListTile key={image}>
              <img src={image} onClick={() => imageClicked(image)} alt="Image-Viewer" />
            </GridListTile>
          ))}
          <p>
            외{images.length - viewingImages.length}
            개의 이미지
          </p>
        </GridList>
      ) : (
        <h1>이미지가 없거나 불러오는 데에 실패했습니다.</h1>
      )}
      {imageViewerOpen && (
        <Lightbox
          reactModalStyle={{ overlay: { zIndex: 2000 } }}
          mainSrc={images[imageIndex]}
          nextSrc={images[(imageIndex + 1) % images.length]}
          prevSrc={images[(imageIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setImageViewerOpen(false)}
          onMovePrevRequest={() => setImageIndex((imageIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setImageIndex((imageIndex + images.length + 1) % images.length)}
          imageCaption={images[imageIndex]}
          enableZoom
          imageTitle={`${images.length}개 중 ${imageIndex + 1}번째 사진`}
        />
      )}
    </S.StyledImages>
  );
}
export default Images;

Images.propTypes = {};
