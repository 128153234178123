import { useEffect, useRef, useState } from 'react';
// import { Outlet, useLocation, Link } from 'react-router-dom';

// import { Headline } from 'loplat-ui';
import styled from '@emotion/styled';
// import { findRouteNodeByPathname } from '@/routes';

import SideMenu from './SideMenu';
import Header from './Header';
import Main from './Main';
import { managerLocalStorage } from '@/utils/localStorageParser';

// ## layout
export default function Layout(): JSX.Element {
  const pageScrollViewRef = useRef<HTMLDivElement>(null);
  const [showSideMenu, setShowMenu] = useState(
    managerLocalStorage.get<boolean>(managerLocalStorage.keys.show_side_menu) ?? false
  );

  const scrollPageViewToTop = () => pageScrollViewRef.current?.scrollTo(0, 0);

  useEffect(() => {
    managerLocalStorage.set(managerLocalStorage.keys.show_side_menu, showSideMenu);
  }, [showSideMenu]);

  return (
    <>
      <StyledLayout showSideMenu={showSideMenu}>
        <aside>
          <SideMenu
            setShowSideMenu={setShowMenu}
            showSideMenu={showSideMenu}
            onClickMenuLinkItem={() => scrollPageViewToTop()}
          />
        </aside>
        <div className="header-main">
          <Header />
          <Main pageScrollViewRef={pageScrollViewRef} />
        </div>
      </StyledLayout>
    </>
  );
}

export const StyledLayout = styled.div<{ showSideMenu: boolean }>`
  display: flex;
  width: 100vw;
  height: 100vh;

  aside {
    flex: 0 1 auto;
    background-color: white;
    transition: width 0.3s, opacity 0.3s;
    width: ${({ showSideMenu }) => (showSideMenu ? `60px` : `240px`)};
    height: 100%;
  }

  .header-main {
    flex: 1 1 auto;
    height: 100%;
    z-index: 1;
    background-color: #fafafa;
    overflow: hidden;

    header {
      height: 65px;
    }

    main {
      height: calc(100% - 65px);
      border: 1px solid #eee;
      overflow: scroll;

      .page {
        padding: 20px;
        padding-top: 10px;
      }
    }
  }
`;
