import { useState } from 'react';
import InputsWrapper from './inputs/InputsWrapper';
import Inputs from './inputs/Inputs';
import TableSection from './TableSection';
import * as S from './styles';

function AddComplex() {
  const [complexData, setComplexData] = useState([]);
  return (
    <S.StyledAddComplex>
      <InputsWrapper>
        <Inputs setComplexData={setComplexData} />
      </InputsWrapper>
      <TableSection complexData={complexData} setComplexData={setComplexData} />
    </S.StyledAddComplex>
  );
}
export default AddComplex;

AddComplex.propTypes = {};
