import styled from 'styled-components';

export const StyledDetail = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;

  & > textarea {
    width: 100%;
    height: 6rem;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;

  .FeedbackButton {
    margin-left: 10px;
  }
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 1rem;

  p {
    margin: 0;
  }
  p:first-of-type {
    font-weight: bold;
  }
`;

export const ImageSection = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  margin-top: 1rem;
  margin-bottom: 3rem;

  height: 100%;

  img {
    padding: 0 1rem;
    width: auto;
    height: 100%;
    max-height: 500px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

export const RedText = styled.p`
  color: red;
  text-align: center;
`;
