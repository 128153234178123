import { useState, useCallback, useEffect, useRef } from 'react';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import { Button, Grid, IconButton, Chip } from '@material-ui/core';
import FingerPrintTableSection from '@P/store/StoreManager/tableSection/detail/FingerPrintTableSection';
import {
  storeNameReviewSelectOptions,
  subNameReviewSelectOptions,
  categoryCodeReviewSelectOptions,
  locationReviewSelectOptions,
  sectionOptions
} from '@P/store/StoreManager/tableSection/detail/Infos/Options';

import { copyMessageToClipboard } from '@HOOK/useCopy';
import CategoryModal from '@F/modal/CategoryModal';
import DialogModal from '@F/DialogModal';
import Loading from '@F/Loading';
import BrandModal from '@F/modal/BrandModal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toJS } from 'mobx';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import { RemoveCircle } from '@material-ui/icons';
import { toastErrorMessage } from '@FUNC/toast';
import ComplexMatchingModal from '@F/modal/ComplexMatchingModal';
import { useInput } from '@HOOK/';
import { snakeObjToCamelObj } from '@FUNC/word';
import { actions } from '@/redux/store/storeManager/state';
import * as S from './styles';
import { valuesStore } from '@/mobX/values';

function Infos(props) {
  const { detail, newLocation, onComplete } = props;
  const classes = S.useStyles();

  const { isLoading, status } = useSelector(
    (state) => ({
      isLoading: state.storeManager.isLoading,
      status: state.storeManager.status
    }),
    shallowEqual
  );

  // State: TextInput
  const [storeNameState, setStoreNameState] = useInput(detail.name);
  const [subNameState, setSubNameState] = useInput(detail.branch_name);
  const [categoryState] = useInput(detail.category);
  const [categoryCodeState, setCategoryCodeState] = useState({ korName: '', code: detail.category_code });
  const [categoryDetailState, setCategoryDetailState] = useInput(detail.category_sub);
  const [floorState, setFloorState] = useInput(detail.floor);
  const [complexIDState, setComplexIDState] = useState(
    detail.cids ? detail.cids.split(',').map((cid) => ({ cid })) : []
  );
  const [brandIDState, setBrandIDState] = useState(() =>
    detail.brands.map((brand) => ({
      id: brand.bid,
      name: brand.name
    }))
  );

  const [nidState, setNIDState] = useInput(detail.nid);
  const [didState, setDIDState] = useInput(detail.did);
  const [siteNameState] = useInput(detail.site_origin_name);
  const [clientCodeState, setClientCodeState] = useState([]);
  const [clientIdState, setClientIdState] = useState([]);
  useEffect(() => {
    if (detail.client_codes?.length > 0) {
      const initialClientCodes = [];
      const initialClientIds = [];
      detail.client_codes.forEach((client) => {
        initialClientCodes.push(client.client_code);
        initialClientIds.push(client.client_id);
      });
      setClientCodeState(initialClientCodes);
      setClientIdState(initialClientIds);
    }
  }, [detail]);

  // State: Modal Visible
  const [isCategoryCodeModalVisible, setIsCategoryCodeModalVisible] = useState(false);
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isBrandIDModalVisible, setIsBrandIDModalVisible] = useState(false);
  const [isComplexMatchingModalOpen, setIsComplexMatchingModalOpen] = useState(false);
  const [clientRemoveModalOpen, setClientRemoveModalOpen] = useState(false);
  const clientRemoveIndex = useRef(null); // NOTE: client 목록 중 [index]번째 삭제 버튼을 눌렀을 때 해당 index 를 임시 저장하는 변수

  // State: UI
  const [isStoreInnerFpTableVisible, setIsStoreInnerFpTableVisible] = useState(false);

  // State: Select; type만 저장한다
  const sectionSelectFormOption = Object.values(sectionOptions);
  const storeNameReviewSelectFormOptions = Object.values(storeNameReviewSelectOptions);
  const subNameReviewSelectFormOptions = Object.values(subNameReviewSelectOptions);
  const categoryCodeReviewSelectFormOptions = Object.values(categoryCodeReviewSelectOptions);
  const locationReviewSelectFormOptions = Object.values(locationReviewSelectOptions);

  const [sectionState, setSectionState] = useInput(
    Object.hasOwnProperty.call(sectionOptions, detail.section) ? sectionOptions[Number(detail.section)] : ''
  );
  const [storeNameReviewState, setStoreNameReviewState] = useInput(
    Object.hasOwnProperty.call(storeNameReviewSelectOptions, detail.qa_name)
      ? storeNameReviewSelectOptions[detail.qa_name]
      : '미검수'
  );
  const [subNameReviewState, setSubNameReviewState] = useInput(
    Object.hasOwnProperty.call(subNameReviewSelectOptions, detail.qa_subname)
      ? subNameReviewSelectOptions[detail.qa_subname]
      : '미검수'
  );
  const [categoryCodeReviewState, setCategoryCodeReviewState] = useInput(
    Object.hasOwnProperty.call(categoryCodeReviewSelectOptions, detail.qa_category)
      ? categoryCodeReviewSelectOptions[detail.qa_category]
      : '미검수'
  );
  const [locationReviewState, setLocationReviewState] = useInput(
    Object.hasOwnProperty.call(locationReviewSelectOptions, detail.qa_location)
      ? locationReviewSelectOptions[detail.qa_location]
      : '미검수'
  );

  useEffect(() => {
    if (status === 'success') onComplete();
  }, [status, onComplete]);

  // update pid
  const dispatch = useDispatch();

  const closeStore = useCallback(() => {
    dispatch(actions.closeStore(detail.pid));
  }, [dispatch, detail]);

  const discardStore = useCallback(async () => {
    dispatch(actions.discardStore(detail.pid));
  }, [dispatch, detail]);

  const deleteStore = useCallback(async () => {
    dispatch(actions.deleteStore(detail.pid));
  }, [dispatch, detail]);

  const completeStore = () => {
    if (clientCodeState.indexOf('') !== -1 || clientIdState.indexOf('') !== -1) {
      toastErrorMessage('클라이언트 코드와 클라이언트 ID는 모두 필요합니다. 매핑을 끊으시려면 행을 삭제해주세요.');
      return;
    }
    const clientCodes = clientIdState
      .map((id, index) => ({
        client_id: id,
        client_code: clientCodeState[index]
      }))
      .filter((item) => item.client_id && item.client_code);

    const storeInfo = {
      cids: String(complexIDState.map(({ cid }) => cid).join(',')) || null,
      pid: detail.pid,
      name: String(storeNameState),
      branchName: String(subNameState),
      floor: Number(floorState),
      lat: Number(newLocation.lat),
      lng: Number(newLocation.lng),
      clientCodes,
      categoryCode: categoryCodeState.code ? String(categoryCodeState.code) : null,
      categorySub: categoryDetailState ? String(categoryDetailState) : null,
      section: Number(Object.keys(sectionOptions).find((key) => sectionOptions[key] === sectionState)),
      nid: nidState ? Number(nidState) : null,
      did: didState ? Number(didState) : null,
      bids: brandIDState.map((brand) => brand.id),
      qaCategory: Number(
        Object.keys(categoryCodeReviewSelectOptions).find(
          (key) => categoryCodeReviewSelectOptions[key] === categoryCodeReviewState
        )
      ),
      qaLocation: Number(
        Object.keys(locationReviewSelectOptions).find((key) => locationReviewSelectOptions[key] === locationReviewState)
      ),
      qaSubName: Number(
        Object.keys(subNameReviewSelectOptions).find((key) => subNameReviewSelectOptions[key] === subNameReviewState)
      ),
      qaName: Number(
        Object.keys(storeNameReviewSelectOptions).find(
          (key) => storeNameReviewSelectOptions[key] === storeNameReviewState
        )
      )
    };
    dispatch(actions.completeStore(storeInfo));
  };

  // Component
  const StoreNameInput = (
    <TextInputForm name="StoreNameInput" label="장소명" onChange={setStoreNameState} value={storeNameState} />
  );
  const StoreNameReviewSelect = (
    <SelectForm
      label="장소명 검수 상태"
      options={storeNameReviewSelectFormOptions}
      value={storeNameReviewState}
      onSelect={setStoreNameReviewState}
      disabled={false}
      name="StoreNameReviewSelect"
    />
  );
  const SubNameInput = (
    <TextInputForm name="SubNameInput" label="subname" onChange={setSubNameState} value={subNameState} />
  );
  const SubNameReviewSelect = (
    <SelectForm
      label="sub name 검수 상태"
      options={subNameReviewSelectFormOptions}
      value={subNameReviewState}
      onSelect={setSubNameReviewState}
      disabled={false}
      name="SubNameReviewSelect"
    />
  );

  const CategoryInput = (
    <TextInputForm name="CategoryInput" label="카테고리" readOnly value={categoryState} variant="filled" />
  );
  const CategoryCodeInput = (
    <TextInputForm
      name="CategoryCodeInput"
      label="카테고리(코드)"
      onClick={() => setIsCategoryCodeModalVisible(!isCategoryCodeModalVisible)}
      value={categoryCodeState.code}
    />
  );
  const CategoryCodeReviewSelect = (
    <SelectForm
      label="카테고리 코드 검수 상태"
      options={categoryCodeReviewSelectFormOptions}
      value={categoryCodeReviewState}
      onSelect={setCategoryCodeReviewState}
      disabled={false}
      name="CategoryCodeReviewSelect"
    />
  );

  const CategoryDetailInput = (
    <TextInputForm
      name="CategoryDetailInput"
      label="카테고리(상세)"
      onChange={setCategoryDetailState}
      value={categoryDetailState}
    />
  );
  const FloorInput = <TextInputForm name="FloorInput" label="층수" onChange={setFloorState} value={`${floorState}`} />;
  const LocationInput = (
    <TextInputForm
      onClick={() => copyMessageToClipboard(`${newLocation.lat}, ${newLocation.lng}`)}
      name="LocationInput"
      label="location"
      value={`${newLocation.lat}, ${newLocation.lng}`}
    />
  );
  const LocationReviewSelect = (
    <SelectForm
      label="Location 검수 상태"
      options={locationReviewSelectFormOptions}
      value={locationReviewState}
      onSelect={setLocationReviewState}
      disabled={false}
      name="LocationReviewSelect"
    />
  );
  const sectionSelect = (
    <SelectForm
      label="Section"
      options={sectionSelectFormOption}
      value={sectionState}
      onSelect={setSectionState}
      disabled={false}
      name="sectionSelect"
    />
  );
  const ComplexIDInput = (
    <TextInputForm
      name="ComplexIDInput"
      label="Complex ID (CID)"
      onClick={() => setIsComplexMatchingModalOpen(true)}
      value={complexIDState.map(({ cid, name }) => (name ? `${name}(${cid})` : `${cid}`)).join(', ')}
      onClear={() => setComplexIDState([])}
    />
  );
  const BrandIDInput = (
    <TextInputForm
      name="BrandIDInput"
      label="Brand ID (BID)"
      onClick={() => setIsBrandIDModalVisible(!isBrandIDModalVisible)}
      value={brandIDState.map(({ id, name }) => `${name}(${id})`).join(', ')}
      readOnly
      onClear={() => setBrandIDState([])}
    />
  );

  const NIDInput = <TextInputForm name="NIDInput" label="NID" onChange={setNIDState} value={`${nidState}`} />;
  const NIDChip = (
    <Chip
      label="NAVER 확인"
      clickable
      color="primary"
      onClick={() => window.open(`https://store.naver.com/restaurants/detail?id=${nidState}`)}
    />
  );
  const DIDInput = <TextInputForm name="DIDInput" label="DID" onChange={setDIDState} value={didState} />;
  const SiteNameInput = (
    <TextInputForm readOnly name="SiteNameInput" label="장소명 (Site)" value={siteNameState} variant="filled" />
  );

  const AddClientButton = (
    <IconButton
      variant="outlined"
      color="default"
      className={classes.button}
      onClick={() => {
        setClientIdState((prevState) => [...prevState, '']);
        setClientCodeState((prevState) => [...prevState, '']);
      }}
    >
      <ControlPointIcon />
    </IconButton>
  );
  const updateClientCode = useCallback((index, value) => {
    setClientCodeState((prevState) => [...prevState.slice(0, index), value, ...prevState.slice(index + 1)]);
  }, []);
  const updateClientId = useCallback((index, value) => {
    setClientIdState((prevState) => [...prevState.slice(0, index), value, ...prevState.slice(index + 1)]);
  }, []);
  const removeClientByIndex = useCallback((index) => {
    setClientCodeState((prevState) => [...prevState.slice(0, index), ...prevState.slice(index + 1)]);
    setClientIdState((prevState) => [...prevState.slice(0, index), ...prevState.slice(index + 1)]);
  }, []);
  const createClientRow = (index) => (
    <S.Row container className={classes.margin} spacing={1} key={index}>
      <Grid item xs>
        <TextInputForm
          error={clientCodeState[index] === ''}
          required
          name="ClientCodeInput"
          label="클라이언트 코드"
          value={clientCodeState[index] ?? ''}
          onChange={(e) => updateClientCode(index, e.target.value)}
        />
      </Grid>
      <Grid item xs>
        <SelectForm
          error={clientIdState[index] === ''}
          label="SDK 클라이언트 ID"
          value={clientIdState[index] ?? ''}
          options={toJS(valuesStore.sdkClientIds)}
          onSelect={(e) => updateClientId(index, e.target.value)}
          name="ClientIdSelect"
        />
      </Grid>
      <IconButton
        onClick={() => {
          clientRemoveIndex.current = index;
          setClientRemoveModalOpen(true);
        }}
      >
        <RemoveCircle />
      </IconButton>
    </S.Row>
  );

  const CloseButton = (
    <Button variant="outlined" color="default" className={classes.button} onClick={() => setCloseModalOpen(true)}>
      폐점
    </Button>
  );
  const DeleteButton = (
    <Button variant="outlined" color="primary" className={classes.button} onClick={() => setDeleteModalOpen(true)}>
      삭제
    </Button>
  );
  const DiscardButton = (
    <Button variant="outlined" color="secondary" className={classes.button} onClick={() => setDiscardModalOpen(true)}>
      휴지통
    </Button>
  );
  const CompleteButton = (
    <Button variant="contained" color="primary" className={classes.completeButton} onClick={completeStore}>
      확인
    </Button>
  );

  const ClientRemoveModal = (
    <DialogModal
      title="정말로 삭제하시겠습니까?"
      description="client 삭제는 poi 삭제 수준의 부작용을 가집니다. 신중히 선택하십시오."
      open={clientRemoveModalOpen}
      setOpen={setClientRemoveModalOpen}
      confirmButtonDidClicked={() => removeClientByIndex(clientRemoveIndex.current)}
    />
  );
  const CloseDialogModal = (
    <DialogModal
      title="정말로 폐점하시겠습니까?"
      description="확인버튼을 누르시면 다시 돌이킬 수 없습니다. 신중히 선택하십시오."
      open={closeModalOpen}
      setOpen={setCloseModalOpen}
      confirmButtonDidClicked={closeStore}
    />
  );
  const DeleteDialogModal = (
    <DialogModal
      title="정말로 삭제하시겠습니까?"
      description="확인버튼을 누르시면 다시 돌이킬 수 없습니다. 신중히 선택하십시오."
      open={deleteModalOpen}
      setOpen={setDeleteModalOpen}
      confirmButtonDidClicked={deleteStore}
    />
  );
  const DiscardDialogModal = (
    <DialogModal
      title="정말로 휴지통에 넣으시겠습니까?"
      description="확인버튼을 누르시면 다시 돌이킬 수 없습니다. 신중히 선택하십시오."
      open={discardModalOpen}
      setOpen={setDiscardModalOpen}
      confirmButtonDidClicked={discardStore}
    />
  );

  return (
    <S.StyledStoreInnerDetail>
      {isLoading && <Loading />}
      {closeModalOpen && CloseDialogModal}
      {deleteModalOpen && DeleteDialogModal}
      {discardModalOpen && DiscardDialogModal}
      {clientRemoveModalOpen && ClientRemoveModal}
      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {StoreNameInput}
        </Grid>
        <Grid item xs={2}>
          {StoreNameReviewSelect}
        </Grid>
        <Grid item xs>
          {SubNameInput}
        </Grid>
        <Grid item xs={2}>
          {SubNameReviewSelect}
        </Grid>
      </S.Row>
      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {CategoryInput}
        </Grid>
        <Grid item xs>
          {CategoryCodeInput}
          {isCategoryCodeModalVisible && (
            <CategoryModal
              open={isCategoryCodeModalVisible}
              setOpen={setIsCategoryCodeModalVisible}
              setValue={setCategoryCodeState}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          {CategoryCodeReviewSelect}
        </Grid>
      </S.Row>
      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {CategoryDetailInput}
        </Grid>
        <Grid item xs>
          {FloorInput}
        </Grid>
        <Grid item xs>
          {LocationInput}
        </Grid>
        <Grid item xs={2}>
          {LocationReviewSelect}
        </Grid>
      </S.Row>
      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {sectionSelect}
        </Grid>
        <Grid item xs>
          {ComplexIDInput}
          {isComplexMatchingModalOpen && (
            <ComplexMatchingModal
              open={isComplexMatchingModalOpen}
              setOpen={setIsComplexMatchingModalOpen}
              setValue={setComplexIDState}
              currentStore={snakeObjToCamelObj(detail)}
            />
          )}
        </Grid>
        <Grid item xs>
          {BrandIDInput}
          {isBrandIDModalVisible && (
            <BrandModal
              open={isBrandIDModalVisible}
              setOpen={setIsBrandIDModalVisible}
              setValue={setBrandIDState}
              previousBrands={brandIDState}
            />
          )}
        </Grid>
      </S.Row>
      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {NIDInput}
          {NIDChip}
        </Grid>
        <Grid item xs>
          {DIDInput}
        </Grid>
        <Grid item xs>
          {SiteNameInput}
        </Grid>
      </S.Row>
      <S.Row container className={classes.margin}>
        <Grid item xs>
          <h3>클라이언트 추가하기</h3>
          <p>클라이언트 코드와 아이디 중 하나라도 비어있으면 안됩니다.</p>
        </Grid>
        {AddClientButton}
      </S.Row>
      <S.Row container spacing={1}>
        {clientIdState.map((_, index) => createClientRow(index))}
      </S.Row>
      <S.ButtonRow>
        <Button
          variant="outlined"
          color="default"
          onClick={() => setIsStoreInnerFpTableVisible(!isStoreInnerFpTableVisible)}
          className={classes.button}
        >
          FP 정보보기
        </Button>
      </S.ButtonRow>
      {isStoreInnerFpTableVisible && (
        <S.InnerRowTable>
          <FingerPrintTableSection pid={detail.pid} fingerprints={detail.fingerprint} />
        </S.InnerRowTable>
      )}
      <S.ButtonRow>
        {CloseButton}
        {DeleteButton}
        {DiscardButton}
        {CompleteButton}
      </S.ButtonRow>
    </S.StyledStoreInnerDetail>
  );
}
export default Infos;

Infos.propTypes = {};
