import { useState } from 'react';
import Wrapper from '@F/materialUI/Wrapper';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { InputsData } from '@TS/cashplace/event';
import { toastErrorMessage } from '@FUNC/toast';
import { actions } from '@/redux/cashplace/event/state';
import Inputs from '../Inputs';
import { kindOptions } from '../options';
import * as S from './styles';

const defaultInputs = {
  content: '',
  description: '',
  eventUrl: '',
  mainImageUrl: '',
  subImageUrl: '',
  buttonName: '',
  kind: ''
};

function isEmptyRequiredInputs(
  content: string,
  description: string,
  mainImageUrl: string,
  subImageUrl: string,
  kind: string
) {
  return (
    content.trim() === '' ||
    description.trim() === '' ||
    mainImageUrl.trim() === '' ||
    subImageUrl.trim() === '' ||
    kind.trim() === ''
  );
}

function CreateEvent(): JSX.Element {
  const [inputs, setInputs] = useState<InputsData>(defaultInputs);

  const dispatch = useDispatch();
  const createEvent = () => {
    if (
      isEmptyRequiredInputs(inputs.content, inputs.description, inputs.mainImageUrl, inputs.subImageUrl, inputs.kind)
    ) {
      toastErrorMessage('필수값이 빠져있습니다. 다시 시도해주세요.');
      return;
    }
    dispatch(actions.createEvent({ ...inputs, kind: kindOptions[inputs.kind] }));
  };

  return (
    <Wrapper>
      <S.StyledCreateEvent>
        <S.Title>이벤트 등록</S.Title>
        <Inputs
          onEmitInputs={setInputs}
          Buttons={
            <Button color="primary" variant="outlined" onClick={createEvent}>
              등록
            </Button>
          }
        />
      </S.StyledCreateEvent>
    </Wrapper>
  );
}
export default CreateEvent;
