import { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { useInput } from '@HOOK/';
import * as S from './styles';

type Props = {
  name: string;
  label: string;
  defaultDate?: string;
  onEmitDate: (date: string) => void;
  disabled: boolean;
};
function SingleDatePicker({ name, label = '날짜', defaultDate, onEmitDate, disabled = false }: Props): JSX.Element {
  const [date, changeDate] = useInput(defaultDate ?? moment().format('YYYY-MM-DD'));

  useEffect(() => {
    onEmitDate(date);
  }, [onEmitDate, date]);

  return (
    <S.Wrapper>
      <TextField
        name={name}
        label={label}
        type="date"
        value={date}
        onChange={changeDate}
        InputLabelProps={{
          shrink: true
        }}
        variant="outlined"
        size="small"
        disabled={disabled}
      />
    </S.Wrapper>
  );
}
export default SingleDatePicker;
