import { useCallback, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import TextInputForm from '@F/materialUI/TextInputForm';
import DialogModal from '@F/DialogModal';
import { useDispatch } from 'react-redux';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import { Report } from '@TS/cashplace/report';
import { copyMessageToClipboard } from '@HOOK/useCopy';
import { kindOptions, resultOptions } from '../../options';
import { actions } from '@/redux/cashplace/report/state';
import * as S from './styles';

type InfoSectionProps = {
  report: Report;
};

function InfoSection({ report }: InfoSectionProps): JSX.Element {
  const NameInput = <TextInputForm name="NameInput" label="Name" value={report.site.name} disabled />;
  const AddressInput = <TextInputForm name="AddressInput" label="Address" value={report.site.addr} disabled />;
  const FloorInput = <TextInputForm name="FloorInput" label="Floor" value={String(report.site.floor ?? '')} disabled />;
  const RefinedCategoryInput = (
    <TextInputForm name="RefinedCategoryInput" label="RefinedCategory" value={report.site.refined_category} disabled />
  );
  const CategoryInput = <TextInputForm name="CategoryInput" label="Category" value={report.site.category} disabled />;
  const PhoneInput = <TextInputForm name="PhoneInput" label="Phone" value={report.site.phone ?? ''} disabled />;
  const ClosedReportCountInput = (
    <TextInputForm
      name="ClosedReportCountInput"
      label="ClosedReportCount"
      value={String(report.site.closed_report_count)}
      disabled
    />
  );
  const PoiStatusInput = (
    <TextInputForm name="PoiStatusInput" label="PoiStatus" value={String(report.site.poi_status)} disabled />
  );
  const StateInput = <TextInputForm name="StateInput" label="State" value={String(report.site.state)} disabled />;
  const WatchingInput = (
    <TextInputForm name="WatchingInput" label="Watching" value={String(report.site.watching)} disabled />
  );
  const OriginPosition = (
    <TextInputForm
      name="OriginPosition"
      label="OriginPosition"
      value={`${String(report.site.lat)}, ${String(report.site.lng)}`}
      disabled
    />
  );
  const NewPosition = (
    <TextInputForm
      name="NewPositionInput"
      label="NewPositionInput"
      value={`${String(report.lat)}, ${String(report.lng)}`}
      disabled
    />
  );

  const [result, setResult] = useState<Report['result']>(resultOptions['올바름']);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleClickButton = useCallback((newResult: Report['result']) => {
    setResult(newResult);
    setIsModalOpen(true);
  }, []);

  const dispatch = useDispatch();
  const updateReportResult = () => {
    let latLngParams = {};
    if (report.kind === kindOptions['위치 수정'] && result === resultOptions['올바름']) {
      latLngParams = { lat: report.lat, lng: report.lng };
    }
    dispatch(actions.updateReport(report, { result, ...latLngParams }));
  };

  return (
    <S.StyledInfoSection>
      <Grid container spacing={1}>
        <Grid item xs>
          <p>이름</p>
          {NameInput}
        </Grid>
        <Grid item xs>
          <p>전화번호</p>
          {PhoneInput}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs>
          <p>주소</p>
          {AddressInput}
        </Grid>
        <Grid item xs>
          <p>층수</p>
          {FloorInput}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs>
          <p>카테고리</p>
          {RefinedCategoryInput}
        </Grid>
        <Grid item xs>
          <p>카테고리</p>
          {CategoryInput}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs>
          <p>Closed Report Count</p>
          {ClosedReportCountInput}
        </Grid>
        <Grid item xs>
          <p>POI Status</p>
          {PoiStatusInput}
        </Grid>
        <Grid item xs>
          <p>State</p>
          {StateInput}
        </Grid>
        <Grid item xs>
          <p>Watching</p>
          {WatchingInput}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <p style={{ color: 'blue', display: 'flex', alignItems: 'center' }}>
            원래 Site 위경도(Lat, Lng)
            <FileCopyTwoToneIcon
              style={{ cursor: 'pointer' }}
              onClick={() => copyMessageToClipboard(`${String(report.site.lat)}, ${String(report.site.lng)}`)}
            />
          </p>
          {OriginPosition}
        </Grid>
        {report.lng && report.lat && (
          <Grid item xs={6}>
            <p style={{ color: 'red', display: 'flex', alignItems: 'center' }}>
              유저가 지정한 위경도(Lat, Lng)
              <FileCopyTwoToneIcon
                style={{ cursor: 'pointer' }}
                onClick={() => copyMessageToClipboard(`${String(report.lat)}, ${String(report.lng)}`)}
              />
            </p>
            {NewPosition}
          </Grid>
        )}
      </Grid>

      <S.ButtonRow>
        <Button variant="outlined" color="secondary" onClick={() => handleClickButton(-1)}>
          틀림
        </Button>
        <Button variant="outlined" color="primary" onClick={() => handleClickButton(1)}>
          올바름
        </Button>
      </S.ButtonRow>

      <DialogModal
        title="리포트 검수"
        description={`해당 리포트를 ${result === resultOptions['올바름'] ? '올바름' : '틀림'} 처리합니다.`}
        open={isModalOpen}
        setOpen={setIsModalOpen}
        confirmButtonDidClicked={updateReportResult}
      />
    </S.StyledInfoSection>
  );
}
export default InfoSection;
