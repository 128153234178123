import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { theme } from '@S/index';
import { AuthContextProvider } from '@HOOK/useAuth';
import { SideMenuContextProvider } from '@HOOK/useSideMenu';

import reduxRoot from '@/redux/common/store';
import App from './App';
import AppErrorBoundary, { setThrownError } from './Templates/AppErrorBoundary';

window.addEventListener('error', (errorEvent) => {
  const { message, lineno, colno, error } = errorEvent;
  setThrownError(JSON.stringify({ message, lineno, colno, error }));
});

ReactDOM.render(
  <>
    <AppErrorBoundary>
      <SideMenuContextProvider>
        <AuthContextProvider>
          <ThemeProvider theme={theme}>
            <Provider store={reduxRoot.store}>
              <App />
            </Provider>
          </ThemeProvider>
        </AuthContextProvider>
      </SideMenuContextProvider>
    </AppErrorBoundary>
  </>,
  document.getElementById('root')
);
