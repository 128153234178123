import { AxiosError } from 'axios';
import { toastErrorMessage } from '@FUNC/toast';
import message from '@/constants/message';

export type AxiosInstanceType = 'manager' | 'homepage' | 'max' | 'cook' | 'cashplace';

const createOnRequestRejected =
  (axiosInstanceType: AxiosInstanceType) =>
  (error: AxiosError): never => {
    console.error({
      error,
      axiosInstanceType
    });

    toastErrorMessage(message[axiosInstanceType].requestFail);
    throw error;
  };

export default createOnRequestRejected;
