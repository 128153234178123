import Divider from '@material-ui/core/Divider';
import { Paper } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import {
  schemeRegionCondition,
  schemeMessageCondition,
  schemeTimeCondition,
  schemeInformation,
  schemeReport,
  schemeSegment
} from '@P/max/campaigns/utils/function';
import type { CampaignDetail } from '@P/max/campaigns/redux/campaigns/slice';
import Loading from '@F/Loading';
import BasicInfoSection from './BasicSection';
import ReportSection from './ReportSection';
import MessageSection from './MessageSection';
import RegionSection from './RegionSection';
import TimeConditionSection from './TimeSection';

type Props = {
  campaign: CampaignDetail | null;
};
const DetailReport = ({ campaign }: Props): JSX.Element => {
  if (!campaign) return <Loading />;

  const information = schemeInformation(campaign);
  const report = schemeReport(campaign);
  const selectedSegment = schemeSegment(campaign);
  const messageCondition = schemeMessageCondition(campaign);
  const timeCondition = schemeTimeCondition(campaign.operation, campaign.triggerRaw.nearby);
  const targetApps = campaign.targets.map((app) => ({ name: app.name, type: app.target_type }));
  const regions = campaign.triggerRaw.areas.map((area) => schemeRegionCondition(area));

  return (
    <NotBigPage>
      <BasicInfoSection information={information} targetApps={targetApps} segment={selectedSegment} />
      <Divider />
      <ReportSection report={report} />
      <Divider />
      <RegionSection regions={regions} />
      <Divider />
      <TimeConditionSection condition={timeCondition} />
      <Divider />
      <MessageSection conditions={messageCondition} />
    </NotBigPage>
  );
};

export default DetailReport;

const NotBigPage = styled(Paper)({
  maxWidth: 1200,
  marginBottom: 70
});
