import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import {
  selectCampaigns,
  selectPagination,
  selectStatus,
  selectCompareCampaigns
} from '@P/max/campaigns/redux/campaigns/selector';
import { roundToDecimalPoint, getPercent } from '@FUNC/math';
import type { TableRowType } from '@TS/max/table/campaignTable';
import RowSkeleton from './RowSkeleton';
import Row from './Row';
import { campaignStatus } from '../data';

const TableContent = (): JSX.Element => {
  const compareCampaigns = useSelector(selectCompareCampaigns);
  const campaigns = useSelector(selectCampaigns);
  const { size } = useSelector(selectPagination);
  const { campaigns: status } = useSelector(selectStatus);

  const currentPageCampaigns: TableRowType[] = useMemo(
    () =>
      campaigns?.map(
        ({
          adids,
          repeatType,
          campaignId,
          clientCode,
          name,
          status,
          hitCnt,
          clickCnt,
          operation,
          createdAt,
          company
        }) => ({
          campaignId,
          company,
          clientCode,
          name: {
            label: name,
            state: adids?.length ? (repeatType === 3 ? 'test' : 'commercial') : null
          },
          status: campaignStatus[status],
          hitCnt,
          click: { count: clickCnt, percent: roundToDecimalPoint(getPercent(clickCnt, hitCnt), 2) },
          start: operation.start,
          end: operation.end,
          createdAt: createdAt.split(' ')[0],
          isCheck: !!compareCampaigns.find((compareCampaign) => compareCampaign.campaignId === campaignId)
        })
      ) ?? [],
    [campaigns, compareCampaigns]
  );

  return (
    <TableBody>
      {status === 'idle' && (
        <TableRow>
          <TableCell colSpan={10}>검색해주세요.</TableCell>
        </TableRow>
      )}

      {status === 'loading' &&
        Array(Number(size))
          .fill(1)
          .map((_, idx) => <RowSkeleton key={idx} />)}

      {status === 'success' && !currentPageCampaigns.length ? (
        <TableRow>
          <TableCell colSpan={10}>검색결과가 없습니다.</TableCell>
        </TableRow>
      ) : (
        currentPageCampaigns.map((campaign) => <Row key={campaign.campaignId} {...campaign} />)
      )}
    </TableBody>
  );
};

export default TableContent;
