export const roleOptions = {
  전체: null,
  관리자: 'admin',
  매니저: 'manager',
  '일반 사용자': 'user',
  '외부 팀장': 'teamjang',
  아르바이트: 'alba',
  고객: 'customer'
};

export const statusOptions = {
  전체: null,
  활성: 1,
  비활성: 0
};
