import styled from 'styled-components';

export const TableWrapper = styled.div`
  margin: 2rem 0;
  border: 1px solid lightgray;
`;

export const TableBodyWrapper = styled.div`
  height: 370px;
  overflow-y: scroll;
`;
