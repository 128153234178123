export default function getCategoryName(targetCode, categoryList, string = '', level = 1) {
  let categoryName = string;
  if (categoryList?.length > 0) {
    return categoryList
      .map((category) => {
        if (targetCode === category.code) {
          return `${string} ${category.kor_name}`;
        }
        for (let i = 1; i < level + 1; i += 1) {
          if (targetCode.substr(0, i * 2 + 1) + 'f'.repeat(8 - i * 2) === category.code) {
            categoryName += `${category.kor_name} > `;
            return getCategoryName(targetCode, category.children, categoryName, level + 1);
          }
        }

        return undefined;
      })
      .flat(level)
      .filter(Boolean);
  }
  return null;
}
