import { complexesApi } from '@API/manager';

export function patchComplex({
  cid,
  name,
  branchName,
  categoryCode,
  addr,
  addrRoad,
  lat,
  lng,
  service,
  qaName,
  qaBranchName,
  qaAddress,
  nid,
  bids,
  parent
}) {
  return complexesApi
    .updateComplex({
      cid,
      name,
      branchName,
      categoryCode,
      addr,
      addrRoad,
      lat,
      lng,
      service,
      qaName,
      qaBranchName,
      qaAddress,
      nid,
      bids,
      parent
    })
    .then((response) => response.data);
}

export function fetchComplexes(
  page,
  perPage,
  {
    name,
    branchName,
    startDate,
    endDate,
    categoryCode,
    categoryIsNull,
    categoryExclude,
    equalSearch,
    collector,
    parent,
    bid
  }
) {
  return complexesApi
    .getComplexes({
      perPage,
      page,
      name,
      branchName,
      startDate,
      endDate,
      categoryCode,
      categoryIsNull,
      categoryExclude,
      equalSearch,
      collector,
      parent,
      bid
    })
    .then((response) => response.data);
}

export function fetchComplex({ cid }) {
  return complexesApi.getComplex({ cid }).then((response) => response.data);
}
