import { CompareCampaign } from '@P/max/campaigns/redux/campaigns/slice';
import { Body } from 'loplat-ui';
import { AlertMessage } from './styles';

type Props = {
  quantity: number;
  compareCampaigns: Pick<CompareCampaign, 'name' | 'campaignId'>[];
};
const HelperMessage = ({ quantity, compareCampaigns }: Props): JSX.Element => (
  <p>
    {quantity === 2 ? (
      <>
        <AlertMessage>
          {compareCampaigns[0].name.label}({compareCampaigns[0].campaignId})
        </AlertMessage>
        <Body tag="span" mx={1}>
          와
        </Body>
        <AlertMessage>
          {compareCampaigns[1].name.label}({compareCampaigns[1].campaignId})
        </AlertMessage>
        를 비교하겠습니까?
      </>
    ) : quantity > 2 ? (
      <AlertMessage>캠페인 비교는 2개까지만 가능합니다.</AlertMessage>
    ) : (
      <AlertMessage>비교할 캠페인이 선택되지 않았습니다. 목록에서 선택해 주세요.</AlertMessage>
    )}
  </p>
);

export default HelperMessage;
