/* eslint-disable no-console */
import '@toast-ui/editor/dist/toastui-editor.css';

import { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import firebase from 'firebase';

import { Editor } from '@toast-ui/react-editor';

import { toastErrorMessage } from '@FUNC/toast';
import { managerFirebaseProject } from '@/firebase';

interface MarkdownEditorProps {
  editorRef: React.RefObject<Editor>;
  initialValue?: string;
  firebaseFolderName?: string;
}

export default function MarkdownEditor({
  editorRef,
  initialValue,
  firebaseFolderName = 'images',
  ...rest
}: MarkdownEditorProps): JSX.Element {
  const uploadBlobImage = useCallback((blobFile: File, callback: any) => {
    const filePath = (file: File) => {
      const today = moment();
      const folderName = `${firebaseFolderName}/${today.format('YYYY_MM_DD')}`;
      const fileName = `${today.format('hh_mm_ss')} - ${file.name.split(' ').join('_')}`;

      return `${folderName}/${fileName}`;
    };

    const uploadPath = filePath(blobFile);
    const uploadTask = managerFirebaseProject.storage().ref(uploadPath).put(blobFile);

    const onStartUpload = (snapshot: any) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

      console.log(`Upload is ${progress}% done`);

      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log('Upload is running');
          break;
        default:
          break;
      }
    };

    const onErrorUpload = (error: Error) => {
      toastErrorMessage(error.message);
    };

    const onSuccessUpload = () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        callback(downloadURL);
      });
    };

    uploadTask.on('state_changed', onStartUpload, onErrorUpload, onSuccessUpload);

    return false;
  }, []);

  useEffect(() => {
    if (!editorRef.current) {
      return;
    }

    editorRef.current.getInstance().addHook('addImageBlobHook', uploadBlobImage);
  }, [uploadBlobImage, editorRef]);

  return (
    <StyledMarkdownEditor {...rest}>
      <Editor
        ref={editorRef}
        initialEditType="wysiwyg"
        initialValue={initialValue}
        height="600px"
        usageStatistics={false}
      />
    </StyledMarkdownEditor>
  );
}

const StyledMarkdownEditor = styled.div`
  margin-bottom: 10px;

  > * {
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 3px; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }

    ::-webkit-scrollbar-thumb {
      background: #eaeaea;
      border-radius: 10px;
    }
  }

  textarea {
    border: none;
    resize: none;
  }
`;
