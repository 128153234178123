import CreateEvent from './CreateEvent';
import Filter from './Filter';
import TableSection from './tableSection/TableSection';
import * as S from './styles';

function Event(): JSX.Element {
  return (
    <S.StyledEvent>
      <CreateEvent />
      <br />
      <Filter />
      <br />
      <TableSection />
    </S.StyledEvent>
  );
}
export default Event;
