import React, { MouseEventHandler, useState } from 'react';
import { TableCell, TableSortLabel } from '@material-ui/core';

import Tooltip from '@F/Tooltip';
import { toggleOrder } from '@FUNC/order';

interface TableHeadCell<N> {
  name?: N;
  label?: string;
  defaultOrderType?: OrderType;
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, name?: N, orderType?: OrderType) => void;
  isActive?: boolean;
  children?: React.ReactNode;
}

export default function TableHeadCell<N>({
  name,
  label,
  defaultOrderType,
  onClick,
  isActive,
  children,
  ...rest
}: TableHeadCell<N>): JSX.Element {
  const [orderType, setOrderType] = useState<OrderType | undefined>(defaultOrderType);

  const handleClick: MouseEventHandler<HTMLSpanElement> = (e) => {
    if (!onClick) return;

    if (!name) {
      onClick(e);
      return;
    }

    if (orderType === undefined) {
      onClick(e, name);
      return;
    }

    setOrderType((prev) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const toggledOrderType = toggleOrder(prev!);
      onClick(e, name, toggledOrderType);
      return toggledOrderType;
    });
  };

  const orderKor = (orderType: OrderType) => (orderType === 'DESC' ? '내림차순' : '오름차순');

  return (
    <TableCell align="center" {...rest}>
      {orderType ? (
        <Tooltip
          title={
            isActive
              ? `${orderKor(orderType)}으로 정렬되었습니다.`
              : `${orderKor(toggleOrder(orderType))}으로 정렬합니다.`
          }
          enterDelay={300}
          style={{ transform: 'translateX(12px)' }}
        >
          <TableSortLabel
            active={isActive}
            direction={orderType.toLowerCase() as Lowercase<OrderType>}
            onClick={handleClick}
          >
            {children || <b>{label}</b>}
          </TableSortLabel>
        </Tooltip>
      ) : (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div onClick={handleClick} role="button" tabIndex={-1}>
          {children || <b>{label}</b>}
        </div>
      )}
    </TableCell>
  );
}
