import PinTier1 from '@I/map/pin-purple.png';
import PinTier2 from '@I/map/pin-blue.png';
import PinTier3 from '@I/map/pin-skyblue.png';
import PinTier4 from '@I/map/pin-lightblue.png';
import RedPin from '@I/map/pin-red.png';

export function getImageBySite(site) {
  if (site.charge) return RedPin;

  const pinTier = site.pin_tier;
  if (pinTier === 1) return PinTier1;
  if (pinTier === 2) return PinTier2;
  if (pinTier === 3) return PinTier3;
  if (pinTier === 4) return PinTier4;
  return null;
}

/**
 * 해당 point 가 폴리곤에 포함되는지 판단하는 함수
 * 참고한 알고리즘: 레이 케스팅 알고리즘 (https://bowbowbow.tistory.com/24)
 * github js 코드 url: https://github.com/substack/point-in-polygon/blob/master/index.js
 */
export function determinePolygonContainsPoint(point, polygonPath) {
  const x = point[0];
  const y = point[1];

  let inside = false;
  // eslint-disable-next-line no-plusplus
  for (let i = 0, j = polygonPath.length - 1; i < polygonPath.length; j = i++) {
    const xi = polygonPath[i][0];
    const yi = polygonPath[i][1];
    const xj = polygonPath[j][0];
    const yj = polygonPath[j][1];

    // 1. 해당 point 의 y 좌표가 yi 와 yj 사이에 있음
    // 2. 해당 point 의 x 좌표에서 오른쪽으로 반직선을 그었을 때, Point i 와 Point j 를 잇는 직선과 만남
    const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

    if (intersect) inside = !inside;
  }
  return inside;
}

export function getComplexFullName(complex) {
  return `${complex.name} ${complex.branch_name}`;
}
