import styled from 'styled-components';
import { FormikValues, FormikErrors, FormikTouched } from 'formik';
import { MenuItem as OptionItem, InputLabel, Select as Options, FormControl } from '@material-ui/core';
import TextInputForm from '@F/materialUI/TextInputForm';
import type { RecruitForm, RecruitCareer, RecruitJob, RecruitType } from '@TS/homepage/recruit';

interface I_FieldsProps {
  values: FormikValues;
  handleChange: <T>(e: React.ChangeEvent<T>) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  errors: FormikErrors<RecruitForm>;
  touched: FormikTouched<RecruitForm>;
}

const RECRUIT_FORM_FIELDS: {
  type?: string;
  label: string;
  name: keyof RecruitForm;
  options?: { label: string; value: RecruitJob | RecruitCareer | RecruitType }[];
}[] = [
  {
    label: '모집기간',
    name: 'recruit_type',
    options: [
      {
        label: '상시',
        value: 'ALWAYS'
      },
      {
        label: '수시',
        value: 'ONDEMAND'
      }
    ]
  },
  {
    label: '경력구분',
    name: 'career',
    options: [
      {
        label: '신입',
        value: 'NEWCOMER'
      },
      {
        label: '경력',
        value: 'EXPERIENCED'
      }
    ]
  },
  {
    label: '직군',
    name: 'job',
    options: [
      {
        label: '개발',
        value: 'DEVELOPMENT'
      },
      {
        label: '비즈니스',
        value: 'BUSINESS'
      },
      {
        label: '디자인',
        value: 'DESIGN'
      },
      {
        label: '데이터',
        value: 'DATA'
      },
      {
        label: '영업',
        value: 'SALES'
      },
      {
        label: 'HR',
        value: 'HR'
      }
    ]
  },
  {
    type: 'date',
    label: '시작일',
    name: 'start_date'
  }
];

export default function RecruitPositionFormFields({
  errors,
  touched,
  values,
  handleChange,
  handleBlur
}: I_FieldsProps): JSX.Element {
  return (
    <StyledRecruitPositionFormFields>
      <TextInputForm
        error={Boolean(errors.title && touched.title)}
        className="field-title"
        label="제목"
        name="title"
        value={values.title}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <div className="fields">
        {RECRUIT_FORM_FIELDS.map((field) =>
          field.options ? (
            <FormControl key={field.name} fullWidth variant="outlined">
              <InputLabel id={field.name} error={Boolean(errors[field.name] && touched[field.name])}>
                {field.label}
              </InputLabel>
              <Options
                error={Boolean(errors[field.name] && touched[field.name])}
                id={field.name}
                label={field.name}
                name={field.name}
                labelId={field.name}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values[field.name]}
              >
                {field.options.map((option) => (
                  <OptionItem value={option.value}>{option.label}</OptionItem>
                ))}
              </Options>
            </FormControl>
          ) : (
            <TextInputForm
              key={field.name}
              fullWidth
              type={field.type}
              name={field.name}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values[field.name]}
            />
          )
        )}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span> ~ </span>
        </div>
        <TextInputForm
          key="end_date"
          fullWidth
          type="date"
          name="end_date"
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.end_date}
        />
      </div>
    </StyledRecruitPositionFormFields>
  );
}

const StyledRecruitPositionFormFields = styled.div`
  .field-title {
    width: 20%;
    padding-right: 15px;
    margin-bottom: 10px;
  }

  .fields {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    & > *:not(:last-child) {
      margin-right: 15px;
    }
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }
`;
