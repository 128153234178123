import { camelToSnake } from '@FUNC/word';
import { ORDER_BY_TO_BACKEND_CONVENTION } from '@FUNC/order';
import { axiosInstancesManager } from '@API/axios';
/* eslint class-methods-use-this: 0 */

class CookSitesApi {
  getSite({ siteId }) {
    return axiosInstancesManager.manager.get(`/cook/sites/${siteId}`);
  }

  getSiteList({
    period,
    charge,
    chargeResult,
    page,
    perPage,
    floor,
    complexId,
    pin_tier,
    addr_lv1,
    addr_lv2,
    addr_lv3
  }) {
    return axiosInstancesManager.manager.get('/cook/sites', {
      params: {
        start_date: period.startDate,
        end_date: period.endDate,
        charge,
        charge_result: chargeResult,
        page,
        per_page: perPage,
        floor,
        cid: complexId,
        pin_tier,
        addr_lv1,
        addr_lv2,
        addr_lv3
      }
    });
  }

  getClosedSites({
    page,
    perPage,
    order,
    orderBy,
    complexId,
    siteId,
    storeId,
    complexName,
    complexBranchName,
    placeName,
    placeBranchName,
    collector,
    reviewState,
    categoryCode,
    floor,
    startDate,
    endDate,
    searchByPeriodOption = 'collected'
  }) {
    return axiosInstancesManager.manager.get('/cook/sites', {
      params: {
        page,
        per_page: perPage,
        orders:
          orderBy && order
            ? [`${camelToSnake(ORDER_BY_TO_BACKEND_CONVENTION.closingManager[orderBy] || orderBy)} ${order}`]
            : [],
        charge_result: 2,
        cid: complexId,
        target_site_id: siteId,
        target_store_id: storeId,
        complex_name: complexName,
        complex_branch_name: complexBranchName,
        name: placeName,
        subname: placeBranchName,
        charge: collector,
        report_status: reviewState,
        category_code: categoryCode,
        floor,
        result_at_start_date: searchByPeriodOption === 'collected' ? startDate : null,
        result_at_end_date: searchByPeriodOption === 'collected' ? endDate : null,
        report_status_qa_at_start_date: searchByPeriodOption === 'reviewed' ? startDate : null,
        report_status_qa_at_end_date: searchByPeriodOption === 'reviewed' ? endDate : null
      }
    });
  }

  getClosedSitesCsv({
    page,
    perPage,
    order,
    orderBy,
    complexId,
    siteId,
    storeId,
    complexName,
    complexBranchName,
    placeName,
    placeBranchName,
    collector,
    reviewState,
    categoryCode,
    floor,
    startDate,
    endDate,
    searchByPeriodOption = 'collected'
  }) {
    return axiosInstancesManager.manager.get('/cook/sites', {
      headers: {
        Accept: 'text/csv'
      },
      params: {
        page,
        per_page: perPage,
        orders:
          orderBy && order
            ? [`${camelToSnake(ORDER_BY_TO_BACKEND_CONVENTION.closingManager[orderBy] || orderBy)} ${order}`]
            : [],
        charge_result: 2,
        cid: complexId,
        target_site_id: siteId,
        target_store_id: storeId,
        complex_name: complexName,
        complex_branch_name: complexBranchName,
        name: placeName,
        subname: placeBranchName,
        charge: collector,
        report_status: reviewState,
        category_code: categoryCode,
        floor,
        result_at_start_date: searchByPeriodOption === 'collected' ? startDate : null,
        result_at_end_date: searchByPeriodOption === 'collected' ? endDate : null,
        report_status_qa_at_start_date: searchByPeriodOption === 'reviewed' ? startDate : null,
        report_status_qa_at_end_date: searchByPeriodOption === 'reviewed' ? endDate : null
      }
    });
  }

  patchSite({ siteId, action }) {
    return axiosInstancesManager.manager.patch(`/cook/sites/${siteId}`, {
      action
    });
  }

  getAddr3List({ addr1, addr2, signal }) {
    return axiosInstancesManager.manager.get('/cook/sites/quota', { params: { addr1, addr2 }, signal });
  }

  getSitesWithinBoundary({ coords, page, perPage }) {
    return axiosInstancesManager.manager.get('/cook/sites/boundary', {
      params: { ...coords, page, per_page: perPage }
    });
  }

  getBulkSites({ perPage, pinTiers, addresses, coords, ...params }) {
    return axiosInstancesManager.manager.get('/cook/sites/bulk', {
      params: {
        per_page: perPage,
        pin_tier: pinTiers,
        ...(addresses
          ? {
              addr_lv1: addresses.addr1,
              addr_lv2: addresses.addr2,
              addr_lv3: addresses.addr3 === '전체' ? '' : addresses.addr3
            }
          : {}),
        ...coords,
        ...params
      }
    });
  }

  chargeSiteOnUser({ user, sites }) {
    return axiosInstancesManager.manager.post('/cook/sites/charge', { targets: sites, collector: user });
  }

  rechargeSiteOnUser({ user, sites }) {
    return axiosInstancesManager.manager.patch('/cook/sites/charge', { targets: sites, collector: user });
  }
}
export default new CookSitesApi();
