import Wrapper from '@F/materialUI/Wrapper';
import Filter from './Filter';
import CreateWhitelist from './CreateWhitelist';
import TableSection from './tableSection/TableSection';
import * as S from './styles';

function Whitelist() {
  return (
    <S.StyledWhitelist>
      <Wrapper>
        <CreateWhitelist />
      </Wrapper>
      <br />
      <Wrapper>
        <Filter />
      </Wrapper>
      <br />
      <Wrapper>
        <TableSection />
      </Wrapper>
    </S.StyledWhitelist>
  );
}
export default Whitelist;

Whitelist.propTypes = {};
