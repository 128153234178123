import { useState } from 'react';
import { storesApi } from '@API/manager';
import DialogModal from '@F/DialogModal';
import Loading from '@F/Loading';
import ResponseAlert from '@F/ResponseAlert';
import { useFetch } from '@HOOK/';

function RemoveFpDialog(props) {
  const { open, setOpen, fpid, pid, setUpdatedFingerPrints, setSelected } = props;
  const { callApi, isLoading, isError, error } = useFetch();
  const [responseAlertOpen, setResponseAlertOpen] = useState(false);

  const confirmButtonDidClicked = async () => {
    const response = await callApi(storesApi.patchStoreUnMap, {
      items: [
        {
          pid,
          bid: 0,
          cid: 0,
          fpid: fpid.fpid,
          delete: true
        }
      ]
    });

    setSelected([]);
    setUpdatedFingerPrints((prev) => prev.filter((fp) => fp.fpid !== fpid.fpid));
    setResponseAlertOpen(true);
    if (response) {
      setOpen(false);
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <ResponseAlert
        error={isError}
        open={responseAlertOpen}
        handleClose={() => setResponseAlertOpen(false)}
        message={isError ? String(error) : '성공하였습니다.'}
      />
      <DialogModal
        title="fpid 삭제"
        description="정말로 fpid를 삭제하시겠습니까?"
        open={open}
        setOpen={setOpen}
        confirmButtonDidClicked={confirmButtonDidClicked}
      />
    </>
  );
}

export default RemoveFpDialog;
