import Wrapper from '@F/materialUI/Wrapper';
import Filter from './Filter';
import TableSection from './tableSection/TableSection';
import * as S from './styles';

function ManualMapping() {
  return (
    <S.StyledManualMapping>
      <Wrapper>
        <Filter />
      </Wrapper>
      <br />
      <Wrapper>
        <TableSection />
      </Wrapper>
    </S.StyledManualMapping>
  );
}
export default ManualMapping;

ManualMapping.propTypes = {};
