import { useCallback, useState } from 'react';
import EnhancedTable from '@F/table/Table';
import Button from '@material-ui/core/Button';

import AddFpDialog from '@P/store/StoreManager/dialogs/AddFpDialog';
import RemoveFpDialog from '@P/store/StoreManager/dialogs/RemoveFpDialog';
import UpdateFpDialog from '@P/store/StoreManager/dialogs/UpdateFpDialog';
import moment from 'moment';
import { storesApi } from '@API/manager';
import ConfirmModal from '@F/modal/ConfirmModal';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';

import * as S from './styles';

function createHeadCells(id, label, numeric = false, disablePadding = false) {
  return {
    id,
    numeric,
    disablePadding,
    label
  };
}

const headCells = [
  createHeadCells('fpID', 'FP ID'),
  createHeadCells('position', '위치 (lat, lng)'),
  createHeadCells('date', 'date (created_at)'),
  createHeadCells('collector', 'collector'),
  createHeadCells('floor', 'floor', true, true),
  createHeadCells('actions', '')
];

function FingerPrintTableSection(props) {
  const { pid, fingerprints } = props;
  const [updatedFingerprints, setUpdatedFingerPrints] = useState(fingerprints);

  const classes = S.useStyles();
  const [selected, setSelected] = useState([]);
  const [selectedFPID, setSelectedFPID] = useState([]);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

  const loadFingerPrintsData = useCallback(async () => {
    const response = await storesApi.getStoreDataByPID({ pid });
    if (response) {
      setUpdatedFingerPrints(response.data.fingerprint);
    }
  }, [fingerprints]);

  // UI: Buttons
  const addButton = (
    <Button
      variant="outlined"
      color="primary"
      disableElevation
      className={classes.button}
      onClick={() => setAddDialogOpen(true)}
      key="addFPButton"
    >
      FP추가
    </Button>
  );
  function updateButton(fpid) {
    return (
      <Button
        id={fpid}
        variant="contained"
        color="primary"
        disableElevation
        className={classes.button}
        disabled={selected.length > 1}
        onClick={() => {
          setUpdateDialogOpen(true);
          setSelectedFPID({ fpid });
        }}
      >
        수정
      </Button>
    );
  }

  function deleteButton(fpid) {
    return (
      <Button
        id={fpid}
        variant="contained"
        color="secondary"
        disableElevation
        className={classes.button}
        disabled={selected.length > 1}
        onClick={() => {
          setRemoveDialogOpen(true);
          setSelectedFPID({ fpid });
        }}
      >
        삭제
      </Button>
    );
  }

  function selectAllButton(selected) {
    return (
      <Button
        variant="contained"
        color="secondary"
        disableElevation
        className={classes.unselectAllButton}
        onClick={() => {
          setSelected(updatedFingerprints.map((fp) => ({ id: String(fp.fpid) })));
        }}
      >
        전체 선택
      </Button>
    );
  }

  function unselectAllButton(selected) {
    return (
      <Button
        variant="contained"
        color="secondary"
        disableElevation
        className={classes.unselectAllButton}
        onClick={() => {
          setSelected([]);
        }}
      >
        선택 취소
      </Button>
    );
  }

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deletingAll, setDeletingAll] = useState(false);
  const permitDeleteAll = async () => {
    setDeletingAll(true);

    try {
      await storesApi.patchStoreUnMap({
        items: selected.map((selectedItem) => {
          const fpid = selectedItem.id;
          return { fpid, pid, bid: 0, cid: 0, delete: true };
        })
      });

      setUpdatedFingerPrints((prev) => prev.filter((fp) => !selected.find((s) => Number(s.id) === fp.fpid)));
      setSelected([]);
      toastSuccessMessage('전체 삭제 요청에 성공했습니다.');
      setDeletingAll(false);
      setShowConfirmModal(false);
    } catch (e) {
      setShowConfirmModal(false);
      setDeletingAll(false);
      toastErrorMessage('전체 삭제 요청에 실패했습니다.');
    }
  };
  const confirmDeleteAllModal = (
    <ConfirmModal
      isOpen={showConfirmModal}
      title="fpid 일괄 삭제"
      content={deletingAll ? '삭제 중입니다...' : `${selected.length}개의 fpid를 일괄 삭제하시겠습니까?`}
      onPermit={permitDeleteAll}
      onCancel={() => setShowConfirmModal(false)}
      disabled={deletingAll}
    />
  );
  function deleteAllButton(selected) {
    return (
      <Button
        variant="contained"
        color="secondary"
        disableElevation
        className={classes.deleteAllButton}
        disabled={selected.length < 2}
        onClick={() => setShowConfirmModal(true)}
      >
        삭제
      </Button>
    );
  }

  const rows =
    updatedFingerprints.length === 0
      ? [
          {
            id: '',
            position: '',
            date: '',
            collector: '',
            floor: '',
            actions: []
          }
        ]
      : updatedFingerprints.map((item) => ({
          id: `${item.fpid}`,
          position: `${item.lat}, ${item.lng}`,
          date: item.created_at ? moment(item.created_at).format('YYYY-MM-DD HH:mm:ss') : '',
          collector: item.collector,
          floor: item.floor,
          actions: [updateButton(item.fpid), deleteButton(item.fpid)]
        }));

  return (
    <S.StyledStoreInnerFpTable>
      <EnhancedTable
        titleText={['FP 정보 ', addButton]}
        headCells={headCells}
        rows={rows}
        initialOrder="desc"
        initialOrderBy="carbs"
        isCollapsible={false}
        isCheckable={false}
        selected={selected}
        setSelected={setSelected}
        selectedActions={[selected.length ? unselectAllButton() : selectAllButton(selected), deleteAllButton(selected)]}
        defaultrowsPerPage={5}
      />

      <AddFpDialog
        pid={pid}
        open={addDialogOpen}
        setOpen={setAddDialogOpen}
        loadFingerPrintsData={loadFingerPrintsData}
      />

      <UpdateFpDialog
        currentPid={pid}
        open={updateDialogOpen}
        setOpen={setUpdateDialogOpen}
        fpid={selectedFPID}
        loadFingerPrintsData={loadFingerPrintsData}
      />
      <RemoveFpDialog
        pid={pid}
        open={removeDialogOpen}
        setOpen={setRemoveDialogOpen}
        fpid={selectedFPID}
        setUpdatedFingerPrints={setUpdatedFingerPrints}
        setSelected={setSelected}
      />
      {confirmDeleteAllModal}
    </S.StyledStoreInnerFpTable>
  );
}

export default FingerPrintTableSection;

FingerPrintTableSection.propTypes = {};
