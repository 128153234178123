import { TableRow, TableCell } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { TABLE_HEAD } from '../data';

const RowSkeleton = (): JSX.Element => (
  <TableRow>
    {TABLE_HEAD.map(({ width }, idx) => (
      <TableCell width={width} key={idx}>
        <Skeleton variant="text" height={25} animation="wave" />
      </TableCell>
    ))}
  </TableRow>
);

export default RowSkeleton;
