import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import ReactLoading from 'react-loading';
import { TableCell, TableRow, Switch, IconButton, colors, withStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { Recruit, RecruitCareer, RecruitJob, RecruitStatus } from '@TS/homepage/recruit';
import ConfirmModal from '@F/modal/ConfirmModal';

import { actions } from '../redux/slice';

const JOB_ENG_TO_KOR: {
  [Property in RecruitJob]: string;
} = {
  DATA: '데이터',
  DEVELOPMENT: '개발',
  DESIGN: '디자인',
  BUSINESS: '비즈니스',
  SALES: '판매',
  HR: '인사'
};

const CAREER_ENG_TO_KOR: {
  [Property in RecruitCareer]: string;
} = {
  EXPERIENCED: '경력',
  NEWCOMER: '신입'
};

interface I_Props {
  recruit: Recruit;
}

export default function RecruitItem({ recruit }: I_Props): JSX.Element | null {
  // recruitItem status
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);

  // api call
  const [isUpdatingRecruit, setIsUpdatingRecruit] = useState(false);
  const [isDeletingRecruit, setIsDeletingRecruit] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const togglePublishStatus = (status: RecruitStatus) => (status === 'PUBLISH' ? 'PENDING' : 'PUBLISH');

  const handleClickPublishStatusToggler = async (e: React.MouseEvent) => {
    e.stopPropagation();

    setIsUpdatingRecruit(true);

    dispatch(
      actions.updateRecruit({
        id: recruit.id,
        data: {
          status: togglePublishStatus(recruit.status)
        },
        callback: () => setIsUpdatingRecruit(false)
      })
    );
  };

  const handleConfirmDeleteRecruit = async (e: React.MouseEvent) => {
    e.stopPropagation();

    setIsDeletingRecruit(true);

    dispatch(
      actions.deleteRecruit({
        id: recruit.id,
        callback: () => {
          setIsDeletingRecruit(false);
        }
      })
    );
  };

  const handleClickRecruitItem = () => {
    dispatch(actions.setFocused(recruit));
    navigate('preview');
  };

  const toEditPage = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(actions.setFocused(recruit));
    navigate('edit');
  };

  const openConfirmDeleteRecruit = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpenConfirmDelete(true);
  };

  const closeConfirmDeleteRecruit = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpenConfirmDelete(false);
  };

  const { id, job, career, title, creator, created_at, updated_at, start_date, end_date } = recruit;

  const korJob = JOB_ENG_TO_KOR[job];
  const korCareer = CAREER_ENG_TO_KOR[career];

  return (
    <>
      <TableRow hover key={id} onClick={handleClickRecruitItem}>
        <StyledCursoredCell align="center" component="th" scope="row">
          {korJob}
        </StyledCursoredCell>
        <StyledCursoredCell align="center">{korCareer}</StyledCursoredCell>
        <StyledCursoredCell align="center">{title}</StyledCursoredCell>
        <StyledCursoredCell align="center">{`${start_date} ~ ${end_date || ''}`}</StyledCursoredCell>
        <StyledCursoredCell align="center">{creator}</StyledCursoredCell>
        <StyledCursoredCell align="center">{moment(created_at).format('YYYY.MM.DD HH:mm:ss')}</StyledCursoredCell>
        <StyledCursoredCell align="center">{moment(updated_at).format('YYYY.MM.DD HH:mm:ss')}</StyledCursoredCell>
        <TableCell align="center">
          {isUpdatingRecruit ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <ReactLoading type="cylon" color={colors.indigo[600]} height={40} width={40} />
            </div>
          ) : (
            <Switch
              color="primary"
              onClick={handleClickPublishStatusToggler}
              checked={recruit.status === 'PUBLISH'}
              disabled={isUpdatingRecruit}
            />
          )}
        </TableCell>
        <TableCell align="center">
          <IconButton aria-label="edit" onClick={toEditPage}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label="delete" onClick={openConfirmDeleteRecruit} disabled={isDeletingRecruit}>
            {isDeletingRecruit ? (
              <ReactLoading type="cylon" color={colors.indigo[600]} height={20} width={20} />
            ) : (
              <DeleteIcon />
            )}
          </IconButton>
        </TableCell>
      </TableRow>

      <ConfirmModal
        isOpen={isOpenConfirmDelete}
        title="삭제"
        subTitle="해당 채용 정보를 삭제하시겠습니까?"
        content={`직군 : ${korJob}\n포지션 : ${title}\n대상 : ${korCareer}`}
        onCancel={closeConfirmDeleteRecruit}
        onPermit={handleConfirmDeleteRecruit}
      />
    </>
  );
}

const StyledCursoredCell = withStyles({
  root: { cursor: 'pointer' }
})(TableCell);
