import { PrimitiveLogParams } from '@TS/max/primitiveLog/api';
import { PrimitiveLog } from '@TS/max/primitiveLog/primitiveLog';
import dayjs from 'dayjs';

export type PrimitiveLogFilterValues = {
  client_id: string;
  log: {
    type: Extract<keyof PrimitiveLogParams, 'adid' | 'campaign_id' | 'echo_code'>;
    value: string;
  };
  dates: [string, string];
};

export type OrderBy = {
  name: Extract<keyof PrimitiveLog, 'ts_local'>;
  orderType: OrderType;
};

export const PRIMITIVE_LOG_TABLE_HEAD_CELLS: {
  name: Extract<keyof PrimitiveLog, 'ts_local' | 'adid' | 'echo_code' | 'campaign_id' | 'client_id'>;
  label: string;
  defaultOrderType: OrderType;
  initialIsActive?: boolean;
}[] = [
  {
    name: 'ts_local',
    label: '시간',
    defaultOrderType: 'DESC',
    initialIsActive: true
  },
  {
    name: 'adid',
    label: 'adid',
    defaultOrderType: 'DESC'
  },
  {
    name: 'echo_code',
    label: 'echo 코드',
    defaultOrderType: 'DESC'
  },
  {
    name: 'campaign_id',
    label: '캠페인 id',
    defaultOrderType: 'DESC'
  },
  {
    name: 'client_id',
    label: '회사명',
    defaultOrderType: 'DESC'
  }
];

export const PRIMITIVE_LOG_TABLE_INITIAL_ORDER_BY: OrderBy = {
  name: 'ts_local',
  orderType: 'DESC'
};

export const INITIAL_PRIMITIVE_LOG_FILTER_VALUES: PrimitiveLogFilterValues = {
  client_id: '',
  log: {
    type: 'adid',
    value: ''
  },
  dates: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
};

export const LOG_TYPES: {
  name: Extract<keyof PrimitiveLogParams, 'adid' | 'campaign_id' | 'echo_code'>;
  label: string;
}[] = [
  {
    name: 'adid',
    label: 'ADID'
  },
  {
    name: 'campaign_id',
    label: 'Campaign ID'
  },
  {
    name: 'echo_code',
    label: 'Echo Code'
  }
];
