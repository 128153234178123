import { useCallback } from 'react';
import { TableCell, TableRow, Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import { grayscale800 } from 'loplat-ui';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { actions } from '@P/max/campaigns/redux/campaigns/slice';
import { CampaignStatusBadge, CampaignName } from '@P/max/campaigns/module';
import type { TableRowType } from '@TS/max/table/campaignTable';
import { TABLE_HEAD } from '../data';

type Campaign = Omit<TableRowType, 'isCheck'>;
const Row = ({ isCheck, ...campaign }: TableRowType): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const toggleCompareCampaign = useCallback(() => {
    const { name, status, start, end, campaignId } = campaign;
    dispatch(
      actions.toggleCompareCampaign({
        name,
        status,
        duration: `${start} ~ ${end}`,
        campaignId,
        selected: true
      })
    );
  }, [dispatch, campaign]);

  const seeFullDetail = useCallback(() => {
    const { campaignId } = campaign;
    navigate(`/x/campaigns/${campaignId}`);
  }, [campaign, navigate]);

  return (
    <ClickableTableRow hover selected={isCheck} onClick={seeFullDetail}>
      <TableCell
        align="center"
        width={60}
        scope="row"
        onClick={(e) => {
          e.stopPropagation();
          toggleCompareCampaign();
        }}
      >
        <Checkbox
          name={campaign.campaignId.toString()} // eslint-disable-line react/destructuring-assignment
          checked={isCheck}
          color="primary"
        />
      </TableCell>
      {(Object.entries(campaign) as Entries<Campaign>).map(([key, value]) => (
        <TableCell
          key={key}
          width={TABLE_HEAD.find(({ name }) => name === key)?.width}
          align={TABLE_HEAD.find(({ name }) => name === key)?.align ?? 'center'}
          variant="body"
          data-width={key === 'clientCode' || key === 'name' ? 'fixed' : ''}
        >
          {key === 'name' && typeDefineByKey(key, value, campaign) ? (
            <CampaignName {...value} />
          ) : key === 'status' && typeDefineByKey(key, value, campaign) ? (
            <CampaignStatusBadge full status={value} />
          ) : key === 'click' && typeDefineByKey(key, value, campaign) ? (
            <CellSpan>
              <span>{value.count.toLocaleString()} 건</span>
              <span>{value.percent.toFixed(2)}%</span>
            </CellSpan>
          ) : (
            <span>{key === 'hitCnt' ? `${value.toLocaleString()} 건` : value}</span>
          )}
        </TableCell>
      ))}
    </ClickableTableRow>
  );
};

export default Row;

function typeDefineByKey<K extends keyof Campaign>(
  key: K,
  value: Campaign[keyof Campaign],
  campaign: Campaign
): value is Campaign[K] {
  return key in campaign;
}

const CellSpan = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    &:last-of-type {
      font-size: 0.75rem;
      color: ${grayscale800};
    }
  }
`;

const ClickableTableRow = styled(TableRow)`
  cursor: pointer;

  td {
    max-width: max-content;
    &[data-width='fixed'] {
      word-break: break-word;
      max-width: 200px;
    }
  }
`;
