export const FINGERPRINT_TABLE_HEAD_CELLS = [
  { name: 'fp_image_validation', label: '상태', defaultOrderType: 'DESC', initialIsActive: true },
  { name: 'fp_fpid', label: 'FINGERPRINT_ID', defaultOrderType: 'DESC', isImportant: true },
  { name: 'name', label: '장소명', defaultOrderType: 'DESC' },
  { name: 'branch_name', label: '지점명', defaultOrderType: 'DESC' },
  { name: 'fp_floor', label: '층수', defaultOrderType: 'DESC' },
  { name: 'category_all_kor', label: '카테고리', defaultOrderType: 'DESC' },
  { name: 'category_sub', label: '카테고리(상세)', defaultOrderType: 'DESC' },
  { name: 'category_code', label: '카테고리 코드', defaultOrderType: 'DESC' },
  { name: 'fp_collector', label: 'COLLECTOR', defaultOrderType: 'DESC' },
  { name: 'fp_created_at', label: 'TIMESTAMP', defaultOrderType: 'DESC' },
  { name: 'phone', label: 'PHONE', defaultOrderType: 'DESC' }
];
