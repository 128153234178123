import { useMemo } from 'react';
import PropTypes from 'prop-types';
import TablePagination from '@F/table/TablePagination';
import { format } from '@FUNC/math';
import * as S from './styles';

function PaginationRow({ perPage, page, setPage, count, reverse }) {
  const totalPage = useMemo(() => Math.ceil(count / perPage), [count, perPage]);

  return (
    <>
      {reverse && (
        <S.TableCountRow>
          {`Displaying ${(page - 1) * perPage + 1} to ${Math.min(page * perPage, count)} of ${format(count)} items`}
        </S.TableCountRow>
      )}

      <S.TablePaginationRow>
        <TablePagination count={totalPage} page={page} setPage={setPage} />
      </S.TablePaginationRow>
      {!reverse && (
        <S.TableCountRow>
          {`Displaying ${(page - 1) * perPage + 1} to ${Math.min(page * perPage, count)} of ${format(count)} items`}
        </S.TableCountRow>
      )}
    </>
  );
}

export default PaginationRow;

PaginationRow.propTypes = {
  perPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  reverse: PropTypes.bool
};

PaginationRow.defaultProps = {
  reverse: false
};
