export const prioritySelectOptions = [
  {
    id: -1,
    type: 'All'
  },
  {
    id: 1,
    type: 'high'
  },
  {
    id: 2,
    type: 'Mid'
  },
  {
    id: 3,
    type: '검수 완료'
  }
];
