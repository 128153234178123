import { createReducer, createSetValueAction, setValueReducer } from '@/redux/common/helper-functions';

/** prefix */
const PREFIX = 'REWARD';

/** initial state */
const INITIAL_STATE = {
  rewards: [],
  count: 0,
  page: 1,
  perPage: 50,
  params: {},
  isLoading: false
};

/** type */
export const types = {
  SET_VALUE: `${PREFIX}/SET_VALUE`,
  SET_LOADING: `${PREFIX}/SET_LOADING`,
  SET_PAGE: `${PREFIX}/SET_PAGE`,
  FETCH_REWARDS: `${PREFIX}/FETCH_REWARDS`,
  SET_NEW_REWARDS: `${PREFIX}/SET_NEW_REWARDS`,
  UPDATE_REWARDS: `${PREFIX}/UPDATE_REWARD`
};

/** action */
export const actions = {
  setValue: createSetValueAction(types.SET_VALUE),
  setLoading: (isLoading) => ({ type: types.SET_LOADING, isLoading }),
  setPage: (page) => ({ type: types.SET_PAGE, page }),
  fetchRewards: () => ({ type: types.FETCH_REWARDS }),
  setNewRewards: (rewards) => ({ type: types.SET_NEW_REWARDS, rewards }),
  updateRewards: (params) => ({ type: types.UPDATE_REWARDS, params })
};

/** reducer */
const reducer = createReducer(INITIAL_STATE, {
  [types.SET_VALUE]: setValueReducer,
  [types.SET_LOADING]: (draft, action) => {
    draft.isLoading = action.isLoading;
  },
  [types.SET_PAGE]: (draft, action) => {
    draft.page = action.page;
  },
  [types.SET_NEW_REWARDS]: (draft, action) => {
    draft.rewards = action.rewards;
  }
});
export default reducer;
