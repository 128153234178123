import styled from 'styled-components';

export const StyledLogin = styled.main`
  height: 100vh;
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    @media (min-width: 800px) {
      flex-direction: row;
    }
  }

  footer {
    color: #999;

    p {
      color: ${(props) => props.theme.palette.text.soft};
      line-height: 1.5;
      text-align: center;
      margin: 0;
    }

    span {
      color: ${(props) => props.theme.palette.primary.deepDark};
    }
  }
`;

export const Lottie = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;

  @media (min-width: 800px) {
    margin-right: 40px;
  }

  .loading {
    width: 400px;
    height: 100%;
  }
`;
