import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import { kindOptions, typeOptions } from '@P/cook/cookUser/options';
import { cookUsersApi } from '@API/manager/cook';
import Loading from '@F/Loading';
import { useFetch, useInput } from '@HOOK/';
import * as S from './styles';

function CreateUser({ teams }) {
  const classes = S.useStyles();

  const [userName, setUserName] = useInput('');
  const UserNameInput = <TextInputForm name="UserName" label="아이디" onChange={setUserName} value={userName} />;
  const [password, setPassword] = useInput('');
  const PasswordInput = <TextInputForm name="Password" label="비밀번호" onChange={setPassword} value={password} />;
  const [name, setName] = useInput('');
  const NameInput = <TextInputForm name="Name" label="이름" onChange={setName} value={name} />;
  const [email, setEmail] = useInput('');
  const EmailInput = <TextInputForm name="Email" label="이메일" onChange={setEmail} value={email} />;
  const [jumin, setJumin] = useInput('');
  const JuminInput = <TextInputForm name="Jumin" label="주민번호" onChange={setJumin} value={jumin} />;
  const [device, setDevice] = useInput('');
  const DeviceInput = <TextInputForm name="Device" label="폰 기종" onChange={setDevice} value={device} />;
  const [phone, setPhone] = useInput('');
  const PhoneInput = <TextInputForm name="Phone" label="전화번호" onChange={setPhone} value={phone} />;

  const [team, setTeam] = useInput(null);
  const TeamSelect = (
    <SelectForm
      label="소속"
      options={teams.filter((ele) => ele.flag !== null).map((ele) => ele.name)}
      value={team}
      onSelect={setTeam}
      name="TeamSelect"
    />
  );
  const [kind, setKind] = useInput('cook');
  const KindSelect = (
    <SelectForm label="종류" options={Object.keys(kindOptions)} value={kind} onSelect={setKind} name="KindSelect" />
  );
  const [type, setType] = useInput(typeOptions[0]);
  const TypeSelect = (
    <SelectForm label="타입" options={typeOptions} value={type} onSelect={setType} name="TypeSelect" />
  );

  const { callApi, isLoading } = useFetch();
  // TODO: validation
  const createUser = async () => {
    const toastOnSuccess = true;
    const errorMessages = {
      IntegrityError: '같은 아이디를 중복 생성할 수 없습니다.',
      ValidationError: '올바른 아이디와 패스워드를 입력해주세요.'
    };
    await callApi(
      cookUsersApi.postUser,
      {
        userName,
        password,
        teamId: teams.find((ele) => ele.name === team)?.id,
        name,
        email,
        jumin,
        device,
        phone,
        kind: kindOptions[kind],
        type
      },
      toastOnSuccess,
      errorMessages
    );
  };

  return (
    <S.StyledCreateUser>
      {isLoading && <Loading />}
      <div className={classes.title}>사용자 생성</div>
      <Grid container spacing={2} className={classes.wrapper}>
        <Grid item xs>
          {UserNameInput}
        </Grid>
        <Grid item xs>
          {PasswordInput}
        </Grid>
        <Grid item xs>
          {TeamSelect}
        </Grid>
        <Grid item xs>
          {NameInput}
        </Grid>
        <Grid item xs>
          {EmailInput}
        </Grid>
      </Grid>

      <Grid container className={classes.wrapper} spacing={2}>
        <Grid item xs>
          {JuminInput}
        </Grid>
        <Grid item xs>
          {DeviceInput}
        </Grid>
        <Grid item xs>
          {PhoneInput}
        </Grid>
        <Grid item xs>
          {KindSelect}
        </Grid>
        <Grid item xs>
          {TypeSelect}
        </Grid>
      </Grid>

      <S.ButtonRow>
        <Button variant="contained" color="primary" onClick={createUser}>
          등록
        </Button>
      </S.ButtonRow>
    </S.StyledCreateUser>
  );
}
export default CreateUser;

CreateUser.propTypes = {
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired
};
