import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import * as S from './styles';

export default function EnhancedTableToolbar(props) {
  const classes = S.useToolbarStyles();
  const { title, numSelected, selectedActions = [] } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {title}
        </Typography>
      )}
      {selectedActions}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
    PropTypes.string
  ]).isRequired,
  numSelected: PropTypes.number.isRequired
};
