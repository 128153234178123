import { GivingRewardParams, FetchingRewardParams } from '@TS/cashplace/reward';
import { axiosInstancesManager } from '@API/axios';
/* eslint class-methods-use-this: 0 */

class CashplaceRewardsApi {
  getRewards({ perPage, page, userId, state }: FetchingRewardParams) {
    return axiosInstancesManager.manager.get('/cashplace/rewards', {
      params: {
        per_page: perPage,
        page,
        user_id: userId,
        state
      }
    });
  }

  giveRewards({ status, rewardIds, reason }: GivingRewardParams) {
    return axiosInstancesManager.manager.post('/cashplace/rewards/withdraws', {
      status,
      reward_ids: rewardIds,
      reason
    });
  }
}
export default new CashplaceRewardsApi();
