import { useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import Wrapper from '@F/materialUI/Wrapper';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import { useDispatch } from 'react-redux';
import useInput from '@HOOK/useInput';
import { Status } from '@TS/cashplace/contact';
import { PerPageOption } from '@TS/perPageOption';
import { actions } from '@/redux/cashplace/contact/state';
import PER_PAGE_OPTIONS from '@/constants/perPageOptions';
import * as S from './styles';
import { statusOptions } from '../options';

function Filter(): JSX.Element {
  const classes = S.useStyles();

  const [userId, setUserId] = useInput('');
  const [status, setStatus] = useInput<Status>('전체');
  const [count, setCount] = useInput<PerPageOption>('15');

  const dispatch = useDispatch();
  const fetchContacts = () => {
    dispatch(
      actions.setValue('params', {
        userId: userId ? Number(userId) : null,
        state: statusOptions[status]
      })
    );
    dispatch(actions.setValue('perPage', Number(count)));
    dispatch(actions.setPage(1));
    dispatch(actions.fetchContacts());
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  return (
    <S.StyledFilter
      onSubmit={(event) => {
        event.preventDefault();
        fetchContacts();
      }}
    >
      <Wrapper>
        <S.Row container className={classes.margin} spacing={1}>
          <Grid item xs>
            <TextInputForm name="UserId" label="USER ID" value={userId} onChange={setUserId} />
          </Grid>
          <Grid item xs>
            <SelectForm
              label="상태"
              options={Object.keys(statusOptions)}
              value={status}
              onSelect={setStatus}
              name="ReviewStateSelect"
            />
          </Grid>
          <Grid item xs>
            <SelectForm label="개수" options={PER_PAGE_OPTIONS} value={count} onSelect={setCount} name="CountSelect" />
          </Grid>
        </S.Row>

        <S.ButtonRow>
          <Button variant="contained" color="primary" type="submit">
            불러오기
          </Button>
        </S.ButtonRow>
      </Wrapper>
    </S.StyledFilter>
  );
}
export default Filter;
