/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// Number.prototype.toLocaleString() 과 같은 역할
export const format = (stringifiedNumber: any): string => {
  if (!stringifiedNumber || Number.isNaN(Number(stringifiedNumber))) return '0';
  const value =
    parseInt(stringifiedNumber, 10) === stringifiedNumber ? stringifiedNumber : fixedPointFormat(stringifiedNumber);
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/** 소수점 한자리로 포맷 */
export const fixedPointFormat = (stringifiedNumber: any): string => {
  const numberValue = parseFloat(stringifiedNumber);
  if (numberValue === 0) return '0';
  return Math.abs(numberValue) < 10 ? numberValue.toFixed(2) : numberValue.toFixed(1);
};

export const getPercent = (partialAmount: number, totalAmount: number): number => {
  if (totalAmount === 0 || Number.isNaN(totalAmount)) return 0;
  return (partialAmount * 100) / totalAmount;
};

/** 주어진 소숫점자리수를 반올림하여 반환함
 * - fn(2.2463, 2) -> 2.25
 * - fn(2.2433, 2) -> 2.24
 */
export const roundToDecimalPoint = (value: number, point: number) => {
  const tmp = Math.pow(10, point); // eslint-disable-line no-restricted-properties
  return Math.round(value * tmp) / tmp;
};
