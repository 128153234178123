import { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PaginationRow from '@F/table/PaginationRow';
import { createHeadCells } from '@F/table/Table/functions';
import EnhancedTable from '@F/table/Table';
import { actions } from '@/redux/admin/userManager/state';
import * as S from './styles';

function TableSection() {
  const { page, perPage, count, users } = useSelector(
    (state) => ({
      page: state.userManager.page,
      perPage: state.userManager.perPage,
      count: state.userManager.count,
      users: state.userManager.users
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const setPage = useCallback(
    (newPage) => {
      dispatch(actions.setPage(newPage));
    },
    [dispatch]
  );
  useEffect(() => {
    dispatch(actions.fetchUsers());
  }, [dispatch, page]);

  const headCells = useMemo(() => columns.map((column) => createHeadCells(column.name, column.label)), []);
  const rows = useMemo(
    () =>
      users.map((user) => ({
        id: user.id,
        name: user.username,
        email: user.email,
        isLoplat: user.is_loplat ? 'O' : 'X',
        role: user.role,
        createdAt: moment(user.created_at).format('YYYY-MM-DD HH:mm:ss')
      })),
    [users]
  );

  return (
    <S.StyledTableSection>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} />
      <EnhancedTable headCells={headCells} rows={rows} isCheckable={false} />
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} reverse />
    </S.StyledTableSection>
  );
}
export default TableSection;

TableSection.propTypes = {};

const columns = [
  { name: 'id', label: '아이디' },
  { name: 'name', label: '이름' },
  { name: 'email', label: '이메일' },
  { name: 'isLoplat', label: '로플랫 직원' },
  { name: 'role', label: '권한' },
  { name: 'createdAt', label: '생성일시' }
];
