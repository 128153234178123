import styled from 'styled-components';

export const StyledUnauthorized = styled.div`
  display: flex;
  flex-flow: column;

  height: 100vh;
  align-items: center;
  justify-content: center;
`;
