import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import firebase from 'firebase/app';
import Viewer from 'react-viewer';
import { FIREBASE_COOK_URL } from '@/config';
import * as S from './styles';

function ImageSection({ report }) {
  const [images, setImages] = useState([]);

  // 이미지 불러오기
  const storage = firebase.storage();
  const fetchImage = useCallback(
    async (baseFileName) => {
      try {
        const targetUrl = `${FIREBASE_COOK_URL}/loplatcook/images/${baseFileName}.jpg`;
        const targetImage = await storage.refFromURL(targetUrl);
        const image = await targetImage.getDownloadURL();
        setImages((state) => [...state, image]);
      } catch (e) {
        console.error('백엔드 api 구조 상 어쩔 수 없는 에러', e);
      }
    },
    [storage]
  );

  useEffect(() => {
    const baseFileName = `${moment(report.report_created_at).format('YYYY-MM-DD')}/${report.report_reporter}/img_fp_${
      report.report_fpid
    }`;
    fetchImage(baseFileName);
    fetchImage(`${baseFileName}_1`);
    fetchImage(`${baseFileName}_2`);
    fetchImage(`${baseFileName}_3`);
  }, [report, fetchImage]);

  // 이미지 Lightbox
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const openLightbox = useCallback(
    (image) => {
      setIsLightboxOpen(true);
      setLightboxIndex(images.findIndex((item) => item === image));
    },
    [images]
  );

  return (
    <>
      <S.StyledImageSection>
        {images.length > 0 && (
          <S.Images>
            {images.map((image) => (
              <img key={image} src={image} alt="cookImage" onClick={() => openLightbox(image)} />
            ))}
          </S.Images>
        )}
        {images.length === 0 && <div>이미지가 없습니다</div>}
      </S.StyledImageSection>
      <Viewer
        visible={isLightboxOpen}
        onClose={() => setIsLightboxOpen(false)}
        images={images.map((image) => ({ src: image }))}
        activeIndex={lightboxIndex}
        zIndex={10000}
        showTotal
      />
    </>
  );
}
export default ImageSection;

ImageSection.propTypes = {
  report: PropTypes.shape({
    report_created_at: PropTypes.string,
    report_fpid: PropTypes.number,
    report_reporter: PropTypes.string
  }).isRequired
};
