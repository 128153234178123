import { useCallback, useEffect, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import CustomizingColumnsDialog from '@F/table/CustomizingColumnsDialog';
import EnhancedTable from '@F/table/Table';
import { createHeadCells, refineRow } from '@F/table/Table/functions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Blue from '@material-ui/core/colors/blue';
import Red from '@material-ui/core/colors/red';
import { reversedStatusOptions } from '@P/cook/cookVersion/options';
import Detail from '@P/cook/cookVersion/tableSection/Detail';
import { actions } from '@/redux/cook/cookVersion/state';
import * as S from './styles';

function TableSection() {
  const versions = useSelector((state) => state.cookVersion.versions);

  // rows
  const [headCells, setHeadCells] = useState(() => columns.map((column) => createHeadCells(column.name, column.label)));
  const rows = useMemo(
    () =>
      versions.map((version) => ({
        id: version.version_id,
        packageName: version.package_name,
        appVersion: version.v_str,
        integerAppVersion: version.v,
        minimumAppVersion: version.minimum_v,
        comment: version.comment,
        status: {
          value: version.status,
          component: (
            <S.StatusLabel
              color={version.status === 1 ? Blue[800] : Red[800]}
              backgroundColor={version.status === 1 ? Blue[200] : Red[200]}
            >
              {reversedStatusOptions[version.status]}
            </S.StatusLabel>
          )
        },
        createdAt: moment(version.created_at).format('YYYY-MM-DD HH:mm:ss'),
        updatedAt: moment(version.updated_at).format('YYYY-MM-DD HH:mm:ss')
      })),
    [versions]
  );

  // column modal
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(() =>
    columns.reduce((obj, column) => ({ ...obj, [column.name]: true }), {})
  );
  const CustomizingColumnsButton = (
    <Button
      key="customizingColumnsButton"
      variant="outlined"
      color="primary"
      onClick={() => setIsCustomModalOpen(true)}
      style={{ marginBottom: '1rem' }}
    >
      column 보이기/숨기기
    </Button>
  );

  const refinedRows = useMemo(() => refineRow(rows, selectedColumns), [rows, selectedColumns]);
  useEffect(() => {
    const updatedHeadCells = headCells.map((cell) => ({
      ...cell,
      hide: !selectedColumns[cell.id]
    }));
    setHeadCells(updatedHeadCells);
  }, [selectedColumns]);

  // expand
  const dispatch = useDispatch();
  const openedVersionIds = useSelector((state) => state.cookVersion.openedVersionIds);
  const setOpenedVersionIds = useCallback(
    (newOpenedVersionIds) => {
      dispatch(actions.setValue('openedVersionIds', newOpenedVersionIds));
    },
    [dispatch]
  );

  return (
    <S.StyledTableSection>
      {CustomizingColumnsButton}
      <CustomizingColumnsDialog
        open={isCustomModalOpen}
        setOpen={setIsCustomModalOpen}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        columns={columns}
      />

      <EnhancedTable
        headCells={headCells.filter((cell) => cell.hide === false)}
        rows={refinedRows}
        isCheckable={false}
        isCollapsible
        opened={openedVersionIds}
        setOpened={setOpenedVersionIds}
      >
        <Detail data={versions} />
      </EnhancedTable>
    </S.StyledTableSection>
  );
}
export default TableSection;

TableSection.propTypes = {};

const columns = [
  { name: 'id', label: 'VERSION ID', isImportant: true },
  { name: 'packageName', label: '패키지명' },
  { name: 'appVersion', label: '앱 버전' },
  { name: 'integerAppVersion', label: '앱 버전(정수)' },
  { name: 'minimumAppVersion', label: '동작 가능 최소 버전' },
  { name: 'comment', label: '비고' },
  { name: 'status', label: '상태' },
  { name: 'createdAt', label: '생성일시' },
  { name: 'updatedAt', label: '수정일시' }
];
