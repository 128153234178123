import { makeStyles } from '@material-ui/core/styles';

export const useTableStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%'
  },
  paper: {
    width: '100%',
    height: '100%'
  },
  container: {
    height: (props) => `calc(100% - ${props.paginationHeight ?? 120}px)`
  },
  table: {},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));
