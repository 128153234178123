import { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import EnhancedTable from '@F/table/Table';
import { createHeadCells } from '@F/table/Table/functions';
import moment from 'moment';
import { reversedKindOptions, reversedStatusOptions } from '@P/cook/cookUser/options';
import Blue from '@material-ui/core/colors/blue';
import Red from '@material-ui/core/colors/red';
import Detail from '@P/cook/cookUser/tableSection/Detail';
import PaginationRow from '@F/table/PaginationRow';
import { actions } from '@/redux/cook/cookUser/state';
import * as S from './styles';

function TableSection({ teams }) {
  const { page, perPage, count, users } = useSelector(
    (state) => ({
      page: state.cookUser.page,
      perPage: state.cookUser.perPage,
      count: state.cookUser.count,
      users: state.cookUser.users
    }),
    shallowEqual
  );
  // pagination
  const dispatch = useDispatch();
  const setPage = useCallback(
    (newPage) => {
      if (page === newPage) dispatch(actions.fetchUsers());
      else dispatch(actions.setPage(newPage));
    },
    [dispatch, page]
  );
  useEffect(() => {
    dispatch(actions.fetchUsers());
  }, [dispatch, page]);

  // rows
  const headCells = useMemo(() => columns.map((column) => createHeadCells(column.name, column.label)), []);
  const rows = useMemo(
    () =>
      users.map((user) => ({
        id: user.user_id,
        username: user.username,
        name: user.name,
        device: user.device,
        dailySalary: user.daily_sal,
        team: teams.find((team) => team.id === user.team_id)?.name,
        status: {
          value: user.status,
          align: 'center',
          component: (
            <S.StatusLabel
              color={user.status === 1 ? Blue[800] : Red[800]}
              backgroundColor={user.status === 1 ? Blue[200] : Red[200]}
            >
              {reversedStatusOptions[user.status]}
            </S.StatusLabel>
          )
        },
        kind: reversedKindOptions[user.kind],
        type: user.type,
        createdAt: {
          value: user.created_at,
          align: 'center',
          component: moment(user.created_at).format('YYYY-MM-DD HH:mm:ss')
        }
      })),
    [users, teams]
  );

  // expand
  const openedUserIds = useSelector((state) => state.cookUser.openedUserIds);
  const setOpenedUserIds = useCallback(
    (newOpenedUserIds) => {
      dispatch(actions.setValue('openedUserIds', newOpenedUserIds));
    },
    [dispatch]
  );

  return (
    <S.StyledTableSection>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} />
      <EnhancedTable
        headCells={headCells}
        rows={rows}
        isCheckable={false}
        isCollapsible
        opened={openedUserIds}
        setOpened={setOpenedUserIds}
      >
        <Detail data={users} teams={teams} />
      </EnhancedTable>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} reverse />
    </S.StyledTableSection>
  );
}
export default TableSection;

TableSection.propTypes = {
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired
};

const columns = [
  { name: 'id', label: 'USER ID' },
  { name: 'username', label: '아이디' },
  { name: 'name', label: '이름' },
  { name: 'device', label: '폰 기종' },
  { name: 'dailySalary', label: '일 가격' },
  { name: 'team', label: '소속' },
  { name: 'status', label: '상태' },
  { name: 'kind', label: '종류' },
  { name: 'type', label: '타입' },
  { name: 'createdAt', label: '생성일시' }
];
