import Wrapper from '@F/materialUI/Wrapper';
import CreateUser from './CreateUser';
import Filter from './Filter';
import TableSection from './TableSection';
import * as S from './styles';

function UserManager() {
  return (
    <S.StyledUserManager>
      <Wrapper>
        <CreateUser />
      </Wrapper>
      <br />
      <Wrapper>
        <Filter />
      </Wrapper>
      <br />
      <Wrapper>
        <TableSection />
      </Wrapper>
    </S.StyledUserManager>
  );
}
export default UserManager;

UserManager.propTypes = {};
