import { cloneDeep } from 'lodash-es';
import { Complex } from '@TS/store/complex';
import { SortOrderNotNull, SortableId, HeadCell, isSortableHeadCell, SortableHeadCell } from '@TS/store/cidTable';

interface SortFunction {
  (complexes: Complex[], label: SortableId, sortOrder: SortOrderNotNull): Complex[];
}

const sorting: SortFunction = (complexes, label, sortOrder) =>
  complexes.sort(
    sortOrder === 'ascending'
      ? ({ [label]: a }, { [label]: b }) => (a < b ? -1 : a > b ? 1 : 0)
      : ({ [label]: a }, { [label]: b }) => (a < b ? 1 : a > b ? -1 : 0)
  );

export const sortComplexesById: SortFunction = (complexes, key, sortOrder): Complex[] => {
  let clonedComplexes = cloneDeep(complexes);
  for (let i = 0; i < clonedComplexes.length; i++) {
    clonedComplexes = sorting(clonedComplexes, key, sortOrder);
    if (clonedComplexes[i].children.length) {
      clonedComplexes[i].children = sortComplexesById(clonedComplexes[i].children, key, sortOrder);
    }
  }

  return clonedComplexes;
};

export const findPivotHead = (newHeadCells: HeadCell[]): SortableHeadCell => {
  const sortingHeadPivot = newHeadCells
    .filter(isSortableHeadCell)
    .filter(({ sortOrder }) => sortOrder !== null)
    .pop();
  if (!sortingHeadPivot) throw new Error('head cell이 선택되지 않음');

  return sortingHeadPivot;
};
