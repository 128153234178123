import { memo, useCallback } from 'react';
import { KeyboardArrowUp, KeyboardArrowDown, Remove } from '@material-ui/icons';
import { HeadCell, IsOpenedAllType, SortableId } from '@TS/store/cidTable';
import TableHeadCell from './TableHeadCell';
import * as S from './TableHead.styles';

interface Props {
  sortingOrder: (id: SortableId) => void;
  headCells: HeadCell[];
  isOpenedAll: IsOpenedAllType;
  setIsOpenedAll: (isOpen: IsOpenedAllType) => void;
}

const TableHead = ({ sortingOrder, headCells, setIsOpenedAll, isOpenedAll }: Props): JSX.Element => {
  const setListOpenAll = useCallback(() => {
    setIsOpenedAll(typeof isOpenedAll === 'boolean' ? !isOpenedAll : true);
  }, [isOpenedAll, setIsOpenedAll]);

  return (
    <S.StyledHead>
      <S.SortButton onClick={setListOpenAll} isOpenedAll={isOpenedAll}>
        {isOpenedAll === 'intermediate' ? <Remove /> : isOpenedAll ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
      </S.SortButton>
      {headCells.map((headCell) => (
        <TableHeadCell key={headCell.label} headCell={headCell} sortingOrder={sortingOrder} />
      ))}
    </S.StyledHead>
  );
};

export default memo(TableHead);
