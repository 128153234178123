// TODO: 여러 페이지에서 공용으로 사용하기
import { useState } from 'react';
import Button from '@material-ui/core/Button';
import CustomizingColumnsDialog from '@F/table/CustomizingColumnsDialog';
import { Column, SelectedColumns } from '@TS/columns';

interface Props {
  columns: Column[];
  selectedColumns: SelectedColumns;
  setSelectedColumns: React.Dispatch<React.SetStateAction<SelectedColumns>>;
}

function CustomizingColumns({ columns, selectedColumns, setSelectedColumns }: Props): JSX.Element {
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);

  return (
    <>
      <Button
        key="customizingColumnsButton"
        variant="outlined"
        color="primary"
        onClick={() => setIsCustomModalOpen(true)}
      >
        column 보이기/숨기기
      </Button>
      <CustomizingColumnsDialog
        open={isCustomModalOpen}
        setOpen={setIsCustomModalOpen}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        columns={columns}
      />
    </>
  );
}
export default CustomizingColumns;
