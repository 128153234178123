import { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from '@F/modal/ModalWrapper';
import EnhancedTable from '@F/table/Table';
import Loading from '@F/Loading';
import * as S from './styles';
import CIDFilter from '../ComplexMatchingModal/CIDFilter';

function ComplexModal({ open, setOpen, setValue }) {
  const [isLoading, setIsLoading] = useState(false);
  const [complexes, setComplexes] = useState([]);
  const [selectedComplex, setSelectedComplex] = useState(null);

  const complexRows = useMemo(
    () =>
      complexes.map((complex) => ({
        id: complex.cid,
        name: complex.name,
        branchName: complex.branch_name,
        address: complex.addr,
        parent: complex.parent ? complex.parent.cid : ''
      })),
    [complexes]
  );

  const confirm = useCallback(() => {
    if (selectedComplex) {
      setValue(selectedComplex);
      setOpen(false);
    }
  }, [selectedComplex, setOpen, setValue]);

  const completeSelection = useCallback(
    (row) => {
      setValue({
        name: row.name,
        id: row.id
      });
      setOpen(false);
    },
    [setValue, setOpen]
  );

  useEffect(() => {
    if (Array.isArray(selectedComplex)) {
      setSelectedComplex(selectedComplex.pop());
    }
  }, [selectedComplex]);

  return (
    <ModalWrapper open={open} setOpen={setOpen} confirm={confirm} title="복합몰 선택">
      <S.StyledComplexModal>
        {isLoading && <Loading />}

        <CIDFilter setIsLoading={setIsLoading} setComplexes={setComplexes} setSelectedComplex={setSelectedComplex} />

        <S.TableWrapper>
          <EnhancedTable
            headCells={headCells}
            rows={complexRows}
            paginationHeight={50}
            selected={selectedComplex ? [selectedComplex] : []}
            setSelected={setSelectedComplex}
            disableAllCheck
            isSingleSelection
            onCompleteSelection={completeSelection}
          />
        </S.TableWrapper>
      </S.StyledComplexModal>
    </ModalWrapper>
  );
}
export default ComplexModal;

ComplexModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired
};

const headCells = [
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: 'cid'
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: '이름'
  },
  {
    id: 'branchName',
    numeric: false,
    disablePadding: false,
    label: '지점명'
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: '주소'
  },
  {
    id: 'parent',
    numeric: true,
    disablePadding: false,
    label: 'parent'
  }
];
