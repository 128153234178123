import { axiosInstancesManager } from '@API/axios';
/* eslint class-methods-use-this: 0 */

class UsersApi {
  getUsers({ page, perPage, id, role, status }) {
    return axiosInstancesManager.manager.get('/users', {
      params: {
        page,
        per_page: perPage,
        id,
        role,
        status
      }
    });
  }

  postUsers({ id, userName, password, role, isLoplat, email, cookUserName }) {
    return axiosInstancesManager.manager.post('/users', {
      id,
      username: userName,
      password,
      role,
      is_loplat: isLoplat,
      email,
      cook_username: cookUserName
    });
  }
}
export default new UsersApi();
