import { RootStore, UnCollectedComplexPlaceTodosStore } from '@TS/redux';
import { PlaceTodoParams } from '@TS/cook/api/placetodos';
import { PlaceTodo } from '@TS/cook/placetodo';

type SelectedUnCollectedComplexPlaceTodoParams = PlaceTodoParams;
type SelectedUnCollectedComplexPlaceTodos = PlaceTodo[];
type SelectedStatus = 'idle' | 'loading' | 'success' | 'failure';
type SelectedPagination = Pick<PlaceTodoParams, 'page' | 'perPage'> &
  Pick<UnCollectedComplexPlaceTodosStore, 'totalCount'>;

export const selectParams = (state: RootStore): SelectedUnCollectedComplexPlaceTodoParams =>
  state.uncollectedComplexPlaceTodos.params;
export const selectUncollectedComplexPlaceTodos = (state: RootStore): SelectedUnCollectedComplexPlaceTodos =>
  state.uncollectedComplexPlaceTodos.data;
export const selectStatus = (state: RootStore): SelectedStatus => state.uncollectedComplexPlaceTodos.status;
export const selectPagination = (state: RootStore): SelectedPagination => ({
  page: state.uncollectedComplexPlaceTodos.params.page,
  perPage: state.uncollectedComplexPlaceTodos.params.perPage,
  totalCount: state.uncollectedComplexPlaceTodos.totalCount
});
