import { cashplaceUsersApi } from '@API/manager/cashplace';

import { FetchingUsersParams, CreditForm, User, BulkCreditForm } from '@TS/cashplace/users';

export function fetchUsers({ page, perPage, userId }: FetchingUsersParams): Promise<User[]> {
  return cashplaceUsersApi
    .get({
      page,
      perPage,
      userId
    })
    .then((response) => response.data);
}

export function updateUser({ credit, title, message, kind, userId }: CreditForm): Promise<User> {
  return cashplaceUsersApi
    .giveCredit({
      credit,
      title,
      message,
      kind,
      userId
    })
    .then((response) => response.data);
}

export function updateUsers({ userCreditList, title, message, kind }: BulkCreditForm) {
  return cashplaceUsersApi
    .giveCredits({
      userCreditList,
      title,
      message,
      kind
    })
    .then((response) => response.data);
}
