import styled from 'styled-components';

export const StyledTableSection = styled.div``;

export const Content = styled.div`
  p {
    margin: 0;
  }
  h4 {
    margin: 0 0 0.5rem;
  }
`;

export const UserId = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-right: 5px;
  }
  svg:hover {
    cursor: pointer;
  }
`;
