import { createReducer, createSetValueAction, setValueReducer } from '@/redux/common/helper-functions';

/** prefix */
const PREFIX = 'EVENT';

/** initial state */
const INITIAL_STATE = {
  events: [],
  count: 0,
  openedEventIds: [],
  page: 1,
  perPage: 15,
  params: {},
  isLoading: false
};

/** type */
export const types = {
  RESET: `${PREFIX}/RESET`,
  SET_VALUE: `${PREFIX}/SET_VALUE`,
  SET_LOADING: `${PREFIX}/SET_LOADING`,
  SET_PAGE: `${PREFIX}/SET_PAGE`,
  FETCH_EVENTS: `${PREFIX}/FETCH_EVENTS`,
  SET_NEW_EVENTS: `${PREFIX}/SET_NEW_EVENTS`,
  SET_NEW_EVENT: `${PREFIX}/SET_NEW_EVENT`,
  CREATE_EVENT: `${PREFIX}/CREATE_EVENT`,
  UPDATE_EVENT: `${PREFIX}/UPDATE_EVENT`
};

/** action */
export const actions = {
  reset: () => ({ type: types.RESET }),
  setValue: createSetValueAction(types.SET_VALUE),
  setLoading: (isLoading) => ({ type: types.SET_LOADING, isLoading }),
  setPage: (page) => ({ type: types.SET_PAGE, page }),
  fetchEvents: () => ({ type: types.FETCH_EVENTS }),
  setNewEvents: (events) => ({ type: types.SET_NEW_EVENTS, events }),
  setNewEvent: (event) => ({ type: types.SET_NEW_EVENT, event }),
  createEvent: (params) => ({ type: types.CREATE_EVENT, params }),
  updateEvent: (event, params) => ({ type: types.UPDATE_EVENT, event, params })
};

/** reducer */
const reducer = createReducer(INITIAL_STATE, {
  [types.RESET]: (draft) => {
    draft.events = [];
    draft.openedEventIds = [];
  },
  [types.SET_VALUE]: setValueReducer,
  [types.SET_LOADING]: (draft, action) => {
    draft.isLoading = action.isLoading;
  },
  [types.SET_PAGE]: (draft, action) => {
    draft.page = action.page;
  },
  [types.SET_NEW_EVENTS]: (draft, action) => {
    draft.events = action.events;
    draft.openedEventIds = [];
  },
  [types.SET_NEW_EVENT]: (draft, action) => {
    const index = draft.events.findIndex((event) => event.event_id === action.event.event_id);
    if (index >= 0) {
      draft.events[index] = action.event;
    }
  }
});
export default reducer;
