import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { PaginationOptions, PaginationHandlers, PaginationSetters } from '@TS/pagination';

type PageSlice = <Data>(data: Data[]) => Data[];

interface PaginationContextType extends PaginationOptions, PaginationHandlers, PaginationSetters {
  pageSlice: PageSlice;
  initialOption: PaginationOptions;
}

const PaginationContext = createContext<PaginationContextType | undefined>(undefined);

interface PaginationContextProviderProps extends PaginationHandlers {
  children: React.ReactNode;
  initialOption: PaginationOptions;
}

export const PaginationContextProvider = ({
  initialOption,
  onChangePage,
  onChangeTotalCount,
  onChangePerPage,
  children
}: PaginationContextProviderProps): JSX.Element => {
  const [totalCount, setTotalCount] = useState(initialOption.totalCount);
  const [page, setPage] = useState(initialOption.page);
  const [perPage, setPerPage] = useState(initialOption.perPage);

  const pageSlice: PageSlice = useCallback(
    (data) => {
      const satrtIdx = (page - 1) * perPage;
      const sliced = data.slice(satrtIdx, satrtIdx + perPage);
      return sliced;
    },
    [page, perPage]
  );

  useEffect(() => {
    if (onChangeTotalCount) {
      onChangeTotalCount(totalCount);
    }
  }, [totalCount, onChangeTotalCount]);

  useEffect(() => {
    if (onChangePage) {
      onChangePage(page);
    }
  }, [page, onChangePage]);

  useEffect(() => {
    if (onChangePerPage) {
      onChangePerPage(perPage);
    }
  }, [perPage, onChangePerPage]);

  return (
    <PaginationContext.Provider
      value={{
        page,
        perPage,
        totalCount,
        setPage,
        setPerPage,
        setTotalCount,
        onChangeTotalCount,
        onChangePage,
        onChangePerPage,
        pageSlice,
        initialOption
      }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export const usePagination = (): PaginationContextType => {
  const paginationContext = useContext(PaginationContext);
  if (!paginationContext) {
    throw new Error('Pagination Context must be used in PaginationContextProvider');
  }

  return paginationContext;
};
