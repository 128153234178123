import { useState } from 'react';
import PropTypes from 'prop-types';
import Viewer from 'react-viewer';
import * as S from './styles';

function ImageSection({ imageUrls, inView, isExpanded }) {
  // 이미지 Lightbox
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const openLightbox = (imgUrl) => {
    setIsLightboxOpen(true);
    setLightboxIndex(imageUrls.findIndex((item) => item === imgUrl));
  };

  return (
    <>
      <S.StyledImageSection>
        {imageUrls.length ? (
          <S.Images>
            {imageUrls.map((imgUrl) => (
              <img key={imgUrl} src={imgUrl} alt="cookImage" onClick={() => openLightbox(imgUrl)} />
            ))}
          </S.Images>
        ) : (
          <div>이미지가 없습니다</div>
        )}
      </S.StyledImageSection>
      {inView && isExpanded && imageUrls.length ? (
        <Viewer
          visible={isLightboxOpen}
          onClose={() => setIsLightboxOpen(false)}
          images={imageUrls?.map((url) => ({ src: url }))}
          activeIndex={lightboxIndex}
          zIndex={10000}
          showTotal
        />
      ) : null}
    </>
  );
}
export default ImageSection;

ImageSection.propTypes = {
  fingerprint: PropTypes.shape({
    fp_created_at: PropTypes.string,
    fp_fpid: PropTypes.number,
    fp_collector: PropTypes.string
  }).isRequired
};
