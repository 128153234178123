import { useNavigate } from 'react-router-dom';

import { toastSuccessMessage } from '@FUNC/toast';
import NoticeForm from './NoticeForm';
import { useManageNotice } from './useManageNotice';

export default function NoticeCreatePage(): JSX.Element {
  const { createNotice, createApiStatus } = useManageNotice();
  const navigate = useNavigate();

  return (
    <NoticeForm
      onClickCancel={() => navigate(-1)}
      onClickSave={(formValues) =>
        createNotice(formValues).then((isSuccess) => {
          if (isSuccess) {
            navigate(-1);
            toastSuccessMessage('공지사항이 성공적으로 등록되었습니다.');
          }
        })
      }
      disabled={createApiStatus === 'loading'}
    />
  );
}
