import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TableHead, TableRow } from '@material-ui/core';

import { RecruitSortableField } from '@TS/homepage/recruit';
import TableHeadCell from '@F/table/cells/TableHeadCell';

import { selectParams } from '../redux/selector';
import { actions } from '../redux/slice';

const TABLE_HEAD_CELLS: { value: string; name?: RecruitSortableField; defaultOrderType?: OrderType }[] = [
  {
    value: '직군'
  },
  {
    value: '대상'
  },
  {
    value: '포지션',
    name: 'title',
    defaultOrderType: 'DESC'
  },
  {
    value: '기간',
    name: 'start_date',
    defaultOrderType: 'DESC'
  },
  {
    value: '작성자'
  },
  {
    value: '작성일',
    name: 'created_at',
    defaultOrderType: 'DESC'
  },
  {
    value: '수정일',
    name: 'updated_at',
    defaultOrderType: 'DESC'
  },
  {
    value: '공고',
    name: 'status',
    defaultOrderType: 'DESC'
  },
  {
    value: '편집/수정'
  }
];

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const initialActiveHeadCell = TABLE_HEAD_CELLS.find((hc) => hc.name === 'updated_at')!;
const initialOrderBy = { name: initialActiveHeadCell.name, orderType: initialActiveHeadCell.defaultOrderType };

export default function RecruitsTableHead(): JSX.Element {
  const params = useSelector(selectParams);
  const [orderBy, setOrderBy] = useState(initialOrderBy);

  const dispatch = useDispatch();

  const handleClickHeadCell = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    _name?: string,
    orderType?: OrderType
  ) => {
    const name = _name as RecruitSortableField;

    if (!name || !orderType) {
      return;
    }

    setOrderBy({ name, orderType });
  };

  const getNewOrder = (
    oldOrder: `${RecruitSortableField} ${OrderType}`[],
    newOrderName: RecruitSortableField,
    newOrderType: OrderType
  ) => {
    const newOrder = [...oldOrder];
    newOrder.pop();
    newOrder.push(`${newOrderName} ${newOrderType}`);
    return newOrder;
  };

  useEffect(() => {
    if (!orderBy.name || !orderBy.orderType) return;

    const newOrder = getNewOrder(params.order, orderBy.name, orderBy.orderType);

    dispatch(actions.fetchRecruits({ ...params, order: newOrder }));
    // params를 포함시킬 경우 무한루프 발생
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, dispatch]);

  return (
    <TableHead>
      <TableRow>
        {TABLE_HEAD_CELLS.map(({ value, name, defaultOrderType }, i) => (
          <TableHeadCell
            key={i}
            name={name}
            label={value}
            defaultOrderType={defaultOrderType}
            isActive={orderBy.name === name}
            onClick={handleClickHeadCell}
          />
        ))}
      </TableRow>
    </TableHead>
  );
}
