import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Backdrop, Modal } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import * as S from './styles';

function ModalWrapper({
  children,
  open,
  setOpen,
  title,
  confirm,
  confirmText,
  isLoading,
  width,
  withCancelButton,
  customButton,
  isErrorOnConfirm,
  isInnerModalOpened,
  setIsInnerModalOpened,
  successMessage
}) {
  const classes = S.useStyles({ width });

  const onConfirmInnerModal = useCallback(() => {
    if (isErrorOnConfirm) {
      setIsInnerModalOpened(false);
    } else {
      setOpen(false);
    }
  }, [isErrorOnConfirm, setOpen, setIsInnerModalOpened]);

  const closeModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    setTimeout(() => {
      const button = document.querySelector('.ConfirmButton');
      if (button) button.focus();
    }, 0);
  }, [open]);

  return (
    <S.StyledModalWrapper>
      <Modal
        open={open}
        onClose={closeModal}
        closeAfterTransition
        className={classes.modal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {title && (
              <S.Header>
                <h3>{title}</h3>
                <Button onClick={closeModal}>X</Button>
              </S.Header>
            )}
            {children}
            {
              <S.Footer>
                {customButton}
                {withCancelButton && (
                  <Button className={classes.cancelButton} variant="contained" color="default" onClick={closeModal}>
                    취소
                  </Button>
                )}
                <Button
                  className="ConfirmButton"
                  variant="contained"
                  color="primary"
                  onClick={confirm}
                  disabled={isLoading}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      confirm();
                    }
                  }}
                >
                  {confirmText}
                </Button>
              </S.Footer>
            }
            {isInnerModalOpened && (
              <S.InnerModalWrapper>
                <ModalWrapper
                  open={isInnerModalOpened}
                  setOpen={setIsInnerModalOpened}
                  confirm={onConfirmInnerModal}
                  width="30vw"
                  withCancelButton={false}
                >
                  {isErrorOnConfirm ? <p>오류가 발생했습니다. 다시 시도해주세요.</p> : <p>{successMessage}</p>}
                </ModalWrapper>
              </S.InnerModalWrapper>
            )}
          </div>
        </Fade>
      </Modal>
    </S.StyledModalWrapper>
  );
}
export default ModalWrapper;

ModalWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string,
  confirm: PropTypes.func,
  confirmText: PropTypes.string,
  isLoading: PropTypes.bool,
  width: PropTypes.string,
  customButton: PropTypes.element,
  withCancelButton: PropTypes.bool,
  isErrorOnConfirm: PropTypes.bool,
  isInnerModalOpened: PropTypes.bool,
  setIsInnerModalOpened: PropTypes.func,
  successMessage: PropTypes.string
};

ModalWrapper.defaultProps = {
  title: null,
  width: null,
  confirm: () => {},
  confirmText: '확인',
  isLoading: false,
  customButton: null,
  withCancelButton: true,
  isErrorOnConfirm: false,
  isInnerModalOpened: false,
  setIsInnerModalOpened: () => {},
  successMessage: '성공하였습니다.'
};
