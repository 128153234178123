import { useCallback, useEffect, useMemo, useState } from 'react';
import ModalWrapper from '@F/modal/ModalWrapper';
import EnhancedTable from '@F/table/Table';
import { brandsApi } from '@API/manager';
import useInputs from '@HOOK/useInputs';
import type { HeadCell, Id, Label, SelectedBrand } from '@TS/store/brandModal';
import { Brand, BrandItem } from '@TS/store/brand';
import TextInputForm from '@F/materialUI/TextInputForm';
import { Caption, grayscale800 } from 'loplat-ui';
import PreviousSelectedBrands from './PreviousSelectedBrands';
import * as S from './styles';

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  setValue: React.Dispatch<React.SetStateAction<SelectedBrand[]>>;
  multiSelectable: boolean;
  previousBrands?: SelectedBrand[];
}

function BrandModal({ open, setOpen, setValue, multiSelectable = true, previousBrands }: Props): JSX.Element {
  const { value, onChange, clearByKey } = useInputs({ bid: '', name: '' });
  const { bid, name } = value;

  const [brands, setBrands] = useState<BrandItem[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<SelectedBrand[]>(previousBrands ?? []);

  const brandRows: Brand[] = useMemo(() => {
    if (!brands.length) return [];
    return brands.map((brand) => ({
      id: brand.bid,
      name: brand.name,
      category: brand.category_all_kor
    }));
  }, [brands]);

  const searchBrand = useCallback(async () => {
    try {
      if (bid.trim()) {
        const { data } = await brandsApi.get({ bid: Number(bid) });
        if (data) setBrands([data]);
        else throw Error('검색 결과 없음');
      } else if (name.trim()) {
        const {
          data: { items }
        } = await brandsApi.getByName({
          searchTerm: name
        });
        setBrands(items);
      }
    } catch (error) {
      console.error(error);
      setBrands([]);
    }
  }, [bid, name]);

  const confirm = useCallback(() => {
    if (selectedBrands.length) {
      setValue(
        selectedBrands.map(({ id, name }) => ({
          id,
          name
        }))
      );
    }
    setOpen(false);
  }, [selectedBrands, setOpen, setValue]);

  const completeSelection = useCallback(
    (brand: Brand) => {
      setValue([
        {
          name: brand.name,
          id: brand.id
        }
      ]);
      setOpen(false);
    },
    [setValue, setOpen]
  );

  const deleteSelectedBrandByID = useCallback(
    (bid: SelectedBrand['id']) => {
      setSelectedBrands(selectedBrands.filter(({ id }) => id !== bid));
    },
    [selectedBrands]
  );

  const selectBrands = useCallback((brands: Brand[]) => {
    setSelectedBrands(brands.map(({ id, name }) => ({ id, name })));
  }, []);

  useEffect(() => {
    if (multiSelectable) return;
    if (selectedBrands && selectedBrands.length > 1) {
      setSelectedBrands(selectedBrands.slice(-1));
    }
  }, [multiSelectable, selectedBrands]);

  return (
    <ModalWrapper open={open} setOpen={setOpen} confirm={confirm} title="브랜드 선택">
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            searchBrand();
          }}
        >
          <S.SearchBox>
            {inputParamsList.map(({ name, ...props }) => (
              <TextInputForm
                key={name}
                name={name}
                {...props}
                onClear={() => clearByKey(name)}
                onChange={onChange}
                value={value[name]}
              />
            ))}
            <input type="submit" hidden />
          </S.SearchBox>
          <Caption size="sm" color={grayscale800}>
            입력 후 엔터키를 누르면 검색됩니다.
          </Caption>
        </form>

        <PreviousSelectedBrands
          isVisible={multiSelectable && !!selectedBrands?.length}
          brands={selectedBrands}
          deleteSelectedBrandByID={deleteSelectedBrandByID}
        />

        <S.TableWrapper>
          <EnhancedTable
            headCells={HEAD_CELLS}
            rows={brandRows}
            paginationHeight={50}
            selected={selectedBrands}
            setSelected={selectBrands}
            disableAllCheck
            isSingleSelection={!multiSelectable}
            onCompleteSelection={completeSelection}
          />
        </S.TableWrapper>
      </div>
    </ModalWrapper>
  );
}
export default BrandModal;

const inputParamsList: {
  name: Exclude<Id, 'category'>;
  label: Exclude<Label, 'category'>;
  helperText: string;
}[] = [
  { name: 'bid', label: 'bid', helperText: '일치' },
  { name: 'name', label: '이름', helperText: '포함' }
];

const HEAD_CELLS: HeadCell[] = [
  {
    id: 'bid',
    numeric: true,
    disablePadding: false,
    label: 'bid'
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: '이름'
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: '카테고리'
  }
];
