import { useCallback, useState } from 'react';
import ModalWrapper from '@F/modal/ModalWrapper';
import Loading from '@F/Loading';
import type { Complex, Store } from '@TS/store/complex';
import CIDFilter from './CIDFilter';
import CIDTable from './CIDTable';
import { CurrentCIDWrapper } from './styles';

export type SelectedCID = { cid: Complex['cid']; name?: Complex['name'] };

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  currentStore: Store;
  setValue: React.Dispatch<React.SetStateAction<SelectedCID[]>>;
}

const ComplexMatchingModal = ({ open, setOpen, setValue, currentStore }: Props): React.ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [complexes, setComplexes] = useState<Complex[]>([]);
  const [selectedCID, setSelectedCID] = useState<SelectedCID | null>(null);

  const details: { field: keyof Store; title: string; value: Store[keyof Store] }[] = [
    { field: 'pid', title: 'PID', value: currentStore.pid },
    { field: 'name', title: '장소명', value: currentStore.name },
    { field: 'floor', title: '층수', value: currentStore.floor },
    { field: 'branchName', title: '지점명', value: currentStore.branchName }
  ];

  const confirm = useCallback(
    (cid?: Complex['cid'], name?: Complex['name']) => {
      // NOTE: 현재 기획상 한개만 선택가능하지만, 추후에 여러개를 선택할 수 있으므로 array 타입으로 작성함.
      setOpen(false);
      if (cid && name) setValue([{ cid, name }]);
      else if (selectedCID) setValue([selectedCID]);
      else setValue([]);
    },
    [selectedCID]
  );

  return (
    <ModalWrapper open={open} setOpen={setOpen} confirm={confirm} title="복합몰 cid 추가">
      <div>
        {isLoading && <Loading />}
        <div style={{ marginBottom: '2rem' }}>
          <h4>현재 선택된 가게</h4>
          <CurrentCIDWrapper>
            {details.map((item) => (
              <li key={item.field}>
                <span>{item.title}</span>
                <span>{item.value}</span>
              </li>
            ))}
          </CurrentCIDWrapper>
        </div>
        <h4>추가할 복합몰 검색</h4>
        <CIDFilter setIsLoading={setIsLoading} setComplexes={setComplexes} setSelectedComplex={setSelectedCID} isTree />
        <CIDTable
          complexes={complexes}
          selectedCID={selectedCID}
          setSelectedCID={setSelectedCID}
          confirmCID={confirm}
        />
      </div>
    </ModalWrapper>
  );
};

export default ComplexMatchingModal;
