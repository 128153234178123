import { createReducer, createSetValueAction, setValueReducer } from '@/redux/common/helper-functions';

/** prefix */
const PREFIX = 'MANUAL_MAPPING';

/** initial state */
const INITIAL_STATE = {
  fingerprints: [],
  openedFingerprintIds: [],
  count: 0,
  page: 1,
  perPage: 15,
  params: {},
  isLoading: false,
  charge: ''
};

/** type */
export const types = {
  SET_VALUE: `${PREFIX}/SET_VALUE`,
  SET_LOADING: `${PREFIX}/SET_LOADING`,
  SET_PAGE: `${PREFIX}/SET_PAGE`,
  FETCH_FINGERPRINTS: `${PREFIX}/FETCH_FINGERPRINTS`,
  SET_NEW_FINGERPRINTS: `${PREFIX}/SET_NEW_FINGERPRINTS`,
  PATCH_FINGERPRINT: `${PREFIX}/PATCH_FINGERPRINT`,
  PATCH_FINGERPRINT_VALIDATION: `${PREFIX}/PATCH_FINGERPRINT_VALIDATION`,
  DELETE_FINGERPRINT: `${PREFIX}/DELETE_FINGERPRINT`
};

/** action */
export const actions = {
  setValue: createSetValueAction(types.SET_VALUE),
  setLoading: (isLoading) => ({ type: types.SET_LOADING, isLoading }),
  setPage: (page) => ({ type: types.SET_PAGE, page }),
  fetchFingerprints: () => ({ type: types.FETCH_FINGERPRINTS }),
  patchFingerprint: (fingerprint, params) => ({ type: types.PATCH_FINGERPRINT, fingerprint, params }),
  patchFingerprintValidation: (fingerprint, state) => ({
    type: types.PATCH_FINGERPRINT_VALIDATION,
    fingerprint,
    state
  }),
  setNewFingerprints: (fingerprints) => ({ type: types.SET_NEW_FINGERPRINTS, fingerprints }),
  deleteFingerprint: (fingerprint) => ({ type: types.DELETE_FINGERPRINT, fingerprint })
};

/** reducer */
const reducer = createReducer(INITIAL_STATE, {
  [types.SET_VALUE]: setValueReducer,
  [types.SET_LOADING]: (draft, action) => {
    draft.isLoading = action.isLoading;
  },
  [types.SET_PAGE]: (draft, action) => {
    draft.page = action.page;
  },
  [types.SET_NEW_FINGERPRINTS]: (draft, action) => {
    draft.fingerprints = action.fingerprints;
    draft.openedFingerprintIds = [];
  },
  [types.DELETE_FINGERPRINT]: (draft, action) => {
    const index = draft.fingerprints.findIndex((fingerprint) => fingerprint.id === action.fingerprint.id);
    if (index >= 0) {
      draft.fingerprints = [...draft.fingerprints.slice(0, index), ...draft.fingerprints.slice(index + 1)];
    }
  }
});
export default reducer;
