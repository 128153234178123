import { all, call, put, takeLeading, select } from 'redux-saga/effects';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { actions, types } from './state';
import { fetchRewards, updateRewards } from './api.ts';

export function* fetchRewardSaga() {
  const { page, perPage, params } = yield select((state) => ({
    page: state.reward.page,
    perPage: state.reward.perPage,
    params: state.reward.params
  }));

  yield put(actions.setLoading(true));
  try {
    const data = yield call(fetchRewards, { page, perPage, ...params });
    yield put(actions.setNewRewards(data.result));
    yield put(actions.setValue('count', data.total_count));
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* updateRewardsSaga(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(updateRewards, action.params);

    yield fetchRewardSaga();

    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* sagaReducer() {
  yield all([takeLeading(types.FETCH_REWARDS, fetchRewardSaga), takeLeading(types.UPDATE_REWARDS, updateRewardsSaga)]);
}
