import { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import { Grid, Radio } from '@material-ui/core';
import Map from '@P/store/StoreManager/tableSection/detail/Map';
import Infos from '@P/store/StoreManager/tableSection/detail/Infos';
import Images from '@P/store/StoreManager/tableSection/detail/Images';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as S from './styles';

function MapOrImages({ detail, newLocation, setNewLocation, select }) {
  return (
    <StyledMapOrImages select={select}>
      <Map detail={detail} newLocation={newLocation} setNewLocation={setNewLocation} />
      <Images detail={detail} />
    </StyledMapOrImages>
  );
}
const StyledMapOrImages = styled.div`
  width: 100%;
  height: calc(100% - 42px);
  display: flex;
  transition: transform 0.3s;
  ${({ select }) => (select === 'map' ? '' : 'transform: translateX(-100%);')}

  > * {
    width: 100%;
    flex: 1 0 auto;
    padding: 4px;
  }
`;
function Wrapper({ id, data, opened, setOpened }) {
  const detail = useMemo(() => data.find((item) => item.pid === id), [data, id]);

  const [newLocation, setNewLocation] = useState({
    lat: detail?.lat,
    lng: detail?.lng
  });
  const [select, setSelect] = useState('map');

  const closeDetailSection = useCallback(() => {
    const newOpened = opened.map((item) => (item.id === id ? { id, opened: false } : item));
    setOpened(newOpened);
  }, [id, setOpened, opened]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={5} style={{ overflow: 'hidden' }}>
        <MapOrImages detail={detail} newLocation={newLocation} setNewLocation={setNewLocation} select={select} />
        <S.RadioButtonWrapper>
          <FormControl component="fieldset">
            <RadioGroup row value={select} onChange={(event, value) => setSelect(value)}>
              <FormControlLabel value="map" control={<Radio />} label="지도" />
              <FormControlLabel value="image" control={<Radio />} label="사진" />
            </RadioGroup>
          </FormControl>
        </S.RadioButtonWrapper>
      </Grid>
      <Grid item xs={7}>
        <Infos
          detail={detail}
          newLocation={newLocation}
          setNewLocation={setNewLocation}
          onComplete={closeDetailSection}
        />
      </Grid>
    </Grid>
  );
}
export default Wrapper;

Wrapper.propTypes = {};
