import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import { Button } from '@material-ui/core';
import useAuth from '@HOOK/useAuth';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { VERSION, MODE } from '@/config';

import { valuesStore } from '@/mobX/values';
import { managerLocalStorage } from '@/utils/localStorageParser';

// layout - header
export default function Header(): JSX.Element {
  const { logout } = useAuth();

  const deleteCache = async () => {
    window.localStorage.removeItem('manager/cashplace/study/devices');
    await valuesStore.setValues('categories', {
      all: [],
      manager: [],
      'auto-fp': []
    });
    await valuesStore.setValues('collectors', []);
    await valuesStore.setValues('sdkClientIds', []);
    managerLocalStorage.remove(managerLocalStorage.keys['max/companies?type=2']);

    if (
      window.localStorage.getItem('manager/cashplace/study/devices') === null &&
      valuesStore.categories.all.length === 0 &&
      valuesStore.categories.manager.length === 0 &&
      valuesStore.categories['auto-fp'].length === 0 &&
      valuesStore.collectors.length === 0 &&
      valuesStore.sdkClientIds.length === 0
    ) {
      toastSuccessMessage('캐시를 정상적으로 삭제했습니다. 새로고침 해주세요.');
    } else {
      toastErrorMessage('캐시를 삭제하지 못했습니다. 관리자에게 문의해주세요.');
    }
  };

  return (
    <StyledHeader>
      <StyledLink to="/">
        <div className="version">{`로플랫 매니저 v.${VERSION}`}</div>
      </StyledLink>
      <div className="toolbars">
        <Button onClick={deleteCache} color="inherit">
          캐시초기화
        </Button>
        <Button onClick={logout} color="inherit">
          로그아웃
        </Button>
      </div>
    </StyledHeader>
  );
}

const StyledHeader = styled.header`
  flex-shrink: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: ${MODE === 'staging' ? '#FC4C94' : '#3f50b5'};
  color: white;
  font-size: 1.2rem;

  .toolbars {
    display: flex;
    align-items: center;
  }
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  :hover {
    color: black;
  }
`;
