import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from '@F/modal/ModalWrapper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from '@material-ui/core';
import CombineTable from '@P/store/StoreCombine/combineModal/CombineTable';
import { useDispatch } from 'react-redux';
import { toastErrorMessage } from '@FUNC/toast';
import { actions } from '@/redux/store/storeCombine/state';
import * as S from './styles';

function CombineModal({ isOpen, setIsOpen, stores, onConfirm, isMasterPidChangeable, additionalMessage }) {
  // master pid
  const [masterPid, setMasterPid] = useState(null);
  useEffect(() => {
    setMasterPid(
      stores.reduce((prev, cur) => {
        if (!prev) return cur.pid;
        if (cur.pid < prev) return cur.pid;
        return prev;
      }, null)
    );
  }, [stores]);

  // 검수 완료 체크 박스
  const [isInspected, setIsInspected] = useState(false);
  const IsInspectedOption = (
    <FormControlLabel
      control={<Checkbox color="primary" checked={isInspected} onChange={() => setIsInspected((state) => !state)} />}
      label="검수완료"
    />
  );

  // 병합 정보
  const dispatch = useDispatch();
  const [targetPids, setTargetPids] = useState({});
  const confirm = () => {
    if (Object.values(targetPids).some((pid) => !pid)) {
      toastErrorMessage('선택되지 않은 항목이 있습니다.');
    } else {
      dispatch(
        actions.combineStores({
          ...targetPids,
          masterPid,
          inspected: isInspected,
          slavePidList: stores.filter((store) => store.pid !== masterPid).map((store) => store.pid)
        })
      );
      setIsOpen(false);
      onConfirm();
    }
  };

  return (
    <ModalWrapper
      open={isOpen}
      setOpen={setIsOpen}
      title="병합할 매장 선택"
      confirmText="병합"
      confirm={confirm}
      customButton={IsInspectedOption}
      withCancelButton={false}
      width="80vw"
    >
      <div>
        <p>
          선택한 스토어 {stores.map((store) => store.pid).join(', ')}가 {masterPid}
          로 병합됩니다.
          <br />
          한번 하면 되돌리기 어려우니 신중히 선택한 후 아래 병합 버튼을 눌러주세요.
          <br />
          <S.AdditionalMessage>{additionalMessage}</S.AdditionalMessage>
        </p>
        <br />
        <CombineTable
          stores={stores}
          onEmitTargetPids={setTargetPids}
          masterPid={masterPid}
          setMasterPid={setMasterPid}
          isMasterPidChangeable={isMasterPidChangeable}
        />
        <br />
      </div>
    </ModalWrapper>
  );
}
export default CombineModal;

CombineModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  stores: PropTypes.arrayOf(
    PropTypes.shape({
      pid: PropTypes.number
    })
  ).isRequired,
  onConfirm: PropTypes.func,
  isMasterPidChangeable: PropTypes.bool,
  additionalMessage: PropTypes.string
};

CombineModal.defaultProps = {
  onConfirm: () => {},
  isMasterPidChangeable: false,
  additionalMessage: ''
};
