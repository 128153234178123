import { ReactComponent as AndroidAd } from '@ICON/segment/adid.svg';
import { ReactComponent as IOSAd } from '@ICON/segment/idfa.svg';
import { Wrapper } from './index.styles';

export type AdProps = { name: string };
const AdModule = ({ name }: AdProps): JSX.Element => (
  <Wrapper>
    {name === name.toUpperCase() ? <IOSAd /> : <AndroidAd />}
    {name}
  </Wrapper>
);

export default AdModule;
