export const statusOptions = {
  전체: null,
  활성: 1,
  비활성: 0
};
export const reversedStatusOptions = {
  null: '전체',
  1: '활성',
  0: '비활성'
};
