import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { PlaceTodoParams, PatchPlaceTodoAction } from '@TS/cook/api/placetodos';
import type { PlaceTodo } from '@TS/cook/placetodo';

interface InitialState {
  status: ApiStatus;
  error: Error | null;
  data: PlaceTodo[];
  totalCount: number;
  params: Pick<
    PlaceTodoParams,
    'page' | 'perPage' | 'orders' | 'chargeResult' | 'startDate' | 'endDate' | 'hasComplex' | 'reportStatus'
  >;
}

type UICallback = (isSuccess: boolean) => void;

const initialState: InitialState = {
  status: 'idle',
  error: null,
  data: [],
  totalCount: 200,
  params: {
    page: 1,
    perPage: 20,
    orders: ['created_at DESC'],
    chargeResult: [1, 2, 3],
    reportStatus: 0,
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10),
    hasComplex: true
  }
};

// slice
const slice = createSlice({
  name: 'unCollectedComplexPlaceTodos',
  initialState,
  reducers: {
    reset: () => initialState,
    // utils
    setParams: (state, action: PayloadAction<PlaceTodoParams>) => {
      state.params = action.payload;
    },

    // read
    fetchUnCollectedComplexPlaceTodosStart: (state) => {
      state.status = 'loading';
    },
    fetchUnCollectedComplexPlaceTodosSuccess: (
      state,
      action: PayloadAction<{ data: PlaceTodo[]; totalCount: number }>
    ) => {
      const { data, totalCount } = action.payload;

      state.status = 'success';
      state.data = data;
      state.totalCount = totalCount;
    },
    fetchUnCollectedComplexPlaceTodosFailure: (state, action: PayloadAction<Error>) => {
      state.status = 'failure';
      state.error = action.payload;
    },
    // update
    // updateUnCollectedComplexPlaceTodoSuccess: (state, action: PayloadAction<{ siteId: number; data: PlaceTodo }>) => {
    //   const { siteId, data } = action.payload;

    //   state.data = state.data.map((placetodo) => (placetodo.siteId === siteId ? { ...placetodo, ...data } : placetodo));
    // },

    // create actions to use action.types in saga
    fetchUnCollectedComplexPlaceTodos: (state, action: PayloadAction<PlaceTodoParams | undefined>) => {},
    updateUnCollectedComplexPlaceTodo: (
      state,
      action: PayloadAction<{ siteId: number; action: PatchPlaceTodoAction; callback?: UICallback }>
    ) => {},
    changePage: (state, action: PayloadAction<number>) => {}
  }
});

export const { actions } = slice;

export default slice;
