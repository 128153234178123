import styled from 'styled-components';
import { grayscale900, Caption } from 'loplat-ui';

const ConjunctionConditions = ({ type }: { type: 'and' | 'or' }): JSX.Element => (
  <Wrapper>
    <Caption size="2xs" weight="extraBold">
      {type === 'and' && '/* AND */'}
      {type === 'or' && '/* OR */'}
    </Caption>
  </Wrapper>
);

const Wrapper = styled.div`
  color: ${grayscale900};
  width: fit-content;
  margin: 16px 0;
  text-align: start;
`;

export default ConjunctionConditions;
