import { forwardRef } from 'react';
import styled from 'styled-components';

type Props = {
  color: string;
  text: string;
  full?: boolean;
} & React.HtmlHTMLAttributes<HTMLDivElement>;
const Badge = forwardRef<HTMLDivElement, Props>(
  ({ color, text, ...props }, ref): JSX.Element => (
    <Wrapper color={color} ref={ref} {...props}>
      {text}
    </Wrapper>
  )
);

const Wrapper = styled.div<Pick<Props, 'color' | 'full'>>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ full }) => (full ? '100%' : 'max-content')};
  padding: 5px 11px;
  font-size: 0.8rem;
  border-radius: 4px;
  color: #ffffff;
  font-weight: bold;
  background-color: ${({ color }) => color};
`;

export default Badge;
