import { TextField, IconButton } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import type { TextFieldProps } from '@material-ui/core';
import { CloseIcon, SearchIcon } from 'loplat-ui';

type TextInputFormProps = {
  name: string;
  value?: string;
  variant?: 'outlined' | 'standard' | 'filled';
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id: string) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement>, id: string) => void;
  readOnly?: boolean;
  onClear?: () => void;
  onSearch?: () => void;
} & Omit<TextFieldProps, 'name' | 'onChange' | 'value'>;

function TextInputForm({
  name,
  variant = 'outlined',
  label,
  onChange,
  onClick,
  readOnly = false,
  value = '',
  required = false,
  error = false,
  disabled = false,
  size = 'medium',
  onClear,
  onSearch,
  ...props
}: TextInputFormProps): JSX.Element {
  return (
    <TextField
      autoComplete="off"
      name={name}
      variant={variant}
      label={label}
      fullWidth
      onChange={(event) => onChange && onChange(event, name)}
      onClick={(event) => onClick && !disabled && onClick(event, name)}
      InputProps={{
        readOnly,
        ...(onSearch && {
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label={`${label} 검색`}
                onClick={(e) => {
                  e.stopPropagation();
                  onSearch();
                }}
                size="small"
              >
                <SearchIcon size={20} />
              </IconButton>
            </InputAdornment>
          )
        }),
        ...(onClear &&
          value && {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={`${label} 삭제`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClear();
                  }}
                  size="small"
                >
                  <CloseIcon size={16} />
                </IconButton>
              </InputAdornment>
            )
          })
      }}
      value={value}
      required={required}
      error={error}
      disabled={disabled}
      size={size}
      {...props}
    />
  );
}

export default TextInputForm;
