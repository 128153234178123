import { ArticlesStore, RootStore } from '@TS/redux';
import { ArticlesParams, Article } from '@TS/homepage/article';

type SelectedArticleParams = ArticlesParams;
type SelectedArticles = Article[];
type SelectedStatus = 'idle' | 'loading' | 'success' | 'failure';
type SelectedPagination = Pick<ArticlesParams, 'page' | 'perPage'> & Pick<ArticlesStore, 'totalCount'>;

export const selectParams = (state: RootStore): SelectedArticleParams => state.articles.params;
export const selectArticles = (state: RootStore): SelectedArticles => state.articles.data;
export const selectStatus = (state: RootStore): SelectedStatus => state.articles.status;
export const selectPagination = (state: RootStore): SelectedPagination => ({
  page: state.articles.params.page,
  perPage: state.articles.params.perPage,
  totalCount: state.articles.totalCount
});
