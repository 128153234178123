import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { InputsData, Event } from '@TS/cashplace/event';
import useInput from '@HOOK/useInput';
import { reversedKindOptions } from '../options';
import * as S from './styles';

type InputsProps = {
  defaultInputs?: Event;
  onEmitInputs?: ({ content, description, eventUrl, mainImageUrl, subImageUrl, buttonName, kind }: InputsData) => void;
  Buttons?: React.ReactNode;
};

function Inputs({ defaultInputs, onEmitInputs, Buttons }: InputsProps): JSX.Element {
  const classes = S.useStyles();

  const [content, setContent] = useState<string>(defaultInputs?.content || '');
  const ContentInput = (
    <textarea id="ContentInput" value={content} onChange={(e): void => setContent(e.target.value)} />
  );
  const [description, setDescription] = useState<string>(defaultInputs?.desc || '');
  const DescriptionInput = (
    <textarea id="DescriptionInput" value={description} onChange={(e): void => setDescription(e.target.value)} />
  );

  const [eventUrl, setEventUrl] = useInput(defaultInputs?.event_url || '');
  const EventUrlInput = (
    <TextInputForm name="EventUrlInput" label="이벤트 URL" value={eventUrl} onChange={setEventUrl} />
  );
  const [mainImageUrl, setMainImageUrl] = useInput(defaultInputs?.main_img_url || '');
  const MainImageUrlInput = (
    <TextInputForm name="MainImageUrlInput" label="이미지 URL" value={mainImageUrl} onChange={setMainImageUrl} />
  );
  const [subImageUrl, setSubImageUrl] = useInput(defaultInputs?.sub_img_url || '');
  const SubImageUrlInput = (
    <TextInputForm name="SubImageUrlInput" label="서브 이미지 URL" value={subImageUrl} onChange={setSubImageUrl} />
  );

  const [buttonName, setButtonName] = useInput(defaultInputs?.btn_name || '');
  const ButtonNameInput = (
    <TextInputForm name="ButtonNameInput" label="연결 버튼명" value={buttonName} onChange={setButtonName} />
  );

  const [kind, setKind] = useInput(reversedKindOptions[defaultInputs?.kind || 1]);
  const KindSelect = (
    <SelectForm
      label="종류"
      options={['이벤트', '공지', '이벤트(iOS)', '공지(iOS)']}
      value={kind}
      onSelect={setKind}
      name="KindSelect"
    />
  );

  useEffect(() => {
    if (onEmitInputs) {
      onEmitInputs({
        content,
        description,
        eventUrl,
        mainImageUrl,
        subImageUrl,
        buttonName,
        kind
      });
    }
  }, [content, description, eventUrl, mainImageUrl, subImageUrl, buttonName, kind]);

  useEffect(() => {
    if (defaultInputs?.main_img_url) {
      const img = new Image();
      img.src = defaultInputs?.main_img_url;
    }
    if (defaultInputs?.sub_img_url) {
      const img = new Image();
      img.src = defaultInputs?.sub_img_url;
    }
  }, [defaultInputs]);

  return (
    <S.StyledInputs>
      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          <p>이벤트 내용</p>
          {ContentInput}
        </Grid>
        <Grid item xs>
          <p>이벤트 설명</p>
          {DescriptionInput}
        </Grid>
      </S.Row>
      <S.Row container className={classes.margin} spacing={1} alignItems="flex-end">
        <Grid item xs>
          {EventUrlInput}
        </Grid>
        <Grid item xs>
          {defaultInputs?.main_img_url && (
            <S.Tooltip title={<img src={defaultInputs?.main_img_url} alt="로딩 중..." width={500} height="auto" />}>
              <VisibilityIcon />
            </S.Tooltip>
          )}
          {MainImageUrlInput}
        </Grid>
        <Grid item xs>
          {defaultInputs?.sub_img_url && (
            <S.Tooltip title={<img src={defaultInputs?.sub_img_url} alt="로딩 중..." width={500} height="auto" />}>
              <VisibilityIcon />
            </S.Tooltip>
          )}
          {SubImageUrlInput}
        </Grid>
        <Grid item xs>
          {ButtonNameInput}
        </Grid>
        <Grid item xs>
          {KindSelect}
        </Grid>
      </S.Row>
      <S.ButtonRow>{Buttons}</S.ButtonRow>
    </S.StyledInputs>
  );
}
export default Inputs;
