import { useState } from 'react';
import { Report } from '@TS/cashplace/report';
import MapSection from '../MapSection';
import InfoSection from '../InfoSection';
import { kindOptions } from '../../options';
import * as S from './styles';

type DetailProps = {
  reports: Report[];
  id?: number;
};

function Detail({ reports, id }: DetailProps): JSX.Element | null {
  const [report, setReport] = useState<Report | null>(() => reports.find((report) => report.report_id === id) ?? null);

  const changeReportLatLng = (naverLatLng: naver.maps.LatLng) => {
    if (report?.kind === kindOptions['위치 수정']) {
      setReport({ ...report, lat: naverLatLng.lat(), lng: naverLatLng.lng() });
    }
  };

  if (!report) return null;
  return (
    <S.StyledDetail>
      <MapSection report={report} onChangeReportLatLng={changeReportLatLng} />
      <InfoSection report={report} />
    </S.StyledDetail>
  );
}
export default Detail;
