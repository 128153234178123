/* eslint-disable max-classes-per-file */
import dayjs from 'dayjs';
import { sortByName } from '@FUNC/sort';
import type { Comment, CustomSegment, Period, Select } from '@TS/max/campaigns/segment';
import assert from 'assert';
import type { CampaignDetail } from '../redux/campaigns/slice';
import type {
  SegmentOptionType,
  SegmentAllUserScheme,
  SegmentBase,
  SegmentSchemesType,
  SegmentCustomScheme,
  SegmentFileScheme,
  SegmentCommercialScheme,
  SegmentCondition
} from './type';

const classifySegmentType = (campaign: CampaignDetail): SegmentSchemesType => {
  const segment = campaign.segments[0] as SegmentAllUserScheme | CustomSegment;
  if (isSegmentAllType(segment)) {
    if (segment.name === '모든 사용자') return 'allUser';
    return 'commercial';
  }
  if (segment.name === '전체 타겟') return 'allUser'; // 레거시
  if (segment.segment_type === 'file') return 'file';
  return 'custom';
};

class SegmentTemplate {
  campaign: CampaignDetail;

  constructor(campaign: CampaignDetail) {
    this.campaign = campaign;
  }

  /** - all user 타입은 period 필드가 없음.
   * - default 값 = 90 */
  private defaultPeriod: Period = 90;

  protected getDuration(period = this.defaultPeriod, createdAt?: string): string {
    return serializeSegmentDuration(period, createdAt);
  }

  protected createBase(): SegmentBase {
    const { estimatedUsers } = this.campaign;
    const { name, description } = this.campaign.segments[0] as CustomSegment;

    const segmentForAllCase = ['모든 사용자', '테스트용 광고 ID'];
    const formattedValue = {
      name: name === '전체 타겟' ? '모든 사용자' : name,
      get description() {
        return segmentForAllCase.includes(this.name) ? '' : description;
      }
    };

    return {
      estimatedUsers: estimatedUsers ?? 0,
      ...formattedValue,
      period: this.defaultPeriod
    };
  }
}

export class AllUserSegmentScheme extends SegmentTemplate {
  print(): SegmentAllUserScheme {
    return {
      ...this.createBase(),
      type: 'allUser',
      duration: this.getDuration()
    };
  }
}

export class FileSegmentScheme extends SegmentTemplate {
  print(): SegmentFileScheme {
    return {
      ...this.createBase(),
      type: 'file'
    };
  }
}

export class CustomSegmentScheme extends SegmentTemplate {
  segment: CustomSegment;

  constructor(campaign: CampaignDetail) {
    super(campaign);
    this.segment = campaign.segments[0] as CustomSegment;
  }

  print(): SegmentCustomScheme {
    const { comment, period } = this.segment;
    const parsedComment: Comment[] | null = comment !== null ? JSON.parse(comment) : null;
    const options = parsedComment === null ? [] : generateSegmentOptions(parsedComment);
    const duration = this.getDuration(this.segment.period, this.segment.created_at);

    return {
      ...this.createBase(),
      type: 'custom',
      period,
      options,
      duration
    };
  }
}

export class CommercialSegmentScheme extends SegmentTemplate {
  print(): SegmentCommercialScheme {
    const { adids } = this.campaign;
    // NOTE: 광고ID는 무조건 adids가 있어야 함
    assert(adids !== null);
    return {
      ...this.createBase(),
      type: 'commercial',
      name: '광고 ID',
      adids
    };
  }
}

const generateSegmentOptions = (segments: Comment[]): SegmentOptionType[] =>
  segments.map(({ lv1, selects, areas }) => {
    const selection: Partial<Select> = (Object.entries(selects[0]) as Entries<Select>)
      .filter(([, value]) => value.length > 0)
      .sort(([a], [b]) => sortByName(a, b))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    const address: SegmentOptionType['lv2'] = areas.reduce(
      (acc, curr) => {
        if (curr.slice(-2) === '상권') {
          acc.commercial.push(curr);
        } else {
          acc.district.push(curr);
        }
        return acc;
      },
      { district: [], commercial: [] } as SegmentOptionType['lv2']
    );

    return {
      lv1,
      lv2: address,
      selection
    };
  });

const serializeSegmentDuration = (period: Period, createdAt?: string): string => {
  let endDate = dayjs().subtract(1, 'day');
  if (createdAt && period) {
    endDate = dayjs(createdAt).subtract(1, 'day');
  }
  const [end, start] = [endDate.subtract(period - 1, 'day'), endDate].map((item) => item.format('YYYY.MM.DD(dd)'));
  return `${end} ~ ${start} (총 ${period}일)`;
};

function isSegmentAllType(segment: SegmentAllUserScheme | CustomSegment): segment is SegmentAllUserScheme {
  return !('period' in segment);
}

export const schemeSegment = (campaign: CampaignDetail): SegmentCondition => {
  const type = classifySegmentType(campaign);

  if (type === 'allUser') return new AllUserSegmentScheme(campaign).print();
  if (type === 'commercial') return new CommercialSegmentScheme(campaign).print();
  if (type === 'file') return new FileSegmentScheme(campaign).print();
  return new CustomSegmentScheme(campaign).print();
};
