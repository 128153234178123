import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import ClosingManagerInnerImages from '@P/ClosingManager/tableSection/detail/Images';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import useDueDate from '@HOOK/useDueDate';
import useAuth from '@HOOK/useAuth';
import { actions } from '@/redux/closingManager/state';
import * as S from './styles';

function Detail({ detail, detailProperties }) {
  // MC-1096: 수집 완료된 후 4일 뒤 자동 비활성화
  const { isAuthoritative } = useAuth();
  const { leftDaysBeforeDueDate, isOverdue } = useDueDate(moment(detail.charge_result_at).add(3, 'days').toString());

  // UI
  const classes = S.useStyles();
  const dispatch = useDispatch();

  const acceptButtonConfig = useMemo(
    () =>
      detail.report_status === 4
        ? {
            title: '승인 취소',
            callback: () => dispatch(actions.patchClosingData(detail.site_id, 'close_qa_cancel'))
          }
        : {
            title: '승인',
            callback: () => dispatch(actions.patchClosingData(detail.site_id, 'close_accept_pending'))
          },
    [detail, dispatch]
  );
  const isAcceptButtonDisabled = useMemo(
    () => (isOverdue && !isAuthoritative) || ![1, 4].includes(detail.report_status),
    [isOverdue, isAuthoritative, detail]
  );
  const rejectButtonConfig = useMemo(
    () =>
      detail.report_status === 2
        ? {
            title: '거절 취소',
            callback: () => dispatch(actions.patchClosingData(detail.site_id, 'close_qa_cancel'))
          }
        : {
            title: '거절',
            callback: () => dispatch(actions.patchClosingData(detail.site_id, 'close_reject_pending'))
          },
    [detail, dispatch]
  );
  const isRejectButtonDisabled = useMemo(
    () => (isOverdue && !isAuthoritative) || ![1, 2].includes(detail.report_status),
    [isOverdue, isAuthoritative, detail]
  );
  const pendingButtonConfig = useMemo(
    () =>
      detail.report_status === 3
        ? {
            title: '보류 취소',
            callback: () => dispatch(actions.patchClosingData(detail.site_id, 'close_qa_cancel'))
          }
        : {
            title: '판단 보류',
            callback: () => dispatch(actions.patchClosingData(detail.site_id, 'close_pending'))
          },
    [detail, dispatch]
  );
  const isPendingButtonDisabled = useMemo(
    () => (isOverdue && !isAuthoritative) || ![1, 3].includes(detail.report_status),
    [isOverdue, isAuthoritative, detail]
  );

  return (
    <Box className={classes.box}>
      <Paper className={classes.paper} variant="outlined">
        {!detailProperties.siteId && (
          <p>
            수집대상 (site_id): <strong>{detail.site_id}</strong>
          </p>
        )}
        <p>
          위도 (lat): <strong>{detail.lat ?? ''}</strong>
        </p>
        <p>
          경도 (lng): <strong>{detail.lng ?? ''}</strong>
        </p>
        {!detailProperties.cid && (
          <p>
            cid: <strong>{detail.cid}</strong>
          </p>
        )}
        {!detailProperties.complexName && (
          <p>
            복합몰: <strong>{detail.complex_name}</strong>
          </p>
        )}
        {!detailProperties.complexBranchName && (
          <p>
            복합몰 지점: <strong>{detail.complex_branch_name}</strong>
          </p>
        )}
        {!detailProperties.storeId && (
          <p>
            수집대상 (store_id): <strong>{detail.store_id}</strong>
          </p>
        )}
        {!detailProperties.placeName && (
          <p>
            장소명: <strong>{detail.name}</strong>
          </p>
        )}
        {!detailProperties.placeBranchName && (
          <p>
            지점명: <strong>{detail.branch_name}</strong>
          </p>
        )}
        {!detailProperties.floor && (
          <p>
            층: <strong>{detail.floor}</strong>
          </p>
        )}
        {!detailProperties.category && (
          <p>
            카테고리: <strong>{detail.category_all_kor}</strong>
          </p>
        )}
        {!detailProperties.collector && (
          <p>
            수집자: <strong>{detail.charge}</strong>
          </p>
        )}
        {!detailProperties.timestamp && (
          <p>
            수집완료시간: <strong>{detail.created_at}</strong>
          </p>
        )}
        <p>
          미수집사유: <strong>{detail.report_reason}</strong>
        </p>
      </Paper>
      <Paper className={classes.paper} variant="outlined">
        <ClosingManagerInnerImages detail={detail} />
      </Paper>
      <S.ButtonRow>
        {!isAuthoritative && (
          <Typography color={leftDaysBeforeDueDate === 0 ? 'secondary' : 'default'}>
            D{isOverdue ? '+' : '-'}
            {Math.abs(leftDaysBeforeDueDate)}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className={classes.button}
          onClick={acceptButtonConfig.callback}
          disabled={isAcceptButtonDisabled}
        >
          {acceptButtonConfig.title}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          className={classes.button}
          onClick={rejectButtonConfig.callback}
          disabled={isRejectButtonDisabled}
        >
          {rejectButtonConfig.title}
        </Button>
        <Button
          variant="contained"
          color="default"
          disableElevation
          className={classes.button}
          onClick={pendingButtonConfig.callback}
          disabled={isPendingButtonDisabled}
        >
          {pendingButtonConfig.title}
        </Button>
      </S.ButtonRow>
    </Box>
  );
}
export default Detail;

Detail.propTypes = {
  detail: PropTypes.objectOf(PropTypes.any).isRequired,
  detailProperties: PropTypes.objectOf(PropTypes.any).isRequired
};
