import { grayscale800, Body, grayscale100 } from 'loplat-ui';
import styled from 'styled-components';
import { gridSpread, listMargin, ulMargin } from '@P/max/campaigns/utils/styles';

type Props = {
  subTitle: string;
  children: React.ReactNode;
  wide?: boolean;
};
const SectionRow = ({ subTitle, children, wide }: Props): JSX.Element => (
  <RowWrapper wide={wide}>
    <Body tag="span" color={grayscale800} weight="bold" size="base">
      {subTitle}
    </Body>
    <RowContent wide={wide}>{children}</RowContent>
  </RowWrapper>
);

export default SectionRow;

export const RowWrapper = styled.div<Pick<Props, 'wide'>>`
  display: flex;
  align-items: flex-start;
  ${gridSpread};
  padding: 12px 16px;
  border-bottom: 1px solid ${grayscale100};

  > span {
    word-break: keep-all;
    grid-column: ${({ wide }) => (wide ? '1/3' : '1/4')};
    padding: 0;
    margin: 0;
  }
`;

export const RowContent = styled.div<Pick<Props, 'wide'>>`
  grid-column: ${({ wide }) => (wide ? '3/-1' : '4/-1')};
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;

  ul {
    text-align: right;
    ${ulMargin};
    li {
      ${listMargin()};
    }
  }
`;
