import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { toastErrorMessage, toastSuccessMessage, toastWarningMessage } from '@FUNC/toast';
import Loading from '@F/Loading';
import useAuth from '@HOOK/useAuth';
import '@lottiefiles/lottie-player';
import { managerLocalStorage } from '@/utils/localStorageParser';
import LoginForm from './LoginForm';
import * as S from './styles';

function Login(): JSX.Element {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLogining, setIsLogining] = useState(false);
  const { login, refresh } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const redirect = () => {
    if (location.state) {
      // redirect로 넘어온 경우
      const { from } = location.state as { from: { pathname: string } };

      navigate(from.pathname);
    } else {
      navigate('/');
    }
  };

  const handleSubmit = async (username: string, password: string) => {
    try {
      setIsLogining(true);
      await login(username, password);
      redirect();
      toastSuccessMessage('로그인에 성공했습니다.');
    } catch (e) {
      toastErrorMessage('아이디와 비밀번호를 다시 확인해주세요');
    } finally {
      setIsLogining(false);
    }
  };

  useEffect(() => {
    const refreshToken = managerLocalStorage.get(managerLocalStorage.keys.refresh_token);

    if (refreshToken) {
      setIsRefreshing(true);

      refresh()
        .then((_) => {
          toastSuccessMessage('자동 로그인에 성공했습니다.');
          redirect();
        })
        .catch((e) => {
          switch (e.message) {
            case 'REFRESH_TOKEN_IS_EXPIRED': {
              toastWarningMessage('토큰이 만료되었습니다. 다시 로그인을 시도해주세요.');
              break;
            }
            case 'REFRESH_TOKEN_DOES_NOT_EXIST': {
              break;
            }
            default: {
              toastErrorMessage('로그인에 실패했습니다. 다시 로그인을 시도해주세요.');
            }
          }
        })
        .finally(() => {
          setIsRefreshing(false);
        });
    }
  }, []);

  return (
    <S.StyledLogin>
      {isLogining && <Loading />}
      <main>
        {Introduction}
        <LoginForm submit={handleSubmit} disabled={isRefreshing || isLogining} />
      </main>
      <footer>
        <p>
          로플랫 직원용 사이트입니다. <br />
          이용 문의는
          <span> jyp0808@loplat.com </span>
          으로 연락주세요.
        </p>
      </footer>
    </S.StyledLogin>
  );
}
export default Login;

const Introduction = (
  <S.Lottie>
    <div className="loading" />
    <lottie-player
      autoplay
      loop
      speed="3"
      mode="normal"
      src="https://assets3.lottiefiles.com/private_files/lf30_fvqxryww.json"
      style={{ width: '100%', height: '100%' }}
    />
  </S.Lottie>
);
