import styled from 'styled-components';
import { ulMargin } from '@P/max/campaigns/utils/styles';

export const Container = styled.section<{ expanded: boolean }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 32px;

  h3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    button svg {
      transition: transform 0.3s ease;
      ${({ expanded }) => (expanded ? 'transform: rotate(0deg);' : 'transform: rotate(180deg);')}
    }
  }

  ul {
    ${ulMargin};
  }

  > div {
    padding: 16px;
  }
`;
