import { TableRow, TableCell } from '@material-ui/core';
import styled from '@emotion/styled';
import MapSection from '../MapSection';
import ImageSection from '../ImageSection';
import InfoSection from '../InfoSection';

import * as S from './styles';

function DetailTableRow({ inView, colSpan, fingerprint, isExpanded, imageUrls }) {
  return (
    <StyledTableRow isExpanded={isExpanded}>
      <TableCell colSpan={colSpan}>
        <S.StyledDetail>
          {inView ? (
            <>
              {isExpanded ? <MapSection fingerprint={fingerprint} isExpanded={isExpanded} /> : null}
              <ImageSection fingerprint={fingerprint} imageUrls={imageUrls} inView={inView} isExpanded={isExpanded} />
            </>
          ) : null}
          <InfoSection fingerprint={fingerprint} />
        </S.StyledDetail>
      </TableCell>
    </StyledTableRow>
  );
}
export default DetailTableRow;

const StyledTableRow = styled(TableRow)`
  ${({ isExpanded }) =>
    isExpanded ? `visibility: visible; ` : `visibility:hidden; height: 0px; width: 0px; display:block;`}
`;
