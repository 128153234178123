import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';

export const copyMessageToClipboard = async (text: string): Promise<void> => {
  try {
    await navigator.clipboard.writeText(text);
    toastSuccessMessage('클립보드에 복사되었습니다.');
  } catch (e) {
    toastErrorMessage('복사에 실패하였습니다.');
  }
};
