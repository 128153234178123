import { action, observable, makeObservable } from 'mobx';
import { create, persist } from 'mobx-persist';

class ValuesStore {
  // NOTE: 권한과 메뉴에 따라 볼 수 있는 카테고리 영역이 다릅니다. MC-1067
  @persist('object') @observable categories = {
    all: [],
    manager: [],
    'auto-fp': []
  };

  @persist('list') @observable collectors = [];

  @persist('list') @observable sdkClientIds = [];

  constructor() {
    makeObservable(this);
  }

  async getValues(key) {
    /**
     * NOTE: react 와 mobX-persist 사이에 race condition 이 걸려서 setTimeout 으로 풀어줌
     * */
    await setTimeout(() => {}, 0);
    return this[key];
  }

  @action setValues(key, newValue) {
    this[key] = newValue;
  }
}
export const valuesStore = new ValuesStore();

const hydrate = create();
hydrate('values', valuesStore);
