export const statusOptions: any = {
  전체: null,
  활성: 1,
  비활성: 0
};

export const kindOptions: any = {
  전체: null,
  이벤트: 1,
  공지: 2,
  '이벤트(iOS)': 11,
  '공지(iOS)': 12
};

export const reversedKindOptions: any = {
  1: '이벤트',
  2: '공지',
  11: '이벤트(iOS)',
  12: '공지(iOS)'
};
