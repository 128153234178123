import { useEffect, useState } from 'react';
import Wrapper from '@F/materialUI/Wrapper';
import { cookUsersApi } from '@API/manager/cook';
import CreateUser from './CreateUser';
import Filter from './Filter';
import TableSection from './tableSection/TableSection';

function CookUser() {
  const [teams, setTeams] = useState([]);
  useEffect(() => {
    cookUsersApi.getCookTeams().then((response) => {
      setTeams([{ id: null, name: '없음' }, ...response.data]);
    });
  }, []);

  return (
    <div>
      <Wrapper>
        <CreateUser teams={teams} />
      </Wrapper>
      <br />
      <Wrapper>
        <Filter teams={teams} />
      </Wrapper>
      <br />
      <Wrapper>
        <TableSection teams={teams} />
      </Wrapper>
    </div>
  );
}
export default CookUser;

CookUser.propTypes = {};
