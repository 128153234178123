import styled from 'styled-components';

export const StyledDashboard = styled.div``;

export const ChartContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  padding: 10px 0;
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row;
  justify-content: center;
  align-items: center;
`;
