import { useCallback, useEffect, useMemo } from 'react';
import { createHeadCells } from '@F/table/Table/functions';
import PaginationRow from '@F/table/PaginationRow';
import EnhancedTable from '@F/table/Table';
import { shallowEqual, useDispatch } from 'react-redux';
import Loading from '@F/Loading';
import StatusLabel from '@F/StatusLabel';
import Red from '@material-ui/core/colors/red';
import Blue from '@material-ui/core/colors/blue';
import moment from 'moment';
import { Event } from '@TS/cashplace/event';
import { Column } from '@TS/columns';
import { useAppSelector } from '@HOOK/useSelector';
import Detail from '../Detail';
import { reversedKindOptions } from '../../options';
import { actions } from '@/redux/cashplace/event/state';
import * as S from './styles';

const columns: Column[] = [
  { name: 'status', label: '상태' },
  { name: 'id', label: 'Event ID', isImportant: true },
  { name: 'content', label: '이벤트 내용' },
  { name: 'description', label: '이벤트 설명' },
  { name: 'buttonName', label: '연결 버튼명' },
  { name: 'kind', label: '종류' },
  { name: 'eventUrl', label: '이벤트 URL' },
  { name: 'createdAt', label: '생성일시' },
  { name: 'updatedAt', label: '수정일시' }
];

function TableSection(): JSX.Element {
  const { isLoading, page, perPage, count, events, openedEventIds } = useAppSelector(
    (state) => ({
      isLoading: state.event.isLoading,
      page: state.event.page,
      perPage: state.event.perPage,
      count: state.event.count,
      events: state.event.events,
      openedEventIds: state.event.openedEventIds
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const setPage = useCallback(
    (newPage) => {
      if (page === newPage) dispatch(actions.fetchEvents());
      else dispatch(actions.setPage(newPage));
    },
    [dispatch, page]
  );
  useEffect(() => {
    dispatch(actions.fetchEvents());
  }, [dispatch, page]);

  const setOpenedEventIds = useCallback(
    (newOpenedEventIds) => {
      dispatch(actions.setValue('openedEventIds', newOpenedEventIds));
    },
    [dispatch]
  );

  const headCells = columns.map((column) => createHeadCells(column.name, column.label));
  const rows = useMemo(
    () =>
      events?.map((event: Event) => ({
        status: {
          value: event.status,
          component: formatResultLabel(event.status)
        },
        id: event.event_id,
        content: event.content,
        description: event.desc,
        buttonName: event.btn_name,
        kind: reversedKindOptions[event.kind],
        eventUrl: event.event_url,
        createdAt: event.created_at ? moment(event.created_at).add(9, 'hour').format('YYYY-MM-DD HH:mm:ss') : '',
        updatedAt: event.updated_at ? moment(event.updated_at).add(9, 'hour').format('YYYY-MM-DD HH:mm:ss') : ''
      })) ?? [],
    [events]
  );

  return (
    <S.StyledTableSection>
      {isLoading && <Loading />}

      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} />
      <EnhancedTable
        rows={rows}
        headCells={headCells}
        isCheckable={false}
        isCollapsible
        opened={openedEventIds}
        setOpened={setOpenedEventIds}
      >
        <Detail data={events} />
      </EnhancedTable>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} reverse />
    </S.StyledTableSection>
  );
}
export default TableSection;

function formatResultLabel(status: boolean): React.ReactNode {
  switch (status) {
    case true:
      return (
        <StatusLabel color={Blue[800]} backgroundColor={Blue[200]}>
          활성
        </StatusLabel>
      );
    case false:
    default:
      return (
        <StatusLabel color={Red[800]} backgroundColor={Red[200]}>
          비활성
        </StatusLabel>
      );
  }
}
