import { axiosInstancesManager } from '@API/axios';
import { PrimitiveLogParams } from '@TS/max/primitiveLog/api';
import { PrimitiveLog } from '@TS/max/primitiveLog/primitiveLog';
import { AxiosPromise } from 'axios';
/* eslint class-methods-use-this: 0 */

class LogApi {
  getLogQuery(params: PrimitiveLogParams): AxiosPromise<PrimitiveLog[]> {
    return axiosInstancesManager.manager.get('/log/query', {
      params
    });
  }
  getLogQueryCsv(params: PrimitiveLogParams): AxiosPromise<string> {
    return axiosInstancesManager.manager.get('/log/query', {
      headers: { Accept: 'text/csv' },
      params
    });
  }
}

export default new LogApi();
