import { useEffect } from 'react';
import Wrapper from '@F/materialUI/Wrapper';
import SelectForm from '@F/materialUI/SelectForm';
import { Button, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import useInput from '@HOOK/useInput';
import { PerPageOption } from '@TS/perPageOption';
import { EventKind, EventStatus } from '@TS/cashplace/event';
import { actions } from '@/redux/cashplace/event/state';
import * as S from './styles';
import { kindOptions, statusOptions } from '../options';
import PER_PAGE_OPTIONS from '@/constants/perPageOptions';

function Filter(): JSX.Element {
  const classes = S.useStyles();

  const [count, setCount] = useInput<PerPageOption>('15');
  const [status, setStatus] = useInput<EventStatus>('전체');
  const [kind, setKind] = useInput<EventKind>('전체');

  const dispatch = useDispatch();
  const fetchEvents = () => {
    dispatch(
      actions.setValue('params', {
        status: statusOptions[status],
        kind: kindOptions[kind]
      })
    );
    dispatch(actions.setValue('perPage', Number(count)));
    dispatch(actions.setPage(1));
    dispatch(actions.fetchEvents());
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <Wrapper>
      <S.StyledFilter
        onSubmit={(event) => {
          event.preventDefault();
          fetchEvents();
        }}
      >
        <S.Row className={classes.margin} container spacing={1}>
          <Grid item xs={3}>
            <SelectForm label="개수" options={PER_PAGE_OPTIONS} value={count} onSelect={setCount} name="CountSelect" />
          </Grid>
          <Grid item xs={3}>
            <SelectForm
              label="상태"
              options={Object.keys(statusOptions)}
              value={status}
              onSelect={setStatus}
              name="StatusSelect"
            />
          </Grid>
          <Grid item xs={3}>
            <SelectForm
              label="종류"
              options={Object.keys(kindOptions)}
              value={kind}
              onSelect={setKind}
              name="KindSelect"
            />
          </Grid>
        </S.Row>
        <S.ButtonRow>
          <Button variant="contained" color="primary" type="submit">
            불러오기
          </Button>
        </S.ButtonRow>
      </S.StyledFilter>
    </Wrapper>
  );
}
export default Filter;
