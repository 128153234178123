import { TableBody, TableRow } from '@material-ui/core';
import { usePagination } from '@HOOK/usePagination';
import TableSkeletonCell from '@F/table/cells/TableSkeletonCell';

export default function TableBodySkeleton(): JSX.Element {
  const { perPage } = usePagination();

  return (
    <TableBody>
      {Array(perPage)
        .fill(0)
        .map((_, i) => (
          <TableRow key={i}>
            {[
              [40, 30],
              [175, 20],
              [350, 20],
              [110, 20],
              [90, 20],
              [80, 20]
            ].map(([width, height], i) => (
              <TableSkeletonCell key={i} width={width} height={height} />
            ))}
          </TableRow>
        ))}
    </TableBody>
  );
}
