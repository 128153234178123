import { camelToSnake } from '@FUNC/word';
import qs from 'qs';

/**
 *  주요 기능
 *  - camelCase => snake_case
 *  - 배열 파라미터를 stringify: ['a','b','c'] => 'a,b,c'
 *  - object의 파라미터를 stringify: {a: {b: 'c'}} => 'a.b=c'
 *  - value가 null | undefined 이면 해당 필드 삭제
 */
export const paramsSerializer = (params: Record<string, unknown>): string =>
  Object.entries(params)
    .map(([key, value]) => stringifyQueries(key, value))
    .filter((parameter) => parameter !== '')
    .join('&');

function stringifyQueries(key: string, value: unknown): string {
  let validValue = value;
  if (Array.isArray(value) && value.length === 1) {
    validValue = value[0];
  }

  return qs.stringify(
    { [camelToSnake(key)]: validValue },
    { arrayFormat: 'comma', allowDots: true, skipNulls: true, indices: false }
  );
}
