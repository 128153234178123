import { Fragment } from 'react';
import { Section } from '@P/max/campaigns/detail/section';
import type { DeepLink, MessageCondition } from '@P/max/campaigns/utils/type';
import RowHeader from './RowHeader';
import { TreeWrapper } from './styles';

const Message = ({ conditions }: { conditions: MessageCondition[] }): JSX.Element => (
  <Section title="메시지 설정">
    <TreeWrapper data-depth="1">
      {conditions.map((item) => (
        <Fragment key={item.title}>{renderTree({ ...item, depth: 2 })}</Fragment>
      ))}
    </TreeWrapper>
  </Section>
);

export default Message;

export type Props = MessageCondition & { depth: number; type?: DeepLink['type'] };
const renderTree = ({ depth, title, content, children, type }: Props): JSX.Element => (
  <li>
    <RowHeader title={title} type={type} content={content} />
    {children && (
      <ul data-depth={depth}>
        {children.map((condition) => (
          <Fragment key={condition.title}>{renderTree({ depth: depth + 1, ...condition })}</Fragment>
        ))}
      </ul>
    )}
  </li>
);
