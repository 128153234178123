import styled from 'styled-components';

export const StyledSiteMapToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 80px;
  padding: 0 30px;
  background-color: ${(props) => props.theme.palette.common.white};

  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  border-radius: 10px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  word-break: keep-all;
  padding: 10px;
`;

export const SelectFormGroup = styled.div`
  display: flex;

  > .MuiFormControl-root {
    width: 200px;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;
