import { Button, Grid } from '@material-ui/core';
import Wrapper from '@F/materialUI/Wrapper';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import { useDispatch } from 'react-redux';
import useInput from '@HOOK/useInput';
import { PerPageOption } from '@TS/perPageOption';
import PER_PAGE_OPTIONS from '@/constants/perPageOptions';
import { actions } from '@/redux/cashplace/report/state';
import { kindOptions, resultOptions } from '../options';
import * as S from './styles';

function Filter(): JSX.Element {
  const classes = S.useStyles();

  const [userId, setUserId] = useInput('');
  const [result, setResult] = useInput<keyof typeof resultOptions>('전체');
  const [kind, setKind] = useInput<keyof typeof kindOptions>('전체');
  const [count, setCount] = useInput<PerPageOption>('15');

  const dispatch = useDispatch();
  const fetchReports = () => {
    dispatch(
      actions.setValue('params', {
        userId: userId ? Number(userId) : null,
        state: resultOptions[result],
        kind: kindOptions[kind]
      })
    );
    dispatch(actions.setValue('perPage', Number(count)));
    dispatch(actions.setPage(1));
    dispatch(actions.fetchReports());
  };

  return (
    <Wrapper>
      <S.StyledFilter
        onSubmit={(e) => {
          e.preventDefault();
          fetchReports();
        }}
      >
        <S.Row container className={classes.margin} spacing={1}>
          <Grid item xs>
            <TextInputForm name="UserIdInput" label="User ID" value={userId} onChange={setUserId} />
          </Grid>
          <Grid item xs>
            <SelectForm
              label="검수결과"
              options={Object.keys(resultOptions)}
              value={result}
              onSelect={setResult}
              name="ResultSelect"
            />
          </Grid>
          <Grid item xs>
            <SelectForm
              label="종류"
              options={Object.keys(kindOptions)}
              value={kind}
              onSelect={setKind}
              name="KindSelect"
            />
          </Grid>
          <Grid item xs>
            <SelectForm label="개수" options={PER_PAGE_OPTIONS} value={count} onSelect={setCount} name="CountSelect" />
          </Grid>
        </S.Row>

        <S.ButtonRow>
          <Button variant="contained" color="primary" type="submit">
            불러오기
          </Button>
        </S.ButtonRow>
      </S.StyledFilter>
    </Wrapper>
  );
}
export default Filter;
