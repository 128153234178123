import { useState } from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from '@F/modal/ModalWrapper';
import UserSelectForm from '@F/modal/assignModal/UserSelectForm';
import { cookSitesApi } from '@API/manager/cook';
import Loading from '@F/Loading';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import * as S from './styles';

function AssignModal({ sites, open, setOpen, onConfirm }) {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const confirm = async () => {
    if (!user || !sites.length) {
      toastErrorMessage('유저명을 입력하세요.');
      return;
    }

    try {
      setIsLoading(true);
      const siteIds = sites.map((site) => site.id.value ?? site.id);
      await cookSitesApi.rechargeSiteOnUser({ user, sites: siteIds });
      onConfirm();
      toastSuccessMessage();
    } catch (error) {
      console.error(error);
      toastErrorMessage();
    } finally {
      setIsLoading(false);
      setOpen(false);
    }
  };

  return (
    <>
      <ModalWrapper open={open} setOpen={setOpen} title="수집자 할당" confirm={confirm} isLoading={isLoading}>
        <S.StyledAssignModal>
          <UserSelectForm emitUser={setUser} />
          <S.SitesListBox>
            <p>
              할당할 장소들 (총
              {sites.length}
              개)
            </p>
            <S.SitesList>
              {sites.map((site) => (
                <S.SiteItem key={site.id.value ?? site.id}>{site.name}</S.SiteItem>
              ))}
            </S.SitesList>
          </S.SitesListBox>
        </S.StyledAssignModal>
      </ModalWrapper>
      {isLoading && <Loading />}
    </>
  );
}
export default AssignModal;

AssignModal.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onConfirm: PropTypes.func
};

AssignModal.defaultProps = {
  sites: [],
  open: false,
  setOpen: () => {},
  onConfirm: () => {}
};
