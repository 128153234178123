import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: 50,
    minWidth: 100
  },
  margin: {
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(1)
  }
}));

export const StyledDetail = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

export const Row = styled(Grid)`
  width: 90%;
  margin-bottom: 10px;
`;

export const ButtonRow = styled(Grid)`
  width: 100%;
  max-width: 500px;
  margin-bottom: 10px;
`;

export const ImageSection = styled.div`
  display: flex;
  flex-flow: row;
  overflow: auto;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: auto;
  margin-top: 1rem;
  margin-bottom: 3rem;

  img {
    padding: 0 1rem;
    width: auto;
    height: 100%;
    max-height: 500px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

export const ImageWrapper = styled.div``;

export const LoadingWrapper = styled.div`
  padding: 0 1rem;

  img {
    width: 300px;
    height: 500px;
    object-fit: cover;
  }
`;

export const MetadataWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: center;
  
  span {
    &:last-child {
    color: blue;
    font-weight: bolder;
    margin-left: 20px;
  }
`;
