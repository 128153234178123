import ReactLoading from 'react-loading';
import styled from 'styled-components';
import { theme } from '@S/index';

function Loading(): JSX.Element {
  return (
    <StyledLoading>
      <ReactLoading type="cylon" color={theme.palette.primary.dark} height={100} width={140} />
    </StyledLoading>
  );
}
export default Loading;

export const StyledLoading = styled.div`
  position: fixed;
  inset: 0;

  z-index: ${(props) => props.theme.zIndex.loading};
  pointer-events: none;

  display: flex;
  align-items: center;
  justify-content: center;
`;
