import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: 50,
    minWidth: 100
  },
  margin: {
    padding: theme.spacing(3)
  }
}));

export const Form = styled.form``;

export const Row = styled(Grid)``;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
  padding-right: 20px;
`;

export const AlignRightGrid = styled(Grid)`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
`;
