export const qaStatusOptions = {
  전체: null,
  미검수: -1,
  승인: 1,
  거부: 0,
  현장확인: 2,
  확인완료: 12
};

export const imageUploadStatusOptions = {
  전체: null,
  실패: -1,
  대기: 1,
  성공: 2
};

export const fpStateOptions = {
  전체: null,
  유효: 1
};
