import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  box: {
    padding: theme.spacing(1)
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1)
  }
}));

export const ButtonRow = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
`;
