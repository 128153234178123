import { createReducer, createSetValueAction, setValueReducer } from '@/redux/common/helper-functions';

/** prefix */
const PREFIX = 'WHITELIST';

/** initial state */
const INITIAL_STATE = {
  whitelists: [],
  openedWhitelistIds: [],
  count: 0,
  params: {},
  isLoading: false
};

/** type */
export const types = {
  SET_VALUE: `${PREFIX}/SET_VALUE`,
  SET_LOADING: `${PREFIX}/SET_LOADING`,
  FETCH_LISTS: `${PREFIX}/FETCH_LISTS`,
  SET_NEW_LISTS: `${PREFIX}/SET_NEW_LISTS`,
  SET_NEW_LIST: `${PREFIX}/SET_NEW_LIST`,
  CREATE_LIST: `${PREFIX}/CREATE_LIST`,
  DELETE_LIST: `${PREFIX}/DELETE_LIST`,
  DELETE_LIST_FROM_LISTS: `${PREFIX}/DELETE_LIST_FROM_LISTS`,
  UPDATE_LIST: `${PREFIX}/UPDATE_LIST`
};

/** action */
export const actions = {
  setValue: createSetValueAction(types.SET_VALUE),
  setLoading: (isLoading) => ({ type: types.SET_LOADING, isLoading }),
  fetchLists: () => ({ type: types.FETCH_LISTS }),
  setNewLists: (lists) => ({ type: types.SET_NEW_LISTS, lists }),
  setNewList: (list) => ({ type: types.SET_NEW_LIST, list }),
  createList: (params) => ({ type: types.CREATE_LIST, params }),
  deleteList: (list) => ({ type: types.DELETE_LIST, list }),
  deleteListFromLists: (list) => ({ type: types.DELETE_LIST_FROM_LISTS, list }),
  updateList: (params) => ({ type: types.UPDATE_LIST, params })
};

/** reducer */
const reducer = createReducer(INITIAL_STATE, {
  [types.SET_VALUE]: setValueReducer,
  [types.SET_LOADING]: (draft, action) => {
    draft.isLoading = action.isLoading;
  },
  [types.SET_NEW_LISTS]: (draft, action) => {
    draft.whitelists = action.lists;
    draft.openedWhitelistIds = [];
  },
  [types.SET_NEW_LIST]: (draft, action) => {
    const index = draft.whitelists.findIndex((whitelist) => whitelist.whitelist_id === action.list.whitelist_id);
    if (index >= 0) {
      draft.whitelists[index] = action.list;
    }
  },
  [types.DELETE_LIST_FROM_LISTS]: (draft, action) => {
    const index = draft.whitelists.findIndex((whitelist) => whitelist.whitelist_id === action.list.whitelist_id);
    if (index >= 0) {
      draft.whitelists = [...draft.whitelists.slice(0, index), ...draft.whitelists.slice(index + 1)];
    }
  }
});
export default reducer;
