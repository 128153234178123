import type { TableHead } from '@TS/columns';
import type { CampaignStatus, FetchCampaignStatusKorean } from '@TS/max/campaigns/status';
import type { TableHeadName } from '@TS/max/table/campaignTable';

export const TABLE_HEAD: TableHead<TableHeadName>[] = [
  { name: 'compare', label: '비교', notSortable: true, width: 80 },
  { name: 'campaignId', label: '캠페인 ID', width: 150 },
  { name: 'company', label: '회사명', width: 150, notSortable: true },
  { name: 'clientCode', label: '고객관리코드', width: 200, align: 'left' },
  { name: 'name', label: '캠페인 이름', align: 'left', width: 500 },
  { name: 'status', label: '상태', width: 100 },
  { name: 'hitCnt', label: '도달', width: 100 },
  { name: 'clickCnt', label: '응답', width: 100 },
  { name: 'start', label: '시작일', width: 150 },
  { name: 'end', label: '종료일', width: 150 },
  { name: 'createdAt', label: '생성일', width: 150 }
];

export const campaignStatus: Record<CampaignStatus, FetchCampaignStatusKorean> = {
  0: '대기',
  1: '진행',
  2: '테스트',
  7: '종료'
};
