import { highlight, styled } from 'loplat-ui';

export const AlertMessage = styled.span`
  ${highlight()};
  font-weight: bold;
`;

export const Period = styled.span`
  padding: 2px 6px;
`;
