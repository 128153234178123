import { useEffect } from 'react';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import { Button, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '@F/Loading';
import { useInput } from '@HOOK/';
import { actions } from '@/redux/admin/userManager/state';
import { roleOptions, statusOptions } from '../options';
import * as S from './styles';

function Filter() {
  const classes = S.useStyles();

  const [userName, setUserName] = useInput('');
  const UserNameInput = <TextInputForm name="UserName" label="아이디" onChange={setUserName} value={userName} />;

  const [role, setRole] = useInput('전체');
  const RoleSelect = (
    <SelectForm label="권한" options={Object.keys(roleOptions)} value={role} onSelect={setRole} name="RoleSelect" />
  );
  const [status, setStatus] = useInput('활성');
  const StatusSelect = (
    <SelectForm
      label="상태"
      options={Object.keys(statusOptions)}
      value={status}
      onSelect={setStatus}
      name="StatusSelect"
    />
  );

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.userManager.isLoading);

  const fetchUsers = () => {
    dispatch(actions.setPage(1));
    dispatch(
      actions.setValue('params', {
        id: userName || null,
        role: !userName ? roleOptions[role] : null,
        status: !userName ? statusOptions[status] : null
      })
    );
    dispatch(actions.fetchUsers());
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <S.StyledFilter
      onKeyDown={(event) => {
        if (event.key === 'Enter') fetchUsers();
      }}
    >
      {isLoading && <Loading />}
      <div className={classes.title}>사용자 목록</div>
      <Grid container spacing={2} className={classes.wrapper}>
        <Grid item xs={3}>
          {UserNameInput}
        </Grid>
        <Grid item xs={3}>
          {RoleSelect}
        </Grid>
        <Grid item xs={3}>
          {StatusSelect}
        </Grid>
      </Grid>
      <S.ButtonRow>
        <Button variant="contained" color="primary" onClick={fetchUsers}>
          불러오기
        </Button>
      </S.ButtonRow>
    </S.StyledFilter>
  );
}
export default Filter;

Filter.propTypes = {};
