/* eslint-disable no-underscore-dangle */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { CASHPLACE_API_URL, COOK_API_URL, MANAGER_API_URL, MAX_API_URL } from '@/config';
import createOnRequestRejected, { AxiosInstanceType } from '../interceptor/createOnRequestRejected';
import onResponseRejected from '../interceptor/onResponseRejected';

// config
const axiosInstanceConfigs: { [key in AxiosInstanceType]: AxiosRequestConfig } = {
  cook: {
    baseURL: COOK_API_URL
  },
  manager: {
    baseURL: MANAGER_API_URL
  },
  cashplace: {
    baseURL: CASHPLACE_API_URL,
    headers: {
      'X-API-KEY': 'app-id=lop_manager,signature=bf5efdfcc28273d6a6a8e8f70d059918dde22d5114d19a74b5986db8b7c4a960'
    }
  },
  max: {
    baseURL: MAX_API_URL
  },
  homepage: {
    baseURL: MANAGER_API_URL
  }
};

type AxiosInstances = {
  [key in AxiosInstanceType]: AxiosInstance;
};

const initialAxiosInstances: AxiosInstances = {
  cook: axios.create(),
  manager: axios.create(),
  cashplace: axios.create(),
  max: axios.create(),
  homepage: axios.create()
};

class AxiosInstanceManager {
  _axiosInstances: AxiosInstances;

  constructor() {
    this._axiosInstances = initialAxiosInstances;
    this.resetAxiosInstances();
  }

  get manager(): AxiosInstance {
    return this._axiosInstances.manager;
  }
  get cook(): AxiosInstance {
    return this._axiosInstances.cook;
  }
  get cashplace(): AxiosInstance {
    return this._axiosInstances.cashplace;
  }
  get max(): AxiosInstance {
    return this._axiosInstances.max;
  }
  get homepage(): AxiosInstance {
    return this._axiosInstances.homepage;
  }
  get axiosInstances(): AxiosInstances {
    return this._axiosInstances;
  }

  resetAxiosInstances = () => {
    const axiosInstances = { ...initialAxiosInstances };

    Object.entries(axiosInstanceConfigs).forEach(([_name, instanceConfig]) => {
      const name = _name as AxiosInstanceType;
      const axiosInstance = axios.create(instanceConfig);

      axiosInstance.interceptors.request.use(undefined, createOnRequestRejected(name), undefined);
      axiosInstance.interceptors.response.use(undefined, onResponseRejected, undefined);

      axiosInstances[name] = axiosInstance;
    });

    this._axiosInstances = axiosInstances;
  };
}

const axiosInstancesManager = new AxiosInstanceManager();

export default axiosInstancesManager;
