import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import Loading from '@F/Loading';
import { useInput } from '@HOOK/';
import { actions } from '@/redux/admin/whitelist/state';
import { statusOptions } from '../options';
import * as S from './styles';

function Filter() {
  const classes = S.useStyles();

  const [creator, setCreator] = useInput('');
  const CreatorInput = <TextInputForm name="Creator" label="생성한 사람" onChange={setCreator} value={creator} />;
  const [status, setStatus] = useInput('전체');
  const StatusSelect = (
    <SelectForm
      label="상태"
      options={Object.keys(statusOptions)}
      value={status}
      onSelect={setStatus}
      name="StatusSelect"
    />
  );

  // fetch data
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.whitelist.isLoading);

  const fetchWhitelists = () => {
    dispatch(
      actions.setValue('params', {
        status: statusOptions[status],
        creator: creator || null
      })
    );
    dispatch(actions.fetchLists());
  };
  useEffect(() => {
    fetchWhitelists();
  }, []);

  return (
    <S.StyledFilter
      onKeyDown={(event) => {
        if (event.key === 'Enter') fetchWhitelists();
      }}
    >
      {isLoading && <Loading />}
      <div className={classes.title}>화이트리스트 목록</div>
      <Grid container spacing={2} className={classes.wrapper}>
        <Grid item xs={3}>
          {CreatorInput}
        </Grid>
        <Grid item xs={3}>
          {StatusSelect}
        </Grid>
      </Grid>
      <S.ButtonRow>
        <Button variant="contained" color="primary" onClick={fetchWhitelists}>
          불러오기
        </Button>
      </S.ButtonRow>
    </S.StyledFilter>
  );
}
export default Filter;

Filter.propTypes = {};
