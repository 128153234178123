import styled from 'styled-components';
import { grayscale200, WarningCircleOutlineIcon, Body, grayscale800 } from 'loplat-ui';
import type { CustomError } from '@FUNC/AxiosCustomError';
import { ERROR_MESSAGES } from '@/constants/error';

type Props = {
  error: CustomError;
};

const ErrorFallback = ({ error }: Props): JSX.Element => (
  <Wrapper>
    <WarningCircleOutlineIcon size={100} fillColor={grayscale200} />
    <Body color={grayscale800} weight="extraBold" size="4xl">
      에러가 발생했습니다. :(
    </Body>
    <Body size="xl">{ERROR_MESSAGES.errorBoundary[error.error] ?? (error instanceof Error && error.message)}</Body>
  </Wrapper>
);

export default ErrorFallback;

const Wrapper = styled.div`
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
