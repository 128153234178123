import { ipWhitelistsApi } from '@API/manager';

export function fetchWhitelists(params) {
  return ipWhitelistsApi.getWhitelists(params).then((response) => response.data);
}

export function fetchWhitelist(whitelistId) {
  return ipWhitelistsApi.getWhitelist(whitelistId).then((response) => {
    if (Object.keys(response.data).length === 0) return null;
    return response.data;
  });
}

export function deleteWhitelist(whitelistId) {
  return ipWhitelistsApi.deleteWhitelist(whitelistId).then();
}

export function createWhitelist(params) {
  return ipWhitelistsApi.postWhitelist(params).then();
}

export function updateWhitelist(params) {
  return ipWhitelistsApi.putWhitelist(params).then();
}
