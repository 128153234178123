/* eslint-disable no-console */
import { useState, useMemo } from 'react';
import styled from '@emotion/styled';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import { TableCell, TableRow, Button, withStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import NaverMap from '@F/map/NaverMap';
import ConfirmModal from '@F/modal/ConfirmModal';
import PinTier2 from '@I/map/pin-blue.png';
import { PLACETODO_CHARGE_RESULT } from '@/constants/api/placetodo';
import { actions } from '../redux/slice';
import { TableItem, TableItemStatus } from './index';

type PatchButtonType = 'approve' | 'deny' | 'closeStore';
interface Props {
  item: TableItem;
  toggleCollapsed: (id: number) => void;
  changeStatus: (status: TableItemStatus) => void;
}

export default function UncollectedComplexPlaceTodoItem({
  item: { placetodo, id, collapsed, status },
  toggleCollapsed,
  changeStatus
}: Props): JSX.Element | null {
  const dispatch = useDispatch();

  const [patchButtonsStatus, setPatchButtonsStatus] = useState<Record<PatchButtonType, ApiStatus>>({
    approve: 'idle',
    deny: 'idle',
    closeStore: 'idle'
  });
  const [showCloseStoreConfirmModal, setShowCloseStoreConfirmModal] = useState(false);

  const setPatchButtonStatus = (type: PatchButtonType, status: ApiStatus) =>
    setPatchButtonsStatus((prev) => ({ ...prev, [type]: status }));

  const clickApproveButton = () => {
    setPatchButtonStatus('approve', 'loading');

    dispatch(
      actions.updateUnCollectedComplexPlaceTodo({
        siteId,
        action: 'accept_uncollected',
        callback: (isSuccess) => {
          if (isSuccess) {
            changeStatus('승인');
            setPatchButtonStatus('approve', 'success');
          } else {
            setPatchButtonStatus('approve', 'failure');
          }
        }
      })
    );
  };

  const handleClickDenyButton = () => {
    // 거부 요청
    setPatchButtonStatus('deny', 'loading');
    dispatch(
      // 거부 처리 (placetodo 재할당)
      actions.updateUnCollectedComplexPlaceTodo({
        siteId,
        action: 'recharge',
        callback: (isSuccess) => {
          if (isSuccess) {
            changeStatus('거부');
            setPatchButtonStatus('deny', 'success');
          } else {
            setPatchButtonStatus('deny', 'failure');
          }
        }
      })
    );
  };

  const handleClickCloseStoreButton = () => setShowCloseStoreConfirmModal(true);
  const handleCancelCloseStore = () => setShowCloseStoreConfirmModal(false);
  const handlePermitCloseStore = () => {
    setPatchButtonStatus('closeStore', 'loading');

    // 폐점 처리
    dispatch(
      actions.updateUnCollectedComplexPlaceTodo({
        siteId,
        action: placetodo.chargeResult === 1 ? 'close_accept' : 'close_accept_pending',
        callback: (isSuccess) => {
          if (isSuccess) {
            changeStatus('폐점 처리 중');
            setPatchButtonStatus('closeStore', 'success');
          } else {
            setPatchButtonStatus('closeStore', 'failure');
          }
        }
      })
    );

    setShowCloseStoreConfirmModal(false);
  };

  const {
    siteId,
    complexName,
    complexBranchName,
    name,
    branchName,
    targetStoreId,
    floor,
    charge,
    createdAt,
    chargeResult,
    reportReason,
    lat,
    lng
  } = placetodo;

  const isUpdatedPlaceTodo = status !== '미검수';

  const naverMap = useMemo(() => {
    const markers = [
      {
        type: 'placetodo',
        lat,
        lng,
        image: PinTier2,
        size: [13 * 2.5, 17 * 2.5],
        id: siteId,
        name
      }
    ];

    return (
      <StyledNaverMapWrapper>
        <NaverMap id={`uncollected-complex-placetodo ${id}`} center={{ lat, lng }} markers={markers} />
      </StyledNaverMapWrapper>
    );
  }, [id, siteId, lat, lng, name]);

  const isPatching = Object.values(patchButtonsStatus).some((status) => status === 'loading');

  return (
    <>
      <StyledTableRow hover onClick={() => toggleCollapsed(id)}>
        <StyledCursoredCell align="center" component="th" scope="row">
          {collapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </StyledCursoredCell>
        <StyledCursoredCell align="center" component="th" scope="row">
          <StyledStatusBadge status={status}>{status}</StyledStatusBadge>
        </StyledCursoredCell>
        <StyledCursoredCell align="center">{siteId}</StyledCursoredCell>
        <StyledCursoredCell align="center">{`${complexName}(${complexBranchName})`}</StyledCursoredCell>
        <StyledCursoredCell align="center">{`${name}(${branchName})`}</StyledCursoredCell>
        <StyledCursoredCell align="center">{targetStoreId}</StyledCursoredCell>
        <StyledCursoredCell align="center">{floor}</StyledCursoredCell>
        <StyledCursoredCell align="center">{charge}</StyledCursoredCell>
        <StyledCursoredCell align="center">{moment(createdAt).format('YYYY.MM.DD HH:mm:ss')}</StyledCursoredCell>
        <TableCell align="center">{PLACETODO_CHARGE_RESULT[chargeResult]}</TableCell>
        <TableCell align="center">{reportReason}</TableCell>
      </StyledTableRow>
      {collapsed ? null : (
        <TableRow>
          <TableCell colSpan={11}>
            <StyledTableItemInnerContent>
              {naverMap}
              <StyledUpdateButtonsWrapper>
                <Button
                  disabled={isUpdatedPlaceTodo || isPatching}
                  onClick={handleClickDenyButton}
                  color="secondary"
                  variant="contained"
                >
                  {patchButtonsStatus.deny === 'loading' ? '처리 중' : '거부'}
                </Button>
                {chargeResult === 3 ? null : (
                  <StyledCloseStoreButton
                    disabled={isUpdatedPlaceTodo || isPatching}
                    onClick={handleClickCloseStoreButton}
                    variant="contained"
                  >
                    {patchButtonsStatus.closeStore === 'loading' ? '처리 중' : '폐점'}
                  </StyledCloseStoreButton>
                )}
              </StyledUpdateButtonsWrapper>
            </StyledTableItemInnerContent>
          </TableCell>
        </TableRow>
      )}
      <ConfirmModal
        isOpen={showCloseStoreConfirmModal}
        title="폐점 처리"
        subTitle={`${complexName}(${complexBranchName}) 복합물을 폐점처리 하시겠습니까?`}
        content="확인 버튼 클릭 시 placetodo의 reportStatus가 폐점 처리 중으로 변경됩니다."
        onCancel={handleCancelCloseStore}
        onPermit={handlePermitCloseStore}
      />
    </>
  );
}

const StyledCursoredCell = withStyles({
  root: { cursor: 'pointer' }
})(TableCell);

const StyledTableRow = styled(TableRow)`
  :hover {
    svg {
      color: #000;
    }
  }

  svg {
    color: ${({ collapsed }: { collapsed?: boolean }) => (collapsed ? '#aaa' : '#555')};
  }
`;

const statusBadgeColors: Record<TableItemStatus, string> = {
  미검수: 'gray',
  거부: 'red',
  승인: 'blue',
  '폐점 처리 중': 'orange'
};

const StyledStatusBadge = styled.div<{ status: TableItemStatus }>`
  background-color: ${({ status }) => statusBadgeColors[status]};
  color: white;
  padding: 3px 5px;
  border-radius: 4px;
`;

const StyledNaverMapWrapper = styled.div`
  border-radius: 8px;
  margin-left: 50px;
  height: 400px;
  width: 500px;
  overflow: hidden;
  box-shadow: 0 0 10px #ddd;
  padding: 8px;
`;

const StyledUpdateButtonsWrapper = styled.div`
  display: flex;
  height: 50px;
  margin-top: 20px;

  button {
    margin-left: 30px;
    font-weight: bold;
    font-size: 16px;
    padding: 2px 20px;
  }
`;

const StyledTableItemInnerContent = styled.div`
  display: flex;
`;

const StyledCloseStoreButton = styled(Button)`
  background-color: orange;
  color: white;
`;
