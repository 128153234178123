import { all, call, put, takeLeading, select } from 'redux-saga/effects';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { actions, types } from './state';
import { fetchVersion, createVersion, updateVersion, deleteVersion } from './api.ts';

export function* fetchVersionsSaga() {
  const { page, perPage, params } = yield select((state) => ({
    page: state.version.page,
    perPage: state.version.perPage,
    params: state.version.params
  }));

  yield put(actions.setLoading(true));
  try {
    const data = yield call(fetchVersion, page, perPage, params);
    yield put(actions.setNewVersions(data.result));
    yield put(actions.setValue('count', data.total_count));
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* createVersionSaga(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(createVersion, action.params);
    yield put(actions.fetchVersions());

    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* updateVersionSaga(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(updateVersion, action.params);
    const data = yield call(updateVersion, action.params);
    yield put(actions.setNewVersion(data));

    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* deleteVersionSaga(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(deleteVersion, { versionId: action.versionId });
    yield put(actions.deleteVersion(action.versionId));

    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* sagaReducer() {
  yield all([
    takeLeading(types.FETCH_VERSIONS, fetchVersionsSaga),
    takeLeading(types.CREATE_VERSION, createVersionSaga),
    takeLeading(types.UPDATE_VERSION, updateVersionSaga),
    takeLeading(types.DELETE_VERSION, deleteVersionSaga)
  ]);
}
