import PropTypes from 'prop-types';
import PinTier2 from '@I/map/pin-blue.png';
import NaverMap from '@F/map/NaverMap';
import * as S from './styles';

function Map({ detail, location }) {
  const markers = [
    {
      lat: location.lat,
      lng: location.lng,
      image: PinTier2,
      size: [13 * 2.5, 17 * 2.5],
      name: detail.cid
    }
  ];

  return (
    <S.MapWrapper>
      <NaverMap id={detail.site_id} center={{ lat: detail.lat, lng: detail.lng }} markers={markers} zoom={20} />
    </S.MapWrapper>
  );
}
export default Map;

Map.propTypes = {
  detail: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired
};
