import { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Wrapper from '@F/materialUI/Wrapper';
import CountBox from '@F/CountBox';
import moment from 'moment';
import { DataType } from '@P/cashplace/SiteDashboard/types';
import { cashplaceSitesApi } from '@API/manager/cashplace';
import { useFetch } from '@HOOK/';
import * as S from './styles';

function SummarySection(): JSX.Element {
  const period = {
    startDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    endDate: moment().subtract(1, 'day').format('YYYY-MM-DD')
  };

  const { callApi } = useFetch();
  const [totalData, setTotalData] = useState({
    total_sites: 0,
    complete_sites: 0,
    study_ok_cnt: 0,
    study_abuse_cnt: 0,
    total_studies_not_inspected: 0,
    total_studies: 0,
    partial_complete_sites: 0,
    today_study_cnt: 0
  });
  const [yesterdayData, setYesterdayData] = useState<DataType>();

  const fetchDataByPeriod = async () => {
    const response = await callApi(cashplaceSitesApi.getStatsByPeriod, {
      startDate: period.startDate,
      endDate: period.endDate
    });
    if (response) return response.data.result;
    return null;
  };
  const fetchAllData = async () => {
    const response = await callApi(cashplaceSitesApi.getStatsAll);
    if (response) return response.data;
    return null;
  };

  useEffect(() => {
    fetchAllData().then((data) => setTotalData(data));
    fetchDataByPeriod().then((data) => setYesterdayData(data[0]));
  }, []);
  return (
    <S.StyledSummarySection>
      <Grid container spacing={2}>
        <Grid item xs>
          <Wrapper>
            <CountBox
              title="어제 학습 횟수"
              description=""
              currentData={yesterdayData ? yesterdayData.daily_studies : 0}
              labelText="Yesterday"
              labelBackgroundColor="blue"
            />
          </Wrapper>
        </Grid>
        <Grid item xs>
          <Wrapper>
            <CountBox
              title="오늘 학습 횟수"
              description=""
              currentData={totalData ? totalData.today_study_cnt : 0}
              labelText="Today"
              labelBackgroundColor="red"
            />
          </Wrapper>
        </Grid>
        <Grid item xs>
          <Wrapper>
            <CountBox
              title="완료 Site 수 / 수집 Site 수"
              description="어뷰징 제외 1회 이상/모든 slot이 완료된 site 수"
              currentData={totalData.partial_complete_sites}
              lastData={totalData.partial_complete_sites}
              labelText="Yesterday"
              labelBackgroundColor="blue"
            />
          </Wrapper>
        </Grid>
        <Grid item xs>
          <Wrapper>
            <CountBox
              title="남은 검수 수"
              description=""
              currentData={totalData.total_studies_not_inspected}
              labelText="Now"
              labelBackgroundColor="blue"
            />
          </Wrapper>
        </Grid>
      </Grid>
    </S.StyledSummarySection>
  );
}
export default SummarySection;
