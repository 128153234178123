import moment from 'moment';
import { createReducer, createSetValueAction, setValueReducer } from '@/redux/common/helper-functions';
/** prefix */
const PREFIX = 'FP_MANAGER';

/** initial state */
const INITIAL_STATE = {
  fingerprints: [],
  fingerprintsUpdateStatus: null,
  count: 0,
  openedFingerprintIds: [],
  page: 1,
  perPage: 15,
  params: {
    order: 'desc',
    orderBy: 'fp_fpid',
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  },
  isLoading: false
};

/** type */
export const types = {
  RESET: `${PREFIX}/RESET`,
  SET_VALUE: `${PREFIX}/SET_VALUE`,
  SET_LOADING: `${PREFIX}/SET_LOADING`,
  SET_PAGE: `${PREFIX}/SET_PAGE`,
  FETCH_FINGERPRINTS: `${PREFIX}/FETCH_FINGERPRINTS`,
  FETCH_FINGERPRINTS_CSV: `${PREFIX}/FETCH_FINGERPRINTS_CSV`,
  SET_NEW_FINGERPRINTS: `${PREFIX}/SET_NEW_FINGERPRINTS`,
  SET_NEW_FINGERPRINT: `${PREFIX}/SET_NEW_FINGERPRINT`,
  SET_IMAGE_VALIDATION: `${PREFIX}/SET_IMAGE_VALIDATION`
};

/** action */
export const actions = {
  reset: () => ({ type: types.RESET }),
  setValue: createSetValueAction(types.SET_VALUE),
  setLoading: (isLoading) => ({ type: types.SET_LOADING, isLoading }),
  setPage: (page) => ({ type: types.SET_PAGE, page }),
  fetchFingerprints: () => ({ type: types.FETCH_FINGERPRINTS }),
  fetchFingerprintsCsv: () => ({ type: types.FETCH_FINGERPRINTS_CSV }),
  setNewFingerprints: (fingerprints) => ({ type: types.SET_NEW_FINGERPRINTS, fingerprints }),
  setNewFingerprint: (fingerprint) => ({ type: types.SET_NEW_FINGERPRINT, fingerprint }),
  setImageValidation: (fingerprint, status) => ({ type: types.SET_IMAGE_VALIDATION, fingerprint, status })
};

/** reducer */
const reducer = createReducer(INITIAL_STATE, {
  [types.RESET]: (draft) => {
    draft.fingerprints = [];
    draft.fingerprintsUpdateStatus = 'RESET';
  },
  [types.SET_VALUE]: setValueReducer,
  [types.SET_LOADING]: (draft, action) => {
    draft.isLoading = action.isLoading;
  },
  [types.SET_PAGE]: (draft, action) => {
    draft.page = action.page;
  },
  [types.SET_NEW_FINGERPRINTS]: (draft, action) => {
    draft.fingerprints = action.fingerprints;
    draft.fingerprintsUpdateStatus = 'RESET';
  },
  [types.SET_NEW_FINGERPRINT]: (draft, action) => {
    const index = draft.fingerprints.findIndex((fingerprint) => fingerprint.fp_fpid === action.fingerprint.fp_fpid);
    if (index >= 0) {
      draft.fingerprints[index] = action.fingerprint;
      draft.fingerprintsUpdateStatus = 'UPDATE';
    }
  }
});
export default reducer;
