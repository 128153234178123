import axios, { AxiosError } from 'axios';
import { toastErrorMessage } from '@FUNC/toast';
import jwtDecode from 'jwt-decode';
import { decodedRefreshToken } from '@TS/user';
import { authApi } from '@API/manager';
import { showRefreshTokenModal } from '@HOOK/useAuth';
import { managerLocalStorage } from '@/utils/localStorageParser';
import axiosTokenManager from '../helper/tokenManager';

const onResponseRejected = async (axiosError: AxiosError) => {
  switch (axiosError.response?.status) {
    case 401: {
      const requestURl = axiosError.config.url;

      if (['/auth/login', '/auth/refresh'].some((urlAuthNotRequired) => urlAuthNotRequired === requestURl)) {
        return Promise.reject(axiosError);
      }

      const refreshToken = managerLocalStorage.get<string>(managerLocalStorage.keys.refresh_token);

      if (refreshToken) {
        const $modal = showRefreshTokenModal();

        const { exp } = jwtDecode<decodedRefreshToken>(refreshToken);
        const isRefreshTokenExpired = exp <= new Date().getTime() / 1000;

        if (isRefreshTokenExpired) {
          $modal.remove();
          // 토큰 만료에 의해 로그인 페이지로 이동
          window.location.reload();
        }

        axiosTokenManager.setRefreshToken(refreshToken);

        try {
          const {
            data: { access_token, refresh_token }
          } = await authApi.refresh();
          $modal.remove();

          managerLocalStorage.set(managerLocalStorage.keys.access_token, access_token);
          managerLocalStorage.set(managerLocalStorage.keys.refresh_token, refresh_token);
          axiosTokenManager.setAccessToken(access_token);

          const { config } = axiosError;

          return axios.request({
            ...config,
            headers: {
              ...config.headers,
              Authorization: `Bearer ${access_token}`
            }
          });
        } catch (e) {
          $modal.remove();
        }
      }

      const { config } = axiosError;

      return axios.request(config);
    }

    case 403: {
      toastErrorMessage(`${axiosError.config.url}에 대한 요청 권한이 필요합니다.`);
      break;
    }

    default: {
      return Promise.reject(axiosError);
    }
  }
};

export default onResponseRejected;
