import { useSelector } from 'react-redux';

import { Table, TableBody, TableContainer, Paper } from '@material-ui/core';

import ArticleItem from './articlesTable/ArticleItem';
import Pagination from './articlesTable/Pagination';
import ArticlesTableHead from './articlesTable/ArticlesTableHead';
import TableSkeleton from './articlesTable/TableSkeleton';
import { selectArticles, selectStatus } from './redux/selector';

export default function ArticlesTable(): JSX.Element {
  const articles = useSelector(selectArticles);
  const status = useSelector(selectStatus);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <ArticlesTableHead />
          {status === 'loading' && <TableSkeleton />}
          {status === 'success' && (
            <TableBody>
              {articles?.map((article) => (
                <ArticleItem key={article.id} article={article} />
              ))}
            </TableBody>
          )}
        </Table>
        <Pagination />
      </TableContainer>
    </>
  );
}
