import { RootStore } from '@TS/redux';
import { Recruit, RecruitsParams } from '@TS/homepage/recruit';

export const selectParams = (state: RootStore): RecruitsParams => state.recruits.params;
export const selectRecruits = (
  state: RootStore
): {
  recruits: Recruit[];
  totalCount: number;
  focusedRecruit: Recruit | null;
} => ({
  recruits: state.recruits.data,
  totalCount: state.recruits.totalCount,
  focusedRecruit: state.recruits.focused
});
export const selectStatus = (state: RootStore): ApiStatus => state.recruits.status;
export const selectPagination = (
  state: RootStore
): {
  page: number;
  perPage: number;
  totalCount: number;
} => ({
  page: state.recruits.params.page,
  perPage: state.recruits.params.perPage,
  totalCount: state.recruits.totalCount
});
