import type { FilterStatusKorean, FilterStatus } from '@TS/max/campaigns/status';

export const CAMPAIGN_STATUS: Record<FilterStatusKorean, FilterStatus> = {
  전체: 'all',
  진행: 'proceeding',
  대기: 'inactive',
  종료: 'complete'
};

export const CAMPAIGN_STATUS_KOREAN: Record<FilterStatus, FilterStatusKorean> = (
  Object.entries(CAMPAIGN_STATUS) as Entries<typeof CAMPAIGN_STATUS>
).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {} as Record<FilterStatus, FilterStatusKorean>);
