// NOTE: input 에 name을 할당하여 useInputs 훅을 잘 사용하기 위해서 생성함
// TODO 이후 다른 datePickers도 이 컴포넌트로 교체할 예정
import { CloseIcon } from 'loplat-ui';
import { IconButton } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as S from './styles';
import { serializeProps } from './types';
import type { DatePickersProps, SingleProps } from './types';

export const DatePickers = ({
  onChange,
  error,
  disableds = [false, false],
  mins,
  maxs,
  requireds = [false, false],
  ...props
}: DatePickersProps): JSX.Element => (
  <S.Wrapper>
    <DatePicker
      {...serializeProps(0, props)}
      disabled={disableds[0]}
      onChange={onChange}
      error={error}
      min={mins ? mins[0] : ''}
      max={maxs ? maxs[0] : ''}
      required={requireds[0]}
    />
    <p>~</p>
    <DatePicker
      {...serializeProps(1, props)}
      disabled={disableds[1]}
      onChange={onChange}
      error={error}
      min={mins ? mins[1] : ''}
      max={maxs ? maxs[1] : ''}
      required={requireds[1]}
    />
  </S.Wrapper>
);
export default DatePickers;

export const DatePicker = ({
  helperText,
  hasClearButton,
  onClear,
  min = '',
  max = '',
  required,
  ...props
}: SingleProps): JSX.Element => (
  <S.DateInput
    type="date"
    variant="outlined"
    InputLabelProps={{
      shrink: true
    }}
    helperText={props.error ? helperText : ''}
    InputProps={{
      inputProps: {
        max,
        min,
        required
      },
      ...(hasClearButton &&
        props.value && {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label={`${props.label} 삭제`} onClick={onClear} size="small">
                <CloseIcon size={16} />
              </IconButton>
            </InputAdornment>
          )
        })
    }}
    {...props}
  />
);
