/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useContext, createContext, useCallback } from 'react';

interface TogglersContextType {
  hasOn: () => boolean;
  setOnAll: () => void;
  setOffAll: () => void;
  toggleAll: () => void;
  toggle: (idx: number) => void;
  state: boolean[];
  resetStateWithData: (data: unknown[]) => void;
  setState: React.Dispatch<React.SetStateAction<boolean[]>>;
}

const TogglersContext = createContext<TogglersContextType>({
  hasOn: () => false,
  state: [],
  toggle: () => {},
  toggleAll: () => {},
  setOffAll: () => {},
  setOnAll: () => {},
  setState: () => [],
  resetStateWithData: () => {}
});

export const useTogglers = (): TogglersContextType => useContext<TogglersContextType>(TogglersContext);

export const TogglersContextProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [state, setState] = useState<boolean[]>([]);

  const setOnAll = useCallback(() => setState((prev) => prev.map(() => true)), []);
  const setOffAll = useCallback(() => setState((prev) => prev.map(() => false)), []);
  const toggle = useCallback((idx: number) => setState((prev) => prev.map((_, i) => (idx === i ? !_ : _))), []);
  const hasOn = useCallback(() => state.some(Boolean), [state]);
  const toggleAll = useCallback(() => setState((prev) => prev.map(() => !hasOn())), [hasOn]);
  const resetStateWithData = useCallback((data) => setState(Array(data.length).fill(false)), []);

  return (
    <TogglersContext.Provider
      value={{ setOnAll, setState, setOffAll, toggle, toggleAll, hasOn, state, resetStateWithData }}
    >
      {children}
    </TogglersContext.Provider>
  );
};
