import { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { storesApi } from '@API/manager';
import ModalWrapper from '@F/modal/ModalWrapper';
import Loading from '@F/Loading';
import { Store } from '@TS/store/store';
import { handleErrorWithToast } from '@FUNC/error';
import TextInputForm from '@F/materialUI/TextInputForm';
import * as S from './styles';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onEmitResult: (pids: Store['pid'][], stores: Store[]) => void;
}

function SearchModal({ isOpen, setIsOpen, onEmitResult }: Props): JSX.Element {
  const [pidsString, setPidsString] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const MINIMUM_COUNT = 2;

  const searchAndEmitStores = async () => {
    const pidsWithDuplicate = pidsString.split(',').map(Number);
    const pids = Array.from(new Set(pidsWithDuplicate));

    setIsLoading(true);

    try {
      validatePids(pids);
      const stores = await searchStores(pids);
      onEmitResult(pids, stores);
    } catch (e: unknown) {
      if (e instanceof Error) handleErrorWithToast(e);
    } finally {
      setIsLoading(false);
    }
  };

  const validatePids = (pids: Store['pid'][]) => {
    if (pids.some(Number.isNaN) || pids.length < MINIMUM_COUNT) {
      throw new Error(`'${MINIMUM_COUNT}개' 이상의 '숫자'를 입력해주세요.`);
    }
  };

  const searchStores = async (pids: Store['pid'][]) => {
    const stores = (await storesApi.getStoreData({ pids })).data.items;
    if (stores.length < MINIMUM_COUNT) {
      throw new Error(`${MINIMUM_COUNT}개 이상의 매장을 찾지 못했습니다.`);
    }
    return stores;
  };

  useEffect(() => {
    setPidsString('');
  }, [isOpen]);

  return (
    <ModalWrapper
      open={isOpen}
      setOpen={setIsOpen}
      title="병합할 매장 검색"
      withCancelButton={false}
      confirmText="닫기"
      confirm={() => setIsOpen(false)}
    >
      <S.SearchForm
        onSubmit={(e) => {
          e.preventDefault();
          searchAndEmitStores();
        }}
      >
        <TextInputForm
          label="pid 검색"
          name="pid"
          placeholder="PID 입력 - 콤마(,) 로 구분해주세요."
          onChange={(e) => setPidsString(e.target.value)}
          value={pidsString}
          variant="outlined"
        />
        <Button type="submit">검색</Button>
        {isLoading && <Loading />}
      </S.SearchForm>
    </ModalWrapper>
  );
}
export default SearchModal;
