import TextInputForm from '@F/materialUI/TextInputForm';
import { Button, Grid } from '@material-ui/core';
import SelectForm from '@F/materialUI/SelectForm';
import { priorityOptions } from '@P/fingerprint/manualMapping/Filter/options';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '@F/Loading';
import { useInput } from '@HOOK/';
import useAuth from '@HOOK/useAuth';
import { actions } from '@/redux/fingerprint/manualMapping/state';
import * as S from './styles';

function Filter() {
  const classes = S.useStyles();
  const { user, isAuthoritative } = useAuth();

  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.manualMapping);

  const StateInput = <TextInputForm name="State" label="상태" value="0" disabled />;
  const [charge, setCharge] = useInput(user?.identity);
  const ChargeInput = (
    <TextInputForm name="Charge" label="할당자" onChange={setCharge} value={charge} disabled={!isAuthoritative} />
  );

  const [priority, setPriority] = useInput('1순위');
  const PrioritySelect = (
    <SelectForm
      label="우선순위"
      options={Object.keys(priorityOptions)}
      value={priority}
      onSelect={setPriority}
      name="PrioritySelect"
    />
  );

  const fetchFingerprints = () => {
    dispatch(
      actions.setValue('params', {
        state: 0,
        charge: charge || null,
        priority: priorityOptions[priority]
      })
    );
    dispatch(actions.setPage(1));
    dispatch(actions.fetchFingerprints());
  };
  return (
    <S.StyledFilter
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          fetchFingerprints();
          dispatch(actions.setValue('charge', charge));
        }
      }}
    >
      {isLoading && <Loading />}
      <Grid container spacing={2} className={classes.wrapper}>
        <Grid item xs={4}>
          {StateInput}
        </Grid>
        <Grid item xs={4}>
          {ChargeInput}
        </Grid>
        <Grid item xs={4}>
          {PrioritySelect}
        </Grid>
      </Grid>
      <S.ButtonRow>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            fetchFingerprints();
            dispatch(actions.setValue('charge', charge));
          }}
        >
          불러오기
        </Button>
      </S.ButtonRow>
    </S.StyledFilter>
  );
}
export default Filter;

Filter.propTypes = {};
