import { useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createHeadCells } from '@F/table/Table/functions';
import EnhancedTable from '@F/table/Table';
import moment from 'moment';
import Detail from '../Detail';
import { actions } from '@/redux/admin/whitelist/state';
import * as S from './styles';

function TableSection() {
  const { count, whitelists } = useSelector(
    (state) => ({
      count: state.whitelist.count,
      whitelists: state.whitelist.whitelists
    }),
    shallowEqual
  );

  const headCells = useMemo(() => columns.map((column) => createHeadCells(column.name, column.label)), []);
  const rows = useMemo(
    () =>
      whitelists.map((whitelist) => ({
        id: whitelist.whitelist_id,
        ip: whitelist.ip_address,
        memo: whitelist.comment,
        status: whitelist.status ? '활성' : '비활성',
        creator: whitelist.creator,
        updatedAt: whitelist.updated_at && moment(whitelist.updated_at).format('YYYY-MM-DD HH:mm:ss'),
        createdAt: moment(whitelist.created_at).format('YYYY-MM-DD HH:mm:ss')
      })),
    [whitelists]
  );

  // expand
  const dispatch = useDispatch();
  const openedWhitelistIds = useSelector((state) => state.whitelist.openedWhitelistIds);
  const setOpenedWhitelistIds = useCallback(
    (newOpenedWhitelistIds) => {
      dispatch(actions.setValue('openedWhitelistIds', newOpenedWhitelistIds));
    },
    [dispatch]
  );

  return (
    <S.StyledTableSection>
      <EnhancedTable
        headCells={headCells}
        rows={rows}
        isCheckable={false}
        isCollapsible
        opened={openedWhitelistIds}
        setOpened={setOpenedWhitelistIds}
      >
        <Detail data={whitelists} />
      </EnhancedTable>
      <S.TableCountRow>{`Displaying  ${count} items`}</S.TableCountRow>
    </S.StyledTableSection>
  );
}
export default TableSection;

TableSection.propTypes = {};

const columns = [
  { name: 'id', label: 'WHITELIST ID', isImportant: true },
  { name: 'ip', label: 'IP ADDRESS' },
  { name: 'memo', label: '메모' },
  { name: 'status', label: '상태' },
  { name: 'creator', label: '생성자' },
  { name: 'updatedAt', label: '수정일시' },
  { name: 'createdAt', label: '생성일시' }
];
