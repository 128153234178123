import type { AxiosResponse } from 'axios';

import { axiosInstancesManager } from '@API/axios';
import { GetCompaniesResponse } from '@TS/max/company/api';

class MaxCompaniesApi {
  getCompanies(params?: { type: number }): Promise<AxiosResponse<GetCompaniesResponse>> {
    return axiosInstancesManager.max.get('/companies', { params });
  }
}

export default new MaxCompaniesApi();
