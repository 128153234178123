import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button, Switch, colors } from '@material-ui/core';
import ReactLoading from 'react-loading';
import { RecruitStatus } from '@TS/homepage/recruit';
import ConfirmModal from '@F/modal/ConfirmModal';

import { selectRecruits } from '../redux/selector';
import { actions } from '../redux/slice';

export default function Toolbar(): JSX.Element | null {
  const dispatch = useDispatch();
  const { focusedRecruit } = useSelector(selectRecruits);
  const navigate = useNavigate();

  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);
  const [isUpdatingPublishStatus, setIsUpdatingPublishStatus] = useState(false);
  const [isDeletingRecruit, setIsDeletingRecruit] = useState(false);

  const openConfirmDeleteRecruit = () => setIsOpenConfirmDelete(true);
  const closeConfirmDeleteRecruit = () => setIsOpenConfirmDelete(false);
  const toEditPage = () => navigate('/homepage/manage-recruits/edit', { replace: true });
  const toRecruitsPage = () => navigate('/homepage/manage-recruits', { replace: true });

  const togglePublishStatus = (status: RecruitStatus) => (status === 'PENDING' ? 'PUBLISH' : 'PENDING');

  const handleClickToRecruits = () => {
    dispatch(actions.setFocused(null));
    toRecruitsPage();
  };

  const handlePermitDeleteRecruit = () => {
    if (!focusedRecruit) return;

    setIsDeletingRecruit(true);

    dispatch(
      actions.deleteRecruit({
        id: focusedRecruit.id,
        callback: () => {
          setIsDeletingRecruit(false);
          toRecruitsPage();
        }
      })
    );
  };

  const handleTogglePublishSwitch = () => {
    if (!focusedRecruit) return;

    setIsUpdatingPublishStatus(true);

    dispatch(
      actions.updateRecruit({
        id: focusedRecruit.id,
        data: {
          status: togglePublishStatus(focusedRecruit.status)
        },
        callback: () => setIsUpdatingPublishStatus(false)
      })
    );
  };

  if (!focusedRecruit) return null;

  return (
    <StyledToolbar>
      <Button variant="outlined" onClick={handleClickToRecruits}>
        목록으로
      </Button>
      <Button variant="outlined" onClick={toEditPage}>
        내용 수정
      </Button>
      <div className="publish-wrapper">
        <span>
          {isUpdatingPublishStatus ? (
            <ReactLoading type="cylon" color={colors.indigo[600]} height={20} width={20} />
          ) : (
            '공고'
          )}
        </span>
        <Switch
          color="primary"
          onClick={handleTogglePublishSwitch}
          disabled={isUpdatingPublishStatus}
          checked={focusedRecruit.status === 'PUBLISH'}
        />
      </div>
      <Button variant="outlined" color="secondary" onClick={openConfirmDeleteRecruit} disabled={isDeletingRecruit}>
        {isDeletingRecruit ? <ReactLoading type="cylon" color={colors.red[600]} height={20} width={20} /> : '삭제'}
      </Button>
      <ConfirmModal
        isOpen={isOpenConfirmDelete}
        title="삭제"
        subTitle="해당 채용 공고를 삭제하시겠습니까?"
        content="삭제된 채용 공고 조회는 백엔드 관리자에게 문의바랍니다."
        onPermit={handlePermitDeleteRecruit}
        onCancel={closeConfirmDeleteRecruit}
      />
    </StyledToolbar>
  );
}

const StyledToolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 30px;

  > * {
    margin-left: 10px !important;
    font-weight: bold;
  }
  .publish-wrapper {
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    padding-left: 15px;
    height: 36px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
  }
`;
