import { useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Collapse } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import { grayscale900, Headline } from 'loplat-ui';
import { Container } from './section.styles';

type Props = {
  title: string;
  children: React.ReactNode;
};
const Section = ({ title, children }: Props): JSX.Element => {
  const location = useLocation();
  const path = location.pathname.split('/').pop();

  const [expanded, setExpanded] = useState(true);

  const toggleExpand = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  return (
    <Container expanded={expanded}>
      <Headline tag="h3" color={grayscale900} weight="bold" my={0} mx={0} size="lg">
        {title}
        {path === 'compare' && (
          <IconButton onClick={toggleExpand} aria-expanded={expanded} aria-label="접고 펴기">
            <ExpandMoreIcon />
          </IconButton>
        )}
      </Headline>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Container>
  );
};

export default Section;
