import { TableHead, TableRow, TableCell, Checkbox, TableSortLabel, IconButton } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PropTypes from 'prop-types';
import * as S from './styles';

export default function EnhancedTableHead(props) {
  const {
    onClickAllCheck,
    disableAllCheck,
    onClickAllOpen,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    isCheckable,
    isCollapsible,
    isOpenAll,
    isSingleSelection
  } = props;
  const classes = S.useTableHeadStyles();

  return (
    <TableHead>
      <TableRow height={64}>
        {isCollapsible && (
          <TableCell width={64}>
            <IconButton size="small" onClick={onClickAllOpen}>
              {isOpenAll ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {isCheckable && !isSingleSelection && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onClickAllCheck}
              disabled={disableAllCheck}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            // padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.isSortable === false ? (
              <>{headCell.label}</>
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => onRequestSort(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id && (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                )}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onClickAllCheck: PropTypes.func,
  disableAllCheck: PropTypes.bool,
  onClickAllOpen: PropTypes.func,
  order: PropTypes.oneOf(['desc', 'asc']),
  orderBy: PropTypes.string,
  numSelected: PropTypes.number,
  rowCount: PropTypes.number,
  onRequestSort: PropTypes.func,
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  isCheckable: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  isOpenAll: PropTypes.bool,
  isSingleSelection: PropTypes.bool
};

EnhancedTableHead.defaultProps = {
  onClickAllCheck: () => {},
  disableAllCheck: false,
  onClickAllOpen: () => {},
  onRequestSort: () => {},
  order: 'desc',
  orderBy: null,
  numSelected: 0,
  rowCount: 0,
  isCheckable: true,
  isCollapsible: false,
  isOpenAll: false,
  isSingleSelection: false
};
