import styled from 'styled-components';

export const StyledCountBox = styled.div`
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div<{ backgroundColor: string }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div:first-of-type {
    font-weight: bold;
  }
  div:last-of-type {
    background-color: ${(props): string => props.backgroundColor};
    color: white;
    padding: 2px 10px;
    border-radius: 5px;
    font-size: 0.8rem;
  }
`;

export const Content = styled.div`
  margin-top: 1.5rem;

  div:first-of-type {
    font-size: 1.5rem;
    span:last-of-type {
      font-size: 1rem;
    }
  }
  div:last-of-type {
    font-size: 0.8rem;
  }
`;
