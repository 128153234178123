import Wrapper from '@F/materialUI/Wrapper';
import SendMessage from './SendMessage';
import * as S from './styles';

function Message(): JSX.Element {
  return (
    <Wrapper>
      <S.StyledMessage>
        <S.Title>메시지 보내기</S.Title>
        <SendMessage />
      </S.StyledMessage>
    </Wrapper>
  );
}
export default Message;
