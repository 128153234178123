import { axiosInstancesManager } from '@API/axios';
/* eslint class-methods-use-this: 0 */

class CashplaceSitesStatsApi {
  getStatsAll() {
    return axiosInstancesManager.manager.get('/cashplace/sites/stats/all');
  }

  getStatsByPeriod({ startDate, endDate }: { startDate: string; endDate: string }) {
    return axiosInstancesManager.manager.get('/cashplace/sites/stats/period', {
      params: {
        start_date: startDate,
        end_date: endDate
      }
    });
  }
}
export default new CashplaceSitesStatsApi();
