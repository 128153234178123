import { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@P/poi/SiteMapManager/SelectForm';
import { Button, Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { toJS } from 'mobx';
import axios from 'axios';
import ComplexInput from '@F/input/ComplexInput';
import DatePickers from '@F/materialUI/datePicker/DatePickers';
import { cookSitesApi } from '@API/manager/cook';
import { toastErrorMessage } from '@FUNC/toast';
import { useInput } from '@HOOK/';
import { valuesStore } from '@/mobX/values';
import * as S from './styles';
import { districts, provinces } from '@/static/data/areas';

const PIN_TIERS = [1, 2, 3, 4];
const INITIAL_PIN_TIERS_STATE = PIN_TIERS.map((p) => ({ name: String(p), checked: true }));

let abortController = new AbortController();
function Filter({ onClickLoad, isLoading: isLoadingApi }) {
  const classes = S.useStyles();
  // State:
  const [charge, setCharge] = useState('');
  const [perPage, setPerPage] = useInput('15');
  const [completionState, setCompletionState] = useInput('미완료');
  const [siteId, setSiteId] = useInput('');
  const [complex, setComplex] = useState(null);
  const [floor, setFloor] = useInput('');
  const [period, setPeriod] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });

  const completionStateOption = useMemo(
    () => [
      '전체',
      '미완료',
      '미방문',
      '폐점',
      'StoreManager 중복 매장 존재',
      '환경에 의한 수집 실패',
      '수집 완료',
      '삭제 (미완료일때)',
      '삭제 (미방문일때)',
      'Store에 존재했던 nid'
    ],
    []
  );
  const magicNumberForCompletionState = useMemo(() => {
    const magicNumber = completionStateOption.indexOf(completionState) - 1;
    return magicNumber >= 0 ? magicNumber : null;
  }, [completionStateOption, completionState]);

  // methods:
  const confirmPeriod = useCallback((dates) => {
    setPeriod({
      startDate: dates[0],
      endDate: dates[1]
    });
  }, []);

  const load = async () => {
    if (addr2 === '') {
      toastErrorMessage('시/군을 입력해주세요.');
      return;
    }
    if (pinTierChecks.filter((check) => check.checked).length === 0) {
      toastErrorMessage('pintier 체크박스를 선택해주세요.');
      return;
    }

    onClickLoad({
      pin_tier: pinTierChecks.filter((p) => p.checked).map((p) => p.name),
      addr_lv1: addr1,
      addr_lv2: addr2,
      addr_lv3: addr3 === '전체' ? null : addr3,
      perPage,
      period,
      charge: charge.length ? charge : null,
      completionState: magicNumberForCompletionState,
      siteId: siteId.length ? parseInt(siteId, 10) : null,
      floor: floor.length ? Number(floor) : null,
      complexId: complex ? complex.id : null,
      addr1
    });
  };

  const collectorOptions = useMemo(() => toJS(valuesStore.collectors).map((user) => user.username), []);
  const ChargeInput = (
    <Autocomplete
      id="ChargeInput"
      onInputChange={(e, newValue) => setCharge(newValue)}
      options={collectorOptions}
      clearOnBlur={false}
      renderInput={(params) => <TextField {...params} label="수집자" variant="outlined" />}
    />
  );

  const CountSelect = (
    <SelectForm
      label="개수"
      options={['15', '30', '50', '100', '200', '500']}
      value={perPage}
      onSelect={setPerPage}
      id="CountSelect"
    />
  );

  const IsCompletedSelect = (
    <SelectForm
      label="완료/미완료"
      options={completionStateOption}
      value={completionState}
      onSelect={setCompletionState}
      id="CompletedSelect"
    />
  );

  const PeriodInput = <DatePickers onEmitDate={confirmPeriod} />;

  const SiteIDInput = <TextInputForm label="Site ID" name="siteIDInput" value={siteId} onChange={setSiteId} />;
  const complexInput = <ComplexInput onChange={setComplex} />;

  const floorInput = <TextInputForm label="floor" name="floorInput" value={floor} onChange={setFloor} />;

  const [isLoading, setIsLoading] = useState(false);
  const [addr1, setAddr1] = useState('서울');
  const [addr2, setAddr2] = useState('');
  const [addr3, setAddr3] = useState('전체');

  const [stringifiedAddr3, setStringifiedAddr3] = useState('');
  const [addr3Options, setAddr3Options] = useState([]);
  const stringifiedAddr3Options = useMemo(
    () =>
      addr3Options.map(
        (option) => `${option.addr3}: ${option.sites} (${option.complex ?? option.cids}) (${option.pin_tier_1})`
      ),
    [addr3Options]
  );

  const setValueOfTAddr3 = useCallback((event) => {
    const { value } = event.target;
    setAddr3(value.split(':')[0]);
    setStringifiedAddr3(value);
  }, []);

  const getAddr3Options = useCallback(
    async (abortController) => {
      try {
        setIsLoading(true);

        const response = await cookSitesApi.getAddr3List({ addr1, addr2, signal: abortController.signal });
        const { data } = response;
        setAddr3Options([{ addr3: '전체', ...data.counts }, ...data.items]);
        setIsLoading(false);
      } catch (e) {
        if (axios.isCancel(e)) {
          console.error('canceled');
        } else {
          setIsLoading(false);
        }
      }
    },
    [addr1, addr2]
  );

  useEffect(() => {
    if (addr1) {
      abortController.abort();

      setAddr2('');
      setAddr3('전체');
    }
  }, [addr1]);

  // addr2 선택시
  useEffect(() => {
    if (addr2 !== '') {
      abortController.abort();

      abortController = new AbortController();
      getAddr3Options(abortController);
    }
  }, [addr2, getAddr3Options]);

  const [pinTierChecks, setPinTierChecks] = useState(INITIAL_PIN_TIERS_STATE);
  const isAllChecked = (checks) => checks.filter((d) => d.checked).length === checks.length;
  const handleCheckPinTier = ({ target: { name, checked } }) => {
    setPinTierChecks((prev) => {
      const next = prev.map((pinTier) => (pinTier.name === name ? { name, checked } : pinTier));
      return next;
    });
  };
  const handleCheckAllPinTiers = (e) => {
    setPinTierChecks((prev) =>
      isAllChecked(prev) ? prev.map((p) => ({ ...p, checked: false })) : prev.map((p) => ({ ...p, checked: true }))
    );
  };

  const pinterCheckboxGroup = (
    <>
      {pinTierChecks.map(({ name, checked }) => (
        <S.Check key={name}>
          <div className="name">
            <span>{name}</span>
          </div>
          <FormControlLabel
            className="control"
            key={name}
            control={<Checkbox checked={checked} onChange={handleCheckPinTier} name={name} />}
          />
        </S.Check>
      ))}
      <S.Check>
        <div className="name">
          <span>전체</span>
        </div>
        <FormControlLabel
          className="control"
          control={<Checkbox checked={isAllChecked(pinTierChecks)} onChange={handleCheckAllPinTiers} name="전체" />}
        />
      </S.Check>
    </>
  );

  return (
    <S.FilterForm
      onSubmit={(event) => {
        event.preventDefault();
        load(event);
      }}
    >
      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs={12} sm={3}>
          {SiteIDInput}
        </Grid>
        <Grid item xs={12} sm={3}>
          {complexInput}
        </Grid>
        <Grid item xs={12} sm={3}>
          {floorInput}
        </Grid>
        <Grid item xs={12} sm={3}>
          {ChargeInput}
        </Grid>
        <Grid item xs={12} sm={3}>
          {IsCompletedSelect}
        </Grid>
        <Grid item xs={12} sm={3}>
          {CountSelect}
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectForm
            id="광역시/도"
            label="광역시/도"
            options={provinces}
            value={addr1}
            onSelect={(e) => setAddr1(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectForm
            id="시/군"
            label="시/군"
            options={districts[addr1]}
            value={addr2}
            disabled={addr1 === ''}
            onSelect={(e) => setAddr2(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectForm
            id="행정구역"
            label={`행정구역${isLoading ? '(로딩 중...)' : ''}`}
            options={stringifiedAddr3Options}
            value={stringifiedAddr3}
            disabled={addr2 === '' || isLoading}
            onSelect={setValueOfTAddr3}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Grid container alignItems="center">
            <span style={{ margin: '10px' }}>pin tier</span>
            {pinterCheckboxGroup}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          {PeriodInput}
        </Grid>
      </S.Row>
      <S.ButtonRow>
        <Button variant="contained" color="primary" type="submit" disabled={isLoadingApi}>
          불러오기
        </Button>
      </S.ButtonRow>
    </S.FilterForm>
  );
}

export default Filter;

Filter.propTypes = {
  onClickLoad: PropTypes.func
};

Filter.defaultProps = {
  onClickLoad: () => {}
};
