import styled from 'styled-components';
import { primary } from 'loplat-ui';
import type { TableRowType } from '@TS/max/table/campaignTable';
import { ReactComponent as ADIconT } from '@ICON/campaign/ad_campaign.svg';
import { ReactComponent as TestIcon } from '@ICON/campaign/test_campaign.svg';

type Props = TableRowType['name'];
const CampaignName = ({ label, state }: Props): JSX.Element => (
  <NameWrapper>
    <span>{label}</span>
    {state === 'test' && (
      <span className="caption">
        <TestIcon width={14} height={14} />
        테스트
      </span>
    )}
    {state === 'commercial' && (
      <>
        <ADIconT width={46} height={16} />
      </>
    )}
  </NameWrapper>
);

export default CampaignName;

const NameWrapper = styled.div`
  word-break: break-word;

  span.caption {
    vertical-align: top;
    font-size: 0.75rem;
    color: ${primary};
  }

  svg {
    margin: 0 2px 0 4px;
  }

  img {
    margin: 0 2px 0 4px;
    vertical-align: inherit;
  }
`;
