import { axiosInstancesManager } from '@API/axios';
import type { AxiosResponse } from 'axios';
import type { LoginParams, AuthResponse } from '@TS/auth';

class AuthApi {
  login({ username, password }: LoginParams): Promise<AxiosResponse<AuthResponse>> {
    return axiosInstancesManager.manager.post('/auth/login', { username, password });
  }

  refresh(): Promise<AxiosResponse<AuthResponse>> {
    return axiosInstancesManager.manager.post('/auth/refresh');
  }
}

export default new AuthApi();
