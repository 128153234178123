import { SetStateAction, useEffect, useMemo, useState } from 'react';
import CSVReader, { IFileInfo } from 'react-csv-reader';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import { BulkCreditForm } from '@TS/cashplace/users';
import useInput from '@HOOK/useInput';
import { categories } from '../Detail/options';
import * as S from './styles';

type BulkModalProps = {
  onConfirm: React.Dispatch<SetStateAction<BulkCreditForm | undefined>>;
  failed: number[] | null;
};

function BulkModal({ onConfirm, failed }: BulkModalProps): JSX.Element {
  const [file, setFile] = useState<[number, number][]>();
  const [isError, setIsError] = useState(false);
  const isCompleted = useMemo(() => failed !== null, [failed]);
  useEffect(() => {
    if (!file) return;
    if (file.some((item) => item.length > 2)) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [file]);
  const [, setFileMetadata] = useState<IFileInfo>();
  const papaparseOptions = {
    header: false,
    dynamicTyping: true,
    skipEmptyLines: true
  };
  const rows = useMemo(
    () =>
      file?.map((item, index) => ({
        id: index,
        userId: `${item[0]}`,
        credit: `${item[1]}`,
        result: !failed?.includes(item[0])
      })),
    [file, failed]
  );
  const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
  const sum = useMemo(() => {
    if (!file) return 0;
    if (isCompleted) {
      return file
        .filter((item) => !failed?.includes(item[0]))
        .map((item) => item[1])
        .reduce(reducer);
    }
    return file.map((item) => item[1]).reduce(reducer);
  }, [file, isCompleted]);

  const [title, setTitle] = useInput('');
  const TitleInput = (
    <TextInputForm name="TitleInput" label="제목" value={title} onChange={setTitle} readOnly={isCompleted} />
  );

  const [description, setDescription] = useInput('');
  const DescriptionInput = (
    <TextInputForm
      name="DescriptionInput"
      label="내용"
      value={description}
      onChange={setDescription}
      readOnly={isCompleted}
    />
  );

  const [category, setCategory] = useState<string>('이벤트');
  const CategorySelect = (
    <SelectForm
      label="종류"
      options={Object.keys(categories)}
      value={category}
      onSelect={(e): void =>
        setCategory((prevState) => {
          if (typeof e.target.value === 'string') return e.target.value;
          return prevState;
        })
      }
      name="CategorySelect"
      disabled={isCompleted}
    />
  );

  useEffect(() => {
    onConfirm({
      title,
      message: description,
      userCreditList: file?.map((item) => ({ id: item[0], credit: item[1] })),
      kind: categories[category]
    });
  }, [title, description, file, category]);

  const label = useMemo(() => {
    if (!file) return 'CSV 파일을 업로드하세요.';
    if (isError) return '파일 형식이 올바르지 않습니다.';
    return '성공적으로 업로드 되었습니다.';
  }, [file, isError]);

  return (
    <S.StyledBulkModal>
      {isCompleted ? (
        // 요청 이후
        <>
          <h3>Bulk Credit 결과</h3>
          <p>
            지급된 총 인원:
            {file && failed !== null ? file.length - failed.length : 0}명
          </p>
          <p>
            지급된 총 금액:
            {sum.toLocaleString()}원
          </p>
          <S.Row>{TitleInput}</S.Row>
          <S.Row>{DescriptionInput}</S.Row>
          <S.Row>{CategorySelect}</S.Row>
          <S.Row style={{ height: '300px', width: '100%', margin: '10px 0px' }}>
            <List dense style={{ maxHeight: '100%', overflow: 'auto' }}>
              {rows &&
                rows.map((row) => (
                  <ListItem key={`${row.id}-${row.userId}-${row.credit}`}>
                    <ListItemIcon>
                      {row.result ? <CheckCircleIcon color="primary" /> : <SmsFailedIcon color="error" />}
                    </ListItemIcon>
                    <ListItemText primary={row.userId} secondary="유저 아이디" />
                    <ListItemText primary={row.credit} secondary="리워드" />
                  </ListItem>
                ))}
            </List>
          </S.Row>
        </>
      ) : (
        // 업로드 전
        <>
          <h3>Bulk Credit 추가</h3>
          <p>
            총 인원:
            {file ? file.length : 0}명
          </p>
          <p>
            총 금액:
            {sum.toLocaleString()}원
          </p>
          <S.Row>{TitleInput}</S.Row>
          <S.Row>{DescriptionInput}</S.Row>
          <S.Row>{CategorySelect}</S.Row>
          <S.Row>
            <S.CsvLabel isError={isError}>{label}</S.CsvLabel>
            <CSVReader
              cssClass="csv-reader-input"
              onFileLoaded={(data: [number, number][], fileInfo: IFileInfo) => {
                setFile(data);
                setFileMetadata(fileInfo);
              }}
              parserOptions={papaparseOptions}
              inputId="csv"
              inputName="csv"
              inputStyle={{ color: 'red' }}
            />
          </S.Row>
          <S.Row style={{ height: '300px', width: '100%', margin: '10px 0px' }}>
            <List dense style={{ maxHeight: '100%', overflow: 'auto' }}>
              {rows &&
                rows.map((row) => (
                  <ListItem key={`${row.id}-${row.userId}-${row.credit}`}>
                    <ListItemText primary={row.userId} secondary="유저 아이디" />
                    <ListItemText primary={row.credit} secondary="리워드" />
                  </ListItem>
                ))}
            </List>
          </S.Row>
        </>
      )}
    </S.StyledBulkModal>
  );
}
export default BulkModal;
