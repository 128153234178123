/* eslint-disable react/no-danger */
import styled from '@emotion/styled';
import Badge from '@F/Badge';
import dayjs from 'dayjs';

import { Notice } from '@TS/max/manageNotice';
import { NOTICE_STATUS_COLOR, NOTICE_STATUS_TEXT, NOTICE_TYPE_COLOR, NOTICE_TYPE_TEXT } from '../constant';

export default function NoticeContentViewer({ notice }: { notice: Notice }): JSX.Element {
  const { created_at, published_at, content, title, type, status } = notice;

  return (
    <StyledNoticeContentViewer>
      <div className="badges">
        <Badge text={NOTICE_TYPE_TEXT[type]} color={NOTICE_TYPE_COLOR[type]} />
        <Badge
          text={`${NOTICE_STATUS_TEXT[status]}${status === 'PENDING' ? '' : '됨'}`}
          color={NOTICE_STATUS_COLOR[status]}
        />
      </div>
      <div className="title-date">
        <h1>{title}</h1>
        <div className="date">
          <span>생성일 : {dayjs(created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
          {published_at ? <span>발행일 : {dayjs(published_at).format('YYYY-MM-DD HH:mm:ss')}</span> : null}
        </div>
      </div>
      <span dangerouslySetInnerHTML={{ __html: content || '' }} />
    </StyledNoticeContentViewer>
  );
}

const StyledNoticeContentViewer = styled.div`
  background-color: white;
  padding: 24px;
  box-shadow: 0 0 10px #eee;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  .badges {
    justify-content: flex-end;
    display: flex;
    *:not(:first-of-type) {
      margin-left: 10px;
    }
    margin-bottom: 10px;
  }

  .title-date {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font-size: 20px;
    }

    .date {
      *:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }

  img {
    max-width: 100%;
  }
`;
