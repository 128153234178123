import { useState, useCallback, ChangeEvent } from 'react';

type onChangeType = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
export type Return<T> = {
  value: T;
  onChange: onChangeType;
  clearByKey: (key: keyof T) => void;
  setValue: React.Dispatch<React.SetStateAction<T>>;
  manuallySetValue: (key: keyof T, value: T[keyof T]) => void;
};

const useInputs = <T>(initialValue: T): Return<T> => {
  const [value, setValue] = useState<T>(initialValue);

  const onChange = useCallback((e) => {
    const { name, value: currentValue } = e.target;
    setValue((prev) => ({ ...prev, [name]: currentValue }));
  }, []);

  const clearByKey = useCallback((key: keyof T) => {
    setValue((prev) => ({ ...prev, [key]: '' }));
  }, []);

  const manuallySetValue = useCallback((key: keyof T, value: T[keyof T]) => {
    setValue((prev) => ({ ...prev, [key]: value }));
  }, []);

  return { value, onChange, clearByKey, setValue, manuallySetValue };
};

export default useInputs;
