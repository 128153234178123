import styled from 'styled-components';

export const StyledNaverMap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`;

export const NaverMap = styled.div`
  width: 100%;
  height: 100%;
`;
