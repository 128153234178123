import Wrapper from './filter/Wrapper';
import Filter from './filter/Filter';
import TableSection from './tableSection/TableSection';
import * as S from './styles';

function Store() {
  return (
    <S.StyledStore>
      <Wrapper>
        <Filter />
      </Wrapper>
      <br />
      <Wrapper>
        <TableSection />
      </Wrapper>
    </S.StyledStore>
  );
}
export default Store;
