import { Body, primary } from 'loplat-ui';
import type { RegionCondition } from '@P/max/campaigns/utils/type';
import { SectionRow } from '../section';
import GeofenceCondition from './GeofenceCondition';
import ExtraConditions from './ExtraConditions';
import DistrictCondition from './DistrictCondition';
import { RegionSection, OptionIndex, RegionDetailWrapper, Separator } from './styles';

type Props = {
  region: RegionCondition;
  idx: number;
};

const RegionModule = ({ region, idx }: Props): JSX.Element => {
  const { tab, geofence, condition } = region;
  const { lv1, lv2, ...others } = condition;
  const haveGeofenceCondition = [geofence.group, geofence.single].some((elem) => !!elem.length);

  return (
    <RegionSection>
      <OptionIndex>설정 {idx}</OptionIndex>

      <SectionRow subTitle="지역 설정">
        <Body tag="span" weight="extraBold" color={primary}>
          {tab}
        </Body>
      </SectionRow>

      <RegionDetailWrapper>
        {haveGeofenceCondition && <GeofenceCondition geofence={geofence} />}

        {haveGeofenceCondition && lv1 !== '' && (
          <Separator className="or">
            <span>OR</span>
          </Separator>
        )}

        <DistrictCondition lv1={lv1} lv2={lv2} />
        <ExtraConditions {...others} />
      </RegionDetailWrapper>
    </RegionSection>
  );
};

export default RegionModule;
