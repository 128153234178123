import styled from 'styled-components';

export const StyledSiteMap = styled.div`
  height: calc(100vh - 200px);
`;

export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 10px;
`;

export const FlexItem = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px;
`;
