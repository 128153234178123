import { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import * as S from './styles';

const initialState = {
  targetNamePid: null,
  targetBranchNamePid: null,
  targetCategoryPid: null,
  targetLocationPid: null,
  targetSitePid: null
};

function reducer(state, action) {
  return { ...state, [action.key]: action.pid };
}

function CombineTable({ stores, onEmitTargetPids, isMasterPidChangeable, masterPid, setMasterPid }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    onEmitTargetPids(state);
  }, [state, onEmitTargetPids]);

  return (
    <S.StyledCombineTable>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <S.TableCell>PID</S.TableCell>
              <S.TableCell>장소명</S.TableCell>
              <S.TableCell>지점명</S.TableCell>
              <S.TableCell>카테고리</S.TableCell>
              <S.TableCell>주소</S.TableCell>
              <S.TableCell>SITE ID</S.TableCell>
              <S.TableCell>Naver 확인하기</S.TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stores.map((store) => (
              <TableRow key={store.pid}>
                <S.TableCell
                  onClick={() => isMasterPidChangeable && setMasterPid(store.pid)}
                  selected={masterPid === store.pid ? 1 : 0}
                >
                  {store.pid}
                </S.TableCell>
                <S.TableCell
                  onClick={() => dispatch({ key: 'targetNamePid', pid: store.pid })}
                  isqa={store.qa_name !== 0 ? 1 : 0}
                  selected={state.targetNamePid === store.pid ? 1 : 0}
                >
                  {store.name}
                </S.TableCell>
                <S.TableCell
                  onClick={() => dispatch({ key: 'targetBranchNamePid', pid: store.pid })}
                  isqa={store.qa_subname !== 0 ? 1 : 0}
                  selected={state.targetBranchNamePid === store.pid ? 1 : 0}
                >
                  {store.branch_name}
                </S.TableCell>
                <S.TableCell
                  onClick={() => dispatch({ key: 'targetCategoryPid', pid: store.pid })}
                  isqa={store.qa_category !== 0 ? 1 : 0}
                  selected={state.targetCategoryPid === store.pid ? 1 : 0}
                >
                  {store.category}
                  <br />({store.category_code})
                </S.TableCell>
                <S.TableCell
                  onClick={() => dispatch({ key: 'targetLocationPid', pid: store.pid })}
                  isqa={store.qa_location !== 0 ? 1 : 0}
                  selected={state.targetLocationPid === store.pid ? 1 : 0}
                >
                  {store.addr}
                  <br />
                  (lat:
                  {store.lat}, lng:
                  {store.lng})
                </S.TableCell>
                <S.TableCell
                  onClick={() => dispatch({ key: 'targetSitePid', pid: store.pid })}
                  selected={state.targetSitePid === store.pid ? 1 : 0}
                >
                  {store.site_id || 'null'}
                  (NID: {store.nid || 'null'}, DID: {store.did || 'null'})
                </S.TableCell>
                <S.TableCell>
                  <Button
                    variant="contained"
                    disabled={!store.nid}
                    onClick={() => window.open(`https://store.naver.com/restaurants/detail?id=${store.nid}`)}
                  >
                    naver 확인
                  </Button>
                </S.TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </S.StyledCombineTable>
  );
}
export default CombineTable;

CombineTable.propTypes = {
  stores: PropTypes.arrayOf(
    PropTypes.shape({
      pid: PropTypes.number,
      nid: PropTypes.number,
      did: PropTypes.number,
      site_id: PropTypes.number,
      name: PropTypes.string,
      branch_name: PropTypes.string,
      lat: PropTypes.number,
      lng: PropTypes.number,
      category: PropTypes.string,
      category_code: PropTypes.string
    })
  ).isRequired,
  onEmitTargetPids: PropTypes.func.isRequired,
  isMasterPidChangeable: PropTypes.bool,
  masterPid: PropTypes.number.isRequired,
  setMasterPid: PropTypes.func
};

CombineTable.defaultProps = {
  isMasterPidChangeable: false,
  setMasterPid: () => {}
};
