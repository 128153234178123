export function createHeadCells(id, label, numeric = false, disablePadding = true) {
  return {
    id,
    label,
    numeric,
    disablePadding,
    hide: false
  };
}

export function refineRow(rows, selectedColumns) {
  const updatedColumns = Object.entries(selectedColumns)
    .filter((element) => !!element[1])
    .map((element) => element[0]);

  return rows.map((row) => {
    const updatedRow = {};
    updatedColumns.forEach((column) => {
      updatedRow[column] = row[column];
    });
    return updatedRow;
  });
}
