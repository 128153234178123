import { cashplaceStudiesApi } from '@API/manager/cashplace';
import { Study, FetchStudiesParams, UpdateStudyParams, CDevicesResponse } from '@TS/cashplace/study';

export function fetchStudies(params: FetchStudiesParams): Promise<Study[]> {
  return cashplaceStudiesApi.getStudies(params).then((response) => response.data);
}

export function updateStudy(params: UpdateStudyParams): Promise<Study> {
  return cashplaceStudiesApi.updateStudy(params).then((response) => response.data);
}

export function fetchCDevices(): Promise<CDevicesResponse> {
  return cashplaceStudiesApi.getCDevices().then((response) => response.data);
}
