import styled from 'styled-components';
import { Props } from './TreeNode';

type Leaf = Pick<Props, 'depth'>;
type LastLeaf = Pick<Props, 'depth' | 'isSelected'>;
type LastLeafProps = LastLeaf & {
  haveChildren: boolean;
};

export const ExpandButton = styled.button<Leaf>`
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  border: none;
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 0.875rem;
    path {
      fill: darkgray;
    }
  }

  &:hover {
    border-color: blue;
    svg > path {
      fill: blue;
    }
  }
`;

export const Row = styled.div<LastLeafProps>`
  width: 100%;
  background-color: white;
  border-bottom: 1px solid lightgray;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.875rem;

  ${({ isSelected }) =>
    isSelected
      ? 'background: #ffa4c3'
      : `&:hover {
        background: #ffe9f0}`};

  > span {
    width: 100%;
    max-width: 160px;
    text-align: center;
    margin-left: ${({ depth, haveChildren }) => clarifyMarginLeft(depth, haveChildren)};
  }

  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    > span {
      width: 100%;
      padding: 0 1rem;
      margin-left: 0.5rem;

      &:nth-child(3) {
        width: 200%;
      }
    }
  }
`;

export const InnerBackground = styled.div<Leaf>`
  ${({ depth }) =>
    depth === 0
      ? 'background: lightgray'
      : depth === 1
      ? `margin-left: 1.125rem; 
        background: darkgray;`
      : depth === 2
      ? 'margin-left: 1.125rem; background: gray'
      : 'margin-left: 1.125rem; background: pink'}
`;

// NOTE: 안으로 들여쓰기 + 펼치기 아이콘 때문에 어쩔수없이 margin-left를 단계별로 적용하여 눈에 맞게 작성함
// 첫번째 span인 cid field
const clarifyMarginLeft = (depth: number, haveChildren: boolean): string => {
  if (depth === 0) {
    if (haveChildren) return '2.3rem';
    return '3.4rem';
  }
  if (depth === 1) {
    if (haveChildren) return '1.125rem';
    return '2.2rem';
  }
  if (depth === 2) {
    if (haveChildren) return '0rem';
  }
  if (depth === 3) return '0rem';
  return '0rem';
};
