import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const StyledStoreInnerFpTable = styled.div``;

export const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1)
  },
  unselectAllButton: {
    width: 120,
    marginRight: 10
  },
  deleteAllButton: {
    width: 120
  }
}));
