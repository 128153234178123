import firebase from 'firebase';
import secret from '@/secret';

// default firebase project
const config = {
  apiKey: 'AIzaSyDZi8obPjklS5_XsEkB2k2hRj9IR4sPvPM',
  authDomain: 'cashplace-2aa7c.firebaseapp.com',
  databaseURL: 'https://cashplace-2aa7c.firebaseio.com',
  storageBucket: 'cashplace-2aa7c.appspot.com',
  messagingSenderId: '902911106519'
};

function CashplaceFirebaseProject() {
  this.instance; // eslint-disable-line no-unused-expressions

  this.init = async () => {
    if (this.instance) return this.instance;

    this.instance = firebase.initializeApp(config);

    return this.instance;
  };

  this.auth = async () =>
    this.instance
      .auth()
      .signInWithEmailAndPassword(secret.FIREBASE.CASHPLACE.email, secret.FIREBASE.CASHPLACE.password)
      .catch((error) => {
        console.error(error.code, error.message);
      });
}

const cashplaceFirebaseProject = new CashplaceFirebaseProject();

export default cashplaceFirebaseProject;
