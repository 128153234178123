import { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createHeadCells } from '@F/table/Table/functions';
import EnhancedTable from '@F/table/Table';
import Detail from '@P/fingerprint/manualMapping/tableSection/detail/Detail';
import PaginationRow from '@F/table/PaginationRow';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import InputTwoToneIcon from '@material-ui/icons/InputTwoTone';
import { copyMessageToClipboard } from '@HOOK/useCopy';
import { actions } from '@/redux/fingerprint/manualMapping/state';
import * as S from './styles';

function TableSection() {
  const { page, perPage, count, fingerprints, openedIds, ids } = useSelector(
    (state) => ({
      page: state.manualMapping.page,
      perPage: state.manualMapping.perPage,
      count: state.manualMapping.count,
      fingerprints: state.manualMapping.fingerprints,
      openedIds: state.manualMapping.openedFingerprintIds.filter((row) => row.opened).map((row) => row.id),
      ids: state.manualMapping.openedFingerprintIds
    }),
    shallowEqual
  );

  const [keyword, setKeyword] = useState({});
  // pagination
  const dispatch = useDispatch();
  const setOpenedFingerprintIds = useCallback(
    (newOpenedFingerprintIds) => {
      dispatch(actions.setValue('openedFingerprintIds', newOpenedFingerprintIds));
    },
    [dispatch]
  );
  const setPage = useCallback(
    (newPage) => {
      dispatch(actions.setPage(newPage));
      dispatch(actions.fetchFingerprints());
    },
    [dispatch]
  );

  // rows
  const headCells = columns.map((column) => createHeadCells(column.name, column.label));
  const rows = useMemo(
    () =>
      fingerprints?.map((item) => ({
        id: item.id,
        ssid: {
          value: item.ssid,
          component: (
            <S.CellWithAction>
              {item.ssid}
              {item.ssid && (
                <InputTwoToneIcon
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    setKeyword({
                      id: item.id,
                      value: item.ssid
                    });
                    if (openedIds.includes(item.id)) e.stopPropagation();
                  }}
                />
              )}
            </S.CellWithAction>
          )
        },
        hint: {
          value: item.hint,
          component: (
            <S.CellWithAction>
              {item.hint}
              {item.hint && (
                <InputTwoToneIcon
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    setKeyword({
                      id: item.id,
                      value: item.hint
                    });
                    if (openedIds.includes(item.id)) e.stopPropagation();
                  }}
                />
              )}
            </S.CellWithAction>
          )
        },
        latLng: {
          value: `${item.f_lat}, ${item.f_lng}`,
          component: (
            <S.CellWithAction>
              {item.f_lat},{item.f_lng}
              <FileCopyTwoToneIcon
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  copyMessageToClipboard(`${item.f_lat}, ${item.f_lng}`);
                  e.stopPropagation();
                }}
              />
            </S.CellWithAction>
          )
        }
      })),
    [fingerprints, openedIds]
  );

  return (
    <S.StyledTableSection>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} />
      <EnhancedTable
        headCells={headCells.filter((cell) => cell.hide === false)}
        rows={rows || []}
        isCheckable={false}
        isCollapsible
        opened={ids}
        setOpened={setOpenedFingerprintIds}
      >
        <Detail data={fingerprints} keyword={keyword} />
      </EnhancedTable>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} reverse />
    </S.StyledTableSection>
  );
}
export default TableSection;

TableSection.propTypes = {};

const columns = [
  { name: 'id', label: 'ID', isImportant: true },
  { name: 'ssid', label: 'ssid' },
  { name: 'hint', label: 'hint' },
  { name: 'latLng', label: '위경도' }
];
