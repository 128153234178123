import { all, call, put, takeLeading, select } from 'redux-saga/effects';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { fetchComplexes, fetchComplex, patchComplex } from './api';
import { actions, types } from './state';

export function* fetchDatas() {
  const { page, perPage, params } = yield select((state) => ({
    page: state.complexManager.page,
    perPage: state.complexManager.perPage,
    params: state.complexManager.params
  }));

  yield put(actions.setLoading(true));
  try {
    const data = yield call(fetchComplexes, page, perPage, params);
    yield put(actions.setNewComplexes(data.items));
    yield put(actions.setValue('count', data.count));
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* fetchData(action) {
  yield put(actions.setLoading(true));
  try {
    const data = yield call(fetchComplex, { cid: action.cid });
    yield put(actions.setNewComplexes([data]));
    yield put(actions.setValue('count', 1));
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* updateComplex(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(patchComplex, action.params);
    const data = yield call(fetchComplex, { cid: action.params.cid });
    yield put(actions.setNewComplex(data));

    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* sagaReducer() {
  yield all([
    takeLeading(types.FETCH_COMPLEXES, fetchDatas),
    takeLeading(types.FETCH_COMPLEX, fetchData),
    takeLeading(types.UPDATE_COMPLEX, updateComplex)
  ]);
}
