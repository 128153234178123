import { all, call, put, takeLeading, select } from 'redux-saga/effects';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { actions, types } from './state';
import { fetchCookVersions, deleteCookVersion, updateCookVersion } from './api';

export function* fetchData() {
  const { params } = yield select((state) => ({
    params: state.cookVersion.params
  }));

  yield put(actions.setLoading(true));
  try {
    const data = yield call(fetchCookVersions, params);
    yield put(actions.setNewVersions(data));
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* deleteVersion(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(deleteCookVersion, action.version.version_id);
    yield put(actions.deleteVersionFromVersions(action.version));

    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* updateVersion(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(updateCookVersion, action.params);
    const newAttributes = {
      version_id: action.params.versionId,
      v: action.params.integerVersion,
      v_str: action.params.version,
      minimum_v: action.params.minimumVersion,
      comment: action.params.comment,
      package_name: action.params.packageName,
      download_link: action.params.downloadLink,
      status: action.params.status
    };
    yield put(actions.setNewVersion(newAttributes));

    toastSuccessMessage();
  } catch (e) {
    const { message } = e.response.data;
    toastErrorMessage(message === 'InvalidCookVersion' ? '현재 앱 버전이 동작가능 앱 버전보다 낮습니다.' : message);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* sagaReducer() {
  yield all([
    takeLeading(types.FETCH_VERSIONS, fetchData),
    takeLeading(types.DELETE_VERSION, deleteVersion),
    takeLeading(types.UPDATE_VERSION, updateVersion)
  ]);
}
