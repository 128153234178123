import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import MuiPagination from '@material-ui/lab/Pagination';

import { actions } from './redux/slice';
import { selectPagination } from './redux/selector';

export default function Pagination(): JSX.Element {
  const { page, perPage, totalCount } = useSelector(selectPagination);
  const dispatch = useDispatch();

  const handleChangePage = (e: React.ChangeEvent<unknown>, page: number) => dispatch(actions.changePage(page));

  return (
    <StyledPaginationWrapper>
      <MuiPagination
        defaultPage={1}
        page={page}
        count={Math.ceil(totalCount / (perPage || 20))}
        onChange={handleChangePage}
      />
    </StyledPaginationWrapper>
  );
}

const StyledPaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`;
