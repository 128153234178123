import { useEffect, useState } from 'react';
import Wrapper from '@F/materialUI/Wrapper';
import SelectForm from '@F/materialUI/SelectForm';
import { Button, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { actions } from '@/redux/cashplace/version/state';
import * as S from './styles';

function Filter(): JSX.Element {
  const classes = S.useStyles();

  const [count, setCount] = useState<string>('10');
  const CountSelect = (
    <SelectForm
      label="개수"
      options={['10', '50', '100', '200']}
      value={count}
      onSelect={(e): void =>
        setCount((prevState) => {
          if (typeof e.target.value === 'string') return e.target.value;
          return prevState;
        })
      }
      name="CountSelect"
    />
  );

  const dispatch = useDispatch();
  const fetchVersions = () => {
    dispatch(actions.setPage(1));
    dispatch(actions.setValue('perPage', Number(count)));
    dispatch(actions.fetchVersions());
  };
  useEffect(() => {
    fetchVersions();
  }, []);
  return (
    <S.StyledFilter
      onKeyDown={(event) => {
        if (event.key === 'Enter') fetchVersions();
      }}
    >
      <Wrapper>
        <S.Title>검색</S.Title>
        <S.Row container className={classes.margin} spacing={1}>
          <Grid item xs={3}>
            {CountSelect}
          </Grid>
        </S.Row>
        <S.ButtonRow>
          <Button variant="contained" color="primary" onClick={fetchVersions}>
            검색
          </Button>
        </S.ButtonRow>
      </Wrapper>
    </S.StyledFilter>
  );
}
export default Filter;
