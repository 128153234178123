import { useState } from 'react';
import DialogModal from '@F/DialogModal';
import TextInputForm from '@F/materialUI/TextInputForm';
import { storesApi } from '@API/manager';
import Loading from '@F/Loading';
import ResponseAlert from '@F/ResponseAlert';
import { useFetch, useInput } from '@HOOK/';

function AddFpDialog(props) {
  const { open, setOpen, pid, loadFingerPrintsData } = props;
  const { callApi, isLoading, isError, error } = useFetch();
  const [responseAlertOpen, setResponseAlertOpen] = useState(false);

  const [fpid, setFpid] = useInput(null);

  const confirmButtonDidClicked = async () => {
    const response = await callApi(storesApi.patchStoreMap, {
      pid,
      cid: 0,
      bid: 0,
      fpid
    });

    setResponseAlertOpen(true);
    if (response) {
      setOpen(false);
      loadFingerPrintsData();
    }
  };

  const fpidInput = <TextInputForm name="FpidInput" label="FPID" onChange={setFpid} value={fpid} />;

  return (
    <>
      {isLoading && <Loading />}
      <ResponseAlert
        error={isError}
        open={responseAlertOpen}
        handleClose={() => setResponseAlertOpen(false)}
        message={isError ? String(error) : '성공하였습니다.'}
      />
      <DialogModal
        title="fpid 추가"
        description="추가할 fpid를 입력해주세요."
        open={open}
        setOpen={setOpen}
        confirmButtonDidClicked={confirmButtonDidClicked}
      >
        {fpidInput}
      </DialogModal>
    </>
  );
}
export default AddFpDialog;

AddFpDialog.propTypes = {};
