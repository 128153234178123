import { useNavigate, useParams } from 'react-router-dom';

import { toastSuccessMessage } from '@FUNC/toast';
import NoticeForm from './NoticeForm';
import { useManageNotice } from './useManageNotice';

export default function NoticeEditPage(): JSX.Element | null {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { notices, editNotice, editApiStatus } = useManageNotice();

  if (id !== undefined) {
    const notice = notices.find((notice) => notice.id === +id);

    if (notice !== undefined) {
      const { type, title, content } = notice;

      return (
        <NoticeForm
          initialNoticeFormValues={{ type, title, content }}
          onClickCancel={() => navigate(-1)}
          onClickSave={async (formValues) =>
            editNotice(notice, formValues).then((isSuccess) => {
              if (isSuccess) {
                toastSuccessMessage('공지사항이 성공적으로 수정되었습니다.');
                navigate(`/x/notices/${notice.id}`);
              }
            })
          }
          disabled={editApiStatus === 'loading'}
        />
      );
    }
  }

  navigate('/x/notices');
  return null;
}
