class LocalStorageParser<K> {
  keys: K;
  prefixOfKey: string;

  constructor(keys: K, prefixOfKey: string) {
    this.keys = keys;
    this.prefixOfKey = prefixOfKey;
  }

  prefix = (key: string): string => `${this.prefixOfKey}${key}`;

  get = <T>(key: string): T | null => {
    const item = window.localStorage.getItem(this.prefix(key));

    if (typeof item === 'string') {
      return JSON.parse(item);
    }

    return item;
  };
  set = <T>(key: string, json: T): void => window.localStorage.setItem(this.prefix(key), JSON.stringify(json));
  remove = (key: string): void => window.localStorage.removeItem(this.prefix(key));
}

// manager localstorage
const MANAGER_LOCALSTORAGE_KEYS = {
  'max/companies?type=2': 'max/companies?type=2',
  favorite_route_nodes: 'favorite_route_nodes',
  show_side_menu: 'show_side_menu',
  opened_menus: 'opened_menus',
  refresh_token: 'asdfasdfklqweqweqweqweqdsfqwe',
  access_token: 'asdfasdfqweqeqwdlasfkqedjqwelqwh'
};
const MANAGER_LOCALSTORAGE_PREFIX = 'manager-';

export const managerLocalStorage = new LocalStorageParser<typeof MANAGER_LOCALSTORAGE_KEYS>(
  MANAGER_LOCALSTORAGE_KEYS,
  MANAGER_LOCALSTORAGE_PREFIX
);
