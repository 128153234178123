import styled from '@emotion/styled';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import TableHeadCell from '@F/table/cells/TableHeadCell';
import { useTogglers } from '@HOOK/useTogglers';

type ToggleHeadCellType = 'collapse' | 'check';

export default function ToggleHeadCell({ type = 'collapse' }: { type?: ToggleHeadCellType }): JSX.Element {
  const collapseTogglers = useTogglers();

  let onIcon;
  let offIcon;

  if (type === 'collapse') {
    onIcon = <KeyboardArrowUpIcon />;
    offIcon = <KeyboardArrowDownIcon />;
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    <StyledToggleHeadCell onClick={(_) => collapseTogglers.toggleAll()}>
      <IconButton size="small">{collapseTogglers.hasOn() ? onIcon : offIcon}</IconButton>
    </StyledToggleHeadCell>
  );
}

const StyledToggleHeadCell = styled(TableHeadCell)`
  width: 30px !important;
`;
