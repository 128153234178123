import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;

  > p {
    padding: 0 0.5rem;
    margin-top: 1.5rem;
  }

  .MuiFormHelperText-root {
    position: absolute;
    bottom: -1.5rem;
    padding: 0;
  }

  > div {
    width: 100%;
    margin: 0.6rem 0 1.5rem;
  }
`;

export const DateInput = styled(TextField)`
  input::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }
`;
