import Loading from '@F/Loading';
import { useAppSelector } from '@HOOK/useSelector';
import Filter from './Filter';
import TableSection from './TableSection';
import * as S from './styles';

function Reward(): JSX.Element {
  const isLoading = useAppSelector((state) => state.reward.isLoading);
  return (
    <S.StyledReward>
      {isLoading && <Loading />}
      <Filter />
      <br />
      <TableSection />
    </S.StyledReward>
  );
}
export default Reward;
