import type { SearchInputs } from '@TS/max/campaigns/search';
import type { TextInputProps, SelectInputProps } from '@TS/inputProps';
import dayjs from 'dayjs';
import { CAMPAIGN_STATUS } from '@/constants/max/status';
import PER_PAGE_OPTIONS from '@/constants/perPageOptions';
import type { TextValue } from './type';

const today = dayjs();
const format = 'YYYY-MM-DD';
export const ONE_MONTH = {
  start: today.add(-1, 'month').format(format),
  end: today.format(format)
};

export const INITIAL_SEARCH_VALUE: TextValue = {
  campaignId: '',
  campaignName: ''
};

export const searchFields: TextInputProps<keyof TextValue>[] = [
  {
    name: 'campaignId',
    label: '캠페인 ID'
  },
  {
    name: 'campaignName',
    label: '캠페인 이름'
  }
];

export const statusInputProps: SelectInputProps<keyof Pick<SearchInputs, 'status'>> = {
  name: 'status',
  label: '캠페인 상태',
  required: true,
  options: Object.keys(CAMPAIGN_STATUS)
};

export const sizeInputProps: SelectInputProps<keyof Pick<SearchInputs, 'size'>> = {
  name: 'size',
  label: '1페이지 당 캠페인 개수',
  required: true,
  options: PER_PAGE_OPTIONS
};
