import type { TargetApp as TargetAppProps } from '@TS/max/campaigns/target';
import { AppleIcon, AndroidIcon, primary } from 'loplat-ui';
import { Wrapper } from './index.styles';

export type AppProps = { type: TargetAppProps['target_type']; name: TargetAppProps['name'] };
export const AppModule = ({ type, name }: AppProps): JSX.Element => (
  <Wrapper>
    {type === 'android' && <AndroidIcon fillColor={primary} />}
    {type === 'ios' && <AppleIcon fillColor={primary} />}
    {name}
  </Wrapper>
);

export default AppModule;
