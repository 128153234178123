export const nextOrderInKorean = (orderType: OrderType): string => (orderType === 'ASC' ? '내림차순' : '오름차순');
export const toggleOrder = (orderType: OrderType): OrderType => (orderType === 'ASC' ? 'DESC' : 'ASC');

export const ORDER_BY_TO_BACKEND_CONVENTION = {
  fpManager: {
    id: 'fp_fpid',
    category: 'category_all_kor',
    status: 'fp_image_validation',
    timestamp: 'fp_created_at',
    floor: 'fp_floor',
    collector: 'fp_collector'
  },
  closingManager: {
    id: 'site_id',
    status: 'report_status',
    category: 'category_all_kor',
    placeName: 'name',
    placeBranchName: 'branch_name',
    collector: 'charge',
    timestamp: 'charge_result_at',
    reviewTimestamp: 'report_status_qa_at'
  }
};

// type TableCellValue = string | number | undefined | null | boolean;

/*
    undefined < null < nonnullable

    nonnullable
        string 사전순
        boolean false < true
        number small < big
*/

export const tableSort = <D extends { [index in string]: any }>(
  data: D[],
  name: keyof D,
  orderType: OrderType
): D[] => {
  data.sort((a, b) => {
    const va = a[name];
    const vb = b[name];

    return orderType === 'DESC' ? DESCComparator(va, vb) : ASCComparator(va, vb);
  });
  return data;
};

// ASC
export const ASCComparator = (a: any, b: any): number => {
  if (a == null) {
    if (b == null) {
      return 0;
    }
    return 1;
  }

  if (b == null) {
    return -1;
  }

  if (typeof a === 'boolean' && typeof b === 'boolean') {
    if (a === b) return 0;
    if (a) return 1;
    return -1;
  }

  if (typeof a === 'string' && typeof b === 'string') {
    // 만약 string이 숫자를 나타내는 경우 숫자로 정렬하고 싶다면 숫자 변환호 Number.isNaN 로직 추가
    if (a === b) return 0;
    if (a > b) return 1;
    if (a < b) return -1;
  }

  if (typeof a === 'number' && typeof b === 'number') {
    if (a === b) return 0;
    if (a > b) return 1;
    if (a < b) return -1;
  }

  return 0;
};

// DESC
export const DESCComparator = (a: any, b: any): number => {
  if (a == null) {
    if (b == null) {
      return 0;
    }

    return 1;
  }

  if (b == null) {
    return -1;
  }

  if (typeof a === 'boolean' && typeof b === 'boolean') {
    if (a === b) return 0;
    if (a) return -1;
    return 1;
  }

  if (typeof a === 'string' && typeof b === 'string') {
    // 만약 string이 숫자를 나타내는 경우 숫자로 정렬하고 싶다면 숫자 변환호 Number.isNaN 로직 추가
    if (a === b) return 0;
    if (a > b) return -1;
    if (a < b) return 1;
  }

  if (typeof a === 'number' && typeof b === 'number') {
    if (a === b) return 0;
    if (a > b) return -1;
    if (a < b) return 1;
  }

  return 0;
};
