import { action, observable, makeObservable } from 'mobx';
import { create, persist } from 'mobx-persist';
import jwtDecode from 'jwt-decode';
import type { User } from '@TS/user';
import type { AuthResponse } from '@TS/auth';

export interface UserProps {
  accessToken: string;
  refreshToken: string;
}

class UserStore implements UserProps {
  @persist @observable accessToken = '';

  @persist @observable refreshToken = '';

  constructor() {
    makeObservable(this);
  }

  @action setUserInfo({ access_token, refresh_token }: AuthResponse): void {
    this.accessToken = access_token;
    this.refreshToken = refresh_token;
  }

  @action setAccessToken(newAccessToken: string): void {
    this.accessToken = newAccessToken;
  }

  @action setRefreshToken(newRefreshToken: string): void {
    this.refreshToken = newRefreshToken;
  }

  @action getUser(): User | null {
    return this.accessToken ? jwtDecode(this.accessToken) : null;
  }
}
export const userStore = new UserStore();

const hydrate = create();
hydrate('user', userStore);
