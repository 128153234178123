import { useCallback, useMemo, useState } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Event, InputsData } from '@TS/cashplace/event';
import Inputs from '../../Inputs';
import { kindOptions } from '../../options';
import { actions } from '@/redux/cashplace/event/state';
import * as S from './styles';

type DetailProps = {
  data: Event[];
  id?: number;
};

const defaultInputs = {
  content: '',
  description: '',
  eventUrl: '',
  mainImageUrl: '',
  subImageUrl: '',
  buttonName: '',
  kind: ''
};

function Detail({ data, id }: DetailProps): JSX.Element {
  const event = useMemo(() => data.find((element) => element.event_id === id), [id, data]);
  const [inputs, setInputs] = useState<InputsData>(defaultInputs);

  const dispatch = useDispatch();
  const toggleStatus = useCallback(() => {
    if (event) {
      dispatch(
        actions.updateEvent(event, {
          content: event.content,
          description: event.desc,
          eventUrl: event.event_url,
          mainImageUrl: event.main_img_url,
          subImageUrl: event.sub_img_url,
          buttonName: event.btn_name,
          kind: event.kind,
          status: !event.status
        })
      );
    }
  }, [event, dispatch]);

  const updateEvent = () => {
    dispatch(
      actions.updateEvent(event, {
        content: inputs.content,
        description: inputs.description,
        eventUrl: inputs.eventUrl,
        mainImageUrl: inputs.mainImageUrl,
        subImageUrl: inputs.subImageUrl,
        buttonName: inputs.buttonName,
        kind: kindOptions[inputs.kind],
        status: event?.status || false
      })
    );
  };

  return (
    <S.StyledDetail>
      <Inputs
        defaultInputs={event}
        onEmitInputs={setInputs}
        Buttons={
          <S.ButtonRow>
            <Button color={event?.status ? 'secondary' : 'primary'} variant="outlined" onClick={toggleStatus}>
              {event?.status ? '비활성화' : '활성화'}
            </Button>
            <Button color="primary" variant="contained" onClick={updateEvent}>
              업데이트
            </Button>
          </S.ButtonRow>
        }
      />
    </S.StyledDetail>
  );
}
export default Detail;
