import styled from 'styled-components';

export const CurrentCIDWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin-top: -0.6rem;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    span {
      &:first-of-type {
        font-size: 0.65rem;
        margin-right: 0.4rem;
      }
      &:last-of-type {
        font-size: 0.875rem;
        font-weight: bold;
      }
    }
  }
`;
