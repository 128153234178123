import { useCallback, useEffect, useMemo, useState } from 'react';
import Wrapper from '@F/materialUI/Wrapper';
import { Button, Grid } from '@material-ui/core';
import CountBox from '@F/CountBox';
import moment from 'moment';
import Loading from '@F/Loading';
import DatePickers from '@F/materialUI/datePicker/DatePickers';
import { useFetch } from '@HOOK/';
import { cashplaceSitesApi } from '@API/manager/cashplace';
import Chart from './Chart';
import { DataType } from './types';

import * as S from './styles';

function Dashboard(): JSX.Element {
  const [period, setPeriod] = useState<{ startDate: string; endDate: string }>({
    startDate: moment().subtract(7, 'day').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });
  const confirmPeriod = useCallback((dates: string[]): void => {
    setPeriod(() => ({
      startDate: dates[0],
      endDate: dates[1]
    }));
  }, []);
  const [totalData, setTotalData] = useState({
    total_sites: 0,
    complete_sites: 0,
    study_ok_cnt: 0,
    study_abuse_cnt: 0,
    total_studies_not_inspected: 0,
    total_studies: 0,
    partial_complete_sites: 0,
    today_study_cnt: 0
  });

  const { callApi, isLoading } = useFetch();
  const fetchAllData = async () => {
    const response = await callApi(cashplaceSitesApi.getStatsAll);
    if (response) return response.data;
    return null;
  };

  const [dataByPeriod, setDataByPeriod] = useState<DataType[]>([]);
  const dates: string[] = useMemo(() => dataByPeriod.map((item) => item.day), [dataByPeriod]);
  const studyCounts: number[] = useMemo(() => dataByPeriod.map((item) => item.daily_studies), [dataByPeriod]);
  const abuseCounts: number[] = useMemo(() => dataByPeriod.map((item) => item.daily_studies_abuse), [dataByPeriod]);
  const yesterdayData: DataType | null = useMemo(() => dataByPeriod[dataByPeriod.length - 1] || null, [dataByPeriod]);
  const beforeYesterdayData: DataType | null = useMemo(
    () => dataByPeriod[dataByPeriod.length - 2] || null,
    [dataByPeriod]
  );
  const fetchDataByPeriod = async () => {
    const response = await callApi(cashplaceSitesApi.getStatsByPeriod, {
      startDate: period.startDate,
      endDate: period.endDate
    });
    if (response) return response.data.result;
    return null;
  };

  useEffect(() => {
    fetchAllData().then((data) => setTotalData(data));
    fetchDataByPeriod().then((data) => setDataByPeriod(data));
  }, []);

  const generateCsv = () => {
    let content = 'data:text/csv;charset=utf-8,';
    content += `${[
      'day',
      '완료된 Site',
      '부분 완료된 Site',
      '학습 수',
      '승인 학습 수',
      'Abuse 학습 수',
      '검토 대기중인 학습 수',
      '총 학습 수',
      '총 승인 학습 수',
      '총 Abuse 학습 수',
      '총 Site 수'
    ].join(',')}\n`;

    Object.values(dataByPeriod).forEach((data, index) => {
      content += [
        dates[index],
        data.daily_complete_sites,
        data.daily_partial_complete_sites,
        data.daily_studies,
        data.daily_studies_ok,
        data.daily_studies_abuse,
        data.daily_studies_not_inspected,
        data.total_studies,
        data.study_ok_cnt,
        data.study_abuse_cnt,
        data.total_sites
      ].join(',');
      content += index < dates.length ? '\n' : '';
    });

    window.open(encodeURI(content));
  };

  return (
    <S.StyledDashboard>
      {isLoading && <Loading />}
      <Grid container spacing={2}>
        <Grid item xs>
          <Wrapper>
            <CountBox
              title="어제 학습 횟수"
              description=""
              currentData={yesterdayData ? yesterdayData.daily_studies : 0}
              labelText="Yesterday"
              labelBackgroundColor="blue"
            />
          </Wrapper>
        </Grid>
        <Grid item xs>
          <Wrapper>
            <CountBox
              title="오늘 학습 횟수"
              description=""
              currentData={totalData ? totalData.today_study_cnt : 0}
              labelText="Today"
              labelBackgroundColor="red"
            />
          </Wrapper>
        </Grid>
        <Grid item xs>
          <Wrapper>
            <CountBox
              title="완료 Site 수 / 수집 Site 수"
              description="어뷰징 제외 1회 이상/모든 slot이 완료된 site 수"
              currentData={
                yesterdayData && beforeYesterdayData
                  ? Number(yesterdayData.complete_sites) - Number(beforeYesterdayData.complete_sites)
                  : 0
              }
              lastData={
                yesterdayData && beforeYesterdayData
                  ? Number(yesterdayData.partial_complete_sites) - Number(beforeYesterdayData.partial_complete_sites)
                  : 0
              }
              labelText="Yesterday"
              labelBackgroundColor="blue"
            />
          </Wrapper>
        </Grid>
        <Grid item xs>
          <Wrapper>
            <CountBox
              title="남은 검수 수"
              description=""
              currentData={totalData.total_studies_not_inspected}
              labelText="Now"
              labelBackgroundColor="blue"
            />
          </Wrapper>
        </Grid>
      </Grid>
      <br />
      <Wrapper>
        <S.ChartContainer>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Chart dates={dates} studyCounts={studyCounts} abuseCounts={abuseCounts} />
            </Grid>
            <Grid item xs>
              <Wrapper>
                <CountBox
                  title="전체 학습 수"
                  description=""
                  currentData={totalData.total_studies}
                  labelText=""
                  labelBackgroundColor="blue"
                />
              </Wrapper>
              <br />
              <Wrapper>
                <CountBox
                  title={`승인 학습 수(${((totalData.study_ok_cnt / totalData.total_studies) * 100).toFixed(1)}%)`}
                  description=""
                  currentData={totalData.study_ok_cnt}
                  labelText=""
                  labelBackgroundColor="blue"
                />
              </Wrapper>
              <br />
              <Wrapper>
                <CountBox
                  title={`Abuse 학습 수(${((totalData.study_abuse_cnt / totalData.total_studies) * 100).toFixed(1)}%)`}
                  description=""
                  currentData={totalData.study_abuse_cnt}
                  labelText=""
                  labelBackgroundColor="blue"
                />
              </Wrapper>
            </Grid>
            <S.DatePickerWrapper>
              <DatePickers
                initialStartDate={period.startDate}
                initialEndDate={period.endDate}
                onEmitDate={confirmPeriod}
              />
              <Button
                style={{ marginLeft: '10px' }}
                variant="contained"
                onClick={() => fetchDataByPeriod().then((data) => setDataByPeriod(data))}
              >
                LOAD
              </Button>
              <Button style={{ marginLeft: '10px' }} variant="contained" onClick={generateCsv}>
                DOWNLOAD CSV
              </Button>
            </S.DatePickerWrapper>
          </Grid>
        </S.ChartContainer>
      </Wrapper>
      <br />
      <Grid container spacing={2}>
        <Grid item xs>
          <Wrapper>
            <CountBox
              title="전체 Site 수"
              description="수집 대상 매장 수"
              currentData={totalData.total_sites}
              labelText="Total"
              labelBackgroundColor="blue"
            />
          </Wrapper>
        </Grid>
        <Grid item xs>
          <Wrapper>
            <CountBox
              title="수집 Site 수"
              description="어뷰징 제외 1회 이상 수집된 매장 수(비율)"
              currentData={totalData.partial_complete_sites}
              labelText="Total"
              labelBackgroundColor="blue"
            />
          </Wrapper>
        </Grid>
        <Grid item xs>
          <Wrapper>
            <CountBox
              title="수집 완료 Site 수"
              description="할당된 횟수가 모두 수집된 Site 수(비율)"
              currentData={totalData.complete_sites}
              labelText="Total"
              labelBackgroundColor="blue"
            />
          </Wrapper>
        </Grid>
      </Grid>
    </S.StyledDashboard>
  );
}
export default Dashboard;
