// TODO: typescript 적용
/* eslint class-methods-use-this: 0 */

import { axiosInstancesManager } from '@API/axios/helper';
import { GetParams, PutParams, GetNearParams, PidParams, PatchUnmapRequest } from '@TS/store/store';

class StoresApi {
  getStoreData({
    perPage,
    page,
    fpid,
    name,
    branchName,
    startDate,
    endDate,
    categoryCode,
    categoryIsNull,
    categoryExclude,
    collector,
    equalSearch,
    qaBy,
    searchPriFilter,
    qaPriDedup,
    cid,
    bid,
    pids,
    qualified,
    storeType,
    combined,
    qaByExcluded,
    hasComplex
  }: GetParams) {
    return axiosInstancesManager.manager.get('/stores/search', {
      params: {
        per_page: perPage,
        page,
        fpid,
        name,
        branch_name: branchName,
        start_date: startDate,
        end_date: endDate,
        category_code: categoryCode,
        category_is_null: categoryIsNull,
        category_exclude: categoryExclude,
        collector,
        equal_search: equalSearch,
        cid,
        bid,
        pids: pids?.join(','),
        qualified,
        store_type: storeType,
        qa_by: qaBy,
        qa_pri_dedup: qaPriDedup,
        search_pri_filter: searchPriFilter,
        combined,
        qa_by_exclude: qaByExcluded,
        has_complex: hasComplex
      }
    });
  }

  getStoreDataByPID({ pid }: PidParams) {
    return axiosInstancesManager.manager.get(`/stores/${pid}`);
  }

  getStoresNearPid({ pid, perPage = 100, page = 1, radius }: GetNearParams) {
    return axiosInstancesManager.manager.get('stores/search-near', {
      params: {
        pid,
        per_page: perPage,
        page,
        radius
      }
    });
  }

  deleteStore({ pid }: PidParams) {
    return axiosInstancesManager.manager.delete(`/stores/${pid}`);
  }

  putStore({
    pid,
    name,
    branchName,
    floor,
    lat,
    lng,
    clientCodes,
    categoryCode,
    categorySub,
    section,
    nid,
    did,
    bids,
    qaCategory,
    qaLocation,
    qaSubName,
    qaName,
    cids
  }: PutParams) {
    return axiosInstancesManager.manager.put(`/stores/${pid}`, {
      name,
      branch_name: branchName,
      floor,
      lat,
      lng,
      client_codes: clientCodes,
      category_code: categoryCode,
      category_sub: categorySub,
      section,
      nid,
      cids,
      did,
      bids,
      qa_category: qaCategory,
      qa_location: qaLocation,
      qa_subname: qaSubName,
      qa_name: qaName
    });
  }

  patchStoreClose({ pid }: PidParams) {
    return axiosInstancesManager.manager.patch(`/stores/${pid}/close`);
  }

  patchStoreDiscard({ pid }: PidParams) {
    return axiosInstancesManager.manager.patch(`/stores/${pid}/discard`);
  }

  patchStoreMap({ pid, cid = 0, bid = 0, fpid = 0, force = true }: any) {
    return axiosInstancesManager.manager.patch(`/stores/${pid}/map`, {
      cid,
      bid,
      fpid,
      force
    });
  }

  patchStoreUnMap(request: PatchUnmapRequest) {
    return axiosInstancesManager.manager.patch(`/stores/unmap`, request);
  }

  patchCombineStores({
    masterPid,
    inspected,
    slavePidList,
    targetNamePid,
    targetBranchNamePid,
    targetCategoryPid,
    targetLocationPid,
    targetSitePid
  }: any) {
    return axiosInstancesManager.manager.patch(`/stores/${masterPid}/combine`, {
      inspected,
      slave_pid_list: slavePidList,
      target_name_pid: targetNamePid,
      target_branch_name_pid: targetBranchNamePid,
      target_category_pid: targetCategoryPid,
      target_location_pid: targetLocationPid,
      target_site_pid: targetSitePid
    });
  }

  patchCombineStore({ pid }: PidParams) {
    return axiosInstancesManager.manager.patch(`/stores/${pid}/check-combined`);
  }

  getStoreReports({
    perPage,
    page,
    qaBy,
    isQa,
    reporter,
    startDate,
    endDate,
    pid,
    storeType,
    orFilterLoc,
    orFilterName,
    orFilterBranch,
    orFilterCategory
  }: any) {
    return axiosInstancesManager.manager.get('/stores/reports', {
      params: {
        per_page: perPage,
        page,
        qa_by: qaBy,
        is_qa: isQa,
        reporter,
        start_date: startDate,
        end_date: endDate,
        pid,
        store_type: storeType,
        or_filter_loc: orFilterLoc,
        or_filter_name: orFilterName,
        or_filter_branch: orFilterBranch,
        or_filter_category: orFilterCategory
      }
    });
  }

  getStoreReport(reportId: any) {
    return axiosInstancesManager.manager.get(`/stores/reports/${reportId}`);
  }

  putReport({
    reportId,
    pid,
    locationReportStatus,
    nameReportStatus,
    branchNameReportStatus,
    categoryReportStatus,
    lat,
    lng,
    name,
    branchName,
    categoryCode
  }: any) {
    return axiosInstancesManager.manager.put(`/stores/reports/${reportId}`, {
      pid,
      location_report_status: locationReportStatus,
      name_report_status: nameReportStatus,
      branchname_report_status: branchNameReportStatus,
      category_report_status: categoryReportStatus,
      lat,
      lng,
      name,
      branch_name: branchName,
      category_code: categoryCode
    });
  }
}

export default new StoresApi();
