import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import CustomizingColumnsDialog from '@F/table/CustomizingColumnsDialog';
import EnhancedTable from '@F/table/Table';
import { createHeadCells, refineRow } from '@F/table/Table/functions';
import ClosingManagerInnerContainer from '@P/ClosingManager/tableSection/detail/Container';
import { useDispatch, useSelector } from 'react-redux';
import Red from '@material-ui/core/colors/red';
import Blue from '@material-ui/core/colors/blue';
import Orange from '@material-ui/core/colors/orange';
import Grey from '@material-ui/core/colors/grey';
import StatusLabel from '@F/StatusLabel';
import PaginationRow from '@F/table/PaginationRow';
import { actions } from '@/redux/closingManager/state';
import * as S from './styles';

function TableSection() {
  const { closingData, count, params, page, perPage } = useSelector((state) => ({
    closingData: state.closingManager.closingData,
    count: state.closingManager.count,
    params: state.closingManager.params,
    page: state.closingManager.page,
    perPage: state.closingManager.perPage
  }));

  const dispatch = useDispatch();

  // pagination
  const setPage = useCallback(
    (newPage) => {
      if (page === newPage) dispatch(actions.fetchClosingData());
      else dispatch(actions.setValue('page', newPage));
    },
    [dispatch, page]
  );
  useEffect(() => {
    dispatch(actions.fetchClosingData());
  }, [dispatch, page]);

  // custom modal
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(() =>
    columns.reduce((obj, column) => ({ ...obj, [column.name]: true }), {})
  );
  const CustomizingColumnsButton = (
    <Button
      key="customizingColumnsButton"
      variant="outlined"
      color="primary"
      onClick={() => setIsCustomModalOpen(true)}
      style={{ marginBottom: '1rem' }}
    >
      column 보이기/숨기기
    </Button>
  );

  // rows
  const [headCells, setHeadCells] = useState(() => columns.map((column) => createHeadCells(column.name, column.label)));
  useEffect(() => {
    const updatedHeadCells = headCells.map((cell) => ({
      ...cell,
      hide: !selectedColumns[cell.id]
    }));
    setHeadCells(updatedHeadCells);
  }, [selectedColumns]);

  const rows = useMemo(
    () =>
      closingData.map((item) => ({
        status: {
          value: item.report_status,
          component: formatReportStatus(item.report_status)
        },
        id: item.site_id,
        cid: String(item.cid),
        complexName: item.complex_name,
        complexBranchName: item.complex_branch_name,
        storeId: String(item.store_id),
        placeName: item.name,
        placeBranchName: item.branch_name,
        floor: item.floor,
        category: item.category_all_kor,
        collector: item.charge,
        timestamp: moment(item.charge_result_at).format('YYYY-MM-DD HH:mm:ss'),
        reviewTimestamp: moment(item.report_status_qa_at).format('YYYY-MM-DD HH:mm:ss')
      })),
    [closingData]
  );
  const refinedRows = useMemo(() => refineRow(rows, selectedColumns), [rows, selectedColumns]);

  // expand
  const openedClosingIds = useSelector((state) => state.closingManager.openedClosingIds);
  const setOpenedClosingIds = useCallback(
    (newOpenedClosingIds) => {
      dispatch(actions.setValue('openedClosingIds', newOpenedClosingIds));
    },
    [dispatch]
  );

  const refreshClosingData = (order, orderBy) => {
    dispatch(
      actions.setValue('params', {
        ...params,
        order,
        orderBy
      })
    );

    dispatch(actions.fetchClosingData());
  };

  const downloadCsv = () => {
    dispatch(actions.fetchClosingCsvData());
  };

  return (
    <S.StyledTableSection>
      <CustomizingColumnsDialog
        open={isCustomModalOpen}
        setOpen={setIsCustomModalOpen}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        columns={columns}
      />
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} />
      <EnhancedTable
        titleText={[
          '',
          CustomizingColumnsButton,
          <Button variant="outlined" color="primary" onClick={downloadCsv} style={{ marginBottom: '1rem' }}>
            CSV 다운로드
          </Button>
        ]}
        headCells={headCells.filter((cell) => cell.hide === false)}
        rows={refinedRows}
        initialOrder="desc"
        initialOrderBy="timestamp"
        isCheckable={false}
        isCollapsible
        opened={openedClosingIds}
        setOpened={setOpenedClosingIds}
        isApiSort
        onChangeSortOption={refreshClosingData}
      >
        <ClosingManagerInnerContainer closingData={closingData} selectedColumns={selectedColumns} />
      </EnhancedTable>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} reverse />
    </S.StyledTableSection>
  );
}
export default TableSection;

const columns = [
  { name: 'status', label: '상태' },
  { name: 'id', label: '수집대상 (site_id)', isImportant: true },
  { name: 'cid', label: 'cid' },
  { name: 'complexName', label: '복합몰' },
  { name: 'complexBranchName', label: '복합몰 지점' },
  { name: 'storeId', label: '수집대상 (store_id)' },
  { name: 'placeName', label: '장소명' },
  { name: 'placeBranchName', label: '지점명' },
  { name: 'floor', label: '층' },
  { name: 'category', label: 'category' },
  { name: 'collector', label: '수집자' },
  { name: 'timestamp', label: '수집완료시각' },
  { name: 'reviewTimestamp', label: '검수시각' }
];

function formatReportStatus(status) {
  switch (status) {
    case 2:
      return (
        <StatusLabel color="#ffffff" backgroundColor={Red[700]}>
          거절보류
        </StatusLabel>
      );
    case 3:
      return (
        <StatusLabel color="#ffffff" backgroundColor={Grey[700]}>
          판단보류
        </StatusLabel>
      );
    case 4:
      return (
        <StatusLabel color="#ffffff" backgroundColor={Orange[700]}>
          승인보류
        </StatusLabel>
      );
    case 5:
      return (
        <StatusLabel color="#ffffff" backgroundColor={Blue[900]}>
          승인완료
        </StatusLabel>
      );
    default:
      return (
        <StatusLabel color="#000000" backgroundColor={Grey[200]}>
          미검수
        </StatusLabel>
      );
  }
}
