import type {
  Complex,
  TriggerAreaOption,
  Store,
  GeofenceSingle,
  GeofenceGroup,
  Category
} from '@TS/max/campaigns/area';
import type { RegionCondition } from './type';
import { LEVEL_ONE } from './data';

export const schemeRegionCondition = (trigger: TriggerAreaOption): RegionCondition => {
  const areaOption: RegionCondition = {
    tab: '기본',
    condition: {
      lv1: '',
      lv2: { district: [], commercial: [] },
      category: [],
      brand: [],
      store: [],
      complex: []
    },
    geofence: { single: [], group: [], count: 0 }
  };

  if (trigger.store.some((item) => typeof item === 'number' && item === 1)) {
    areaOption.tab = '주거지';
  }

  trigger.area.forEach((sector) => {
    let isSavedLevelOne = false;

    if (sector.type !== 'geofence') {
      if (!isSavedLevelOne) {
        areaOption.condition.lv1 = LEVEL_ONE[sector.lv1];
        isSavedLevelOne = true;
      }
      if (sector?.lv2) {
        areaOption.condition.lv2[sector.type].push(sector.lv2);
      }
      return;
    }

    if (sector.type === 'geofence') {
      if (sector.gfgids && sector.gfgids.length) {
        sector.gfgids.forEach((group) => {
          const geoGroup = group as GeofenceGroup;
          const { name, active } = geoGroup;
          let count: number;
          if ('count' in geoGroup) {
            count = geoGroup.count;
            areaOption.geofence.count += geoGroup.count;
          } else {
            count = geoGroup.circle_num + geoGroup.polygon_num;
            areaOption.geofence.count += geoGroup.circle_num + geoGroup.polygon_num;
          }
          areaOption.geofence.group.push({ name, active, count });
        });
      }

      if (sector.gfids && sector.gfids.length) {
        sector.gfids.forEach((single) => {
          const { name, active, shape_type } = single as GeofenceSingle;
          areaOption.geofence.single.push({ name, active, shape: shape_type });
          areaOption.geofence.count += 1;
        });
      }
    }
  });

  if ('brand' in trigger) {
    trigger.brand.forEach(({ name }) => areaOption.condition.brand.push(name));
  }

  if ('category' in trigger) {
    trigger.category.forEach((item) => {
      areaOption.condition.category.push((item as Category).label);
    });
  }
  if ('complex' in trigger) {
    trigger.complex.forEach((item) => {
      areaOption.condition.complex.push(stringifyStoreName(item as Complex));
    });
  }
  if ('store' in trigger) {
    trigger.store.forEach((item) => {
      if (item === 1) return;
      areaOption.condition.store.push(stringifyStoreName(item as Store));
    });
  }

  return areaOption;
};

function stringifyStoreName(store: Store | Complex): string {
  const { name, branch_name } = store;
  if (!branch_name) return name;
  return `${name} ${branch_name}`;
}
