import { useState } from 'react';
import { storesApi } from '@API/manager';
import TextInputForm from '@F/materialUI/TextInputForm';
import DialogModal from '@F/DialogModal';
import Loading from '@F/Loading';
import ResponseAlert from '@F/ResponseAlert';
import { useFetch, useInput } from '@HOOK/';

function UpdateFpDialog(props) {
  const { open, setOpen, fpid, loadFingerPrintsData } = props;
  const { callApi, isLoading, isError, error } = useFetch();
  const [responseAlertOpen, setResponseAlertOpen] = useState(false);

  const [nextPid, setNextPid] = useInput(null);
  const confirmButtonDidClicked = async () => {
    const response = await callApi(storesApi.patchStoreMap, {
      pid: nextPid,
      cid: 0,
      bid: 0,
      fpid: fpid.fpid
    });
    setResponseAlertOpen(true);
    if (response) {
      loadFingerPrintsData();
      setOpen(false);
    }
  };
  const pidInput = <TextInputForm name="PidInput" label="PID" onChange={setNextPid} value={nextPid} />;

  return (
    <>
      {isLoading && <Loading />}
      <ResponseAlert
        error={isError}
        open={responseAlertOpen}
        handleClose={() => setResponseAlertOpen(false)}
        message={isError ? String(error) : '성공하였습니다.'}
      />
      <DialogModal
        title="fpid 수정"
        description="다른 pid와 매핑하시겠습니까? 매핑 하고자하는 pid를 입력해주세요."
        open={open}
        setOpen={setOpen}
        confirmButtonDidClicked={confirmButtonDidClicked}
      >
        {pidInput}
      </DialogModal>
    </>
  );
}
export default UpdateFpDialog;
