export const kindOptions = {
  all: 0,
  cook: 1,
  scouter: 2,
  inspector: 3
};
export const reversedKindOptions = {
  0: 'all',
  1: 'cook',
  2: 'scouter',
  3: 'inspector'
};

export const typeOptions = ['INTERNAL', 'EXTERNAL', 'CUSTOMER', 'DEMO'];

export const statusOptions = {
  전체: null,
  활성: 1,
  비활성: -1
};
export const reversedStatusOptions = {
  0: '전체',
  1: '활성',
  '-1': '비활성'
};

export const payTypeOptions = {
  POI: 0,
  일당: 1
};
export const reversedPayTypeOptions = {
  0: 'POI',
  1: '일당'
};

export const rankOptions = {
  팀원: null,
  팀장: 1
};
export const reversedRankOptions = {
  null: '팀원',
  1: '팀장'
};
