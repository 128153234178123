import CountUp from 'react-countup';
import { format } from '@FUNC/math';
import * as S from './styles';

type CountBoxProps = {
  title: string;
  description: string;
  currentData: number;
  lastData?: number;
  labelText: string;
  labelBackgroundColor: string;
};

function CountBox({
  title,
  description,
  currentData,
  lastData,
  labelText,
  labelBackgroundColor
}: CountBoxProps): JSX.Element {
  return (
    <S.StyledCountBox>
      <S.Header backgroundColor={labelBackgroundColor}>
        <div>{title}</div>
        <div>{labelText}</div>
      </S.Header>
      <S.Content>
        <div>
          <CountUp end={currentData} separator="," />
          <span>{lastData && ` / ${format(lastData)}`}</span>
        </div>
        <div>{description}</div>
      </S.Content>
    </S.StyledCountBox>
  );
}
export default CountBox;
