import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from '@material-ui/core';

export type Props = {
  label: string;
  name: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabled?: boolean;
};

const CheckForm = ({ label, ...props }: Props): JSX.Element => (
  <FormControlLabel label={label} control={<Checkbox color="primary" {...props} />} />
);

export default CheckForm;
