import { useEffect, useMemo, useState } from 'react';
import NaverMap from '@F/map/NaverMap';
import PinBlue from '@I/map/pin-blue.png';
import PinRed from '@I/map/pin-red.png';
import { Report } from '@TS/cashplace/report';
import * as S from './styles';

type MapSectionProps = {
  report: Report;
  onChangeReportLatLng: (naverLatLng: naver.maps.LatLng) => void;
};

function MapSection({ report, onChangeReportLatLng }: MapSectionProps): JSX.Element {
  const [bounds, setBounds] = useState({
    getSW(): DOMRect {
      return new DOMRect();
    },
    getNE(): DOMRect {
      return new DOMRect();
    }
  });
  const siteMarker = useMemo(
    () => ({
      lng: report.site.lng,
      lat: report.site.lat,
      image: PinBlue,
      size: [13 * 2, 17 * 2]
    }),
    [report.site.lng, report.site.lat]
  );

  const reportMarker = useMemo(
    () =>
      report.lng
        ? {
            lng: report.lng,
            lat: report.lat,
            image: PinRed,
            size: [13 * 2, 17 * 2]
          }
        : null,
    [report.lng, report.lat]
  );

  const [zoom, setZoom] = useState(21);
  const center = useMemo(() => {
    if (!report.lat || !report.lng) return { lat: report.site.lat, lng: report.site.lng };
    return {
      lat: (report.site.lat + report.lat) / 2,
      lng: (report.site.lng + report.lng) / 2
    };
  }, []);

  useEffect(() => {
    if (bounds && report && Object.entries(bounds).length !== 0) {
      const minCoords = bounds.getSW();
      const maxCoords = bounds.getNE();
      if (
        (minCoords.x > report.site.lng ||
          maxCoords.x < report.site.lng ||
          minCoords.y > report.site.lat ||
          maxCoords.y < report.site.lat) &&
        zoom > 10
      ) {
        setZoom((prevState) => prevState - 1);
      }
    }
  }, [bounds, report]);

  return (
    <S.StyledMapSection>
      <NaverMap
        id={report.report_id}
        center={center}
        markers={[siteMarker, reportMarker].filter(Boolean)}
        zoom={zoom}
        triggerEmitBoundary
        onEmitBoundary={setBounds}
        onEmitCoordinates={onChangeReportLatLng}
      />
    </S.StyledMapSection>
  );
}
export default MapSection;
