import { axiosInstancesManager } from '@API/axios';
import { AxiosResponse } from 'axios';
import { Notice, NoticeFilterValues, NoticeFormValues } from '@TS/max/manageNotice';

export type GetNoticesResponse = {
  count: number;
  items: Notice[];
};

class NoticeApi {
  // 전체/단일 공지사항 정보 조회
  getAll = (params?: NoticeFilterValues): Promise<AxiosResponse<GetNoticesResponse>> => {
    if (params === undefined) {
      return axiosInstancesManager.manager.get('/notification');
    }

    return axiosInstancesManager.manager.get('/notification', { params });
  };

  get(id: number): Promise<AxiosResponse<Notice>> {
    return axiosInstancesManager.manager.get(`/notification/${id}`);
  }

  // 공지사항 정보 등록 (모든 필드 전달)
  post(data: NoticeFormValues): Promise<AxiosResponse<Notice>> {
    return axiosInstancesManager.manager.post('/notification', data);
  }

  // 공지사항 정보 수정 (일부 필드 수정)
  patch(id: number, data: Partial<Notice>): Promise<AxiosResponse<Notice>> {
    return axiosInstancesManager.manager.patch(`/notification/${id}`, data);
  }

  delete(id: number): Promise<AxiosResponse> {
    return axiosInstancesManager.manager.delete(`/notification/${id}`);
  }
}

const noticeApi = new NoticeApi();

export default noticeApi;
