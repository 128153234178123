import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import { Editor } from '@toast-ui/react-editor';
import { Button, colors } from '@material-ui/core';
import ReactLoading from 'react-loading';

import { RecruitForm } from '@TS/homepage/recruit';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import ConfirmModal from '@F/modal/ConfirmModal';
import MarkdownEditor from './recruitPositionForm/MarkdownEditor';
import RecruitPositionFormFields from './recruitPositionForm/RecruitPositionFormFields';
import { selectRecruits } from './redux/selector';
import { actions } from './redux/slice';

const formSchema = Yup.object().shape({
  title: Yup.string().min(4, '제목은 최소 4자 이상이어야 합니다.').required('채용 제목을 입력해주세요.'),
  recruit_type: Yup.string().min(1).required('채용 정보 유형을 선택해주세요.'),
  job: Yup.string().min(1).required('채용 포지션을 선택해주세요.'),
  career: Yup.string().min(1).required('자격 요건(경력)을 선택해주세요.'),
  start_date: Yup.string().required()
});

export default function RecruitPositionForm(): JSX.Element {
  const editorRef = useRef<Editor>(null);

  const { focusedRecruit } = useSelector(selectRecruits);
  const [isOpenCancelConfirmForm, setIsOpenConfirmCancelForm] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openCancelConfirmModal = () => setIsOpenConfirmCancelForm(true);
  const closeCancelConfirmModal = () => setIsOpenConfirmCancelForm(false);
  const popUpSubmitToastErrorMessage = (isValid: boolean, errors: any) => {
    if (!isValid) {
      // 현재 토스트 UI는 line break 미지원
      const errorMessages = Object.entries(errors)
        .map(([fieldName, errorMessage]) => `${fieldName} ${errorMessage}`)
        .join('\n');

      toastErrorMessage(errorMessages);
    }
  };

  const handleCancelRecruitForm = () => {
    dispatch(actions.setFocused(null));
    navigate('/homepage/manage-recruits', { replace: true });
  };

  const handleSubmitRecruitForm = async (
    values: RecruitForm,
    {
      setSubmitting
    }: {
      setSubmitting: (isSubmitting: boolean) => void;
    }
  ) => {
    const formValues: RecruitForm = {
      ...values,
      content: editorRef.current?.getInstance().getHTML() as string
    };
    const isEditingRecruit = !!focusedRecruit;

    setSubmitting(true);
    const callback = (isSuccess: boolean) => {
      if (isSuccess) {
        navigate('/homepage/manage-recruits/preview', { replace: true });
        const successKind = isEditingRecruit ? '수정' : '등록';
        toastSuccessMessage(`입력하신 채용 정보가 성공적으로 ${successKind}되었습니다.`);
        setSubmitting(false);
      }
    };

    dispatch(
      isEditingRecruit
        ? actions.updateRecruit({ id: focusedRecruit.id, data: formValues, callback })
        : actions.createRecruit({ data: formValues, callback })
    );
  };

  const {
    title = '',
    content = '',
    recruit_type = '',
    job = '',
    career = '',
    start_date = moment().format('YYYY-MM-DD'),
    end_date = ''
  } = focusedRecruit || {};

  const initialFormValues = {
    title,
    content,
    recruit_type,
    job,
    career,
    start_date,
    end_date
  } as RecruitForm;

  return (
    <StyledRecruitPositionForm>
      <Formik initialValues={initialFormValues} onSubmit={handleSubmitRecruitForm} validationSchema={formSchema}>
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          touched
        }: FormikProps<RecruitForm>) => (
          <form onSubmit={handleSubmit}>
            <RecruitPositionFormFields
              errors={errors}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              touched={touched}
            />
            <MarkdownEditor editorRef={editorRef} initialValue={focusedRecruit?.content} />
            <div className="buttons">
              <Button variant="contained" color="secondary" onClick={openCancelConfirmModal}>
                취소
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                onClick={() => popUpSubmitToastErrorMessage(isValid, errors)}
              >
                {isSubmitting ? <ReactLoading type="cylon" color={colors.blue[600]} height={20} width={20} /> : '저장'}
              </Button>
            </div>
          </form>
        )}
      </Formik>
      <ConfirmModal
        isOpen={isOpenCancelConfirmForm}
        title="취소"
        subTitle="채용 공고 작성을 취소하시겠습니까?"
        onPermit={handleCancelRecruitForm}
        onCancel={closeCancelConfirmModal}
      />
    </StyledRecruitPositionForm>
  );
}

const StyledRecruitPositionForm = styled.div`
  .buttons {
    display: flex;

    button {
      margin-right: 10px;
    }
  }
`;
