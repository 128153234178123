import { useState, useCallback, useEffect } from 'react';
import { HeadCell, IsOpenedAllType, isSortableHeadCell, SortableId } from '@TS/store/cidTable';
import { Complex } from '@TS/store/complex';
import CIDTableHead from './TableHead';
import { findPivotHead, sortComplexesById } from './sortFunction';
import { appendIsOpenFieldsToComplexes } from './isOpenFunction';
import TableBody from './TableBody';
import * as S from './index.styles';
import { SelectedCID } from '..';

export interface SelectCIDFunction {
  (e: React.MouseEvent, cid: Complex['cid'], name: Complex['name'], isConfirm?: boolean): void;
}

interface Props {
  complexes: Complex[];
  setSelectedCID: React.Dispatch<React.SetStateAction<SelectedCID | null>>;
  selectedCID: SelectedCID | null;
  confirmCID: (cid: Complex['cid'], name: Complex['name']) => void;
}

const HEAD_CELLS: HeadCell[] = [
  { label: 'cid', sortOrder: null, id: 'cid' },
  { label: '이름', sortOrder: null, id: 'name' },
  { label: '지점명', id: 'branch_name' },
  { label: '주소', id: 'addr' }
];

const NOT_SORTABLE_HEAD_CELLS = HEAD_CELLS.filter((headCell) => !isSortableHeadCell(headCell));

const CIDTable = ({ complexes, setSelectedCID, selectedCID, confirmCID }: Props): JSX.Element => {
  const [headCells, setHeadCells] = useState<HeadCell[]>(HEAD_CELLS);
  const [isOpenedAll, setIsOpenedAll] = useState<IsOpenedAllType>(true);
  const [complexesWithToggle, setComplexesWithToggle] = useState<Complex[]>([]);
  const [, setIsUpdated] = useState(false); // NOTE: 단지 리렌더링용으로만 존재함

  const sortComplexes = useCallback(
    (headCells: HeadCell[]) => {
      try {
        const { sortOrder, id } = findPivotHead(headCells);
        if (sortOrder !== null) {
          const newSortedComplexes = sortComplexesById(complexesWithToggle, id, sortOrder);
          setComplexesWithToggle(newSortedComplexes);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [complexesWithToggle]
  );

  const sortComplexesByHeadCell = useCallback(
    (targetId: SortableId) => {
      const sortedHeadCell: HeadCell[] = headCells
        .filter(isSortableHeadCell)
        .map((headCell) =>
          headCell.id !== targetId
            ? { ...headCell, sortOrder: null }
            : { ...headCell, sortOrder: headCell.sortOrder === 'descending' ? 'ascending' : 'descending' }
        );

      const newHeadCell = [...sortedHeadCell, ...NOT_SORTABLE_HEAD_CELLS];

      setHeadCells(newHeadCell);
      sortComplexes(sortedHeadCell);
    },
    [sortComplexes, headCells]
  );

  const toggleUpdate = useCallback(() => {
    setIsUpdated((prev) => !prev);
  }, []);

  const selectCID: SelectCIDFunction = useCallback(
    (e, cid, name, isConfirm) => {
      e.stopPropagation();
      if (isConfirm) confirmCID(cid, name);
      else setSelectedCID(cid === selectedCID?.cid ? null : { cid, name });
    },
    [confirmCID, selectedCID, setSelectedCID]
  );

  // NOTE: 최초 진입 시
  useEffect(() => {
    const OPEN_ALL_TABLE = true;
    setComplexesWithToggle(appendIsOpenFieldsToComplexes(complexes, OPEN_ALL_TABLE));
  }, [complexes]);

  useEffect(() => {
    if (typeof isOpenedAll === 'boolean' && complexesWithToggle) {
      const newComplexes = appendIsOpenFieldsToComplexes(complexesWithToggle, isOpenedAll);
      setComplexesWithToggle(newComplexes);
      toggleUpdate();
    }
  }, [complexesWithToggle, isOpenedAll, toggleUpdate]);

  return (
    <S.TableWrapper>
      <CIDTableHead
        sortingOrder={sortComplexesByHeadCell}
        headCells={headCells}
        isOpenedAll={isOpenedAll}
        setIsOpenedAll={setIsOpenedAll}
      />
      <TableBody
        complexes={complexesWithToggle}
        setComplexes={setComplexesWithToggle}
        setUpdate={toggleUpdate}
        selectedCID={selectedCID}
        selectCID={selectCID}
      />
    </S.TableWrapper>
  );
};

export default CIDTable;
