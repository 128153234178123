import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Badge } from 'loplat-ui';
import { selectRecruits } from '../redux/selector';

const JOB_ENG_TO_KOR = {
  DATA: '데이터',
  DEVELOPMENT: '개발',
  DESIGN: '디자인',
  BUSINESS: '비즈니스',
  SALES: '판매',
  HR: '인사'
};

export default function HomepageRecruitView(): JSX.Element | null {
  const { focusedRecruit } = useSelector(selectRecruits);

  if (!focusedRecruit) {
    return null;
  }

  const { job, title, start_date, end_date, recruit_type, career, content } = focusedRecruit;

  return (
    <StyledPreview>
      <StyledHead>
        <p>{job ? JOB_ENG_TO_KOR[job] : ''}</p>
        <h2>{title}</h2>
        <div className="row">
          <p>
            {start_date} ~ {end_date}
          </p>
          <div className="tags">
            <Badge
              text={recruit_type === 'ALWAYS' ? '상시' : '수시'}
              type="round"
              size="normal"
              className="tag"
              color={recruit_type === 'ALWAYS' ? 'success' : 'primary'}
            />
            <Badge
              text={career === 'NEWCOMER' ? '신입' : '경력'}
              variant="outlined"
              type="round"
              className="tag"
              color={career === 'NEWCOMER' ? 'success' : 'primary'}
            />
          </div>
        </div>
      </StyledHead>
      <StyledContent>
        <span dangerouslySetInnerHTML={{ __html: content || '' }} />
      </StyledContent>
    </StyledPreview>
  );
}

const StyledPreview = styled.div`
  font-family: 'NanumSquareRound', sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: regular;

  padding: 0;
  margin: 0;
  border: 0;
  font-family: 'NanumSquareRound', 'Montserrat', sans-serif;

  a {
    color: inherit;
    text-decoration: none;
  }

  box-sizing: border-box;
  word-break: keep-all;

  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

  * {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  ol,
  ul {
    list-style: none;
  }

  textarea {
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none;
    font-family: inherit;
  }

  border-radius: 4px;
  box-shadow: 0 0 1px #000;
  overflow: scroll;
  max-height: calc(100vh - 320px);
  padding: 30px;
  text-align: left;

  .button {
    background: inherit;
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    overflow: visible;
    cursor: pointer;
    font-size: 100%;
    font-family: inherit;
    text-transform: none;
    outline: none;
    margin: 0 auto;
    box-shadow: 0 2px 12px 0 rgba(64, 166, 253, 0.5);
  }
`;

const StyledHead = styled.section`
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  line-height: 1.5;

  & > p {
    color: palette.$blueScale500;
  }

  & > h2 {
    color: palette.$black500;
    font-size: 2.5rem;
  }

  .row {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;

    & > p {
      color: palette.$black300;
      margin-right: 0.5rem;
    }

    .tags {
      display: flex;

      .tag {
        margin-left: 0.5rem;
      }
    }
  }
`;

const StyledContent = styled.section`
  margin: 4rem 0;
  color: palette.$black500;

  line-height: 24px;
  font-size: 1rem;

  a {
    color: rgba(64, 166, 253);
    text-decoration: underline;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    padding: 0.5rem 0;
  }
  ul {
    list-style: disc inside;

    margin-left: 1rem;
  }
`;
