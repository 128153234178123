import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import TextInputForm from '@F/materialUI/TextInputForm';
import { Button, Checkbox } from '@material-ui/core';
import SelectForm from '@F/materialUI/SelectForm';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CategoryModal from '@F/modal/CategoryModal';
import Loading from '@F/Loading';
import { toJS } from 'mobx';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DatePickers from '@F/materialUI/datePicker/DatePickers';
import { useDispatch, useSelector } from 'react-redux';
import { reviewOptions } from '@P/ClosingManager/options';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import { useInput } from '@HOOK/';
import { actions } from '@/redux/closingManager/state';
import { valuesStore } from '@/mobX/values';
import * as S from './styles';

function Filter() {
  const classes = S.useStyles();

  const [complexId, setComplexId] = useInput();
  const ComplexIdInput = (
    <TextInputForm name="ComplexIdInput" label="Complex ID(CID)" value={complexId} onChange={setComplexId} />
  );
  const params = useSelector((state) => state.closingManager.params);

  const [siteId, setSiteId] = useInput();
  const SiteIdInput = (
    <TextInputForm name="SiteIdInput" label="수집대상 (Site ID)" value={siteId} onChange={setSiteId} />
  );

  const [storeId, setStoreId] = useInput();
  const StoreIdInput = (
    <TextInputForm name="StoreIdInput" label="수집대상 (Store ID)" value={storeId} onChange={setStoreId} />
  );
  const [complexName, setComplexName] = useInput();
  const ComplexNameInput = (
    <TextInputForm name="ComplexNameInput" label="복합몰 장소명" value={complexName} onChange={setComplexName} />
  );
  const [complexBranchName, setComplexBranchName] = useInput();
  const ComplexBranchNameInput = (
    <TextInputForm
      name="ComplexBranchNameInput"
      label="복합몰 지점명"
      value={complexBranchName}
      onChange={setComplexBranchName}
    />
  );
  const [placeName, setPlaceName] = useInput();
  const PlaceNameInput = (
    <TextInputForm name="PlaceNameInput" label="장소명" value={placeName} onChange={setPlaceName} />
  );
  const [placeBranchName, setPlaceBranchName] = useInput();
  const PlaceBranchNameInput = (
    <TextInputForm name="PlaceBranchNameInput" label="지점명" value={placeBranchName} onChange={setPlaceBranchName} />
  );

  const [collector, setCollector] = useState();
  const collectorOptions = useMemo(
    () => toJS(valuesStore.collectors).map((user) => user.username),
    [valuesStore.collectors]
  );

  const CollectorInput = (
    <Autocomplete
      id="ChargeInput"
      options={collectorOptions}
      noOptionsText="수집자를 입력해주세요"
      onInputChange={(_, value) => setCollector(value)}
      renderInput={(params) => <TextField {...params} label="수집자" variant="outlined" />}
    />
  );
  const [reviewState, setReviewState] = useInput('전체');
  const ReviewStateSelect = (
    <SelectForm
      label="검수상태"
      options={Object.keys(reviewOptions)}
      value={reviewState}
      onSelect={setReviewState}
      name="ReviewStateSelect"
    />
  );

  const [category, setCategory] = useState({ korName: '', code: '' });
  const [isCategoryCodeModalVisible, setIsCategoryCodeModalVisible] = useState(false);
  const CategoryInput = (
    <TextInputForm
      name="CategoryInput"
      label="카테고리 (category)"
      value={category.korName}
      onClick={() => setIsCategoryCodeModalVisible(!isCategoryCodeModalVisible)}
      readOnly
    />
  );
  const CategoryEmptyButton = (
    <Button
      size="small"
      variant="text"
      onClick={() => {
        setCategory({
          korName: '',
          code: ''
        });
      }}
    >
      카테고리 비우기
    </Button>
  );
  const [floor, setFloor] = useInput();
  const FloorInput = <TextInputForm name="FloorInput" label="층" value={floor} onChange={setFloor} />;

  const [period, setPeriod] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });
  const [isSearchPeriod, setIsSearchPeriod] = useState(true);
  const [searchByPeriodOption, setSearchByPeriodOption] = useState('collected');
  useEffect(() => {
    if (['판단보류', '미검수', '승인완료', '전체'].includes(reviewState)) {
      setSearchByPeriodOption('collected');
    } else {
      setSearchByPeriodOption('reviewed');
    }
  }, [reviewState]);
  const confirmPeriod = useCallback((dates) => {
    setPeriod(() => ({
      startDate: dates[0],
      endDate: dates[1]
    }));
  }, []);
  const TimeStampInput = <DatePickers onEmitDate={confirmPeriod} />;
  const SearchPeriodOption = (
    <FormControlLabel
      control={
        <Checkbox color="primary" checked={isSearchPeriod} onChange={() => setIsSearchPeriod(!isSearchPeriod)} />
      }
      label="기간 검색"
    />
  );
  const SearchByPeriodOptionRadio = (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="gender"
        name="search"
        row
        value={searchByPeriodOption}
        onChange={(e) => setSearchByPeriodOption(e.target.value)}
      >
        <FormControlLabel value="collected" control={<Radio />} label="수집완료시각" />
        <FormControlLabel value="reviewed" control={<Radio />} label="검수시각" />
      </RadioGroup>
    </FormControl>
  );

  const [count, setCount] = useInput('10');
  const CountSelect = (
    <SelectForm
      label="개수"
      options={['10', '50', '100', '200']}
      value={count}
      onSelect={setCount}
      disabled={false}
      name="CountSelect"
    />
  );

  // fetch data
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.closingManager.isLoading);

  const fetchClosingData = () => {
    dispatch(
      actions.setValue('params', {
        ...params,
        complexId: complexId || null,
        siteId: siteId || null,
        storeId: storeId || null,
        complexName: complexName || null,
        complexBranchName: complexBranchName || null,
        placeName: placeName || null,
        placeBranchName: placeBranchName || null,
        collector: collector || null,
        reviewState: reviewOptions[reviewState],
        categoryCode: category.code || null,
        floor: floor || null,
        startDate: isSearchPeriod ? period.startDate : null,
        endDate: isSearchPeriod ? period.endDate : null,
        searchByPeriodOption
      })
    );

    dispatch(actions.setValue('perPage', Number(count)));
    dispatch(actions.setPage(1));
    dispatch(actions.fetchClosingData());
  };

  useEffect(() => {
    fetchClosingData();
  }, []);

  return (
    <S.StyledFilter
      onKeyDown={(event) => {
        if (event.key === 'Enter') fetchClosingData();
      }}
    >
      {isLoading && <Loading />}
      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {ComplexIdInput}
        </Grid>
        <Grid item xs>
          {SiteIdInput}
        </Grid>
        <Grid item xs>
          {StoreIdInput}
        </Grid>
      </S.Row>

      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {ComplexNameInput}
        </Grid>
        <Grid item xs>
          {CollectorInput}
        </Grid>
        <Grid item xs>
          {ReviewStateSelect}
        </Grid>
      </S.Row>

      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {ComplexBranchNameInput}
        </Grid>
        <Grid item xs>
          {CategoryInput}
          {CategoryEmptyButton}
          <CategoryModal
            open={isCategoryCodeModalVisible}
            setOpen={setIsCategoryCodeModalVisible}
            setValue={setCategory}
          />
        </Grid>
        <Grid item xs>
          {FloorInput}
        </Grid>
      </S.Row>

      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {PlaceNameInput}
        </Grid>
        <Grid item xs>
          {TimeStampInput}
          {SearchPeriodOption}
          {SearchByPeriodOptionRadio}
        </Grid>
        <Grid item xs>
          {CountSelect}
        </Grid>
      </S.Row>

      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {PlaceBranchNameInput}
        </Grid>
        <Grid item xs />
        <Grid item xs />
      </S.Row>

      <S.ButtonRow>
        <Button variant="contained" color="primary" onClick={() => fetchClosingData()}>
          불러오기
        </Button>
      </S.ButtonRow>
    </S.StyledFilter>
  );
}
export default Filter;
