import { call, put, takeLatest } from 'redux-saga/effects';
import type { AxiosResponse } from 'axios';
import { maxCompaniesApi } from '@API/max';
import type { GetCompaniesResponse } from '@TS/max/company/api';
import { sortByName } from '@FUNC/sort';
import { actions } from './slice';

function* fetchCompanies() {
  try {
    yield put(actions.fetchStart());
    const { data }: AxiosResponse<GetCompaniesResponse> = yield call(maxCompaniesApi.getCompanies);
    const sortedCompanies = [...data.companies].sort(sortByName);
    yield put(actions.fetchSuccess({ companies: sortedCompanies }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(actions.fetchFailure({ error }));
    }
  }
}

export default function* sagaReducer(): Generator {
  yield takeLatest(actions.fetchCompanies, fetchCompanies);
}
