import styled from 'styled-components';

export const StyledLoading = styled.div`
  display: flex;

  height: 100vh;
  flex-flow: row;
  align-items: center;
  justify-content: center;
`;
