import { all, call, put, takeLeading, select } from 'redux-saga/effects';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { actions, types } from './state';
import {
  fetchFingerprints,
  updateAutomaticallyCollectedFingerprint,
  updateAutomaticallyCollectedFingerprintValidation
} from './api';

export function* fetchDatas() {
  const { page, perPage, params } = yield select((state) => ({
    page: state.manualMapping.page,
    perPage: state.manualMapping.perPage,
    params: state.manualMapping.params
  }));
  yield put(actions.setLoading(true));
  try {
    const data = yield call(fetchFingerprints, page, perPage, params);
    yield put(actions.setNewFingerprints(data.items));
    yield put(actions.setValue('count', data.count));
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* patchDataValidation(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(updateAutomaticallyCollectedFingerprintValidation, action.fingerprint.id, {
      state: action.state
    });
    yield put(actions.deleteFingerprint(action.fingerprint));
    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e.response.data.message);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* patchData(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(updateAutomaticallyCollectedFingerprint, action.fingerprint.id, action.params);
    yield put(actions.deleteFingerprint(action.fingerprint));
    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e.response.data.message);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* sagaReducer() {
  yield all([
    takeLeading(types.FETCH_FINGERPRINTS, fetchDatas),
    takeLeading(types.PATCH_FINGERPRINT_VALIDATION, patchDataValidation),
    takeLeading(types.PATCH_FINGERPRINT, patchData)
  ]);
}
