import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { TableCell } from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';

import { toggleOrder } from '@FUNC/order';
import { PlaceTodoSortableField } from '@TS/cook/api/placetodos';
import TableHeadLabel from './tableTitleCell/TableHeadLabel';
import { selectParams } from '../redux/selector';
import { actions } from '../redux/slice';

interface Props {
  title: string;
  orders?: {
    fields: CamelToSnake<PlaceTodoSortableField>[];
    defaultOrderType: OrderType;
  };
  isTableItemsCollapsed: boolean;
  toggleTableItemsCollapsed: () => void;
}

export default function TableHeadCell({
  title,
  orders,
  isTableItemsCollapsed,
  toggleTableItemsCollapsed
}: Props): JSX.Element | null {
  const params = useSelector(selectParams);
  const dispatch = useDispatch();

  if (params.orders === undefined) return null;

  let cell;

  if (orders) {
    const recentOrder = params.orders[0];
    const [recentOrderField, recentOrderType] = recentOrder?.split(' ') as [PlaceTodoSortableField, OrderType];

    const isOrderedCell = recentOrderField === orders.fields[0];

    const handleClickSort = (e: React.MouseEvent) => {
      e.stopPropagation();

      const newOrders = orders.fields.map(
        (field): `${CamelToSnake<PlaceTodoSortableField>} ${OrderType}` =>
          `${field as CamelToSnake<PlaceTodoSortableField>} ${
            isOrderedCell ? toggleOrder(recentOrderType) : orders.defaultOrderType
          }`
      );

      const newParams = { ...params, orders: newOrders };

      dispatch(actions.fetchUnCollectedComplexPlaceTodos(newParams));
    };

    cell = (
      <TableHeadLabel
        value={title}
        direction={isOrderedCell ? (recentOrderType.toLowerCase() as Lowercase<OrderType>) : undefined}
        isActive={isOrderedCell}
        onClick={handleClickSort}
      />
    );
  } else {
    const KeyboardArrowIcon = isTableItemsCollapsed ? KeyboardArrowUp : KeyboardArrowDown;

    cell = (
      <StyledTableItemsCollapsingToggler isTableItemsCollapsed={isTableItemsCollapsed}>
        <KeyboardArrowIcon onClick={toggleTableItemsCollapsed} />
      </StyledTableItemsCollapsingToggler>
    );
  }

  return (
    <TableCell key={title} align="center">
      {cell}
    </TableCell>
  );
}

const StyledTableItemsCollapsingToggler = styled.div<{ isTableItemsCollapsed: boolean }>`
  cursor: pointer;

  svg {
    color: ${({ isTableItemsCollapsed }) => (isTableItemsCollapsed ? '#aaa' : '#555')};
  }

  :hover {
    svg {
      color: #000;
    }
  }
`;
