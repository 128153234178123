import Filter from './Filter';
import TableSection from './tableSection/TableSection';
import * as S from './styles';

function Report(): JSX.Element {
  return (
    <S.StyledReport>
      <Filter />
      <br />
      <TableSection />
    </S.StyledReport>
  );
}
export default Report;
