/* eslint-disable vars-on-top */
import React, { useCallback, useState, createContext, useContext, useEffect } from 'react';
import { usePagination } from '@HOOK/usePagination';
import { tableSort } from '@FUNC/order';
import { toastErrorMessage } from '@FUNC/toast';
import noticeApi from '@API/manager/notification';
import { Notice, NoticeFilterValues, NoticeFormValues, NoticeTableFields } from '@TS/max/manageNotice';

import { NOTICE_TABLE_INITIAL_ORDER_BY } from './constant';

type isSuccess = boolean;

type SortNotices = (name: NoticeTableFields, orderType: OrderType) => void;
type FetchNotices = (params: NoticeFilterValues) => Promise<isSuccess>;
type TogglePublishNotice = (notice: Notice) => Promise<isSuccess>;
type DeleteNotice = (notice: Notice) => Promise<isSuccess>;
type EditNotice = (notice: Notice, noticeFormValues: NoticeFormValues) => Promise<isSuccess>;
type CreateNotice = (noticeFormValues: NoticeFormValues) => Promise<isSuccess>;

type ManageNoticeContextType = {
  notices: Notice[];
  pagedNotices: Notice[];
  sortNotices: SortNotices;
  togglePublishApiStatuses: { [key in number]: ApiStatus };
  togglePublishNotice: TogglePublishNotice;
  fetchApiStatus: ApiStatus;
  fetchNotices: FetchNotices;
  editApiStatus: ApiStatus;
  editNotice: EditNotice;
  deleteApiStatus: ApiStatus;
  deleteNotice: DeleteNotice;
  createApiStatus: ApiStatus;
  createNotice: CreateNotice;
};

const ManageNoticeContext = createContext<ManageNoticeContextType | undefined>(undefined);

export function ManageNoticeProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const [notices, setNotices] = useState<Notice[]>([]);
  const [pagedNotices, setPagedNotices] = useState<Notice[]>([]);
  const [fetchApiStatus, setFetchStatus] = useState<ApiStatus>('idle');
  const [togglePublishApiStatuses, setTogglePublishApiStatuses] = useState<{ [key in number]: ApiStatus }>({});
  const [deleteApiStatus, setDeleteApiStatus] = useState<ApiStatus>('idle');
  const [createApiStatus, setCreateApiStatus] = useState<ApiStatus>('idle');
  const [editApiStatus, setEditApiStatus] = useState<ApiStatus>('idle');
  const pagination = usePagination();

  useEffect(() => {
    const pagedNotices = pagination.pageSlice(notices);
    setPagedNotices(pagedNotices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, notices]);

  useEffect(() => {
    fetchNotices({
      status: null,
      type: null
    });
  }, []);

  const fetchNotices: FetchNotices = useCallback(
    async (params) => {
      try {
        setFetchStatus('loading');

        const {
          data: { items: notices }
        } = await noticeApi.getAll(params);

        setFetchStatus('success');

        const sortedNotices = tableSort<Notice>(
          notices,
          NOTICE_TABLE_INITIAL_ORDER_BY.name,
          NOTICE_TABLE_INITIAL_ORDER_BY.type
        );
        const pagedNotices = pagination.pageSlice(sortedNotices);

        setNotices(sortedNotices);
        setPagedNotices(pagedNotices);

        pagination.setTotalCount(notices.length);
        pagination.setPage(1);
        return true;
      } catch (e) {
        const error = e as Error;
        setFetchStatus('idle');
        toastErrorMessage(error.message);
        return false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const sortNotices: SortNotices = useCallback(
    (name, orderType) => setNotices((prev) => tableSort<Notice>([...prev], name, orderType)),
    []
  );

  const togglePublishNotice: TogglePublishNotice = useCallback(async (notice) => {
    const { id, status } = notice;
    try {
      setTogglePublishApiStatuses((prev) => ({ ...prev, [id]: 'loading' }));
      const { data: updated } = await noticeApi.patch(id, { status: status === 'PUBLISH' ? 'PENDING' : 'PUBLISH' });
      setTogglePublishApiStatuses((prev) => ({ ...prev, [id]: 'idle' }));
      setNotices((prev) => prev.map((notice) => (notice.id === id ? updated : notice)));
      return true;
    } catch (e) {
      const error = e as Error;
      setTogglePublishApiStatuses((prev) => ({ ...prev, [id]: 'idle' }));
      toastErrorMessage(error.message);
      return false;
    }
  }, []);

  const deleteNotice: DeleteNotice = useCallback(async (notice) => {
    const { id } = notice;
    try {
      setDeleteApiStatus('loading');
      const { data: updated } = await noticeApi.patch(id, { status: 'DELETED' });
      setDeleteApiStatus('idle');
      setNotices((prev) => prev.map((notice) => (notice.id === id ? updated : notice)));
      return true;
    } catch (e) {
      setDeleteApiStatus('idle');
      const error = e as Error;
      toastErrorMessage(error.message);
      return false;
    }
  }, []);

  const editNotice: EditNotice = useCallback(async (notice, noticeFormValues) => {
    const { id } = notice;
    try {
      setEditApiStatus('loading');
      const { data: updated } = await noticeApi.patch(id, { ...noticeFormValues, status: 'PENDING' });
      setEditApiStatus('idle');
      setNotices((prev) => prev.map((notice) => (notice.id === id ? updated : notice)));
      return true;
    } catch (e) {
      const error = e as Error;
      setEditApiStatus('idle');
      toastErrorMessage(error.message);
      return false;
    }
  }, []);

  const createNotice: CreateNotice = useCallback(async (noticeFormValues) => {
    try {
      setCreateApiStatus('loading');
      const { data: created } = await noticeApi.post(noticeFormValues);
      setCreateApiStatus('idle');
      setNotices((prev) => [created, ...prev]);
      return true;
    } catch (e) {
      const error = e as Error;
      setCreateApiStatus('idle');
      toastErrorMessage(error.message);
      return false;
    }
  }, []);

  return (
    <ManageNoticeContext.Provider
      value={{
        sortNotices,
        fetchNotices,
        togglePublishNotice,
        deleteNotice,
        createNotice,
        editNotice,
        fetchApiStatus,
        deleteApiStatus,
        togglePublishApiStatuses,
        createApiStatus,
        pagedNotices,
        editApiStatus,
        notices
      }}
    >
      {children}
    </ManageNoticeContext.Provider>
  );
}

export function useManageNotice(): ManageNoticeContextType {
  const context = useContext(ManageNoticeContext);

  if (!context) {
    throw new Error('useManageNotice must be used in ManageNoticeProvider');
  }

  return context;
}
