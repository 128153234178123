import { TextField, FormControl, InputLabel, MenuItem, Select, Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { PrimitiveLogFilterValues, LOG_TYPES } from '../data';

export default function Log(): JSX.Element {
  const {
    values: { log },
    setValues,
    touched,
    setTouched
  } = useFormikContext<PrimitiveLogFilterValues>();

  const handleChangeLogType = (e: any) => {
    const type = e.target.value;
    setValues((prev) => ({ ...prev, log: { ...log, type } }));
  };

  const handleChangeLogValue = (e: any) => {
    const { value } = e.target;

    setTouched({ ...touched, log: { value: true } }, false);
    setValues((prev) => ({ ...prev, log: { ...log, value } }));
  };

  return (
    <>
      <Grid item xs={4}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>조회 대상</InputLabel>
          <Select name="log_type" label="조회 대상" value={log.type} onChange={handleChangeLogType}>
            {LOG_TYPES.map((option) => (
              <MenuItem value={option.name} key={option.name}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="log_value"
          label="조회 값 입력"
          value={log.value}
          variant="outlined"
          onChange={handleChangeLogValue}
          fullWidth
        />
      </Grid>
    </>
  );
}
