import { fingerprintsApi } from '@API/manager';
import { fileNameFromContentDisposition } from '@FUNC/download';

export function fetchFingerprints(page, perPage, params) {
  return fingerprintsApi.getFingerprints({ page, perPage, ...params }).then((response) => response.data);
}

export function fetchFingerprintsCsv(page, perPage, params) {
  return fingerprintsApi.getFingerprintsCsv({ page, perPage, ...params }).then((response) => {
    const { data, headers } = response;

    const fileName = fileNameFromContentDisposition(headers['content-disposition']);

    return {
      data,
      fileName
    };
  });
}

export function fetchFingerprint(fpid) {
  return fingerprintsApi.getFingerprint(fpid).then((response) => {
    if (Object.keys(response.data).length === 0) return null;
    return response.data;
  });
}

export function patchImageValidation(fpid, status) {
  return fingerprintsApi.patchImageValidation(fpid, status).then((response) => response);
}
