import styled from 'styled-components';

export const StyledDetail = styled.div``;

export const ButtonRow = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;

  margin-top: 10px;
`;
