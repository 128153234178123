import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  button: {
    marginLeft: 10
  }
}));

export const StyledSiteMapTableWrapper = styled.div`
  height: 100%;
  padding: 10px;

  background-color: ${(props) => props.theme.palette.common.white};
  border: 1px solid ${(props) => props.theme.palette.grey[300]};
  border-radius: 10px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

export const CountContainer = styled.div`
  display: flex;
  flex-flow: column;
`;

export const NonChargedCountLabel = styled.p`
  margin: 0;
  color: ${(props) => props.theme.palette.primary.deepDark};
`;

export const ChargedCountLabel = styled.p`
  margin: 0;
  color: ${(props) => props.theme.palette.text.hint};
`;

export const FullCountLabel = styled.span`
  font-weight: bold;
`;

export const RealCountLabel = styled.span`
  font-weight: bold;
  color: ${(props) => props.theme.palette.text.primary};
`;

export const Content = styled.div`
  height: calc(100% - 70px);
`;

export const TableWrapper = styled.div`
  height: calc(100% - 40px);
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 5px;
  height: 40px;
`;

export const FloorButton = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  color: ${(props) => props.theme.palette.text.primary};
  text-decoration: underline;
`;

export const TableCountRow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 10px 0 0;
`;
