export default {
  SECRET_KEY: process.env.SECRET_KEY || 'RJbLBGHMsfgZwXF8Fad-&RQFg@DL8^A3',
  FIREBASE: {
    CASHPLACE: {
      email: 'dev@loplat.com',
      password: 'loplat1224',
      api_key:
        'AAAA0jmyHdc:APA91bHHrYz5QKhezSm49hAXKY_Hf7UW1IyGAE5XWS96xnBwMXL4ZAHtFqQIFZnd5ARXzxscVZTvMhKf9GGKCnGVzZqXwb7mqh_a6waOEuNigi_UxXS-7-9JQreMI8x2r1jgJavM-7MA'
    },
    MANAGER: {
      email: 'dev@loplat.com',
      password: 'loplat1224'
    }
  }
};

/*

1. back에서 firebase token 요청

*/
