import styled from 'styled-components';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import {
  LocationOnRounded,
  StorefrontRounded,
  BusinessTwoTone,
  HighlightOff,
  PersonPinCircleRounded,
  WifiRounded,
  SupervisorAccountRounded,
  LocalAtm,
  HomeWork,
  LocalAirport,
  Bookmarks
} from '@material-ui/icons';

import useAuth from '@HOOK/useAuth';
import { useSideMenu } from '@HOOK/useSideMenu';
import { isPermitted } from '@FUNC/permission';
import MenuListItem from './MenuListItem';

export const menuIcons = {
  즐겨찾기: <Bookmarks />,
  수집: <LocationOnRounded />,
  캐시플레이스: <LocalAtm />,
  복합몰: <BusinessTwoTone />,
  FP: <WifiRounded />,
  쿡: <PersonPinCircleRounded />,
  폐점: <HighlightOff />,
  홈페이지: <HomeWork />,
  관리자: <SupervisorAccountRounded />,
  X: <LocalAirport />,
  스토어: <StorefrontRounded />
};

const MenuList = ({ parentRouteNode, onClickMenuLinkItem }) => {
  const { user } = useAuth();
  const { isOpenedParentRouteNode, toggleOpenedRouteNode } = useSideMenu();

  if (!user) return null;

  const menuIcon = menuIcons[parentRouteNode.label];
  const isParentPermitted = parentRouteNode.permission ? isPermitted(user, parentRouteNode.permission) : false;
  const handleClickMenuTitle = () => toggleOpenedRouteNode(parentRouteNode);

  return (
    <StyledMenuList>
      <ListItem button onClick={handleClickMenuTitle}>
        <ListItemIcon>{menuIcon}</ListItemIcon>
        <ListItemText primary={parentRouteNode.label} />
        {isOpenedParentRouteNode(parentRouteNode) ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={isOpenedParentRouteNode(parentRouteNode)}>
        <List>
          {parentRouteNode.children
            .filter((routeNode) => isParentPermitted || isPermitted(user, routeNode.permission))
            .map((routeNode, i) => (
              <MenuListItem
                key={i}
                parentRouteNode={parentRouteNode}
                routeNode={routeNode}
                onClickMenuLinkItem={onClickMenuLinkItem}
              />
            ))}
        </List>
      </Collapse>
    </StyledMenuList>
  );
};

const StyledMenuList = styled(List)`
  width: 240px;
`;

export default MenuList;
