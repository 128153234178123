import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '@F/Loading';
import MaxCustomError from '@FUNC/AxiosCustomError';
import { actions } from '../redux/campaigns/slice';
import { selectCampaignDetail, selectStatus, selectError } from '../redux/campaigns/selector';
import CompareReport from './CompareReport';

type CompareQueryParams = { cidA?: string; cidB?: string };
const CampaignsWrapper = (): JSX.Element => {
  const location = useLocation();
  const { cidA: leftId, cidB: rightId } = qs.parse(location.search.slice(1)) as CompareQueryParams;
  if (!leftId || !rightId) throw new Error('비정상적인 접근입니다. 비교할 캠페인을 선택해주세요.');

  const cids = [leftId, rightId].map(Number);
  if (cids.some(Number.isNaN)) throw new Error('전달된 id 중에 숫자값이 아닌게 있습니다. :(');

  const dispatch = useDispatch();
  const detailCampaigns = useSelector(selectCampaignDetail);

  useEffect(() => {
    const [cidA, cidB] = [Number(leftId), Number(rightId)];
    dispatch(actions.fetchCompareCampaigns({ cidA, cidB }));
  }, []);

  const { campaign: status } = useSelector(selectStatus);
  const apiError = useSelector(selectError);

  if (status === 'idle' || status === 'loading') return <Loading />;
  if (status === 'failure' && apiError) throw new MaxCustomError(apiError);

  return <CompareReport cidA={detailCampaigns[0]} cidB={detailCampaigns[1]} />;
};

export default CampaignsWrapper;
