import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';

import { TableBody, TableCell, TableRow } from '@material-ui/core';

import { selectPagination } from '../redux/selector';

export default function TableSkeleton(): JSX.Element {
  const { perPage } = useSelector(selectPagination);

  return (
    <TableBody>
      {Array(perPage)
        .fill(0)
        .map((_, i) => (
          <TableRow key={i}>
            <TableCell align="center">
              <StyledWrapper>
                <StyledSkeleton width={24} height={24} />
              </StyledWrapper>
            </TableCell>
            <TableCell align="center">
              <StyledWrapper>
                <StyledSkeleton width={50} height={24} />
              </StyledWrapper>
            </TableCell>
            <TableCell align="center">
              <StyledWrapper>
                <StyledSkeleton width={80} height={20} />
              </StyledWrapper>
            </TableCell>
            <TableCell align="center">
              <StyledWrapper>
                <StyledSkeleton width={130} height={20} />
              </StyledWrapper>
            </TableCell>
            <TableCell align="center">
              <StyledWrapper>
                <StyledSkeleton width={130} height={20} />
              </StyledWrapper>
            </TableCell>
            <TableCell align="center">
              <StyledWrapper>
                <StyledSkeleton width={50} height={20} />
              </StyledWrapper>
            </TableCell>
            <TableCell align="center">
              <StyledWrapper>
                <StyledSkeleton width={30} height={20} />
              </StyledWrapper>
            </TableCell>
            <TableCell align="center">
              <StyledWrapper>
                <StyledSkeleton width={90} height={20} />
              </StyledWrapper>
            </TableCell>
            <TableCell align="center">
              <StyledWrapper>
                <StyledSkeleton width={90} height={20} />
              </StyledWrapper>
            </TableCell>
            <TableCell align="center">
              <StyledWrapper>
                <StyledSkeleton width={64} height={20} />
              </StyledWrapper>
            </TableCell>
            <TableCell align="center">
              <StyledWrapper>
                <StyledSkeleton width={64} height={20} />
              </StyledWrapper>
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  );
}

const lineAnimation = keyframes`
0% {
  background-position: -1200px 0;
}
100% {
  background-position: 1200px 0;
}
`;

const StyledSkeleton = styled.span`
  border-radius: 4px;

  background-color: #ffffff;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.08) 0%,
    rgba(0, 0, 0, 0.15) 16.5%,
    rgba(0, 0, 0, 0.08) 33%
  );

  background-size: 1200px 100%;
  animation: ${lineAnimation} 3s infinite;

  ${({ width, height }: { width: number; height: number }) => `
    width: ${width}px;
    height: ${height}px;
`}
`;

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
