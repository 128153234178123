import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import ReactLoading from 'react-loading';
import { Button, colors } from '@material-ui/core';
import { toastSuccessMessage } from '@FUNC/toast';
import ConfirmModal from '@F/modal/ConfirmModal';

import { Notice } from '@TS/max/manageNotice';
import { useManageNotice } from '../useManageNotice';

export default function NoticeDetailToolbar({ notice }: { notice: Notice }): JSX.Element {
  const navigate = useNavigate();
  const { togglePublishApiStatuses, togglePublishNotice, deleteApiStatus, deleteNotice } = useManageNotice();

  const [showConfirmDeleteNotice, setShowConfirmDeleteNotice] = useState(false);
  const [showConfirmToggleNoticeStatus, setShowConfirmToggleNoticeStatus] = useState(false);

  return (
    <StyledNoticeDetailToolbar>
      <Button onClick={() => navigate(`/x/notices/edit/${notice.id}`)} color="primary" variant="contained">
        수정
      </Button>
      {notice.status === 'DELETED' ? null : (
        <>
          <Button
            onClick={() => setShowConfirmDeleteNotice(true)}
            color="secondary"
            variant="contained"
            disabled={deleteApiStatus === 'loading'}
          >
            삭제
          </Button>
          <ConfirmModal
            isOpen={showConfirmDeleteNotice}
            disabled={deleteApiStatus === 'loading'}
            title={deleteApiStatus === 'loading' ? '공지사항을 삭제하는 중입니다..' : '공지사항을 삭제하시겠습니까?'}
            onPermit={() => {
              deleteNotice(notice).then((isSuccess) => {
                setShowConfirmToggleNoticeStatus(false);
                if (isSuccess) {
                  navigate('/x/notices');
                  toastSuccessMessage(`공지사항이 성공적으로 삭제되었습니다.`);
                }
              });
            }}
            onCancel={() => setShowConfirmDeleteNotice(false)}
          />
        </>
      )}

      <StyledPublishButton onClick={() => setShowConfirmToggleNoticeStatus(true)} color="primary" variant="contained">
        {togglePublishApiStatuses[notice.id] === 'loading' ? (
          <StyledReactLoadingWrapper>
            <ReactLoading type="cylon" color={colors.indigo[200]} height={40} width={40} />
          </StyledReactLoadingWrapper>
        ) : notice.status === 'PUBLISH' ? (
          '발행취소'
        ) : (
          '발행하기'
        )}
      </StyledPublishButton>
      <ConfirmModal
        isOpen={showConfirmToggleNoticeStatus}
        disabled={togglePublishApiStatuses[notice.id] === 'loading'}
        title={
          togglePublishApiStatuses[notice.id] === 'loading'
            ? `공지사항${notice.status === 'PUBLISH' ? ' 발행을 취소' : '을 발행'}하는 중입니다...`
            : `공지사항${notice.status === 'PUBLISH' ? ' 발행을 취소' : '을 발행'}하시겠습니까?`
        }
        onPermit={() => {
          togglePublishNotice(notice).then((isSuccess) => {
            setShowConfirmToggleNoticeStatus(false);
            isSuccess &&
              toastSuccessMessage(
                `공지사항이 성공적으로 ${notice.status === 'PUBLISH' ? '발행 취소' : '발행'}되었습니다.`
              );
          });
        }}
        onCancel={() => setShowConfirmToggleNoticeStatus(false)}
      />

      <Button onClick={() => navigate('/x/notices')} color="primary" variant="outlined">
        목록으로
      </Button>
    </StyledNoticeDetailToolbar>
  );
}

const StyledNoticeDetailToolbar = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  *:not(:first-of-type) {
    margin-left: 10px;
  }
`;

const StyledPublishButton = styled(Button)`
  width: 90px;
`;

const StyledReactLoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
`;
