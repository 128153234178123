import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import * as S from './styles';

// TODO: setPage를 onChangePage로 변경해야함.
// TablePagination을 사용하는 곳이 많아 주석을 첨부함.
function TablePagination({ count, page, setPage }) {
  return (
    <S.StyledTablePagination>
      <Pagination
        count={count}
        page={page}
        onChange={(event, value) => setPage(value)}
        variant="outlined"
        color="primary"
      />
    </S.StyledTablePagination>
  );
}
export default TablePagination;

TablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired
};
