import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Complex } from '@TS/store/complex';
import { SelectCIDFunction } from '.';
import * as S from './TreeNode.styles';

export interface Props {
  complex: Exclude<Complex, 'children'>;
  depth: number;
  onSelect: SelectCIDFunction;
  isSelected: boolean;
  toggleNode: (cid: Complex['cid']) => void;
  children: ReadonlyArray<JSX.Element>;
}

const TreeNode = ({ complex, depth, onSelect, children, toggleNode, ...props }: Props): JSX.Element => {
  const { cid, isOpen, name, addr, branchName } = complex;

  return (
    <S.InnerBackground depth={depth}>
      <S.Row
        onClick={(e) => {
          toggleNode(cid);
          onSelect(e, cid, name);
        }}
        onDoubleClick={(e) => {
          const CONFIRM_AND_CLOSE_MODAL = true;
          onSelect(e, cid, name, CONFIRM_AND_CLOSE_MODAL);
        }}
        depth={depth}
        {...props}
        haveChildren={!!children.length}
      >
        {children.length > 0 && (
          <S.ExpandButton depth={depth}>
            {isOpen ? <ExpandMoreIcon fontSize="small" /> : <ChevronRightIcon fontSize="small" />}
          </S.ExpandButton>
        )}
        <span>{cid}</span>
        <div>
          <span>{name}</span>
          <span>{branchName}</span>
          <span>{addr}</span>
        </div>
      </S.Row>
      {isOpen && <div>{children}</div>}
    </S.InnerBackground>
  );
};
export default TreeNode;
