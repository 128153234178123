import styled from 'styled-components';

export const StyledAssignModal = styled.div``;

export const SitesListBox = styled.div`
  margin-bottom: 20px;
`;

export const SitesList = styled.div`
  height: 300px;
  padding: 10px;
  background-color: ${(props) => props.theme.palette.grey[100]};
  overflow-y: scroll;
`;

export const SiteItem = styled.div`
  display: inline-block;
  width: 25%;
  vertical-align: top;
  padding: 5px 0;
  font-size: 14px;
`;

export const InnerModalWrapper = styled.div``;
