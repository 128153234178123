export interface Complex {
  cid: number;
  name: string;
  branch_name: string;
  addr: string;
  children: Complex[];
  isOpen?: boolean;
}

export type Label = 'cid' | '이름' | '지점명' | '주소';
export type Id = Exclude<keyof Complex, 'children' | 'isOpen'>;
export type SortableId = Exclude<Id, 'addr' | 'branch_name'>;
export type SortOrder = 'ascending' | 'descending' | null;
export type SortOrderNotNull = NonNullable<SortOrder>;

export type SortableHeadCell = { label: Label; sortOrder: SortOrder; id: SortableId };
export type NotSortableHeadCEll = Pick<SortableHeadCell, 'label'> & { id: Exclude<Id, SortableId> };
export type HeadCell = NotSortableHeadCEll | SortableHeadCell;

export type IsOpenedAllType = boolean | 'intermediate';

export function isSortableHeadCell(headCell: HeadCell): headCell is SortableHeadCell {
  if ('sortOrder' in headCell) return true;
  return false;
}
