/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ReactNode } from 'react';
import type { CustomError } from '@FUNC/AxiosCustomError';
import ErrorFallback from './ErrorFallback';

interface Props {
  children: ReactNode;
}
interface State {
  error: CustomError | null;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: null
    };
  }

  public static getDerivedStateFromError(error: CustomError): State {
    return { error };
  }

  public render(): ReactNode {
    if (this.state.error !== null) {
      return <ErrorFallback error={this.state.error} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
