export const downloadCsv = (csvString: string, fileName: string): void => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';

  const blob = new Blob([csvString], { type: 'octet/stream' });
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = `${fileName}`;
  a.click();
  window.URL.revokeObjectURL(url);

  a.remove();
};

// reference : https://stackoverflow.com/questions/40939380/how-to-get-file-name-from-content-disposition
export const fileNameFromContentDisposition = (contentDisposition: string) => {
  const utf8FileNameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i;
  const asciiFileNameRegex = /filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

  const utf8FileName = utf8FileNameRegex.exec(contentDisposition);
  const asciiFileName = asciiFileNameRegex.exec(contentDisposition);

  if (utf8FileName) {
    return decodeURIComponent(utf8FileName[1]);
  }

  if (asciiFileName) {
    return asciiFileName[2];
  }

  return 'unknown';
};
