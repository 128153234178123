import styled from 'styled-components';

export const StyledCreateEvent = styled.div`
  padding: 0.5rem;
  p {
    margin-bottom: 10px;
  }
`;

export const Title = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
  margin: 0;
`;
