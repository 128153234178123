import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Recruit, RecruitForm, RecruitsParams } from '@TS/homepage/recruit';

interface InitialState {
  status: ApiStatus;
  error: Error | null;
  data: Recruit[];
  totalCount: number;
  focused: Recruit | null;
  params: RecruitsParams;
}

type UICallback = (isSuccess: boolean) => void;

const initialState: InitialState = {
  status: 'idle',
  error: null,
  data: [],
  totalCount: 0,
  focused: null,
  params: {
    page: 1,
    perPage: 20,
    order: ['updated_at DESC'],
    status: ['PENDING', 'PUBLISH']
  }
};

// slice
const slice = createSlice({
  name: 'recruits',
  initialState,
  reducers: {
    // utils
    setParams: (state, action: PayloadAction<RecruitsParams>) => {
      state.params = action.payload;
    },
    setFocused: (state, action: PayloadAction<Recruit | null>) => {
      state.focused = action.payload;
    },

    // create
    createRecruitSuccess: (state, action: PayloadAction<Recruit>) => {
      state.focused = action.payload;
    },
    // read
    fetchRecruitsStart: (state) => {
      state.status = 'loading';
    },
    fetchRecruitsSuccess: (state, action: PayloadAction<{ data: Recruit[]; totalCount: number }>) => {
      const { data, totalCount } = action.payload;

      state.status = 'success';
      state.data = data;
      state.totalCount = totalCount;
    },
    fetchRecruitsFailure: (state, action: PayloadAction<Error>) => {
      state.status = 'failure';
      state.error = action.payload;
    },
    // update
    updateRecruitSuccess: (state, action: PayloadAction<{ id: number; data: Recruit }>) => {
      const { id, data } = action.payload;

      const updatedIndex = state.data.findIndex((recruit) => recruit.id === id);
      if (updatedIndex === -1) return;

      const updated = { ...state.data[updatedIndex], ...data };

      state.data[updatedIndex] = updated;
      state.focused = updated;
    },
    // delete
    deleteRecruitSuccess: (state, action: PayloadAction<number>) => {
      state.data = state.data.filter((recruit) => recruit.id !== action.payload);
      state.focused = null;
    },

    // create actions to use action.types in saga
    createRecruit: (state, action: PayloadAction<{ data: RecruitForm; callback: UICallback }>) => {},
    fetchRecruits: (state, action: PayloadAction<RecruitsParams | undefined>) => {},
    updateRecruit: (state, action: PayloadAction<{ id: number; data: Partial<Recruit>; callback: UICallback }>) => {},
    deleteRecruit: (state, action: PayloadAction<{ id: number; callback: UICallback }>) => {},
    changePage: (state, action: PayloadAction<number>) => {}
  }
});

export const { actions } = slice;

export default slice;
