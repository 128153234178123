import firebase from 'firebase';
import secret from '@/secret';

// secondary firebase project
const config = {
  projectId: 'loplat-manager-frontend-88d51',
  apiKey: 'AIzaSyBf1sgeBuMrsnDYhYKnmc7agSg5GCDurls',
  storageBucket: 'gs://loplat-manager-frontend-88d51.appspot.com'
};

function ManagerFirebaseProject() {
  this.instance; // eslint-disable-line no-unused-expressions

  this.init = async () => {
    if (this.instance) return this.instance;

    this.instance = firebase.initializeApp(config, 'manager');

    return this.instance;
  };

  this.auth = async () =>
    this.instance
      .auth()
      .signInWithEmailAndPassword(secret.FIREBASE.MANAGER.email, secret.FIREBASE.MANAGER.password)
      .catch((err) => {
        throw new Error(err);
      });

  this.storage = () => this.instance.storage();
}

const managerFirebaseProject = new ManagerFirebaseProject();

export default managerFirebaseProject;
