import { useCallback, useState } from 'react';
import { Paper } from '@material-ui/core';
import { cookSitesApi } from '@API/manager/cook';
import Filter from './Filter';
import Table from './Table';
import * as S from './styles';

function SiteMapManager() {
  const classes = S.useStyles();
  const [perPage, setPerPage] = useState(15);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [sites, setSites] = useState([]);
  const [parameters, setParameters] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const loadSites = useCallback(async (newParameters, newPage) => {
    // eslint-disable-next-line no-shadow
    const {
      charge,
      completionState,
      perPage,
      siteId,
      period,
      floor,
      complexId,
      pin_tier,
      addr_lv1,
      addr_lv2,
      addr_lv3
    } = newParameters;

    setIsLoading(true);
    try {
      if (siteId) {
        const response = await cookSitesApi.getSite({ siteId });
        setSites([response.data]);
        setTotalCount(1);
      } else {
        const response = await cookSitesApi.getSiteList({
          period,
          charge,
          chargeResult: completionState,
          perPage,
          page: newPage,
          floor,
          complexId,
          pin_tier,
          addr_lv1,
          addr_lv2,
          addr_lv3
        });
        setSites(response.data.items);
        setTotalCount(response.data.count);
        setPerPage(perPage);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const onClickLoad = useCallback(
    (newParameters) => {
      setPage(1);
      setParameters(newParameters);
      loadSites(newParameters, 1);
    },
    [loadSites]
  );

  const onClickPage = useCallback(
    (newPage) => {
      if (page !== newPage) {
        setPage(newPage);
        loadSites(parameters, newPage);
      }
    },
    [page, parameters, loadSites]
  );

  return (
    <>
      <Paper className={classes.paper}>
        <Filter onClickLoad={onClickLoad} isLoading={isLoading} />
      </Paper>

      <Paper className={classes.paper}>
        <Table
          sites={sites}
          totalCount={totalCount}
          perPage={perPage}
          page={page}
          setPage={onClickPage}
          // TODO: 테스트가 끝나면 테이블에서 해당 row만 삭제하는 로직으로 변경
          onConfirmCharge={() => loadSites(parameters, page)}
          isLoading={isLoading}
        />
      </Paper>
    </>
  );
}
export default SiteMapManager;

SiteMapManager.propTypes = {};
