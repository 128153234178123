import React from 'react';
import MuiPagination from '@material-ui/lab/Pagination';
import styled from '@emotion/styled';

import { usePagination } from '@HOOK/usePagination';
import { MenuItem, Select } from '@material-ui/core';

export * from '@HOOK/usePagination';

export default function Pagination({
  perPageOptions = [10, 20, 40, 100, 200, 1000]
}: {
  perPageOptions?: number[];
}): JSX.Element {
  const paginationContext = usePagination();

  if (!paginationContext) {
    throw new Error(
      `Pagination 컴포넌트는 PaginationProvider 내에서 사용될 수 있습니다.
       Paginationprovider가 적절한 곳에서 사용되고 있는지 확인해주세요.`
    );
  }

  const { page, totalCount, perPage, setPage, setPerPage } = paginationContext;

  const handleChangePerPage: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: React.ReactNode
  ) => void = (e) => {
    const startIdx = (page - 1) * perPage;

    const newPerPage = e.target.value as number;
    const newPage = Math.max(1, Math.ceil(startIdx / newPerPage));

    setPage(newPage);
    setPerPage(newPerPage);
  };

  return (
    <StyledPaginationWrapper>
      <StyledPerPage>
        <Select onChange={handleChangePerPage} value={perPage}>
          {perPageOptions.map((perPage) => (
            <MenuItem key={perPage} value={perPage}>
              {perPage}
            </MenuItem>
          ))}
        </Select>
        <span>개씩 보기 </span>
      </StyledPerPage>
      <MuiPagination
        defaultPage={page}
        page={page}
        count={Math.ceil(totalCount / (perPage || 20))}
        onChange={(e, page) => setPage(page)}
      />
      <div>{`현재 페이지 ${page} / ${Math.max(1, Math.ceil(totalCount / perPage))}`}</div>{' '}
    </StyledPaginationWrapper>
  );
}

const StyledPerPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledPaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
`;
