import { createReducer, createSetValueAction, setValueReducer } from '@/redux/common/helper-functions';

/** prefix */
const PREFIX = 'INSPECT';

/** initial state */
const INITIAL_STATE = {
  studies: [],
  cDevices: {
    iOS: [],
    Android: []
  },
  count: 0,
  openedStudyIds: [],
  page: 1,
  perPage: 15,
  params: {},
  isLoading: false,
  loadingStudyIds: []
};

/** type */
export const types = {
  RESET: `${PREFIX}/RESET`,
  SET_VALUE: `${PREFIX}/SET_VALUE`,
  SET_LOADING: `${PREFIX}/SET_LOADING`,
  SET_PAGE: `${PREFIX}/SET_PAGE`,
  FETCH_STUDIES: `${PREFIX}/FETCH_STUDIES`,
  SET_NEW_STUDIES: `${PREFIX}/SET_NEW_STUDIES`,
  SET_NEW_STUDY: `${PREFIX}/SET_NEW_STUDY`,
  UPDATE_STUDY: `${PREFIX}/UPDATE_STUDY`,
  CLOSE_STUDY: `${PREFIX}/CLOSE_STUDY`,
  ADD_LOADING_STUDY: `${PREFIX}/ADD_LOADING_STUDY`,
  DELETE_LOADING_STUDY: `${PREFIX}/DELETE_LOADING_STUDY`,
  UPDATE_ERROR_STUDY: `${PREFIX}/UPDATE_ERROR_STUDY`,
  FETCH_C_DEVICES: `${PREFIX}/FETCH_C_DEVICES`
};

/** action */
export const actions = {
  reset: () => ({ type: types.RESET }),
  setValue: createSetValueAction(types.SET_VALUE),
  setLoading: (isLoading) => ({ type: types.SET_LOADING, isLoading }),
  setPage: (page) => ({ type: types.SET_PAGE, page }),
  fetchInspects: () => ({ type: types.FETCH_STUDIES }),
  setNewInspects: (studies) => ({ type: types.SET_NEW_STUDIES, studies }),
  setNewInspect: (study) => ({ type: types.SET_NEW_STUDY, study }),
  updateInspect: (params) => ({ type: types.UPDATE_STUDY, params }),
  closeInspect: (studyId) => ({ type: types.CLOSE_STUDY, studyId }),
  addLoadingInspect: (studyId) => ({ type: types.ADD_LOADING_STUDY, studyId }),
  deleteLoadingInspect: (studyId) => ({ type: types.DELETE_LOADING_STUDY, studyId }),
  updateErrorInspect: (studyId) => ({ type: types.UPDATE_ERROR_STUDY, studyId }),
  fetchCDevices: () => ({ type: types.FETCH_C_DEVICES })
};

/** reducer */
const reducer = createReducer(INITIAL_STATE, {
  [types.RESET]: (draft) => {
    draft.studies = [];
    draft.openedStudyIds = [];
  },
  [types.SET_VALUE]: setValueReducer,
  [types.SET_LOADING]: (draft, action) => {
    draft.isLoading = action.isLoading;
  },
  [types.SET_PAGE]: (draft, action) => {
    draft.page = action.page;
  },
  [types.SET_NEW_STUDIES]: (draft, action) => {
    draft.studies = action.studies;
  },
  [types.SET_NEW_STUDY]: (draft, action) => {
    const index = draft.studies.findIndex((study) => study.study_log.study_id === action.study.study_log.study_id);
    if (index >= 0) {
      draft.studies[index] = action.study;
    }
  },
  [types.CLOSE_STUDY]: (draft, action) => {
    const index = draft.openedStudyIds.findIndex((study) => study.id === action.studyId);
    if (index >= 0) {
      draft.openedStudyIds[index] = { id: action.studyId, opened: false };
    }
  },
  [types.ADD_LOADING_STUDY]: (draft, action) => {
    draft.loadingStudyIds = draft.loadingStudyIds.concat(action.studyId);
  },
  [types.DELETE_LOADING_STUDY]: (draft, action) => {
    const index = draft.loadingStudyIds.findIndex((id) => id === action.studyId);
    if (index >= 0) {
      draft.loadingStudyIds = [...draft.loadingStudyIds.slice(0, index), ...draft.loadingStudyIds.slice(index + 1)];
    }
  },
  [types.UPDATE_ERROR_STUDY]: (draft, action) => {
    const index = draft.studies.findIndex((study) => study.study_log.study_id === action.studyId);
    if (index >= 0) {
      draft.studies[index] = {
        ...draft.studies[index],
        study_log: {
          ...draft.studies[index].study_log,
          state: 'error'
        }
      };
    }
  }
});
export default reducer;
