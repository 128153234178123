import LinkButton from '@F/LinkButton';
import CampaignWrapper from './compare/index';

const Compare = (): JSX.Element => (
  <>
    <LinkButton mb={4} go="/x/campaigns">
      뒤로가기
    </LinkButton>
    <CampaignWrapper />
  </>
);

export default Compare;
