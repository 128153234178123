import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import {
  kindOptions,
  payTypeOptions,
  rankOptions,
  reversedKindOptions,
  reversedPayTypeOptions,
  reversedRankOptions,
  reversedStatusOptions,
  statusOptions,
  typeOptions
} from '@P/cook/cookUser/options';
import { useDispatch } from 'react-redux';
import Loading from '@F/Loading';
import DialogModal from '@F/DialogModal';
import { useFetch, useInput } from '@HOOK/';
import { cookUsersApi } from '@API/manager/cook';
import { actions } from '@/redux/cook/cookUser/state';
import * as S from './styles';

function Detail({ id, data, teams }) {
  const user = useMemo(() => data.find((element) => element.user_id === Number(id)), [id, data]);
  const classes = S.useStyles();

  const [userName, setUserName] = useInput(user.username);
  const UserNameInput = <TextInputForm name="UserName" label="아이디" onChange={setUserName} value={userName} />;
  const [password, setPassword] = useInput('');
  const PasswordInput = <TextInputForm name="Password" label="비밀번호" onChange={setPassword} value={password} />;
  const [name, setName] = useInput(user.name);
  const NameInput = <TextInputForm name="Name" label="이름" onChange={setName} value={name} />;
  const [email, setEmail] = useInput(user.email);
  const EmailInput = <TextInputForm name="Email" label="이메일" onChange={setEmail} value={email} />;
  const [jumin, setJumin] = useInput(user.jumin);
  const JuminInput = <TextInputForm name="Jumin" label="주민번호" onChange={setJumin} value={jumin} />;
  const [device, setDevice] = useInput(user.device);
  const DeviceInput = <TextInputForm name="Device" label="폰 기종" onChange={setDevice} value={device} />;
  const [phone, setPhone] = useInput(user.phone);
  const PhoneInput = <TextInputForm name="Phone" label="전화번호" onChange={setPhone} value={phone} />;
  const [dailyMinimum, setDailyMinimum] = useInput(user.daily_min);
  const DailyMinimumInput = (
    <TextInputForm name="DailyMinimum" label="일 최소개수" onChange={setDailyMinimum} value={dailyMinimum} />
  );
  const [dailySalary, setDailySalary] = useInput(user.daily_sal);
  const DailySalaryInput = (
    <TextInputForm name="DailySalary" label="일 가격" onChange={setDailySalary} value={dailySalary} />
  );

  const [payType, setPayType] = useInput(reversedPayTypeOptions[user.pay_type]);
  const PayTypeSelect = (
    <SelectForm
      label="단가설정"
      options={Object.keys(payTypeOptions)}
      value={payType}
      onSelect={setPayType}
      name="PayTypeSelect"
    />
  );
  const [team, setTeam] = useInput(teams.find((ele) => ele.id === user.team_id)?.name);
  const TeamSelect = (
    <SelectForm label="소속" options={teams.map((ele) => ele.name)} value={team} onSelect={setTeam} name="TeamSelect" />
  );
  const [kind, setKind] = useInput(reversedKindOptions[user.kind]);
  const KindSelect = (
    <SelectForm
      label="종류"
      options={Object.keys(kindOptions).slice(1)}
      value={kind}
      onSelect={setKind}
      name="KindSelect"
    />
  );
  const [type, setType] = useInput(user.type);
  const TypeSelect = (
    <SelectForm label="타입" options={typeOptions} value={type} onSelect={setType} name="TypeSelect" />
  );
  const [status, setStatus] = useInput(reversedStatusOptions[user.status]);
  const StatusSelect = (
    <SelectForm
      label="상태"
      options={Object.keys(statusOptions).slice(1)}
      value={status}
      onSelect={setStatus}
      name="StatusSelect"
    />
  );
  const [rank, setRank] = useInput(reversedRankOptions[user.rank]);
  const RankSelect = (
    <SelectForm
      label="팀 직급"
      options={Object.values(reversedRankOptions)}
      value={rank}
      onSelect={setRank}
      name="RankSelect"
    />
  );

  const dispatch = useDispatch();
  const updateUser = () => {
    dispatch(
      actions.updateUser(user, {
        userId: user.user_id,
        userName,
        password: password || null,
        kind: kindOptions[kind],
        type,
        name,
        email,
        jumin,
        phone,
        device,
        teamId: teams.find((ele) => ele.name === team)?.id,
        status: statusOptions[status],
        payType: payTypeOptions[payType],
        dailyMinimum,
        dailySalary,
        rank: rankOptions[rank]
      })
    );
  };

  const { callApi, isLoading: isUpdating } = useFetch();
  const initToken = useCallback(async () => {
    await callApi(cookUsersApi.initToken, user.username);
    dispatch(actions.setNewUser({ ...user, user_token: null }));
  }, [dispatch, callApi, user]);

  // 재확인 모달
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [confirmFunction, setConfirmFunction] = useState(() => () => {});
  const reconfirmInitToken = useCallback(() => {
    setIsModalOpen(true);
    setModalTitle('토큰을 초기화할까요?');
    setConfirmFunction(() => initToken);
  }, [initToken]);

  const reconfirmUpdateUser = () => {
    setIsModalOpen(true);
    setModalTitle('유저를 업데이트할까요?');
    setConfirmFunction(() => updateUser);
  };

  return (
    <S.StyledDetail>
      {isUpdating && <Loading />}
      <Grid container spacing={2} className={classes.wrapper}>
        <Grid item xs>
          {UserNameInput}
        </Grid>
        <Grid item xs>
          {NameInput}
        </Grid>
        <Grid item xs>
          {PasswordInput}
        </Grid>
        <Grid item xs>
          {EmailInput}
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.wrapper}>
        <Grid item xs>
          {JuminInput}
        </Grid>
        <Grid item xs>
          {PhoneInput}
        </Grid>
        <Grid item xs>
          {DeviceInput}
        </Grid>
        <Grid item xs>
          {PayTypeSelect}
        </Grid>
        <Grid item xs>
          {DailyMinimumInput}
        </Grid>
        cook
      </Grid>
      <Grid container spacing={2} className={classes.wrapper}>
        <Grid item xs>
          {DailySalaryInput}
        </Grid>
        <Grid item xs>
          {TeamSelect}
        </Grid>
        <Grid item xs>
          {KindSelect}
        </Grid>
        <Grid item xs>
          {StatusSelect}
        </Grid>
        <Grid item xs>
          {TypeSelect}
        </Grid>
        <Grid item xs>
          {RankSelect}
        </Grid>
      </Grid>

      <hr />
      <S.ButtonRow>
        <Button variant="contained" color="primary" disabled={!user.user_token} onClick={reconfirmInitToken}>
          토큰 초기화
        </Button>
        <Button variant="contained" color="primary" className={classes.button} onClick={reconfirmUpdateUser}>
          UPDATE
        </Button>
      </S.ButtonRow>

      <DialogModal
        title={modalTitle}
        description="확인버튼을 누르시면 다시 돌이킬 수 없습니다. 신중히 선택하십시오."
        open={isModalOpen}
        setOpen={setIsModalOpen}
        confirmButtonDidClicked={confirmFunction}
      />
    </S.StyledDetail>
  );
}
export default Detail;

Detail.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired
};

Detail.defaultProps = {
  id: 0
};
