import Loading from '@F/Loading';
import { useAppSelector } from '@HOOK/useSelector';
import Filter from './Filter';
import TableSection from './tableSection/TableSection';
import * as S from './styles';

function Users(): JSX.Element {
  const isLoading = useAppSelector((state) => state.user.isLoading);
  return (
    <S.StyledUsers>
      {isLoading && <Loading />}
      <Filter />
      <br />
      <TableSection />
    </S.StyledUsers>
  );
}
export default Users;
