import { cookUsersApi } from '@API/manager/cook';

export function fetchCookVersions(params) {
  return cookUsersApi.getCookVersions(params).then((response) => response.data);
}

export function deleteCookVersion(versionId) {
  return cookUsersApi.deleteVersion(versionId).then((response) => response.data);
}

export function updateCookVersion(params) {
  return cookUsersApi.putVersion(params).then((response) => response.data);
}
