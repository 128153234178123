import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import EnhancedTable from '@F/table/Table';
import { createHeadCells } from '@F/table/Table/functions';
import { Button } from '@material-ui/core';
import { storesApi } from '@API/manager';
import moment from 'moment';
import CombineModal from '@P/store/StoreCombine/combineModal/CombineModal';
import DialogModal from '@F/DialogModal';
import { useDispatch } from 'react-redux';
import { toastErrorMessage } from '@FUNC/toast';
import { useFetch } from '@HOOK/';
import { actions } from '@/redux/store/storeCombine/state';
import * as S from './styles';

function Detail({ id, data, onCloseDetailList }) {
  const store = useMemo(() => data.find((element) => element.pid === id), [id, data]);
  const [selectedStores, setSelectedStores] = useState([]);
  const headCells = useMemo(() => columns.map((column) => createHeadCells(column.name, column.label)), []);
  const [nearStores, setNearStores] = useState([]);

  const rows = useMemo(
    () =>
      nearStores
        .map((nearStore) => ({
          id: nearStore.pid,
          cid: nearStore.complexes.map((complex) => complex.cid).join(', '),
          bid: nearStore.brands.map((brand) => brand.bid).join(', '),
          name: nearStore.name,
          branchName: nearStore.branch_name,
          addr: nearStore.addr,
          floor: `${nearStore.floor}F`,
          lat: parseFloat(nearStore.lat).toFixed(6),
          lng: parseFloat(nearStore.lng).toFixed(6),
          createdAt: moment(nearStore.created_at).format('YYYY-MM-DD HH:mm:ss')
        }))
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)),
    [nearStores]
  );

  useEffect(() => {
    setSelectedStores(rows.filter((row) => row.id === store.pid));
  }, [rows, store.pid]);

  // 주변 매장 불러오기
  const { callApi, isLoading } = useFetch();
  const fetchNearStores = useCallback(async () => {
    if (!store.pid) return;
    const response = await callApi(storesApi.getStoresNearPid, { pid: store.pid });
    setNearStores(response.data.items);
  }, [store, callApi]);
  useEffect(() => {
    fetchNearStores();
  }, [fetchNearStores]);

  // 병합 모달
  const [isCombineModalOpen, setIsCombineModalOpen] = useState(false);
  const [isSingleCombineModalOpen, setIsSingleCombineModalOpen] = useState(false);

  const combineStores = () => {
    if (selectedStores.length === 0) {
      toastErrorMessage('병합될 장소가 선택되지 않았습니다.');
    } else if (selectedStores.length === 1) {
      setIsSingleCombineModalOpen(true);
    } else {
      setIsCombineModalOpen(true);
    }
  };

  const dispatch = useDispatch();
  const combineSingleStore = () => {
    onCloseDetailList(id);
    dispatch(actions.combineSingleStore(store));
  };

  return (
    <S.StyledDetail>
      <EnhancedTable
        headCells={headCells}
        rows={rows}
        selected={selectedStores}
        setSelected={setSelectedStores}
        isCheckable
        isLoading={isLoading}
      />

      <S.ButtonRow>
        <Button variant="outlined" color="primary" onClick={combineStores}>
          병합 데이터 선택
        </Button>
      </S.ButtonRow>

      <CombineModal
        isOpen={isCombineModalOpen}
        setIsOpen={setIsCombineModalOpen}
        stores={nearStores.filter((nearStore) => selectedStores.map((ele) => ele.id).includes(nearStore.pid))}
        onConfirm={() => onCloseDetailList(id)}
      />

      <DialogModal
        title="단일 스토어 정보를 병합검수 완료처리합니다."
        description="확인버튼을 누르시면 다시 돌이킬 수 없습니다. 신중히 선택하십시오."
        open={isSingleCombineModalOpen}
        setOpen={setIsSingleCombineModalOpen}
        confirmButtonDidClicked={combineSingleStore}
      />
    </S.StyledDetail>
  );
}
export default Detail;

Detail.propTypes = {
  id: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  onCloseDetailList: PropTypes.func.isRequired
};

Detail.defaultProps = {
  id: 0
};

const columns = [
  { name: 'id', label: 'STORE ID', isImportant: true },
  { name: 'cid', label: 'CID' },
  { name: 'bid', label: 'BID' },
  { name: 'name', label: '장소명' },
  { name: 'branchName', label: '지점명' },
  { name: 'floor', label: '층수' },
  { name: 'addr', label: '주소' },
  { name: 'lat', label: '위도' },
  { name: 'lng', label: '경도' },
  { name: 'createdAt', label: '생성 시간' }
];
