import type { ErrorKey, ErrorMessage, ManagerApiError } from '@TS/customError';

/**
 * NOTE: 매니저 X 의 에러바운더리 전용.
 * - 다른 페이지에도 적용하기 위해서는 types/CustomError의 에러확장이 필요함.
 * - 참고: api/max/instance & types/customError & @C/max/campaigns/redux/slice의 error 관리
 */
export interface CustomError {
  error: ErrorKey;
  errorMessage: ErrorMessage;
}

class MaxCustomError extends Error {
  error: ErrorKey;
  errorMessage: ErrorMessage;
  constructor(xError: ManagerApiError) {
    super();
    this.error = xError.error;
    this.errorMessage = xError.error_msg;
  }
}

export default MaxCustomError;
