import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TrashCan from '@I/map/trashcan.svg';
import DrawingCancel from '@I/map/drawing-cancel.svg';
import Drawing from '@I/map/drawing.svg';
import { theme } from '@S/index';
import * as S from './styles';

function DrawingManagerTool({ map, onEmitPolygon }) {
  const [drawingManager, setDrawingManager] = useState();
  const [polygon, setPolygon] = useState();
  const [isDrawingMode, setIsDrawingMode] = useState(false);

  const initializeDrawingManager = useCallback(() => {
    window.naver.maps.Event.once(map, 'init_stylemap', () => {
      const newDrawingManager = new window.naver.maps.drawing.DrawingManager({
        map,
        ...drawingOptions
      });

      newDrawingManager.addListener('polygonAdded', (newPolygon) => {
        newPolygon.setOptions('clickable', false);
        setPolygon(newPolygon);
        setIsDrawingMode(false);
      });

      newDrawingManager.addListener('polygonRemoved', () => {
        setPolygon(null);
        setIsDrawingMode(false);
      });

      setDrawingManager(newDrawingManager);
    });
  }, [map]);

  const setDrawingMode = useCallback(() => {
    drawingManager.setOptions('drawingMode', window.naver.maps.drawing.DrawingMode.POLYGON);
    setIsDrawingMode(true);

    if (polygon) {
      drawingManager.removeDrawing(polygon);
      drawingManager.setOptions('drawingMode', window.naver.maps.drawing.DrawingMode.HAND);
    }
  }, [polygon, drawingManager]);

  useEffect(() => {
    if (!map) return;
    initializeDrawingManager();
  }, [map, initializeDrawingManager]);

  useEffect(() => {
    onEmitPolygon(polygon);
  }, [polygon, onEmitPolygon]);

  return (
    <S.Button onClick={setDrawingMode} isDrawingMode={isDrawingMode}>
      <img src={polygon ? TrashCan : isDrawingMode ? DrawingCancel : Drawing} alt="" />
    </S.Button>
  );
}
export default DrawingManagerTool;

DrawingManagerTool.propTypes = {
  map: PropTypes.objectOf(PropTypes.any),
  onEmitPolygon: PropTypes.func
};

DrawingManagerTool.defaultProps = {
  map: null,
  onEmitPolygon: () => {}
};

const drawingOptions = {
  drawingControl: null,
  polygonOptions: {
    fillColor: theme.palette.secondary.lighter,
    fillOpacity: 0.5,
    strokeWeight: 3,
    strokeColor: theme.palette.secondary.light
  }
};
