import styled from '@emotion/styled';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { TableCell } from '@material-ui/core';

export default function ToggleBodyCell({
  onState,
  type = 'collapse'
}: {
  onState: boolean;
  type?: 'collapse' | 'check';
}): JSX.Element {
  let onIcon;
  let offIcon;

  if (type === 'collapse') {
    onIcon = <KeyboardArrowUpIcon />;
    offIcon = <KeyboardArrowDownIcon />;
  }

  return (
    <StyledToggleBodyCell align="center">
      <IconButton size="small">{onState ? onIcon : offIcon}</IconButton>
    </StyledToggleBodyCell>
  );
}

const StyledToggleBodyCell = styled(TableCell)`
  width: 30px !important;
`;
