import styled from 'styled-components';
import { grayscale100, grayscale700, grayscale800, bluescale50 } from 'loplat-ui';
import { gridSpread, listMargin, ulMargin } from '@P/max/campaigns/utils/styles';

export const RegionSection = styled.section`
  position: relative;
  padding: 0 16px;
  border: 1px solid ${grayscale100};
  border-radius: 8px;
  border-top-left-radius: 0;
  margin-top: 32px;
`;

export const RegionDetailWrapper = styled.dl`
  ${gridSpread};
  grid-template-rows: 1fr;
  padding: 12px 16px;
  margin: 0;

  dl,
  dt,
  dd {
    margin: 0;
  }

  dt.title {
    grid-column: 1/4;
    color: ${grayscale800};
    font-weight: bold;
    font-size: 1rem;
  }
  dd.body {
    margin: 0;
    width: 100%;
    grid-column: 4/-1;
  }
`;

export const DetailSection = styled.dd`
  background: ${bluescale50};
  border-radius: 7px;
  padding: 11px 25px;

  p {
    grid-column: 3/-1;
    line-height: 1.5rem;
    margin: 0;
    word-break: keep-all;
  }

  > dl {
    ${gridSpread};
    grid-gap: 5px 0;
    margin-bottom: 8px;

    dt {
      grid-column: 1/3;
      font-weight: bold;
    }
    dd {
      margin: 0;
      display: flex;
      overflow-wrap: wrap;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 5px;
      grid-column: 3/-1;
    }
  }

  small {
    grid-column: -1;
  }

  div.caption {
    display: flex;
    flex-direction: column;
  }
`;

export const ZoneWrapper = styled.div<{ direction: 'row' | 'column' }>`
  ${gridSpread};
  ${listMargin(11)};
  > span {
    font-weight: bold;
    grid-column: 1/3;
  }

  > div {
    grid-column: 3/-1;
    display: flex;
    flex-direction: ${({ direction }) => direction};
    ${listMargin()};

    > p {
      ${listMargin()};
    }
  }
`;

export const Separator = styled.div`
  position: relative;
  grid-column: 4/-1;
  width: 100%;
  height: 1px;
  border-top: 1px solid ${grayscale100};
  position: relative;
  margin: 32px 0;
  font-size: 0.8rem;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    width: 3.1rem;
    height: max-content;
    transform: translateY(-50%);
    border-radius: 5px;
    background: ${grayscale700};
    position: absolute;
    left: 0;
    color: white;
    font-weight: bold;
  }

  &.or {
    grid-column: 3/-1;
  }
  &.and {
    grid-column: 4/-1;
  }
`;

export const OptionIndex = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  font-size: 0.8rem;
  font-weight: bold;
  padding: 8px 16px;
  background: ${grayscale800};
  color: white;
  border-radius: 4px 4px 0 0;
`;

export const RegionList = styled.ul`
  ${ulMargin};
  ${listMargin(48)};
`;
