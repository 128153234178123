import styled from 'styled-components';

export const SearchBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 1rem;
`;

export const TableWrapper = styled.div`
  height: 500px;
  margin: 20px 0;
`;

export const PreviousSelectedBrandsWrapper = styled.div`
  width: 100%;

  background-color: #fafafa;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;

  h2 {
    margin: 0 0 0.5rem 0;
    font-size: 1rem;
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;
    max-height: 80px;

    margin: 0;
    padding-left: 1rem;
    list-style: none;

    li {
      margin-bottom: 0.5rem;
      &:not(:last-of-type) {
        margin-right: 0.5rem;
      }
    }
  }
`;
