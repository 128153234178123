import { CreditForm, FetchingUsersParams, BulkCreditForm } from '@TS/cashplace/users';
import { axiosInstancesManager } from '@API/axios';
/* eslint class-methods-use-this: 0 */

class CashplaceUsersApi {
  get({ perPage, page, userId }: FetchingUsersParams) {
    return axiosInstancesManager.manager.get('/cashplace/users', {
      params: {
        per_page: perPage,
        page,
        user_id: userId
      }
    });
  }

  giveCredit({ userId, credit, kind, message, title }: CreditForm) {
    return axiosInstancesManager.manager.post(`/cashplace/users/${userId}/credits`, {
      credit,
      kind,
      message,
      title
    });
  }

  giveCredits({ userCreditList, title, message, kind }: BulkCreditForm) {
    return axiosInstancesManager.manager.post('/cashplace/users/credits', {
      user_credit_list: userCreditList,
      kind,
      message,
      title
    });
  }

  getStatsAll() {
    return axiosInstancesManager.manager.get('/cashplace/users/stats/all');
  }

  getStatsByPeriod({ startDate, endDate }: { startDate: string; endDate: string }) {
    return axiosInstancesManager.manager.get('/cashplace/users/stats/period', {
      params: {
        start_date: startDate,
        end_date: endDate
      }
    });
  }
}
export default new CashplaceUsersApi();
