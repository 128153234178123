import styled from 'styled-components';

import { PaginationContextProvider } from '@HOOK/usePagination';

import { ManageNoticeProvider } from './useManageNotice';

export default function ManageNoticeLayout({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <PaginationContextProvider initialOption={{ page: 1, perPage: 20, totalCount: 0 }}>
      <ManageNoticeProvider>
        <StyledManageRecruit>{children}</StyledManageRecruit>
      </ManageNoticeProvider>
    </PaginationContextProvider>
  );
}

const StyledManageRecruit = styled.div`
  .add-recruit-info {
    margin-bottom: 20px;
  }
`;
