import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Switch,
  colors
} from '@material-ui/core';

import styled from '@emotion/styled';
import ReactLoading from 'react-loading';
import { toastSuccessMessage } from '@FUNC/toast';

import Wrapper from '@F/materialUI/Wrapper';
import TableHeadCell from '@F/table/cells/TableHeadCell';
import ConfirmModal from '@F/modal/ConfirmModal';
import { Notice, NoticeTableFields } from '@TS/max/manageNotice';

import { useManageNotice } from '../useManageNotice';
import TableBodySkeleton from './TableBodySkeleton';
import {
  NOTICE_STATUS_TEXT,
  NOTICE_TABLE_HEAD_CELLS,
  NOTICE_TABLE_INITIAL_ORDER_BY,
  NOTICE_TYPE_TEXT
} from '../constant';

export default function NoticesTable(): JSX.Element {
  const { pagedNotices, sortNotices, fetchApiStatus } = useManageNotice();
  const [orderName, setOrderName] = useState(NOTICE_TABLE_INITIAL_ORDER_BY.name);

  const handleClickHeadCell = (
    _: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    _name?: string,
    toggledOrderType?: OrderType
  ): void => {
    if (!_name || !toggledOrderType) return;

    const name = _name as NoticeTableFields;
    setOrderName(name);
    sortNotices(name, toggledOrderType);
  };

  return (
    <Wrapper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {NOTICE_TABLE_HEAD_CELLS.map(({ name, label, defaultOrderType }, i) => (
                <TableHeadCell
                  key={i}
                  name={name}
                  label={label}
                  defaultOrderType={defaultOrderType}
                  onClick={handleClickHeadCell}
                  isActive={Boolean(name === orderName)}
                />
              ))}
            </TableRow>
          </TableHead>
          {fetchApiStatus === 'loading' ? <TableBodySkeleton /> : null}
          <TableBody>
            {fetchApiStatus === 'idle' ? (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  <span>검색해주세요.</span>
                </TableCell>
              </TableRow>
            ) : null}
            {fetchApiStatus === 'success' ? (
              pagedNotices.length ? (
                pagedNotices.map((notice) => <TableItem key={notice.id} notice={notice} />)
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <span>검색된 결과가 없습니다.</span>
                  </TableCell>
                </TableRow>
              )
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
}

function TableItem({ notice }: { notice: Notice }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { togglePublishNotice, togglePublishApiStatuses } = useManageNotice();
  const [showConfirmTogglePublishStatus, setShowConfirmTogglePublishStatus] = useState(false);

  const { id, title, creator, type, created_at, published_at, status } = notice;

  const bodyCells: { [field in NoticeTableFields]: string } = {
    id: String(id),
    title,
    creator,
    type: NOTICE_TYPE_TEXT[type],
    created_at: dayjs(created_at).format('YYYY-MM-DD HH:mm:ss'),
    published_at: published_at ? dayjs(published_at).format('YYYY-MM-DD HH:mm:ss') : '',
    status: NOTICE_STATUS_TEXT[status]
  };

  return (
    <>
      <StyledTableRow hover onClick={() => navigate(`${pathname}/${notice.id}`)}>
        {Object.entries(bodyCells).map(([name, value]) => (
          <TableCell key={name} align="center">
            <StyledPublishCellWrapper>
              {name === 'status' && value !== '삭제' ? (
                togglePublishApiStatuses[id] === 'loading' ? (
                  <ReactLoading type="cylon" color={colors.indigo[600]} height={40} width={40} />
                ) : (
                  <Switch
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowConfirmTogglePublishStatus(true);
                    }}
                    checked={status === 'PUBLISH'}
                  />
                )
              ) : (
                value
              )}
            </StyledPublishCellWrapper>
          </TableCell>
        ))}
      </StyledTableRow>
      <ConfirmModal
        isOpen={showConfirmTogglePublishStatus}
        disabled={togglePublishApiStatuses[id] === 'loading'}
        title={
          togglePublishApiStatuses[id] === 'loading'
            ? `공지사항${notice.status === 'PUBLISH' ? ' 발행을 취소' : '을 발행'}하는 중입니다..`
            : `공지사항${notice.status === 'PUBLISH' ? ' 발행을 취소' : '을 발행'}하시겠습니까?`
        }
        onPermit={() => {
          togglePublishNotice(notice).then((isSuccess) => {
            setShowConfirmTogglePublishStatus(false);
            isSuccess &&
              toastSuccessMessage(
                `공지사항이 성공적으로 ${notice.status === 'PUBLISH' ? '발행 취소' : '발행'}되었습니다.`
              );
          });
        }}
        onCancel={() => setShowConfirmTogglePublishStatus(false)}
      />
    </>
  );
}

const StyledPublishCellWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
`;

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
`;
