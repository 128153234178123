import { getEventsParams, updateEventParams } from '@TS/cashplace/event';
import { axiosInstancesManager } from '@API/axios';
/* eslint class-methods-use-this: 0 */

class CashplaceEventsApi {
  getEvents({ status, kind, page, perPage }: getEventsParams) {
    return axiosInstancesManager.manager.get('/cashplace/events', {
      params: {
        status,
        kind,
        page,
        per_page: perPage
      }
    });
  }

  postEvent({ content, description, eventUrl, mainImageUrl, subImageUrl, buttonName, kind }: updateEventParams) {
    return axiosInstancesManager.manager.post('/cashplace/events', {
      content,
      event_url: eventUrl,
      main_img_url: mainImageUrl,
      sub_img_url: subImageUrl,
      btn_name: buttonName,
      kind,
      desc: description
    });
  }

  putEvent(
    eventId: number,
    { content, description, eventUrl, mainImageUrl, subImageUrl, buttonName, kind, status }: updateEventParams
  ) {
    return axiosInstancesManager.manager.put(`/cashplace/events/${eventId}`, {
      content,
      event_url: eventUrl,
      main_img_url: mainImageUrl,
      sub_img_url: subImageUrl,
      btn_name: buttonName,
      kind,
      desc: description,
      status
    });
  }
}
export default new CashplaceEventsApi();
