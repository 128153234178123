import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import ReactLoading from 'react-loading';
import { TableCell, TableRow, Switch, IconButton, colors } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { ArticleStatus, Article } from '@TS/homepage/article';
import ConfirmModal from '@F/modal/ConfirmModal';
import { actions } from '../redux/slice';

/**
 * ArticleItem
 *
 * [UI state]
 * redux/articles의 관심사 밖인, ArticleItem UI에 의존하는 상태들은 ArticleItem 컴포넌트의 로컬 상태로 관리합니다.
 * 비동기 처리 후, success/failure에 따라 UI state를 변경하기 위해 관련 로직을 payload.callback으로 전달합니다.
 * status를 통해 ArticleItem의 업데이트, 삭제 요청 중인 상태를 확인합니다.
 *
 * */

interface ArticleItemProps {
  article: Exclude<Article, 'image-url' | 'content'>;
}

export default function ArticleItem({
  article: { id, status: publishStatus, title, creator, created_at, updated_at, published_at, url }
}: ArticleItemProps): JSX.Element | null {
  // UI state
  const [isOpenConfirmDeleteArticle, setIsOpenConfirmDeleteArticle] = useState(false);
  const [status, setStatus] = useState('idle');
  const isPublished = publishStatus === 'PUBLISH';

  // helper
  const dispatch = useDispatch();
  const openArticleUrlPage = () => window.open(url);
  const getToggledStatus = (isPublished: boolean): Extract<ArticleStatus, 'PENDING' | 'PUBLISH'> =>
    isPublished ? 'PENDING' : 'PUBLISH';

  // handlers
  const handleClickPublishStatusToggler = () => {
    setStatus('updating');

    dispatch(
      actions.updateArticle({
        id,
        data: {
          status: getToggledStatus(isPublished)
        },
        callback: () => setStatus('idle')
      })
    );
  };
  const handleClickDeleteBtn = () => setIsOpenConfirmDeleteArticle(true);
  const handleCancelDeleteArticle = () => setIsOpenConfirmDeleteArticle(false);
  const handlePermitDeleteArticle = () => {
    setIsOpenConfirmDeleteArticle(false);
    setStatus('deleting');

    dispatch(
      actions.deleteArticle({
        id,
        callback: () => setStatus('idle')
      })
    );
  };

  return (
    <TableRow hover key={id}>
      <TableCell align="center" component="th" scope="row" onClick={openArticleUrlPage}>
        <StyledTitleCellInner>{title}</StyledTitleCellInner>
      </TableCell>
      <TableCell align="center">{moment(published_at).format('YYYY-MM-DD')}</TableCell>
      <TableCell align="center">{creator}</TableCell>
      <TableCell align="center">{moment(created_at).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
      <TableCell align="center">{moment(updated_at).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
      <TableCell align="center">
        {status === 'updating' ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ReactLoading type="cylon" color={colors.indigo[600]} height={40} width={40} />
          </div>
        ) : (
          <Switch color="primary" onClick={handleClickPublishStatusToggler} checked={isPublished} />
        )}
      </TableCell>
      <TableCell align="center">
        <IconButton aria-label="delete" onClick={handleClickDeleteBtn} disabled={status === 'deleting'}>
          {status === 'deleting' ? (
            <ReactLoading type="cylon" color={colors.indigo[600]} height={20} width={20} />
          ) : (
            <DeleteIcon />
          )}
        </IconButton>
      </TableCell>
      <ConfirmModal
        isOpen={isOpenConfirmDeleteArticle}
        onCancel={handleCancelDeleteArticle}
        onPermit={handlePermitDeleteArticle}
        title="삭제"
        subTitle="해당 기사 내용을 삭제하시겠습니까?"
        content="삭제된 기사 정보 조회는 백엔드 관리자에게 문의해주세요."
      />
    </TableRow>
  );
}

const StyledTitleCellInner = styled.b`
  line-height: 1.5;
  color: ${colors.blue[600]};
  text-decoration: underline;

  :hover {
    color: ${colors.blue[800]};
    cursor: pointer;
  }
`;
