import TextInputForm from '@F/materialUI/TextInputForm';
import ComplexModal from '@F/modal/ComplexModal';
import { Tooltip } from '@material-ui/core';
import { useEffect, useState } from 'react';

type SelectedComplex = {
  name: string;
  id: string;
};

type ComplexInputProps = {
  onChange: (complex: SelectedComplex | null) => void;
  initialState?: SelectedComplex | null;
  label?: string;
  required?: boolean;
  initialValueType?: ComplexInputValueType;
};

/* 
  complex = { name: '', id: '' }
  검색 시 name으로
  수동 입력 시 id로
*/
type ComplexInputValueType = 'name' | 'id';

export default function ComplexInput({
  onChange,
  initialState = null,
  label = '복합몰',
  required = false,
  initialValueType = 'id'
}: ComplexInputProps): JSX.Element {
  const [complex, setComplex] = useState<SelectedComplex | null>(initialState);
  const [showComplexModal, setShowComplexModal] = useState(false);
  const [valueType, setValueType] = useState<ComplexInputValueType>(initialValueType);

  useEffect(() => {
    onChange(complex);
  }, [complex]);

  const openModal = () => setShowComplexModal(true);

  return (
    <div>
      <Tooltip
        title={
          complex
            ? `${Object.entries(complex)
                .filter((entry) => entry[1])
                .map((entry) => `${entry[0]}: ${entry[1]}`)
                .join(' , ')}`
            : ''
        }
        placement="top-start"
      >
        <TextInputForm
          name="ComplexInput"
          label={label}
          value={complex ? (valueType === 'id' ? complex.id : `${complex.id}(${complex.name})`) : ''}
          onChange={(e) => {
            setValueType('id');
            setComplex({ name: '', id: e.target.value });
          }}
          onClear={required ? undefined : () => setComplex(null)}
          onSearch={() => {
            setValueType('name');
            openModal();
          }}
        />
      </Tooltip>
      <ComplexModal open={showComplexModal} setOpen={setShowComplexModal} setValue={setComplex} />
    </div>
  );
}
