import styled, { css } from 'styled-components';

export const StyledTableSection = styled.div``;

export const IdText = styled.span`
  ${(props) =>
    props.emphasize &&
    css`
      color: ${props.theme.palette.text.primary};
    `};
`;
