import type { RootStore } from '@TS/redux';
import type { State } from './slice';

export const selectStatus = (state: RootStore): State['status'] => state.max.status;
export const selectError = (state: RootStore): State['error'] => state.max.error;
export const selectParams = (state: RootStore): State['params'] => state.max.params;
export const selectCampaigns = (state: RootStore): State['campaigns'] => state.max.campaigns;
export const selectCampaignDetail = (state: RootStore): State['campaignDetails'] => state.max.campaignDetails;

export const selectPagination = (
  state: RootStore
): Pick<State['params'], 'size' | 'page'> & Pick<State, 'totalCount'> => ({
  page: state.max.params.page,
  size: state.max.params.size,
  totalCount: state.max.totalCount
});

export const selectOrderType = (state: RootStore): State['orderType'] => state.max.orderType;
export const selectCompareCampaigns = (state: RootStore): State['compareCampaigns'] => state.max.compareCampaigns;
