import { getPercent, roundToDecimalPoint } from '@FUNC/math';
import type { CampaignReport } from './type';
import type { CampaignDetail } from '../redux/campaigns/slice';

export const schemeReport = (campaign: CampaignDetail): CampaignReport => {
  const { hitCnt = 0, clickCnt = 0 } = campaign;
  return {
    hitCnt,
    click: { count: clickCnt, percent: roundToDecimalPoint(getPercent(clickCnt, hitCnt), 2) }
  };
};
