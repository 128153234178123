import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';

import { cookPlaceTodosApi } from '@API/manager/cook';
import { snakeObjToCamelObj } from '@/utils/functions/word';
import { selectParams } from './selector';
import { actions } from './slice';

// saga

function* updateUnCollectedComplexPlaceTodo({ payload: { siteId, action, callback } }) {
  try {
    yield call(cookPlaceTodosApi.patch, { siteId, action });

    // 리덕스 패턴 상 success에서 데이터를 mutate해줘야하지만, backend에서 response를 넘겨주기 어렵다고 판단하여 구현 보류합니다.
    // yield put(actions.updatePlaceTodoSuccess({ siteId, data: snakeObjToCamelObj(data) }));
    callback(true);
  } catch (error) {
    callback(false);
  }
}

function* fetchUnCollectedComplexPlaceTodos({ payload: newParams }) {
  try {
    if (newParams) yield put(actions.setParams(newParams));
    yield put(actions.fetchUnCollectedComplexPlaceTodosStart());

    const params = yield select(selectParams);

    const {
      data: { count, items }
    } = yield call(cookPlaceTodosApi.getAll, params);

    yield put(
      actions.fetchUnCollectedComplexPlaceTodosSuccess({
        data: items.map(snakeObjToCamelObj),
        totalCount: count
      })
    );
  } catch (e) {
    yield put(actions.fetchUnCollectedComplexPlaceTodosFailure(e));
  }
}

function* changePage({ payload: page }) {
  const params = yield select(selectParams);

  if (params.page === page) return;

  const newParams = { ...params, page };

  yield put(actions.setParams(newParams));
  yield put(actions.fetchUnCollectedComplexPlaceTodos());
}

export default function* sagaReducer() {
  yield takeLatest(actions.fetchUnCollectedComplexPlaceTodos().type, fetchUnCollectedComplexPlaceTodos);
  yield takeEvery(actions.updateUnCollectedComplexPlaceTodo().type, updateUnCollectedComplexPlaceTodo);
  yield takeLatest(actions.changePage().type, changePage);
}
