import { useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import firebase from 'firebase/app';
import DialogModal from '@F/DialogModal';
import type { Contact, Image } from '@TS/cashplace/contact';
import type { Study } from '@TS/cashplace/study';
import { cashplaceStudiesApi } from '@API/manager/cashplace';
import { actions } from '@/redux/cashplace/contact/state';
import * as Inspect from '@/redux/cashplace/inspect/state';
import { FIREBASE_CASHPLACE_URL, MODE } from '@/config';
import { userStore } from '@/mobX/user';
import * as S from './styles';

type DetailProps = {
  data: Contact[];
  id?: number;
};

function Detail({ data, id }: DetailProps): JSX.Element {
  const contact = useMemo(() => data.find((element) => element.contact_id === id), [id, data]);

  const [study, setStudy] = useState<Study | null>(null);
  const [images, setImages] = useState<string[] | null>(null);
  const [imageInfos, setImageInfos] = useState<Image[] | null>(null);

  const [feedback, setFeedback] = useState<string>(contact?.reply || '');
  const FeedbackInput = (
    <textarea id="FeedbackInput" value={feedback} onChange={(e): void => setFeedback(e.target.value)} />
  );
  const dispatch = useDispatch();
  const reply = () => {
    if (feedback) {
      dispatch(actions.updateContact(contact, { reply: feedback }));
    }
  };

  useEffect(() => {
    if (!id) return;
    if (!contact) return;
    if (contact?.study_id) {
      (async () => {
        const response = await cashplaceStudiesApi.getStudies({
          page: 1,
          perPage: 1,
          studyId: String(contact.study_id)
        });
        setStudy(response.data.result[0]);
      })();
    }
  }, [id, contact]);

  useEffect(() => {
    if (!study) return;

    const names = study.image_names;
    setImageInfos(study.image_results);

    // NOTE: load images from firebase.
    const imageLength = names.length;
    const newImages = Array(imageLength).fill(null);
    let count = 0;
    names.forEach((image: string, index: number) => {
      const url = firebase
        .storage()
        .refFromURL(`${FIREBASE_CASHPLACE_URL}/${MODE === 'staging' ? 'images-staging' : 'images'}/${image}`);
      url
        .getDownloadURL()
        .then((responseUrl) => {
          newImages.splice(index, 1, responseUrl);
          if (count === imageLength - 1) {
            setImages(newImages);
          }
        })
        .finally(() => {
          count += 1;
        });
    });
  }, [study]);

  function changeInout(seq: number, value: string) {
    if (value !== 'in' && value !== 'out' && value !== 'unknown') return;
    setImageInfos(
      (prevState) =>
        prevState &&
        prevState.map((item: Image, index: number) => {
          if (index === seq) {
            return {
              ...item,
              inout: value
            };
          }
          return item;
        })
    );
  }

  const changeAllIn = () => {
    setImageInfos(
      (prevState) =>
        prevState &&
        prevState.map((item: Image) => ({
          ...item,
          inout: 'in'
        }))
    );
  };

  const changeAllOut = () => {
    setImageInfos(
      (prevState) =>
        prevState &&
        prevState.map((item: Image) => ({
          ...item,
          inout: 'out'
        }))
    );
  };

  const [isForced, setIsForced] = useState(false);
  const IsForcedCheckbox = (
    <FormControlLabel
      control={
        <Checkbox color="primary" checked={isForced} onChange={(): void => setIsForced((prevState) => !prevState)} />
      }
      label="FORCE"
    />
  );
  const isCompleted = useMemo(() => !!(study && study.slot), [study]);
  const IsCompletedCheckbox = (
    <FormControlLabel control={<Checkbox color="primary" disabled checked={isCompleted} />} label="완료여부" />
  );
  const [isClosed, setIsClosed] = useState(false);
  const IsClosedCheckbox = (
    <FormControlLabel
      control={
        <Checkbox color="primary" checked={isClosed} onChange={(): void => setIsClosed((prevState) => !prevState)} />
      }
      label="사이트 닫기"
    />
  );

  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const user = userStore.getUser();

  const approve = () => {
    if (!study) return;
    if (!user) return;
    dispatch(
      Inspect.actions.updateInspect({
        studyId: study.study_log.study_id,
        siteId: study.study_log.site_id,
        userId: study.study_log.user_id,
        images: imageInfos,
        result: 1,
        inspector: typeof user.identity === 'string' && user.identity,
        complete: isClosed
      })
    );
    setIsApproveModalOpen(false);
    dispatch(actions.fetchContacts());
  };
  const reject = () => {
    if (!study) return;
    if (!user) return;
    dispatch(
      Inspect.actions.updateInspect({
        studyId: study.study_log.study_id,
        siteId: study.study_log.site_id,
        userId: study.study_log.user_id,
        images: imageInfos,
        result: -1,
        inspector: typeof user.identity === 'string' && user.identity,
        complete: isClosed
      })
    );
    setIsRejectModalOpen(false);
    dispatch(actions.fetchContacts());
  };

  return (
    <S.StyledDetail>
      {FeedbackInput}
      <DialogModal
        title="정말 승인하시겠습니까?"
        description="한번 선택하면 돌이킬 수 없습니다."
        open={isApproveModalOpen}
        setOpen={setIsApproveModalOpen}
        confirmButtonDidClicked={approve}
      />
      <DialogModal
        title="정말 거부하시겠습니까?"
        description="한번 선택하면 돌이킬 수 없습니다."
        open={isRejectModalOpen}
        setOpen={setIsRejectModalOpen}
        confirmButtonDidClicked={reject}
      />
      <S.Row>
        {study ? (
          <S.Row>
            <S.Cell>
              <p>장소명</p>
              <p>{study?.site.name}</p>
            </S.Cell>
            <S.Cell>
              <p>주소</p>
              <p>{study?.site.addr}</p>
            </S.Cell>
            <S.Cell>
              <p>리워드</p>
              <p>{study?.slot.credit}원</p>
            </S.Cell>
          </S.Row>
        ) : null}
        {!study && <div />}
        <S.Row>
          {/* {study ? <Button variant="contained" color="default">이미지 열기</Button> : null} */}
          <Button variant="contained" color="primary" className="FeedbackButton" onClick={reply}>
            답변 달기
          </Button>
        </S.Row>
      </S.Row>
      {study && images === null && <S.RedText>로딩 중입니다. 시간이 지연될 시 해당 Row를 다시 펼쳐주세요.</S.RedText>}
      {study && images !== null && images.length !== 0 && (
        <S.ImageSection>
          {images.filter(Boolean).map((image, index) => (
            <S.ImageWrapper key={image + index}>
              {imageInfos && imageInfos[index] && (
                <p>{imageInfos[index].floor ? `${imageInfos[index].floor} 층` : ''}</p>
              )}
              <img key={image} src={image} alt="로딩중" />
              {imageInfos && imageInfos[index] && (
                <RadioGroup
                  row
                  aria-label="inout"
                  name="inout"
                  value={imageInfos ? imageInfos[index].inout : 'unknown'}
                  onChange={(e) => changeInout(index, e.target.value)}
                >
                  <FormControlLabel value="unknown" control={<Radio />} label="?" />
                  <FormControlLabel value="in" control={<Radio />} label="in" />
                  <FormControlLabel value="out" control={<Radio />} label="out" />
                </RadioGroup>
              )}
            </S.ImageWrapper>
          ))}
        </S.ImageSection>
      )}
      {study && images !== null && images.length !== 0 && (
        <div style={{ width: '100%', maxWidth: '500px' }}>
          <S.Row>
            <Grid item xs>
              {IsForcedCheckbox}
            </Grid>
            <Grid item xs>
              {IsCompletedCheckbox}
            </Grid>
            <Grid item xs>
              {IsClosedCheckbox}
            </Grid>
          </S.Row>

          <S.Row>
            <Grid item xs>
              <Button variant="outlined" color="primary" onClick={changeAllIn}>
                ALL IN
              </Button>
            </Grid>
            <Grid item xs>
              <Button variant="outlined" color="secondary" onClick={changeAllOut}>
                ALL OUT
              </Button>
            </Grid>

            <Grid item xs={1} />

            <Grid item xs>
              <Button variant="contained" color="primary" onClick={() => setIsApproveModalOpen(true)}>
                승인
              </Button>
            </Grid>
            <Grid item xs>
              <Button variant="contained" color="secondary" onClick={() => setIsRejectModalOpen(true)}>
                거부
              </Button>
            </Grid>
          </S.Row>
        </div>
      )}
      {study && images !== null && images.length === 0 && <S.RedText>이미지가 존재하지 않습니다.</S.RedText>}
    </S.StyledDetail>
  );
}
export default Detail;
