import { useState } from 'react';
import PropTypes from 'prop-types';
import TextInputForm from '@F/materialUI/TextInputForm';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Button, Checkbox } from '@material-ui/core';
import SelectForm from '@F/materialUI/SelectForm';
import { originOptions } from '@P/complex/addComplex/inputs/Inputs/OriginOptions';
import Grid from '@material-ui/core/Grid';
// import BrandModal from '@F/modal/BrandModal';
import CategoryModal from '@F/modal/CategoryModal';
import ComplexInput from '@F/input/ComplexInput';

import Loading from '@F/Loading';
import { complexesApi } from '@API/manager';
import ResponseAlert from '@F/ResponseAlert';
import { toastErrorMessage } from '@FUNC/toast';
import { useFetch, useInput } from '@HOOK/';
import * as S from './styles';

function Inputs({ setComplexData }) {
  const classes = S.useStyles();
  const { callApi, isLoading, isError, error } = useFetch();
  const [responseAlertOpen, setResponseAlertOpen] = useState(false);
  const [isCreateComplexButtonClicked, setIsCreateComplexButtonClicked] = useState(false);

  const [name, setName] = useInput();
  const NameInput = (
    <TextInputForm
      name="NameInput"
      label="장소명"
      onChange={setName}
      value={name}
      required
      error={isCreateComplexButtonClicked && name.length === 0}
    />
  );

  const [nameOrigin, setNameOrigin] = useInput('네이버 지도');
  const NameOriginSelect = (
    <SelectForm
      name="storeNameOriginSelect"
      label="장소명 출처"
      options={originOptions.map((option) => option.value)}
      value={nameOrigin}
      onSelect={setNameOrigin}
      disabled={false}
    />
  );

  const [branchName, setBranchName] = useInput();
  const BranchNameInput = (
    <TextInputForm name="BranchNameInput" label="지점명" onChange={setBranchName} value={branchName} />
  );

  const [branchNameOrigin, setBranchNameOrigin] = useInput('미입력');
  const BranchNameOriginSelect = (
    <SelectForm
      name="branchNameOriginSelect"
      label="주소 출처"
      options={originOptions.map((option) => option.value)}
      value={branchNameOrigin}
      onSelect={setBranchNameOrigin}
      disabled={false}
    />
  );

  const [oldAdress, setOldAdress] = useInput(); // 지번 주소
  const OldAdressInput = (
    <TextInputForm
      name="OldAdressInput"
      label="지번 주소"
      onChange={setOldAdress}
      value={oldAdress}
      required
      error={isCreateComplexButtonClicked && oldAdress.length === 0}
    />
  );

  const [newAdress, setNewAdress] = useInput(); // 도로명 주소
  const NewAdressInput = (
    <TextInputForm name="BranchNameInput" label="도로명 주소" onChange={setNewAdress} value={newAdress} />
  );

  const [adressOrigin, setAdressOrigin] = useInput('네이버 지도');
  const AdressOriginSelect = (
    <SelectForm
      name="adressOriginSelect"
      label="지점명 출처"
      options={originOptions.map((option) => option.value)}
      value={adressOrigin}
      onSelect={setAdressOrigin}
      disabled={false}
    />
  );

  const [category, setCategory] = useState({
    korName: '',
    code: ''
  });
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
  const CategoryInput = (
    <TextInputForm
      name="CategoryInput"
      label="카테고리"
      onClick={() => setIsCategoryModalVisible(!isCategoryModalVisible)}
      value={category.korName}
      required
      error={isCreateComplexButtonClicked && category.korName.length === 0}
      onClear={() =>
        setCategory({
          korName: '',
          code: ''
        })
      }
    />
  );

  const [parentComplex, setParentComplex] = useState(null);
  const parentComplexInput = <ComplexInput label="부모 복합몰" onChange={setParentComplex} />;

  const [latitude, setLatitude] = useInput(); // 위도
  const LatitudeInput = (
    <TextInputForm
      name="BranchNameInput"
      label="위도"
      onChange={setLatitude}
      value={latitude}
      required
      error={isCreateComplexButtonClicked && (latitude.length === 0 || !IsRationalNumber(latitude))}
    />
  );

  const [longtitude, setLongtitude] = useInput(); // 경도
  const LogtitudeInput = (
    <TextInputForm
      name="LogtitudeInput"
      label="경도"
      onChange={setLongtitude}
      value={longtitude}
      required
      error={isCreateComplexButtonClicked && (longtitude.length === 0 || !IsRationalNumber(longtitude))}
    />
  );

  // 1.13일 기준 기획에서 브랜드 입력창이 없습니다. 추후에 필요할 수 있어서 주석처리 해두었습니다.
  // const [brands, setBrands] = useState([]);
  // const [isBrandModalVisible, setIsBrandModalVisible] = useState(false);
  // const BrandsInput = (
  //   <TextInputForm
  //     id="BrandsInput"
  //     label="브랜드"
  //     onClick={() => setIsBrandModalVisible(!isBrandModalVisible)}
  //     value={brands.map(brand => `${brand.name}(${brand.id})`).join(', ')}
  //   />
  // );

  const [isShownInLoplatService, setIsShownInLoplatService] = useState(false); // 로플랫 X/ i 노출
  const IsShownInLoplatServiceOption = (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          checked={isShownInLoplatService}
          onChange={() => setIsShownInLoplatService(!isShownInLoplatService)}
        />
      }
      label="로플랫 X/i 노출"
    />
  );

  const initializeInputs = () => {
    setName({ target: { value: '' } });
    setBranchName({ target: { value: '' } });
    setOldAdress({ target: { value: '' } });
    setNewAdress({ target: { value: '' } });
    setCategory({
      korName: '',
      code: ''
    });
    setLatitude({ target: { value: '' } });
    setLongtitude({ target: { value: '' } });
    setParentComplex(null);
    setIsShownInLoplatService(false);
  };

  const createComplex = async () => {
    setIsCreateComplexButtonClicked(true);
    if (!name || !oldAdress || !category.code || !latitude || !longtitude) {
      toastErrorMessage('필수값이 채워지지 않았습니다.');
      return;
    }
    const response = await callApi(complexesApi.addComplex, {
      name,
      branchName,
      categoryCode: category.code,
      addr: oldAdress,
      addrRoad: newAdress,
      lat: latitude,
      lng: longtitude,
      parent: parentComplex ? parentComplex.id : null,
      service: isShownInLoplatService ? 1 : 0,
      qaName: originOptions.filter((option) => option.value === nameOrigin)[0].key,
      qaBranchName: originOptions.filter((option) => option.value === branchNameOrigin)[0].key,
      qaAddress: originOptions.filter((option) => option.value === adressOrigin)[0].key
    });
    setResponseAlertOpen(() => true);
    if (response) {
      const responseRecentComplexes = await callApi(complexesApi.getComplexes, {
        perPage: 10,
        page: 1
      });
      if (responseRecentComplexes) {
        setComplexData(responseRecentComplexes.data.items);
        initializeInputs();
        setIsCreateComplexButtonClicked(false);
      }
    }
  };
  return (
    <S.Form
      onSubmit={(event) => {
        event.preventDefault();
        createComplex();
      }}
    >
      {isLoading && <Loading />}
      <ResponseAlert
        error={isError}
        open={responseAlertOpen}
        handleClose={() => setResponseAlertOpen(() => false)}
        message={isError ? String(error) : '성공하였습니다.'}
      />
      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {NameInput}
        </Grid>
        <Grid item xs>
          {NameOriginSelect}
        </Grid>
        <Grid item xs>
          {OldAdressInput}
        </Grid>
        <Grid item xs>
          {AdressOriginSelect}
        </Grid>
      </S.Row>

      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {BranchNameInput}
        </Grid>
        <Grid item xs>
          {BranchNameOriginSelect}
        </Grid>
        <Grid item xs>
          {NewAdressInput}
        </Grid>
        <Grid item xs />
      </S.Row>

      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {CategoryInput}
          <CategoryModal open={isCategoryModalVisible} setOpen={setIsCategoryModalVisible} setValue={setCategory} />
        </Grid>
        <Grid item xs>
          {LatitudeInput}
        </Grid>
        <Grid item xs>
          {LogtitudeInput}
        </Grid>
      </S.Row>

      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {parentComplexInput}
        </Grid>
        {/* {1.13일 기준 기획에서 브랜드 입력창이 없습니다. 추후에 필요할 수 있어서 주석처리 해두었습니다. } */}
        {/* <Grid item xs> */}
        {/*  {BrandsInput} */}
        {/*  <BrandModal */}
        {/*    open={isBrandModalVisible} */}
        {/*    setOpen={setIsBrandModalVisible} */}
        {/*    setValue={setBrands} */}
        {/*  /> */}
        {/* </Grid> */}
      </S.Row>

      <S.Row container className={classes.margin} spacing={1}>
        {IsShownInLoplatServiceOption}
      </S.Row>

      <S.ButtonRow>
        <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
          생성하기
        </Button>
      </S.ButtonRow>
    </S.Form>
  );
}
export default Inputs;

Inputs.propTypes = {
  setComplexData: PropTypes.func.isRequired
};

function IsRationalNumber(value) {
  const pattern = /^[-]?\d+(?:[.]\d+)?$/;
  return pattern.test(value);
}
