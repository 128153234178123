import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Grid, Switch } from '@material-ui/core';
import moment from 'moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import Divider from '@material-ui/core/Divider';
import CategoryModal from '@F/modal/CategoryModal';
import Loading from '@F/Loading';
import { useNavigate } from 'react-router-dom';
import DatePickers from '@F/materialUI/datePicker/DatePickers';
import ComplexInput from '@F/input/ComplexInput';
import BrandsInput from '@F/input/BrandsInput';
import { useDispatch, useSelector } from 'react-redux';
import { useInput } from '@HOOK/';
import { actions } from '@/redux/complex/state';
import * as S from './styles';
import PER_PAGE_OPTIONS from '@/constants/perPageOptions';

function Filter({ initialCid }) {
  const classes = S.useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.complexManager);

  const [complex, setComplex] = useState({ name: '', id: initialCid });
  const complexInput = <ComplexInput initialState={{ name: '', id: initialCid }} onChange={setComplex} />;

  const [name, setName] = useInput();
  const NameInput = <TextInputForm name="NameInput" label="장소명" onChange={setName} value={name} />;
  const [nameMatchExactlyOption, setNameMatchExactlyOption] = useState(false);
  const StoreNameMatchExactlyOption = (
    <FormControlLabel
      value="StoreNameMatchExactlyOption"
      control={
        <Checkbox
          color="primary"
          checked={nameMatchExactlyOption}
          onChange={() => setNameMatchExactlyOption(!nameMatchExactlyOption)}
        />
      }
      label="정확히 일치"
    />
  );

  const [category, setCategory] = useState({ korName: '', code: '' });
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
  const [isWithoutCategory, setIsWithoutCategory] = useState(false);

  const CategoryInput = (
    <TextInputForm
      name="CategoryInput"
      label="카테고리"
      onClick={() => setIsCategoryModalVisible(!isCategoryModalVisible)}
      value={category.korName}
      onClear={() =>
        setCategory({
          korName: '',
          code: ''
        })
      }
    />
  );

  const WithoutCategoryOption = (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          checked={isWithoutCategory}
          onChange={() => setIsWithoutCategory(!isWithoutCategory)}
        />
      }
      label="선택 카테고리 제외"
    />
  );
  const [isCategoryNull, setIsCategoryNull] = useState(false);
  const CategoryNullOption = (
    <FormControlLabel
      control={
        <Checkbox color="primary" checked={isCategoryNull} onChange={() => setIsCategoryNull(!isCategoryNull)} />
      }
      label="카테고리 Null"
    />
  );

  const [branchName, setBranchName] = useInput();
  const BranchNameInput = (
    <TextInputForm name="BranchNameInput" label="지점명" onChange={setBranchName} value={branchName} />
  );

  const [parentComplex, setParentComplex] = useState(null);
  const ParentComplexInput = <ComplexInput label="부모 복합물" onChange={setParentComplex} />;

  const [brands, setBrands] = useState([]);
  const brandsInput = <BrandsInput onChange={setBrands} />;

  const [collector, setCollector] = useInput();
  const CollectorInput = (
    <TextInputForm name="CollectorInput" label="작업자(수집자)" onChange={setCollector} value={collector} />
  );

  const [period, setPeriod] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });
  const [isSearchPeriod, setIsSearchPeriod] = useState(true);
  const confirmPeriod = useCallback((dates) => {
    setPeriod(() => ({
      startDate: dates[0],
      endDate: dates[1]
    }));
  }, []);
  const TimeStampInput = <DatePickers onEmitDate={confirmPeriod} />;
  const SearchPeriodOption = (
    <FormControlLabel
      control={
        <Switch checked={isSearchPeriod} onChange={(_, checked) => setIsSearchPeriod(checked)} color="primary" />
      }
      label="기간 검색"
      labelPlacement="top"
    />
  );

  const [count, setCount] = useInput('15');
  const CountSelect = (
    <SelectForm
      label="개수"
      options={PER_PAGE_OPTIONS}
      value={count}
      onSelect={setCount}
      disabled={false}
      name="CountSelect"
    />
  );

  const pushCidToHistory = useCallback(() => {
    const cid = String(complex.id).trim();
    const numberRegex = /^\d+$/;
    if (numberRegex.test(String(cid))) {
      navigate(`/complex/manager/${cid}`);
    }
  }, [navigate, complex]);

  // fetch data
  const fetchComplexes = () => {
    dispatch(
      actions.setValue('params', {
        name: name || null,
        branchName: branchName || null,
        startDate: isSearchPeriod ? period.startDate : null,
        endDate: isSearchPeriod ? period.endDate : null,
        categoryCode: category.code || null,
        categoryIsNull: isCategoryNull || null,
        categoryExclude: isWithoutCategory || null,
        equalSearch: nameMatchExactlyOption || null,
        collector: collector || null,
        parent: parentComplex ? parentComplex.id : undefined,
        bid: brands.length ? brands[0].id : null
      })
    );
    dispatch(actions.setValue('perPage', Number(count)));
    dispatch(actions.setValue('passedCid', null));
    dispatch(actions.setPage(1));
    dispatch(actions.fetchComplexes());
  };

  const fetchComplex = useCallback(() => {
    if (complex) {
      dispatch(actions.fetchComplex(complex.id));
      dispatch(actions.setValue('perPage', Number(count)));
    }
  }, [dispatch, complex, count]);

  useEffect(() => {
    if (initialCid) {
      fetchComplex();
    } else {
      fetchComplexes();
      setComplex({ name: '', id: '' });
    }
  }, [initialCid]);

  return (
    <S.Form
      onSubmit={(event) => {
        event.preventDefault();
        fetchComplexes();
      }}
    >
      {isLoading && <Loading />}
      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {complexInput}
        </Grid>
        <Grid item xs>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              if (complex) {
                fetchComplex();
                pushCidToHistory();
              }
            }}
          >
            불러오기
          </Button>
        </Grid>
        <Grid item xs={5} />
      </S.Row>

      <Divider variant="middle" />

      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {NameInput}
          {StoreNameMatchExactlyOption}
        </Grid>
        <Grid item xs>
          {CategoryInput}
          {WithoutCategoryOption}
          <CategoryModal open={isCategoryModalVisible} setOpen={setIsCategoryModalVisible} setValue={setCategory} />
        </Grid>
        <Grid item xs>
          {CategoryNullOption}
        </Grid>
      </S.Row>

      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {BranchNameInput}
        </Grid>
        <Grid item xs>
          {ParentComplexInput}
        </Grid>
        <Grid item xs>
          {brandsInput}
        </Grid>
      </S.Row>

      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {CollectorInput}
        </Grid>
        <S.AlignRightGrid item xs={1}>
          {SearchPeriodOption}
        </S.AlignRightGrid>
        <Grid item xs>
          {TimeStampInput}
        </Grid>
        <Grid item xs>
          {CountSelect}
        </Grid>
        <Grid item xs={1} />
        <Grid item>
          <Button variant="contained" color="primary" type="submit">
            불러오기
          </Button>
        </Grid>
      </S.Row>
    </S.Form>
  );
}
export default Filter;

Filter.propTypes = {
  initialCid: PropTypes.string
};

Filter.defaultProps = {
  initialCid: ''
};
