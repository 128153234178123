import type { FetchCampaignStatusKorean } from '@TS/max/campaigns/status';
import { AppModule, CampaignStatusBadge, CampaignName } from '@P/max/campaigns/module';
import type { AppProps } from '@P/max/campaigns/module/AppModule';
import type { InformationCondition, SegmentCondition } from '@P/max/campaigns/utils/type';
import { Section, SectionRow } from '../section';
import Segment from './Segment';
import { GapWrapper } from '../styles';

export type Props = {
  targetApps: AppProps[];
  information: InformationCondition[];
  segment: SegmentCondition;
};

const BasicInfo = ({ information, targetApps, segment }: Props): JSX.Element => (
  <Section title="기본정보">
    {information.map((info) => (
      <BasicInfoElement key={info.title} {...info} />
    ))}

    <SectionRow subTitle="타겟 App">
      <GapWrapper>
        {targetApps.map((app, index) => (
          <AppModule {...app} key={`${app.name}_${index}`} />
        ))}
      </GapWrapper>
    </SectionRow>

    <SectionRow subTitle="타겟 유저 세그먼트" wide>
      <Segment segment={segment} />
    </SectionRow>
  </Section>
);

export default BasicInfo;

const BasicInfoElement = ({ title, content }: InformationCondition): JSX.Element => {
  const contentElement = (): React.ReactNode => {
    if (isStateField(title, content)) return <CampaignStatusBadge status={content} />;
    if (typeof content === 'object') return <CampaignName {...content} />;
    return <span>{content}</span>;
  };

  return <SectionRow subTitle={title}>{contentElement()}</SectionRow>;
};

function isStateField(title: string, content: unknown): content is FetchCampaignStatusKorean {
  if (title === '상태') return true;
  return false;
}
