import { cashplaceEventsApi } from '@API/manager/cashplace';
import { Event, updateEventParams } from '@TS/cashplace/event';

type fetchEventsParams = {
  status: number;
  kind: number;
};

export function fetchEvents(page: number, perPage: number, params: fetchEventsParams): Promise<Event[]> {
  return cashplaceEventsApi.getEvents({ page, perPage, ...params }).then((response) => response.data);
}

export function createEvent(params: updateEventParams): Promise<Event> {
  return cashplaceEventsApi.postEvent(params).then((response) => response.data);
}

export function updateEvent(eventId: number, params: updateEventParams): Promise<Event> {
  return cashplaceEventsApi.putEvent(eventId, params).then((response) => response.data);
}
