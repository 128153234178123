/* eslint-disable no-console */
import styled from '@emotion/styled';
import SearchFilter from './SearchFilter';
import Table from './table';
import Pagination from './Pagination';

export default function UncollectedComplexPlaceTodoManager(): JSX.Element {
  return (
    <StyledUncollectedComplexPlaceTodoManager>
      <SearchFilter />
      <Pagination />
      <Table />
      <Pagination />
    </StyledUncollectedComplexPlaceTodoManager>
  );
}

const StyledUncollectedComplexPlaceTodoManager = styled.div`
  padding: 0 10px 0 20px;
`;
