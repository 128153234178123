import styled from 'styled-components';

export const StyledTableSection = styled.div``;

export const ActionBar = styled.div`
  width: 100%;
  height: 100%;
  max-height: 50px;
  display: flex;
  align-items: center;
  flex-flow: row;
  margin-bottom: 10px;
`;
