export const priorityOptions = {
  전체: -1,
  High: 1,
  Mid: 2,
  검수완료: 3
};

export const combinedOptions = {
  전체: null,
  합쳐짐: true,
  '합쳐지지 않음': false
};
