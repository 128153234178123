import { axiosInstancesManager } from '@API/axios';
/* eslint class-methods-use-this: 0 */

class IpWhitelistsApi {
  getWhitelists({ status, creator }) {
    return axiosInstancesManager.manager.get('/ip-whitelists', {
      params: { status, creator }
    });
  }

  getWhitelist(whitelistId) {
    return axiosInstancesManager.manager.get(`/ip-whitelists/${whitelistId}`);
  }

  postWhitelist({ ipAddress, comment }) {
    return axiosInstancesManager.manager.post('/ip-whitelists', {
      ip_address: ipAddress,
      comment
    });
  }

  putWhitelist({ whitelistId, ipAddress, status, comment }) {
    return axiosInstancesManager.manager.put(`/ip-whitelists/${whitelistId}`, {
      ip_address: ipAddress,
      comment,
      status
    });
  }

  deleteWhitelist(whitelistId) {
    return axiosInstancesManager.manager.delete(`/ip-whitelists/${whitelistId}`);
  }
}
export default new IpWhitelistsApi();
