import { TableSortLabel } from '@material-ui/core';
import Tooltip from '@F/Tooltip';

interface Props {
  value: string;
  direction: Lowercase<OrderType> | undefined;
  isActive: boolean;
  onClick: (e: React.MouseEvent) => void;
}

export default function TableHeadLabel({ value, direction, isActive, onClick }: Props): JSX.Element {
  return (
    <Tooltip title="Sort" enterDelay={300} style={{ transform: 'translateX(12px)' }}>
      <TableSortLabel active={isActive} direction={direction} onClick={onClick}>
        <b>{value}</b>
      </TableSortLabel>
    </Tooltip>
  );
}
