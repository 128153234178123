import { all, call, put, takeLeading, select } from 'redux-saga/effects';
import { toastSuccessMessage } from '@FUNC/toast';
import { handleErrorWithToast } from '@FUNC/error';
import { actions, types } from './state';
import { fetchReports, updateReport } from './api.ts';

export function* fetchData() {
  const { page, perPage, params } = yield select((state) => ({
    page: state.report.page,
    perPage: state.report.perPage,
    params: state.report.params
  }));

  yield put(actions.setLoading(true));
  try {
    const data = yield call(fetchReports, page, perPage, params);
    yield put(actions.setNewReports(data.result));
    yield put(actions.setValue('count', data.total_count));
  } catch (e) {
    handleErrorWithToast(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* updateReportSaga(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(updateReport, action.report.report_id, action.params);
    yield put(actions.setNewReport({ ...action.report, ...action.params }));

    toastSuccessMessage();
  } catch (e) {
    handleErrorWithToast(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* sagaReducer() {
  yield all([takeLeading(types.FETCH_REPORTS, fetchData), takeLeading(types.UPDATE_REPORT, updateReportSaga)]);
}
