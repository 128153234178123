export const PLACETODO_CHARGE_RESULT = {
  0: '할당',
  1: '방문 실패',
  2: '폐점',
  3: 'todo 중복매장 존재',
  4: '기술적 환경적 문제로 인한 수집 실패',
  5: '수집 완료',
  6: '삭제 (result = 0일 때)',
  7: '삭제 (result = 1일 때)',
  8: 'store에 존재했던 nid'
} as const;

export const PLACETODO_REPORT_STATUS = {
  0: 'default',
  1: '사진과 함께 폐점 보고된 상태',
  2: '거절 보류',
  3: '판단 보류',
  4: '승인 보류',
  5: '승인'
} as const;
