import DialogModal from '@F/DialogModal';
import type { CompareCampaign } from '@P/max/campaigns/redux/campaigns/slice';
import ModalContent from './ModalContent';
import HelperMessage from './HelperMessage';

export type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  confirm: () => void;
  campaigns: CompareCampaign[];
  setCampaigns: React.Dispatch<React.SetStateAction<CompareCampaign[]>>;
};
const Modal = ({ open, setOpen, confirm, campaigns, setCampaigns }: Props): JSX.Element => {
  const selectedQuantity = campaigns.reduce((acc, curr) => (curr.selected ? acc + 1 : acc), 0);

  return (
    <DialogModal
      title="캠페인 비교"
      description="아래의 목록에서 비교하고 싶은 2개의 캠페인을 선택해주세요. 확인을 눌러야 저장됩니다."
      open={open}
      setOpen={setOpen}
      confirmButtonDidClicked={confirm}
      maxWidth="md"
      confirmButtonDisabled={selectedQuantity !== 2}
    >
      <ModalContent selectedQuantity={selectedQuantity} campaigns={campaigns} setCampaigns={setCampaigns} />
      <HelperMessage
        quantity={selectedQuantity}
        compareCampaigns={campaigns
          .filter((campaign) => campaign.selected)
          .map(({ name, campaignId }) => ({
            name,
            campaignId
          }))
          .slice(0, 2)}
      />
    </DialogModal>
  );
};

export default Modal;
