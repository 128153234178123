import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { homepageRecruitsApi } from '@API/manager/homepage';

import { selectParams } from './selector';
import { actions } from './slice';

// saga

function* createRecruit({ payload: { data, callback } }) {
  try {
    const { data: recruit } = yield call(homepageRecruitsApi.post, data);
    yield put(actions.createRecruitSuccess(recruit));
    yield put(actions.fetchRecruits());
    callback(true);
  } catch (error) {
    callback(false);
  }
}

function* fetchRecruits({ payload: newParams }) {
  try {
    if (newParams) yield put(actions.setParams(newParams));
    const params = yield select(selectParams);

    yield put(actions.fetchRecruitsStart());
    const {
      data: { result, total_count }
    } = yield call(homepageRecruitsApi.getAll, params);

    yield put(actions.fetchRecruitsSuccess({ data: result, totalCount: total_count }));
  } catch (error) {
    yield put(actions.fetchRecruitsFailure(error));
  }
}

/** updateRecruit
 * 상황에 따라 put,patch api를 모두 사용했었는데요.
 * updateRecruit 변수명을 사용하기 위해 patch api로 통일했습니다.
 */
function* updateRecruit({ payload: { id, data, callback } }) {
  try {
    yield call(homepageRecruitsApi.patch, { id, data });
    yield put(actions.updateRecruitSuccess({ id, data }));
    callback(true);
  } catch (error) {
    callback(false);
  }
}

function* deleteRecruit({ payload: { id, callback } }) {
  try {
    yield call(homepageRecruitsApi.delete, id);
    yield put(actions.deleteRecruitSuccess(id));
    callback(true);
  } catch (error) {
    callback(false);
  }
}

function* changePage({ payload: page }) {
  const params = yield select(selectParams);

  if (params.page === page) return;

  const newParams = { ...params, page };

  yield put(actions.setParams(newParams));
  yield put(actions.fetchRecruits());
}

export default function* sagaReducer() {
  yield takeLatest(actions.createRecruit().type, createRecruit);
  yield takeLatest(actions.fetchRecruits().type, fetchRecruits);
  yield takeEvery(actions.updateRecruit().type, updateRecruit);
  yield takeLatest(actions.deleteRecruit().type, deleteRecruit);
  yield takeLatest(actions.changePage().type, changePage);
}
