import styled from '@emotion/styled';
import Blue from '@material-ui/core/colors/blue';
import Red from '@material-ui/core/colors/red';
import Orange from '@material-ui/core/colors/orange';
import { theme } from '@S/index';
import StatusLabel from '@F/StatusLabel';

export default function FpImageValidationStatusBadge({ status }) {
  const getInner = (status) => {
    switch (status) {
      case null:
      case -1:
        return (
          <StatusLabel color={theme.palette.grey[800]} backgroundColor={theme.palette.grey[200]}>
            미검수
          </StatusLabel>
        );
      case 0:
        return (
          <StatusLabel color={Red[800]} backgroundColor={Red[200]}>
            거부
          </StatusLabel>
        );
      case 1:
        return (
          <StatusLabel color={Blue[800]} backgroundColor={Blue[200]}>
            승인
          </StatusLabel>
        );
      case 2:
        return (
          <StatusLabel color={Orange[800]} backgroundColor={Orange[200]}>
            현장확인
          </StatusLabel>
        );
      case 12:
        return (
          <StatusLabel color={Orange[800]} backgroundColor={Orange[200]}>
            확인완료
          </StatusLabel>
        );
      case 10:
        return (
          <StatusLabel color={Red[800]} backgroundColor={Red[200]}>
            승인거부
          </StatusLabel>
        );
      case 11:
        return (
          <StatusLabel color={Blue[800]} backgroundColor={Blue[200]}>
            승인
          </StatusLabel>
        );
      default:
        return (
          <StatusLabel color={theme.palette.grey[800]} backgroundColor={theme.palette.grey[200]}>
            미검수
          </StatusLabel>
        );
    }
  };

  return <StyledFpImageValidationStatusBadge>{getInner(status)}</StyledFpImageValidationStatusBadge>;
}

const StyledFpImageValidationStatusBadge = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  * {
    margin-left: 0;
  }
`;
