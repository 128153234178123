import { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

function Markers({ map, markers }) {
  const [naverMarkers, setNaverMarkers] = useState([]);
  const [infoWindows, setInfoWindows] = useState([]);
  const isClickable = useMemo(() => !!markers[0]?.name, [markers]);

  const setNewMarkers = useCallback(() => {
    const newNaverMarkers = [];
    const newInfoWindows = [];

    markers.forEach((marker) => {
      newNaverMarkers.push(
        new window.naver.maps.Marker({
          map,
          position: new window.naver.maps.LatLng(marker.lat, marker.lng),
          icon: {
            url: marker.image,
            scaledSize: new window.naver.maps.Size(...marker.size)
          },
          onClick: marker.onClickMarker
        })
      );
      newInfoWindows.push(
        new window.naver.maps.InfoWindow({
          content: `<div>${marker.id} <b>${marker.name}</b></div>`
        })
      );
    });

    setNaverMarkers(newNaverMarkers);
    setInfoWindows(newInfoWindows);
  }, [map, markers]);

  const clearMarkers = useCallback(() => {
    naverMarkers.forEach((marker) => {
      marker.setMap(null);
    });
    infoWindows.forEach((infoWindow) => {
      infoWindow.close();
    });
  }, [naverMarkers, infoWindows]);

  const makeMarkersClickable = useCallback(() => {
    naverMarkers.forEach((marker, i) => {
      window.naver.maps.Event.addListener(marker, 'click', () => {
        const infoWindow = infoWindows[i];
        if (infoWindow.getMap()) {
          infoWindow.close();
        } else {
          infoWindow.open(map, marker);
        }

        marker.onClick && marker.onClick();
      });
    });
  }, [naverMarkers, infoWindows, map]);

  useEffect(() => {
    clearMarkers();
    setNewMarkers();
  }, [map, markers]);

  useEffect(() => {
    if (isClickable) {
      makeMarkersClickable();
    }
  }, [naverMarkers, isClickable]);

  return null;
}
export default Markers;

Markers.propTypes = {
  map: PropTypes.objectOf(PropTypes.any),
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      image: PropTypes.string.isRequired,
      size: PropTypes.arrayOf(PropTypes.number).isRequired
    })
  )
};

Markers.defaultProps = {
  map: null,
  markers: []
};
