import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import EnhancedTable from '@F/table/Table';

import Loading from '@F/Loading';
import { useNavigate } from 'react-router-dom';
import { useFetch } from '@HOOK/';
import { complexesApi } from '@API/manager';
import * as S from './styles';

function TableSection({ complexData, setComplexData }) {
  const { callApi, isLoading } = useFetch();
  const navigate = useNavigate();

  const loadComplexData = useCallback(async () => {
    const responseRecentComplexes = await callApi(complexesApi.getComplexes, {
      perPage: 10,
      page: 1
    });
    if (responseRecentComplexes) {
      setComplexData(responseRecentComplexes.data.items);
    }
  }, []);
  useEffect(() => {
    loadComplexData();
  }, []);

  const onClickRow = useCallback(
    (id) => {
      navigate(`/complex/manager/${id}`);
    },
    [navigate]
  );

  function createHeadCells(id, label, numeric = false, disablePadding = false) {
    return {
      id,
      numeric,
      disablePadding,
      label
    };
  }

  function createRows(id, storeName, branchName, category, categoryCode, brands, parentComplex, service, createdDate) {
    return {
      id: id.toString(),
      storeName,
      branchName,
      category,
      categoryCode,
      brandName:
        brands !== null && brands.length > 0 ? brands.map((brand) => `${brand.name}(${brand.bid})`).join(', ') : null,
      parentComplex: parentComplex === null ? '' : parentComplex.name,
      service: service ? 'O' : 'X',
      createdDate
    };
  }

  const headCells = [
    createHeadCells('id', 'Complex ID (CID)'),
    createHeadCells('storeName', '장소명'),
    createHeadCells('branchName', '지점명'),
    createHeadCells('category', '카테고리'),
    createHeadCells('categoryCode', '키테고리 코드'),
    createHeadCells('brand', '브랜드'),
    createHeadCells('parentComplex', '부모 복합몰'),
    createHeadCells('service', '로플랫 X/i 노출여부'),
    createHeadCells('createdDate', '생성 시각')
  ];

  const rows = complexData.map((item) =>
    createRows(
      item.cid,
      item.name,
      item.branch_name,
      item.category_all_kor,
      item.category_code,
      item.brands,
      item.parent,
      item.service,
      item.created_at
    )
  );
  return (
    <S.StyledRecentComplexTable>
      {isLoading && <Loading />}
      <EnhancedTable
        titleText="최근 등록된 10개의 복합몰"
        rows={rows}
        headCells={headCells}
        initialOrder="desc"
        initialOrderBy="createdDate"
        isCheckable={false}
        onClickRow={onClickRow}
      />
    </S.StyledRecentComplexTable>
  );
}
export default TableSection;

TableSection.propTypes = {
  complexData: PropTypes.arrayOf(PropTypes.any).isRequired,
  setComplexData: PropTypes.func.isRequired
};
