import Wrapper from '@F/materialUI/Wrapper';
import CreateVersion from './CreateVersion';
import Filter from './Filter';
import TableSection from './tableSection/TableSection';
import * as S from './styles';

function CookVersion() {
  return (
    <S.StyledCookVersion>
      <Wrapper>
        <CreateVersion />
      </Wrapper>
      <br />
      <Wrapper>
        <Filter />
      </Wrapper>
      <br />
      <Wrapper>
        <TableSection />
      </Wrapper>
    </S.StyledCookVersion>
  );
}
export default CookVersion;

CookVersion.propTypes = {};
