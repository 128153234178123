import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';

import { homepageArticlesApi } from '@API/manager/homepage';
import { selectParams } from './selector';
import { actions } from './slice';

// saga

function* createArticle({ payload: { data, callback } }) {
  try {
    const res = yield call(homepageArticlesApi.post, data);

    yield put(actions.createArticleSuccess(res.data));

    callback(true);
  } catch (error) {
    callback(false);
  }
}

function* updateArticle({ payload: { id, data, callback } }) {
  try {
    yield call(homepageArticlesApi.patch, { id, data });
    yield put(actions.updateArticleSuccess({ id, data }));
    callback(true);
  } catch (error) {
    callback(false);
  }
}

function* fetchArticles({ payload: newParams }) {
  try {
    if (newParams) yield put(actions.setParams(newParams));
    yield put(actions.fetchArticlesStart());

    const params = yield select(selectParams);

    const {
      data: { result, total_count }
    } = yield call(homepageArticlesApi.getAll, params);

    yield put(
      actions.fetchArticlesSuccess({
        data: result,
        totalCount: total_count
      })
    );
  } catch (e) {
    yield put(actions.fetchArticlesFailure(e));
  }
}

function* changePage({ payload: page }) {
  const params = yield select(selectParams);

  if (params.page === page) return;

  const newParams = { ...params, page };

  yield put(actions.setParams(newParams));
  yield put(actions.fetchArticles());
}

function* deleteArticle({ payload: { id, callback } }) {
  try {
    yield call(homepageArticlesApi.delete, id);
    yield put(actions.deleteArticleSuccess(id));
    callback(true);
  } catch (error) {
    callback(false);
  }
}

export default function* sagaReducer() {
  yield takeLatest(actions.createArticle().type, createArticle);
  yield takeLatest(actions.fetchArticles().type, fetchArticles);
  yield takeEvery(actions.updateArticle().type, updateArticle);
  yield takeLatest(actions.deleteArticle().type, deleteArticle);
  yield takeLatest(actions.changePage().type, changePage);
}
