import { useEffect, useMemo, useState } from 'react';
import { AreaChart, AreaChartProps } from '@toast-ui/chart';
import * as S from './styles';

type ChartProps = {
  dates: string[];
  counts: number[];
};

function Chart({ dates, counts }: ChartProps): JSX.Element {
  const [chart, setChart] = useState<AreaChart | null>(null);
  const data = useMemo(
    () => ({ categories: dates, series: [{ name: '전체 사용자 수', data: counts }] }),
    [dates, counts]
  );
  const options = {
    chart: { width: '100%', height: 400 },
    legend: { visible: false },
    series: { showDot: true, spline: true },
    exportMenu: {
      visible: false
    }
  };
  useEffect(() => {
    chart?.destroy();
    if (data.categories.length > 0) {
      const el = document.getElementById('chart') as HTMLElement;
      setChart(new AreaChart({ el, data, options } as AreaChartProps));
    }
  }, [data]);
  return (
    <S.StyledChart>
      {data.categories.length === 0 && <h1 style={{ textAlign: 'center' }}>데이터 없음</h1>}
      <div id="chart" />
    </S.StyledChart>
  );
}
export default Chart;
