import Filter from './search/Filter';
import List from './search/List';

const Campaigns = (): JSX.Element => (
  <>
    <Filter />
    <List />
  </>
);

export default Campaigns;
