import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Loading from '@F/Loading';
import { Button, Grid } from '@material-ui/core';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import { kindOptions, statusOptions, typeOptions } from '@P/cook/cookUser/options';
import { useDispatch, useSelector } from 'react-redux';
import { useInput } from '@HOOK/';
import { actions } from '@/redux/cook/cookUser/state';
import * as S from './styles';

function Filter({ teams }) {
  const classes = S.useStyles();

  const [userName, setUserName] = useInput('');
  const UserNameInput = <TextInputForm name="UserName" label="아이디" onChange={setUserName} value={userName} />;
  const [name, setName] = useInput('');
  const NameInput = <TextInputForm name="Name" label="이름" onChange={setName} value={name} />;

  const [status, setStatus] = useInput('활성');
  const StatusSelect = (
    <SelectForm
      label="상태"
      options={Object.keys(statusOptions)}
      value={status}
      onSelect={setStatus}
      name="StatusSelect"
    />
  );
  const [team, setTeam] = useInput(null);
  const TeamSelect = (
    <SelectForm
      label="소속"
      options={teams.filter((ele) => ele.flag !== null).map((ele) => ele.name)}
      value={team}
      onSelect={setTeam}
      name="TeamSelect"
    />
  );
  const [kind, setKind] = useInput('all');
  const KindSelect = (
    <SelectForm label="종류" options={Object.keys(kindOptions)} value={kind} onSelect={setKind} name="KindSelect" />
  );
  const [type, setType] = useInput(null);
  const TypeSelect = (
    <SelectForm label="타입" options={typeOptions} value={type} onSelect={setType} name="TypeSelect" />
  );

  // fetch data
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.cookUser.isLoading);

  const fetchUsers = () => {
    const params = userName
      ? {
          username: userName,
          status: null,
          kind: 0
        }
      : {
          name: name || null,
          status: statusOptions[status],
          kind: kindOptions[kind],
          userType: type || null,
          teamId: teams.find((ele) => ele.name === team)?.id
        };
    dispatch(actions.setValue('params', params));
    dispatch(actions.setValue('perPage', 15));
    dispatch(actions.setPage(1));
    dispatch(actions.fetchUsers());
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <S.StyledFilter
      onKeyDown={(event) => {
        if (event.key === 'Enter') fetchUsers();
      }}
    >
      {isLoading && <Loading />}
      <div className={classes.title}>사용자 목록</div>
      <Grid container spacing={2} className={classes.wrapper}>
        <Grid item xs>
          {UserNameInput}
        </Grid>
        <Grid item xs>
          {NameInput}
        </Grid>
        <Grid item xs>
          {TeamSelect}
        </Grid>
        <Grid item xs>
          {StatusSelect}
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.wrapper}>
        <Grid item xs={3}>
          {KindSelect}
        </Grid>
        <Grid item xs={3}>
          {TypeSelect}
        </Grid>
      </Grid>
      <S.ButtonRow>
        <Button variant="contained" color="primary" onClick={fetchUsers}>
          불러오기
        </Button>
      </S.ButtonRow>
    </S.StyledFilter>
  );
}
export default Filter;

Filter.propTypes = {
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired
};
