import styled from 'styled-components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Tooltip as MTooltip } from '@material-ui/core';

export const StyledInputs = styled.div`
  textarea {
    width: 100%;
    height: 5rem;
  }
`;

export const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: 50,
    minWidth: 100
  },
  margin: {
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(1)
  }
}));
export const Tooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white'
  }
}))(MTooltip);

export const Row = styled(Grid)`
  margin-bottom: 10px;
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
`;
