import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '@F/Loading';
import MaxCustomError from '@FUNC/AxiosCustomError';
import { selectCampaignDetail, selectStatus, selectError } from '../redux/campaigns/selector';
import { actions } from '../redux/campaigns/slice';
import DetailReport from './DetailReport';

const CampaignDetail = ({ id }: { id: number }): JSX.Element => {
  const detailCampaigns = useSelector(selectCampaignDetail);
  const dispatch = useDispatch();
  const { campaign: status } = useSelector(selectStatus);
  const apiError = useSelector(selectError);

  useEffect(() => {
    dispatch(actions.fetchCampaignById(id));
  }, []);

  if (status === 'idle' || status === 'loading') return <Loading />;
  if (status === 'failure' && apiError) throw new MaxCustomError(apiError);

  return <DetailReport campaign={detailCampaigns[0]} />;
};

export default CampaignDetail;
