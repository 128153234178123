import { useEffect, useState } from 'react';
import firebase from 'firebase';
import { useInView } from 'react-intersection-observer';
import moment from 'moment';
import { TableRow, TableCell } from '@material-ui/core';
import ToggleBodyCell from '@F/table/cells/ToggleBodyCell';
import { FIREBASE_COOK_URL } from '@/config';
import DetailTableRow from './detailTableRow/DetailTableRow';
import FpImageValidationStatusBadge from './FpImageValidationStatusBadge';

export default function FingerprintTableItem({ isExpanded, fingerprint, columns, onClick, tableScrollViewRef }) {
  const [imageUrls, setImageUrls] = useState([]);
  const { inView, ref } = useInView({ rootMargin: '4000px', root: tableScrollViewRef.current });

  const loadFingerprintImages = () => {
    const { fp_created_at, fp_collector, fp_fpid } = fingerprint;
    const baseFileName = `${moment(fp_created_at).format('YYYY-MM-DD')}/${fp_collector}/img_fp_${fp_fpid}`;

    const imageStorageUrls = Array(3)
      .fill(0)
      .map((_, i) => `${FIREBASE_COOK_URL}/loplatcook/images/${baseFileName}_${i + 1}.jpg`);

    const imageUrlsPromises = imageStorageUrls.map(
      (imageStorageUrl) =>
        new Promise((resolve, reject) => {
          firebase
            .storage()
            .refFromURL(imageStorageUrl)
            .getDownloadURL()
            .then((imageUrl) => resolve(imageUrl))
            .catch((e) => reject(e));
        })
    );

    Promise.allSettled(imageUrlsPromises).then((results) => {
      const imageUrls = results.filter((result) => result.status === 'fulfilled').map((result) => result.value);
      setImageUrls(imageUrls);
    });
  };

  const { fp_image_validation, fp_created_at, phone } = fingerprint;

  const tableCells = {
    ...fingerprint,
    fp_image_validation: <FpImageValidationStatusBadge status={fp_image_validation} />,
    fp_created_at: moment(fp_created_at).format('YYYY-MM-DD HH:mm:ss'),
    phone: phone ?? ''
  };

  useEffect(() => {
    if (inView && !imageUrls.length) {
      loadFingerprintImages();
    }
  }, [inView]);

  return (
    <>
      <TableRow hover onClick={onClick}>
        <ToggleBodyCell onState={isExpanded} />
        {columns.map(({ name }, i) => (
          <TableCell key={i} align="center">
            {tableCells[name]}
          </TableCell>
        ))}
      </TableRow>
      <div ref={ref} />
      <DetailTableRow
        isExpanded={isExpanded}
        colSpan={columns.length + 1}
        fingerprint={fingerprint}
        imageUrls={imageUrls}
        inView={inView}
      />
    </>
  );
}
