import styled from 'styled-components';

export const StyledTableSection = styled.div``;

export const PaymentsContainer = styled.div`
  margin-bottom: 20px;
`;

export const Row = styled.div`
  margin: 10px 0;
`;

export const ActionBar = styled.div`
  width: 100%;
  height: 100%;
  max-height: 50px;
  display: flex;
  align-items: center;
  flex-flow: row;
  margin-bottom: 10px;
`;

export const List = styled.div`
  width: 100%;
  height: auto;
  background-color: gainsboro;

  padding: 10px;
`;
