import { axiosInstancesManager } from '@API/axios';
/* eslint class-methods-use-this: 0 */

class ComplexesApi {
  updateComplex({
    cid,
    name,
    branchName,
    categoryCode,
    addr,
    addrRoad,
    lat,
    lng,
    service,
    qaName,
    qaBranchName,
    qaAddress,
    nid,
    bids,
    parent
  }) {
    return axiosInstancesManager.manager.put(`/complexes/${cid}`, {
      name,
      branch_name: branchName,
      category_code: categoryCode,
      addr,
      addr_road: addrRoad,
      lat,
      lng,
      service,
      qa_name: qaName,
      qa_branchname: qaBranchName,
      qa_addr: qaAddress,
      nid,
      bids,
      parent
    });
  }

  getComplexes({
    perPage,
    page,
    name,
    branchName,
    startDate,
    endDate,
    categoryCode,
    categoryIsNull,
    categoryExclude,
    equalSearch,
    collector,
    parent,
    bid,
    isTree,
    cid
  }) {
    return axiosInstancesManager.manager.get('/complexes/search', {
      params: {
        per_page: perPage,
        page,
        name,
        cid,
        branch_name: branchName,
        start_date: startDate,
        end_date: endDate,
        category_code: categoryCode,
        category_is_null: categoryIsNull,
        category_exclude: categoryExclude,
        equal_search: equalSearch,
        collector,
        parent,
        bid,
        is_tree: isTree
      }
    });
  }

  getComplex({ cid }) {
    return axiosInstancesManager.manager.get(`/complexes/${cid}`);
  }

  addComplex({
    name,
    branchName,
    categoryCode,
    addr,
    addrRoad,
    lat,
    lng,
    parent,
    service,
    qaName,
    qaBranchName,
    qaAddress
  }) {
    return axiosInstancesManager.manager.post('/complexes', {
      name,
      branch_name: branchName,
      category_code: categoryCode,
      addr,
      addr_road: addrRoad,
      lat,
      lng,
      parent,
      service,
      qa_name: qaName,
      qa_branchname: qaBranchName,
      qa_addr: qaAddress
    });
  }
}
export default new ComplexesApi();
