import { axiosInstancesManager } from '@API/axios';
/* eslint class-methods-use-this: 0 */

class SdkClientsApi {
  getSdkClientIds() {
    return axiosInstancesManager.manager.get('/sdk_clients');
  }
}

export default new SdkClientsApi();
