import { all, call, put, takeLeading, select } from 'redux-saga/effects';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { actions, types } from './state';
import { createWhitelist, deleteWhitelist, fetchWhitelist, fetchWhitelists, updateWhitelist } from './api';

export function* fetchWhitelistsSaga() {
  const params = yield select((state) => state.whitelist.params);

  yield put(actions.setLoading(true));
  try {
    const data = yield call(fetchWhitelists, params);
    yield put(actions.setNewLists(data));
    yield put(actions.setValue('count', data.length));
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* createWhitelistSaga(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(createWhitelist, action.params);
    yield put(actions.fetchLists());

    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* updateWhitelistSaga(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(updateWhitelist, action.params);
    const data = yield call(fetchWhitelist, action.params.whitelistId);
    yield put(actions.setNewList(data));

    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* deleteWhitelistSaga(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(deleteWhitelist, action.list.whitelist_id);
    yield put(actions.deleteListFromLists(action.list));

    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* sagaReducer() {
  yield all([
    takeLeading(types.FETCH_LISTS, fetchWhitelistsSaga),
    takeLeading(types.CREATE_LIST, createWhitelistSaga),
    takeLeading(types.DELETE_LIST, deleteWhitelistSaga),
    takeLeading(types.UPDATE_LIST, updateWhitelistSaga)
  ]);
}
