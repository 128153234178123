import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PinBlue from '@I/map/pin-blue.png';
import PinRed from '@I/map/pin-red.png';
import PinGreen from '@I/map/pin-green.png';
import { ListItem, Button, Grid, LinearProgress, List } from '@material-ui/core';
import { fingerprintsApi } from '@API/manager';
import { useFetch } from '@HOOK/';
import TextInputForm from '@F/materialUI/TextInputForm';
import * as S from './styles';

function InnerMap({ detail, setSelectedPlace, recommendedKeyword }) {
  const { callApi, isLoading } = useFetch();
  const classes = S.useStyles();

  const [keyword, setKeyword] = useState(recommendedKeyword);
  const [result, setResult] = useState([]);

  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    if (recommendedKeyword) {
      setKeyword(recommendedKeyword);
      setSearchedResult(recommendedKeyword);
    }
  }, [recommendedKeyword]);

  async function search(target) {
    const response = await callApi(fingerprintsApi.getSearchNaverMap, {
      keyword: target,
      lat: detail.f_lat,
      lng: detail.f_lng
    });
    if (response) {
      return response.data.result;
    }
    return [];
  }
  function setSearchedResult(target) {
    search(target).then((response) => {
      setResult(response.sort((a, b) => a.distance - b.distance));
      setMarkers(
        [initialMarker].concat(
          response.map((place, index) => ({
            type: 'place',
            lat: Number(place.y),
            lng: Number(place.x),
            image: PinBlue,
            size: [13 * 2.5 * 0.66, 17 * 2.5 * 0.66],
            name: place.name,
            id: place.id,
            onClickMarker: () => changeSelectedIndex(place, index)
          }))
        )
      );
      setSelectedIndex(null);
    });
  }

  const [center, setCenter] = useState({
    lat: detail.f_lat,
    lng: detail.f_lng
  });

  function changeSelectedIndex(marker, index) {
    setSelectedIndex(index);
    setSelectedPlace(marker);
    // NOTE: 검색결과 중 하나를 선택하면, center를 이동하도록 한 기능.
    // 이 기능에 대한 추가 개발 요구가 없어, 주석처리 함.
    // setCenter({
    //   lat: Number(marker.y),
    //   lng: Number(marker.x),
    // });
  }

  const initialMarker = {
    type: null,
    lat: detail.f_lat,
    lng: detail.f_lng,
    image: PinGreen,
    size: [13 * 2.5, 17 * 2.5],
    name: '추정된 위치',
    id: detail.id
  };

  const [markers, setMarkers] = useState([initialMarker]);

  function updateMarkers() {
    let selected = null;
    setMarkers((prevState) =>
      [initialMarker]
        .concat(
          prevState.slice(1).map((place, index) => {
            if (index === selectedIndex) {
              selected = {
                ...place,
                image: PinRed
              };
            }
            return {
              ...place,
              image: PinBlue
            };
          })
        )
        .concat(selected)
    );
  }
  useEffect(() => {
    if (selectedIndex === null) return;
    updateMarkers();
  }, [selectedIndex]);

  const [bounds, setBounds] = useState({
    getSW() {
      return new DOMRect();
    },
    getNE() {
      return new DOMRect();
    }
  });

  const [zoom, setZoom] = useState(19);
  useEffect(() => {
    if (result.length <= 0) {
      setCenter({
        lat: detail.f_lat,
        lng: detail.f_lng
      });
    } else {
      setCenter({
        lat: (detail.f_lat + Number(result[0].y)) / 2,
        lng: (detail.f_lng + Number(result[0].x)) / 2
      });
    }
  }, [detail, result, zoom]);

  useEffect(() => {
    if (bounds && result.length !== 0 && Object.entries(bounds).length !== 0) {
      const minCoords = bounds.getSW();
      const maxCoords = bounds.getNE();
      if (
        (minCoords.x > result[0].x ||
          maxCoords.x < result[0].x ||
          minCoords.y > result[0].y ||
          maxCoords.y < result[0].y) &&
        zoom > 10
      ) {
        setZoom((prevState) => prevState - 1);
      }
    }
  }, [bounds, result]);

  return (
    <S.InnerMapContainer>
      <h3>매장 검색</h3>
      <S.MapWrapper>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSearchedResult(keyword);
          }}
        >
          <S.Row container spacing={2}>
            <Grid item xs>
              <TextInputForm
                name="store"
                label="장소 검색"
                onChange={(e) => setKeyword(e.target.value)}
                value={keyword}
                variant="standard"
                onClear={() => setKeyword('')}
              />
            </Grid>
            <Grid item xs>
              <Button type="submit" variant="contained" color="primary">
                검색
              </Button>
            </Grid>
          </S.Row>
          {isLoading && <LinearProgress />}
        </form>
        <List className={classes.root}>
          {result.length > 0 ? (
            result.map((place, index) => (
              <ListItem
                key={place.id}
                button
                dense
                selected={index === selectedIndex}
                onClick={() => {
                  setSelectedPlace(place);
                  setSelectedIndex(index);
                  // NOTE: 검색결과 중 하나를 선택하면, center를 이동하도록 한 기능.
                  // 이 기능에 대한 추가 개발 요구가 없어, 주석처리 함.
                  // setCenter({
                  //   lat: Number(place.y),
                  //   lng: Number(place.x),
                  // });
                }}
              >
                <ListItem>{place.name}</ListItem>
              </ListItem>
            ))
          ) : (
            <ListItem>검색결과가 없습니다.</ListItem>
          )}
        </List>
        <S.Map
          id={detail.id}
          center={center}
          markers={markers}
          zoom={zoom}
          triggerEmitBoundary
          onEmitBoundary={setBounds}
        >
          <S.ResetCenterButton
            onClick={() => {
              setCenter({
                lat: detail.f_lat,
                lng: detail.f_lng
              });
            }}
          >
            원위치
          </S.ResetCenterButton>
        </S.Map>
      </S.MapWrapper>
    </S.InnerMapContainer>
  );
}
export default InnerMap;

InnerMap.propTypes = {
  detail: PropTypes.objectOf(PropTypes.any).isRequired,
  setSelectedPlace: PropTypes.func.isRequired,
  recommendedKeyword: PropTypes.string.isRequired
};
