import styled from 'styled-components';

export const TableCountRow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 10px 0;
`;

export const TablePaginationRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem 0;
`;
