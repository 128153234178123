import { useCallback, useEffect, useMemo, useState } from 'react';
import EnhancedTable from '@F/table/Table';
import Button from '@material-ui/core/Button';
import CustomizingColumnsDialog from '@F/table/CustomizingColumnsDialog';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { refineRow, createHeadCells } from '@F/table/Table/functions';
import moment from 'moment';
import Detail from '@P/store/ErrorReport/tableSection/detail/Detail';
import PaginationRow from '@F/table/PaginationRow';
import { actions } from '@/redux/store/storeErrorReport/state';
import * as S from './styles';

function TableSection() {
  const { page, perPage, count, reports } = useSelector(
    (state) => ({
      page: state.storeErrorReport.page,
      perPage: state.storeErrorReport.perPage,
      count: state.storeErrorReport.count,
      reports: state.storeErrorReport.reports
    }),
    shallowEqual
  );

  // pagination
  const dispatch = useDispatch();
  const setPage = useCallback(
    (newPage) => {
      if (page === newPage) dispatch(actions.fetchReports());
      else dispatch(actions.setPage(newPage));
    },
    [dispatch, page]
  );
  useEffect(() => {
    dispatch(actions.fetchReports());
  }, [dispatch, page]);

  // rows
  const [headCells, setHeadCells] = useState(() => columns.map((column) => createHeadCells(column.name, column.label)));
  const rows = useMemo(
    () =>
      reports.map((report) => ({
        id: report.report_id,
        pid: {
          value: report.pid,
          component: <S.IdText emphasize={report.fp_cnt > 1}>{report.pid}</S.IdText>
        },
        reporter: report.report_reporter,
        name: report.name,
        branchName: report.branch_name,
        isQa: (report.report_qa_result && 'OK') || '',
        qaBy: report.report_qa_by || '',
        createdAt: moment(report.report_created_at).format('YYYY-MM-DD HH:mm:ss')
      })),
    [reports]
  );

  // column modal
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(() =>
    columns.reduce((obj, column) => ({ ...obj, [column.name]: true }), {})
  );
  const CustomizingColumnsButton = (
    <Button
      key="customizingColumnsButton"
      variant="outlined"
      color="primary"
      onClick={() => setIsCustomModalOpen(true)}
    >
      column 보이기/숨기기
    </Button>
  );
  const refinedRows = useMemo(() => refineRow(rows, selectedColumns), [rows, selectedColumns]);
  useEffect(() => {
    const updatedHeadCells = headCells.map((cell) => ({
      ...cell,
      hide: !selectedColumns[cell.id]
    }));
    setHeadCells(updatedHeadCells);
  }, [selectedColumns]);

  // expand
  const openedReportIds = useSelector((state) => state.storeErrorReport.openedReportIds);
  const setOpenedReportIds = useCallback(
    (newOpenedReportIds) => {
      dispatch(actions.setValue('openedReportIds', newOpenedReportIds));
    },
    [dispatch]
  );

  return (
    <S.StyledTableSection>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} />
      {CustomizingColumnsButton}
      <EnhancedTable
        headCells={headCells.filter((cell) => cell.hide === false)}
        rows={refinedRows}
        isCheckable={false}
        isCollapsible
        opened={openedReportIds}
        setOpened={setOpenedReportIds}
      >
        <Detail data={reports} />
      </EnhancedTable>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} reverse />

      <CustomizingColumnsDialog
        open={isCustomModalOpen}
        setOpen={setIsCustomModalOpen}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        columns={columns}
      />
    </S.StyledTableSection>
  );
}
export default TableSection;

TableSection.propTypes = {};

const columns = [
  { name: 'id', label: 'REPORT ID', isImportant: true },
  { name: 'pid', label: 'STORE ID' },
  { name: 'reporter', label: 'REPORTER' },
  { name: 'name', label: '장소명' },
  { name: 'branchName', label: '지점명' },
  { name: 'isQa', label: 'QA 여부' },
  { name: 'qaBy', label: 'QA By' },
  { name: 'createdAt', label: '보고 일시' }
];
