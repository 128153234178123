import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import NaverMap from '@F/map/NaverMap';
import RedPin from '@I/map/pin-red.png';
import PinTier2 from '@I/map/pin-blue.png';
import * as S from './styles';

function MapSection({ report, reportLngLat, onEmitReportLngLat }) {
  const reportMarker = useMemo(
    () => ({
      lng: reportLngLat.lng,
      lat: reportLngLat.lat,
      image: RedPin,
      size: [13 * 2, 17 * 2]
    }),
    [reportLngLat]
  );
  const storeMarker = useMemo(
    () => ({
      lng: report.lng,
      lat: report.lat,
      image: PinTier2,
      size: [13 * 2, 17 * 2]
    }),
    [report.lng, report.lat]
  );
  const center = useMemo(() => ({ lng: report.lng, lat: report.lat }), [report.lng, report.lat]);

  const changeReportLngLat = useCallback(
    (coordinates) => {
      onEmitReportLngLat({ lng: coordinates.x, lat: coordinates.y });
    },
    [onEmitReportLngLat]
  );

  return (
    <S.StyledMapSection>
      <NaverMap
        id={report.report_id}
        center={center}
        markers={[storeMarker, reportMarker]}
        onEmitCoordinates={changeReportLngLat}
        zoom={17}
      />
    </S.StyledMapSection>
  );
}
export default MapSection;

MapSection.propTypes = {
  report: PropTypes.shape({
    report_id: PropTypes.number,
    lng: PropTypes.number,
    lat: PropTypes.number
  }).isRequired,
  reportLngLat: PropTypes.shape({
    lng: PropTypes.number,
    lat: PropTypes.number
  }).isRequired,
  onEmitReportLngLat: PropTypes.func.isRequired
};
