import { createReducer, createSetValueAction, setValueReducer } from '@/redux/common/helper-functions';

/** prefix */
const PREFIX = 'COOK_VERSION';

/** initial state */
const INITIAL_STATE = {
  versions: [],
  openedVersionIds: [],
  params: {},
  isLoading: false
};

/** type */
export const types = {
  RESET: `${PREFIX}/RESET`,
  SET_VALUE: `${PREFIX}/SET_VALUE`,
  SET_LOADING: `${PREFIX}/SET_LOADING`,
  FETCH_VERSIONS: `${PREFIX}/FETCH_VERSIONS`,
  SET_NEW_VERSIONS: `${PREFIX}/SET_NEW_VERSIONS`,
  SET_NEW_VERSION: `${PREFIX}/SET_NEW_VERSION`,
  DELETE_VERSION_FROM_VERSIONS: `${PREFIX}/DELETE_VERSION_FROM_VERSIONS`,
  UPDATE_VERSION: `${PREFIX}/UPDATE_VERSION`,
  DELETE_VERSION: `${PREFIX}/DELETE_VERSION`
};

/** action */
export const actions = {
  reset: () => ({ type: types.RESET }),
  setValue: createSetValueAction(types.SET_VALUE),
  setLoading: (isLoading) => ({ type: types.SET_LOADING, isLoading }),
  fetchVersions: () => ({ type: types.FETCH_VERSIONS }),
  setNewVersions: (versions) => ({ type: types.SET_NEW_VERSIONS, versions }),
  setNewVersion: (version) => ({ type: types.SET_NEW_VERSION, version }),
  deleteVersionFromVersions: (version) => ({ type: types.DELETE_VERSION_FROM_VERSIONS, version }),
  updateVersion: (version, params) => ({ type: types.UPDATE_VERSION, version, params }),
  deleteVersion: (version) => ({ type: types.DELETE_VERSION, version })
};

/** reducer */
const reducer = createReducer(INITIAL_STATE, {
  [types.RESET]: (draft) => {
    draft.versions = [];
    draft.openedVersionIds = [];
  },
  [types.SET_VALUE]: setValueReducer,
  [types.SET_LOADING]: (draft, action) => {
    draft.isLoading = action.isLoading;
  },
  [types.SET_NEW_VERSIONS]: (draft, action) => {
    draft.versions = action.versions;
    draft.openedVersionIds = [];
  },
  [types.SET_NEW_VERSION]: (draft, action) => {
    const index = draft.versions.findIndex((version) => version.version_id === action.version.version_id);
    if (index >= 0) {
      draft.versions[index] = action.version;
    }
  },
  [types.DELETE_VERSION_FROM_VERSIONS]: (draft, action) => {
    const index = draft.versions.findIndex((version) => version.version_id === action.version.version_id);
    if (index >= 0) {
      draft.versions = [...draft.versions.slice(0, index), ...draft.versions.slice(index + 1)];
    }
  }
});
export default reducer;
