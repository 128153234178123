import { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectCompareCampaigns } from '@P/max/campaigns/redux/campaigns/selector';
import { actions } from '@P/max/campaigns/redux/campaigns/slice';
import qs from 'qs';
import Modal from './Modal';

const CompareModalButton = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const compareCampaigns = useSelector(selectCompareCampaigns);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [campaigns, setCampaigns] = useState([...compareCampaigns]);
  const dispatch = useDispatch();

  useEffect(() => {
    setCampaigns([...compareCampaigns]);
  }, [compareCampaigns, isModalOpen]);

  const confirm = () => {
    const selected = campaigns.filter((campaign) => campaign.selected);
    const queryString = qs.stringify({
      cidA: selected[0].campaignId,
      cidB: selected[1].campaignId
    });
    dispatch(actions.setCompareCampaigns(campaigns));
    navigate(`${location.pathname}/compare?${queryString}`);
  };

  return (
    <>
      <Button key="customizingColumnsButton" variant="outlined" color="primary" onClick={() => setIsModalOpen(true)}>
        캠페인 비교
      </Button>
      {isModalOpen && (
        <Modal
          open={isModalOpen}
          setOpen={setIsModalOpen}
          confirm={confirm}
          campaigns={campaigns}
          setCampaigns={setCampaigns}
        />
      )}
    </>
  );
};

export default CompareModalButton;
