import { useCallback, useEffect, useMemo, useState } from 'react';
import { toJS } from 'mobx';
import { Button, Checkbox, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import moment from 'moment';
import DatePickers from '@F/materialUI/datePicker/DatePickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Loading from '@F/Loading';
import { useDispatch, useSelector } from 'react-redux';
import CategoryInput from '@F/modal/CategoryInput';
import { useTogglers } from '@HOOK/useTogglers';
import { useInput } from '@HOOK/';
import { actions } from '@/redux/fingerprint/fpManager/state';
import { qaStatusOptions, imageUploadStatusOptions, fpStateOptions } from './options';
import * as S from './styles';
import PER_PAGE_OPTIONS from '@/constants/perPageOptions';
import { valuesStore } from '@/mobX/values';

function Filter() {
  const classes = S.useStyles();

  // 필터 states
  const [fingerprintId, setFingerprintId] = useInput(null);
  const [name, setName] = useInput(null);
  const [branchName, setBranchName] = useInput(null);
  const [collector, setCollector] = useInput(null);
  const params = useSelector((state) => state.fpManager.params);
  const collectorOptions = useMemo(
    () => toJS(valuesStore.collectors).map((user) => user.username),
    [valuesStore.collectors]
  );

  const FingerprintIdInput = (
    <TextInputForm name="FingerprintInput" label="Fingerprint ID" onChange={setFingerprintId} value={fingerprintId} />
  );
  const NameInput = <TextInputForm name="NameInput" label="장소명" onChange={setName} value={name} />;
  const BranchNameInput = (
    <TextInputForm name="BranchNameInput" label="지점명" onChange={setBranchName} value={branchName} />
  );

  const CollectorInput = (
    <Autocomplete
      id="ChargeInput"
      options={collectorOptions}
      noOptionsText="수집자를 입력해주세요"
      onInputChange={(_, value) => setCollector({ target: { value } })}
      renderInput={(params) => <TextField {...params} label="수집자" variant="outlined" />}
    />
  );

  const [qaStatus, setQaStatus] = useInput('전체');
  const QaStatusSelect = (
    <SelectForm
      label="검수 상태"
      options={Object.keys(qaStatusOptions)}
      value={qaStatus}
      onSelect={setQaStatus}
      name="qaStatusSelect"
    />
  );

  const [imageUploadStatus, setImageUploadStatus] = useInput('전체');
  const ImageUploadStatusSelect = (
    <SelectForm
      label="이미지 업로드 상태"
      options={Object.keys(imageUploadStatusOptions)}
      value={imageUploadStatus}
      onSelect={setImageUploadStatus}
      name="imageUploadSelect"
    />
  );

  const [fpState, setFpState] = useInput('유효');
  const FpStateSelect = (
    <SelectForm
      label="상태"
      options={Object.keys(fpStateOptions)}
      value={fpState}
      onSelect={setFpState}
      name="fpStateSelect"
    />
  );

  const [perPage, setPerPage] = useInput('15');
  const CountSelect = (
    <SelectForm label="개수" options={PER_PAGE_OPTIONS} value={perPage} onSelect={setPerPage} name="CountSelect" />
  );

  const [period, setPeriod] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });
  const [isSearchPeriod, setIsSearchPeriod] = useState(true);
  const confirmPeriod = useCallback((dates) => {
    setPeriod(() => ({
      startDate: dates[0],
      endDate: dates[1]
    }));
  }, []);
  const TimeStampInput = <DatePickers onEmitDate={confirmPeriod} />;
  const SearchPeriodCheckbox = (
    <FormControlLabel
      control={
        <Checkbox color="primary" checked={isSearchPeriod} onChange={() => setIsSearchPeriod(!isSearchPeriod)} />
      }
      label="기간 검색"
    />
  );

  const [categoryCode, setCategoryCode] = useState({ korName: '', code: '' });
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);

  // fetch data
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.fpManager.isLoading);

  const togglers = useTogglers();

  const fetchFingerprints = () => {
    dispatch(
      actions.setValue('params', {
        ...params,
        startDate: isSearchPeriod ? period.startDate : null,
        endDate: isSearchPeriod ? period.endDate : null,
        fpid: fingerprintId || null,
        name: name || null,
        branchName: branchName || null,
        collector: collector || null,
        categoryCode: categoryCode?.code || null,
        imageInspected: qaStatusOptions[qaStatus],
        imageUploadStatus: imageUploadStatusOptions[imageUploadStatus],
        fpState: fpStateOptions[fpState]
      })
    );
    dispatch(actions.setValue('perPage', Number(perPage)));
    dispatch(actions.setPage(1));
    dispatch(actions.fetchFingerprints());
  };

  useEffect(() => {
    fetchFingerprints();
    togglers.setOffAll();
  }, []);

  return (
    <S.StyledFilter
      onSubmit={(e) => {
        e.preventDefault();
        fetchFingerprints();
        togglers.setOffAll();
      }}
    >
      {isLoading && <Loading />}
      <Grid container className={classes.wrapper} spacing={2}>
        <Grid item xs>
          {FingerprintIdInput}
        </Grid>
        <Grid item xs>
          {NameInput}
        </Grid>
        <Grid item xs>
          {BranchNameInput}
        </Grid>
        <Grid item xs>
          {CollectorInput}
        </Grid>
      </Grid>

      <Grid container className={classes.wrapper} spacing={2}>
        <Grid item xs>
          <CategoryInput
            categoryCode={categoryCode}
            setCategoryCode={setCategoryCode}
            isCategoryModalOpen={isCategoryModalOpen}
            setIsCategoryModalOpen={setIsCategoryModalOpen}
          />
        </Grid>
        <Grid item xs>
          {QaStatusSelect}
        </Grid>
        <Grid item xs>
          {ImageUploadStatusSelect}
        </Grid>
        <Grid item xs>
          {FpStateSelect}
        </Grid>
      </Grid>
      <Grid container className={classes.wrapper} spacing={2}>
        <Grid item xs={3}>
          {CountSelect}
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={9}>
              {TimeStampInput}
            </Grid>
            <Grid item xs={3}>
              {SearchPeriodCheckbox}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" color="primary" type="submit" fullWidth>
            불러오기
          </Button>
        </Grid>
      </Grid>
    </S.StyledFilter>
  );
}
export default Filter;

Filter.propTypes = {};
