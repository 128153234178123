import { useState, useCallback, ChangeEvent } from 'react';

type onChangeType = (e: ChangeEvent<unknown>) => void;
type returnType<T> = [T, onChangeType, () => void, React.Dispatch<React.SetStateAction<T>>];
const useInput = <T = string>(initialValue: T, clearedValue?: T): returnType<T> => {
  const [value, setValue] = useState<T>(initialValue);

  const onChangeInput = useCallback((e) => {
    setValue(e.target.value);
  }, []);

  const clearInput = useCallback(() => {
    if (clearedValue !== undefined) setValue(clearedValue);
    else setValue(initialValue);
  }, [clearedValue, initialValue]);

  return [value, onChangeInput, clearInput, setValue];
};

export default useInput;
