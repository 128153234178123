export const reviewStateOptions: any = {
  전체: null,
  미검수: 0,
  승인: 1,
  '승인 거부': -1
};

export const categoryOptions: any = {
  전체: null,
  음식점: '음식점',
  패스트푸드: '패스트푸드',
  카페: '카페',
  베이커리: '베이커리',
  편의점: '편의점',
  약국: '약국',
  쇼핑: '쇼핑',
  뷰티: '뷰티',
  병원: '병원',
  은행: '은행',
  기타: '기타'
};
