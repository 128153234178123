import { useCallback, useEffect, useMemo, useState } from 'react';
import Wrapper from '@F/materialUI/Wrapper';
import moment from 'moment';
import DatePickers from '@F/materialUI/datePicker/DatePickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Button, Checkbox, Grid, FormControl, RadioGroup, Radio } from '@material-ui/core';
import SelectForm from '@F/materialUI/SelectForm';
import TextInputForm from '@F/materialUI/TextInputForm';
import { CDevice, OS } from '@TS/cashplace/study';
import { useAppSelector } from '@HOOK/useSelector';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { actions } from '@/redux/cashplace/inspect/state';
import { reviewStateOptions, categoryOptions } from './options';
import * as S from './styles';

function Filter(): JSX.Element {
  const classes = S.useStyles();
  const cDevices = useAppSelector((state) => state.inspect.cDevices) as { [key in OS]: CDevice[] };

  const [siteId, setSiteId] = useState<string>();
  const SiteIdInput = (
    <TextInputForm
      name="SiteIdInput"
      label="Site ID"
      value={siteId}
      onChange={(e): void => setSiteId(e.target.value)}
    />
  );

  const [studyId, setStudyId] = useState<string>();
  const StudyIdInput = (
    <TextInputForm
      name="StudyIdInput"
      label="Study ID"
      value={studyId}
      onChange={(e): void => setStudyId(e.target.value)}
    />
  );
  const [userId, setUserId] = useState<string>();
  const UserIdInput = (
    <TextInputForm
      name="UserIdInput"
      label="User ID"
      value={userId}
      onChange={(e): void => setUserId(e.target.value)}
    />
  );

  const [placeName, setPlaceName] = useState<string>();
  const PlaceNameInput = (
    <TextInputForm
      name="PlaceNameInput"
      label="Place Name"
      value={placeName}
      onChange={(e): void => setPlaceName(e.target.value)}
    />
  );

  const [reviewState, setReviewState] = useState<string>('전체');
  const ReviewStateSelect = (
    <SelectForm
      label="상태"
      options={Object.keys(reviewStateOptions)}
      value={reviewState}
      onSelect={(e) => {
        setReviewState((prevState) => {
          if (typeof e.target.value === 'string') return e.target.value;
          return prevState;
        });
      }}
      name="ReviewStateSelect"
    />
  );

  const [category, setCategory] = useState<string>('전체');
  const CategorySelect = (
    <SelectForm
      label="카테고리"
      options={Object.keys(categoryOptions)}
      value={category}
      onSelect={(e): void => {
        setCategory((prevState) => {
          if (typeof e.target.value === 'string') return e.target.value;
          return prevState;
        });
      }}
      name="CategorySelect"
    />
  );

  const oses: OS[] = ['iOS', 'Android'];

  const [os, setOs] = useState<OS | '전체'>('전체');
  const [cDeviceModel, setCDeviceModel] = useState<CDevice['model']>('');

  const OsSelect = (
    <SelectForm
      label="OS"
      options={['전체', ...oses]}
      value={os}
      onSelect={(e): void => {
        setOs(e.target.value as OS);
        setCDeviceModel('');
      }}
      name="CategorySelect"
    />
  );

  const allCDevices = cDevices.iOS.concat(cDevices.Android);
  const selectedOSDevices = os === '전체' ? allCDevices : cDevices[os];
  const CDeviceSelect = (
    <Autocomplete
      options={selectedOSDevices.map((cDevice) => cDevice.model)}
      onInputChange={(_, value) => {
        setCDeviceModel(value);
      }}
      renderInput={(params) => <TextField {...params} label="기종" variant="outlined" />}
    />
  );

  const [count, setCount] = useState<string>('10');
  const CountSelect = (
    <SelectForm
      label="개수"
      options={['10', '50', '100', '200']}
      value={count}
      onSelect={(e): void => {
        setCount((prevState) => {
          if (typeof e.target.value === 'string') return e.target.value;
          return prevState;
        });
      }}
      name="CountSelect"
    />
  );
  const [period, setPeriod] = useState({
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD')
  });
  const [isSearchPeriod, setIsSearchPeriod] = useState(true);
  const [searchByPeriodOption, setSearchByPeriodOption] = useState('created_at');

  const confirmPeriod = useCallback((dates) => {
    setPeriod(() => ({
      startDate: dates[0],
      endDate: dates[1]
    }));
  }, []);
  const TimeStampInput = <DatePickers onEmitDate={confirmPeriod} />;
  const SearchPeriodOption = (
    <FormControlLabel
      control={
        <Checkbox color="primary" checked={isSearchPeriod} onChange={(): void => setIsSearchPeriod(!isSearchPeriod)} />
      }
      label="기간 검색"
    />
  );
  const SearchByPeriodOptionRadio = (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label="SearchByPeriodOption"
        name="search"
        row
        value={searchByPeriodOption}
        onChange={(e): void => setSearchByPeriodOption(e.target.value)}
      >
        <FormControlLabel value="inspected_at" control={<Radio />} label="검수한 시각" />
        <FormControlLabel value="created_at" control={<Radio />} label="생성된 시각" />
      </RadioGroup>
    </FormControl>
  );

  const dispatch = useDispatch();
  const fetchStudies = ({ defaultUserId }: { defaultUserId?: string | null }) => {
    dispatch(actions.setPage(1));
    dispatch(actions.setValue('perPage', Number(count)));
    dispatch(
      actions.setValue('params', {
        userId: defaultUserId || userId ? Number(defaultUserId || userId) : null,
        placename: placeName,
        endDate: isSearchPeriod ? period.endDate : null,
        startDate: isSearchPeriod ? period.startDate : null,
        dateType: searchByPeriodOption,
        category: categoryOptions[category],
        siteId: siteId ? Number(siteId) : undefined,
        status: reviewStateOptions[reviewState],
        studyId: studyId ? Number(studyId) : undefined,
        verbosity: 1,
        os: cDeviceModel ? undefined : os === '전체' ? undefined : os,
        deviceIds: cDeviceModel
          ? [(allCDevices.find((cDevice) => cDevice.model === cDeviceModel) as CDevice).id]
          : undefined
      })
    );
    dispatch(actions.setValue('openedStudyIds', []));
    dispatch(actions.fetchInspects());
  };
  const { pathname, search } = useLocation();
  const url = useMemo(() => new URL(`https://internal-manager.loplat.com${pathname}${search}`), [pathname, search]);
  const defaultUserId = useMemo(() => url.searchParams.get('userId'), [url]);
  useEffect(() => {
    if (defaultUserId) {
      setUserId(defaultUserId);
      fetchStudies({ defaultUserId });
    } else {
      fetchStudies({});
    }
  }, [defaultUserId]);

  return (
    <S.StyledFilter>
      <Wrapper>
        <S.Row container className={classes.margin} spacing={1}>
          <Grid item xs>
            {SiteIdInput}
          </Grid>
          <Grid item xs>
            {StudyIdInput}
          </Grid>
          <Grid item xs>
            {UserIdInput}
          </Grid>
          <Grid item xs>
            {PlaceNameInput}
          </Grid>
        </S.Row>

        <S.Row container className={classes.margin} spacing={1}>
          <Grid item xs>
            {ReviewStateSelect}
          </Grid>
          <Grid item xs>
            {CategorySelect}
          </Grid>
          <Grid item xs>
            {OsSelect}
          </Grid>
          <Grid item xs>
            {CDeviceSelect}
          </Grid>
          <Grid item xs>
            {CountSelect}
          </Grid>
        </S.Row>

        <S.Row container className={classes.margin} spacing={1}>
          <Grid item xs>
            {TimeStampInput}
          </Grid>
          <Grid item xs={3}>
            {SearchPeriodOption}
          </Grid>
          <Grid item xs>
            {SearchByPeriodOptionRadio}
          </Grid>
        </S.Row>

        <S.ButtonRow>
          <Button variant="contained" color="primary" onClick={() => fetchStudies({})}>
            불러오기
          </Button>
        </S.ButtonRow>
      </Wrapper>
    </S.StyledFilter>
  );
}
export default Filter;
