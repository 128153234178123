import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    height: 500,
    flexGrow: 1,
    maxWidth: 400
  },
  input: {
    marginBottom: 30
  }
});

export const StyledCategoryModal = styled.div`
  overflow-y: scroll;
`;
