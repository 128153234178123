import { all, call, put, takeLeading, select } from 'redux-saga/effects';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { actions, types } from './state';
import { fetchReports, discardStore, deleteStore, putReport, fetchReport } from './api';

export function* fetchData() {
  const { page, perPage, params } = yield select((state) => ({
    page: state.storeErrorReport.page,
    perPage: state.storeErrorReport.perPage,
    params: state.storeErrorReport.params
  }));

  yield put(actions.setLoading(true));
  try {
    const data = yield call(fetchReports, page, perPage, params);
    yield put(actions.setNewReports(data.items));
    yield put(actions.setValue('count', data.count));
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* confirmReport(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(putReport, action.params);
    const updatedReport = yield call(fetchReport, action.params.reportId);
    yield put(actions.setNewReport(updatedReport));

    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* discardReportedStore(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(discardStore, action.report.pid);
    yield put(actions.deleteReport(action.report));

    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* deleteReportedStore(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(deleteStore, action.report.pid);
    yield put(actions.deleteReport(action.report));

    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* sagaReducer() {
  yield all([
    takeLeading(types.FETCH_REPORTS, fetchData),
    takeLeading(types.CONFIRM_REPORT, confirmReport),
    takeLeading(types.DISCARD_STORE, discardReportedStore),
    takeLeading(types.DELETE_STORE, deleteReportedStore)
  ]);
}
