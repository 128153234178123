import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import HomepageRecruitView from './previewHomepageRecruitView/HomepageRecruitView';
import Toolbar from './previewHomepageRecruitView/Toolbar';
import { selectRecruits } from './redux/selector';

export default function PreviewHomepageRecruitInfoView(): JSX.Element | null {
  const { focusedRecruit } = useSelector(selectRecruits);

  const navigate = useNavigate();

  if (!focusedRecruit) {
    navigate('/homepage/manage-recruit', { replace: true });

    return null;
  }

  return (
    <StyledPreviewHomepageRecruitView>
      <Toolbar />
      <HomepageRecruitView />
    </StyledPreviewHomepageRecruitView>
  );
}

const StyledPreviewHomepageRecruitView = styled.div`
  padding: 20px;
`;
