// TODO: foundation 의 Select Component 이용
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

function SelectForm({ label, options, value, disabled, onSelect, id }) {
  return (
    <FormControl variant="outlined" fullWidth disabled={disabled}>
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={(event) => onSelect(event, id)} label={label}>
        {options.map((option) => (
          <MenuItem value={option} key={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

SelectForm.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func.isRequired
};

SelectForm.defaultProps = {
  options: [],
  disabled: false
};

export default SelectForm;
