import { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Radio } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { theme } from '@S/index';
import TextInputForm from '@F/materialUI/TextInputForm';
import CategoryInput from '@F/modal/CategoryInput';
import DialogModal from '@F/DialogModal';
import { useDispatch } from 'react-redux';
import ResponseAlert from '@F/ResponseAlert';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import { useInput } from '@HOOK/';
import { copyMessageToClipboard } from '@HOOK/useCopy';
import { actions } from '@/redux/store/storeErrorReport/state';
import * as S from './styles';

function ReportSection({ report, newReportLngLat }) {
  const classes = S.useStyles();
  const isQa = useMemo(() => !!report.report_qa_result, [report.report_qa_result]);

  const isReportOnLngLat = useMemo(
    () => !!(report.report_lat > 0 && report.report_lng > 0),
    [report.report_lng, report.report_lat]
  );
  const [selectLngLat, setSelectLngLat] = useState(0);

  const isReportOnName = useMemo(() => !!report.report_name, [report.report_name]);
  const [selectName, setSelectName] = useState(0);
  const [newName, setNewName] = useInput(report.report_name || '');

  const isReportOnBranchName = useMemo(() => !!report.report_branch_name, [report.report_branch_name]);
  const [selectBranchName, setSelectBranchName] = useState(0);
  const [newBranchName, setNewBranchName] = useInput(report.report_branch_name || '');

  const isReportOnCategory = useMemo(() => !!report.report_category_all_kor, [report.report_category_all_kor]);
  const [selectCategory, setSelectCategory] = useState(0);
  const [newCategory, setNewCategory] = useState({
    korName: report.report_category_all_kor || '',
    code: report.report_category_code || ''
  });
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);

  // 재확인 모달
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [confirmFunction, setConfirmFunction] = useState(() => () => {});

  // 버튼 클릭시 TODO: 함수 하나로 리팩토링
  const dispatch = useDispatch();
  const onClickConfirmButton = () => {
    if (
      (isReportOnLngLat && !selectLngLat) ||
      (isReportOnName && !selectName) ||
      (isReportOnBranchName && !selectBranchName) ||
      (isReportOnCategory && !selectCategory)
    ) {
      setIsAlertOpen(true);
      return;
    }
    setIsModalOpen(true);
    setModalTitle('오류 보고사항을 처리할까요?');
    setConfirmFunction(
      () => () =>
        dispatch(
          actions.confirmReport(report, {
            reportId: report.report_id,
            pid: report.pid,
            locationReportStatus: Number(selectLngLat),
            nameReportStatus: Number(selectName),
            branchNameReportStatus: Number(selectBranchName),
            categoryReportStatus: Number(selectCategory),
            lat: newReportLngLat.lat,
            lng: newReportLngLat.lng,
            name: newName,
            branchName: newBranchName,
            categoryCode: newCategory.code
          })
        )
    );
  };
  const onClickDeleteButton = useCallback(() => {
    setIsModalOpen(true);
    setModalTitle('오류 보고사항을 삭제 처리할까요?');
    setConfirmFunction(() => () => dispatch(actions.deleteStore(report)));
  }, [dispatch, report]);
  const onClickDiscardButton = useCallback(() => {
    setIsModalOpen(true);
    setModalTitle('이 정보를 휴지통에 버리겠습니까?');
    setConfirmFunction(() => () => dispatch(actions.discardStore(report)));
  }, [dispatch, report]);

  return (
    <S.StyledReportSection>
      <S.Section isDisabled={!isReportOnLngLat || isQa}>
        <S.Title>위경도</S.Title>
        <S.Content>
          <RadioGroup row value={selectLngLat} onChange={(event, value) => setSelectLngLat(value)}>
            <FormControlLabel value="1" control={<Radio />} label="반영함" />
            <FormControlLabel value="2" control={<Radio />} label="반영하지 않음" />
            <FormControlLabel value="3" control={<Radio />} label="오류 의심(반영하지 않음)" />
          </RadioGroup>
          <S.ContentRow>
            <p>Store</p>
            <FileCopyTwoToneIcon
              style={{ cursor: 'pointer' }}
              onClick={() => copyMessageToClipboard(`${report.lat}, ${report.lng}`)}
            />
            <S.Text color={theme.palette.text.primary}>{`${report.lat}, ${report.lng}`}</S.Text>
          </S.ContentRow>
          <S.ContentRow>
            <p>Report</p>
            <FileCopyTwoToneIcon
              style={{ cursor: 'pointer' }}
              onClick={() => copyMessageToClipboard(`${report.report_lat}, ${report.report_lng}`)}
            />
            <S.Text color={theme.palette.text.hint}>{`${report.report_lat}, ${report.report_lng}`}</S.Text>
          </S.ContentRow>
          <S.ContentRow>
            <p>수정</p>
            <FileCopyTwoToneIcon
              style={{ cursor: 'pointer' }}
              onClick={() => copyMessageToClipboard(`${newReportLngLat.lat}, ${newReportLngLat.lng}`)}
            />
            <S.Text color={theme.palette.text.hint}>{`${newReportLngLat.lat}, ${newReportLngLat.lng}`}</S.Text>
          </S.ContentRow>
        </S.Content>
      </S.Section>

      <S.Section isDisabled={!isReportOnName || isQa}>
        <S.Title>매장명</S.Title>
        <S.Content>
          <RadioGroup row value={selectName} onChange={(event, value) => setSelectName(value)}>
            <FormControlLabel value="1" control={<Radio />} label="반영함" />
            <FormControlLabel value="2" control={<Radio />} label="반영하지 않음" />
            <FormControlLabel value="3" control={<Radio />} label="오류 의심(반영하지 않음)" />
          </RadioGroup>
          <S.ContentRow>
            <p>Store</p>
            <S.Text color={theme.palette.text.primary}>{report.name}</S.Text>
          </S.ContentRow>
          <S.ContentRow>
            <p>Report</p>
            <S.Text color={theme.palette.text.hint}>{report.report_name}</S.Text>
          </S.ContentRow>
          <S.ContentRow>
            <p>수정</p>
            <TextInputForm
              small
              name="NameInput"
              label="오류를 수정할 장소명 입력"
              size="small"
              onChange={setNewName}
              value={newName}
            />
          </S.ContentRow>
        </S.Content>
      </S.Section>

      <S.Section isDisabled={!isReportOnBranchName || isQa}>
        <S.Title>지점명</S.Title>
        <S.Content>
          <RadioGroup row value={selectBranchName} onChange={(event, value) => setSelectBranchName(value)}>
            <FormControlLabel value="1" control={<Radio />} label="반영함" />
            <FormControlLabel value="2" control={<Radio />} label="반영하지 않음" />
            <FormControlLabel value="3" control={<Radio />} label="오류 의심(반영하지 않음)" />
          </RadioGroup>
          <S.ContentRow>
            <p>Store</p>
            <S.Text color={theme.palette.text.primary}>{report.branch_name}</S.Text>
          </S.ContentRow>
          <S.ContentRow>
            <p>Report</p>
            <S.Text color={theme.palette.text.hint}>{report.report_branch_name}</S.Text>
          </S.ContentRow>
          <S.ContentRow>
            <p>수정</p>
            <TextInputForm
              small
              name="NameInput"
              label="오류를 수정할 지점명 입력"
              size="small"
              onChange={setNewBranchName}
              value={newBranchName}
            />
          </S.ContentRow>
        </S.Content>
      </S.Section>

      <S.Section isDisabled={!isReportOnCategory || isQa}>
        <S.Title>카테고리</S.Title>
        <S.Content>
          <RadioGroup row value={selectCategory} onChange={(event, value) => setSelectCategory(value)}>
            <FormControlLabel value="1" control={<Radio />} label="반영함" />
            <FormControlLabel value="2" control={<Radio />} label="반영하지 않음" />
            <FormControlLabel value="3" control={<Radio />} label="오류 의심(반영하지 않음)" />
          </RadioGroup>
          <S.ContentRow>
            <p>Store</p>
            <S.Text color={theme.palette.text.primary}>{report.category_all_kor}</S.Text>
          </S.ContentRow>
          <S.ContentRow>
            <p>Report</p>
            <S.Text color={theme.palette.text.hint}>{report.report_category_all_kor}</S.Text>
          </S.ContentRow>
          <S.ContentRow>
            <p>수정</p>
            <CategoryInput
              categoryCode={newCategory}
              setCategoryCode={setNewCategory}
              isCategoryModalOpen={isCategoryModalOpen}
              setIsCategoryModalOpen={setIsCategoryModalOpen}
            />
          </S.ContentRow>
        </S.Content>
      </S.Section>

      <S.Buttons>
        {!isQa && (
          <Button variant="outlined" color="primary" className={classes.button} onClick={onClickConfirmButton}>
            완료
          </Button>
        )}
        <Button variant="outlined" color="secondary" className={classes.button} onClick={onClickDeleteButton}>
          삭제
        </Button>
        <Button variant="outlined" color="default" className={classes.button} onClick={onClickDiscardButton}>
          휴지통
        </Button>
      </S.Buttons>

      <DialogModal
        title={modalTitle}
        description="확인버튼을 누르시면 다시 돌이킬 수 없습니다. 신중히 선택하십시오."
        open={isModalOpen}
        setOpen={setIsModalOpen}
        confirmButtonDidClicked={confirmFunction}
      />
      <ResponseAlert
        open={isAlertOpen}
        handleClose={() => setIsAlertOpen(false)}
        message="선택되지 않은 오류값이 있습니다."
      />
    </S.StyledReportSection>
  );
}
export default ReportSection;

ReportSection.propTypes = {
  report: PropTypes.shape({
    report_id: PropTypes.number,
    pid: PropTypes.number,
    report_qa_result: PropTypes.string,
    lng: PropTypes.number,
    lat: PropTypes.number,
    report_lng: PropTypes.number,
    report_lat: PropTypes.number,
    name: PropTypes.string,
    report_name: PropTypes.string,
    branch_name: PropTypes.string,
    report_branch_name: PropTypes.string,
    category_all_kor: PropTypes.string,
    report_category_all_kor: PropTypes.string,
    report_category_code: PropTypes.string
  }).isRequired,
  newReportLngLat: PropTypes.shape({
    lng: PropTypes.number,
    lat: PropTypes.number
  }).isRequired
};
