import { axiosInstancesManager } from '@API/axios';
import axios from 'axios';

const BACKEND_ARTICLE_NAME = 'news';

class HomepageArticlesApi {
  cancelTokenSources = [];

  // 전체/단일 채용정보 조회
  getAll = (params) => {
    const cancelTokenSource = axios.CancelToken.source();

    this.cancelTokenSources.push(cancelTokenSource);

    if (params === undefined) {
      return axiosInstancesManager.manager.get(`/${BACKEND_ARTICLE_NAME}`);
    }

    return axiosInstancesManager.manager.get(`/${BACKEND_ARTICLE_NAME}`, { params });
  };

  get(id) {
    return axiosInstancesManager.manager.get(`/${BACKEND_ARTICLE_NAME}/${id}`);
  }

  // 뉴스정보 등록 (모든 필드 전달)
  post(data) {
    return axiosInstancesManager.manager.post(`/${BACKEND_ARTICLE_NAME}`, data);
  }

  // 뉴스정보 수정 (일부 필드 수정)
  patch({ id, data }) {
    return axiosInstancesManager.manager.patch(`/${BACKEND_ARTICLE_NAME}/${id}`, data);
  }

  // 뉴스정보 수정 (모든 필드 전달)
  put({ id, data }) {
    return axiosInstancesManager.manager.put(`/${BACKEND_ARTICLE_NAME}/${id}`, data);
  }

  delete(id) {
    return axiosInstancesManager.manager.delete(`/${BACKEND_ARTICLE_NAME}/${id}`);
  }
}

export default new HomepageArticlesApi();
