import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NaverMap from '@F/map/NaverMap';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 150
  }
}));

export const MapWrapper = styled.div`
  width: 100%;
  height: 90%;
  min-height: 776px;
  display: flex;
  flex-flow: column;
  position: relative;
  > form {
    width: 100%;
  }
`;

export const Row = styled(Grid)``;

export const Map = styled(NaverMap)`
  width: 100%;
  min-height: 500px;
  height: 100%;
`;

export const InnerMapContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const ResetCenterButton = styled.button`
  position: absolute;
  left: 60px;
  top: 10px;

  width: 60px;
  height: 45px;

  background-color: ${(props) => props.theme.palette.common.white};
  border: 1px solid ${(props) => props.theme.palette.grey[400]};
  border-radius: 10px;
  outline: none;

  cursor: pointer;
`;
