import { GetReportsParams, PutReportParams } from '@TS/cashplace/report';
import { axiosInstancesManager } from '@API/axios';
/* eslint class-methods-use-this: 0 */

class CashplaceReportsApi {
  getReports({ userId, state, kind, page, perPage }: GetReportsParams) {
    return axiosInstancesManager.manager.get('/cashplace/reports', {
      params: {
        user_id: userId,
        state,
        kind,
        page,
        per_page: perPage
      }
    });
  }

  putReport(reportId: number, { result, lat, lng }: PutReportParams) {
    return axiosInstancesManager.manager.put(`/cashplace/reports/${reportId}`, { result, lat, lng });
  }
}
export default new CashplaceReportsApi();
