import { createGlobalStyle } from 'styled-components';
import { createTheme } from '@material-ui/core/styles';
import '@toast-ui/chart/dist/toastui-chart.min.css';

export const GlobalStyle = createGlobalStyle`
  html, body {
    font-size: 16px !important;
  }

  * {
      margin:0;
      padding:0;
      box-sizing: border-box;
  }           

  p {
      margin-bottom:10px;
  }

  body, span, li, textarea {
    font-family: 'NanumSquareRound','Montserrat', sans-serif;
  }

  .refresh-token-modal{
    position:fixed;
    z-index: 9999;
    top:0;
    right:0;
    bottom:0;
    left:0;
    background-color: rgba(0,0,0,0.6);
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

    .refresh-token-modal-box{
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        align-items:center;
        background-color:white;
        border-radius: 8px;
        padding: 20px 20px 0 20px;
        font-size:14px;
        font-weight:bold;
    }    
  }

  .lds-spinner {
    transform: scale(0.8);
    margin: 0 auto;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    scale: 0.6;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #3f51b5;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const theme = createTheme({
  palette: {
    primary: {
      lighter: '#ce93d8',
      light: '#b388ff',
      main: '#7c4dff',
      dark: '#651fff',
      deepDark: '#311b92'
    },
    secondary: {
      lighter: '#ffcc80',
      light: '#ff9800',
      main: '#ef6c00',
      dark: '#e65100'
    },
    error: {
      main: '#d32f2f'
    },
    text: {
      primary: '#2196f3',
      secondary: '#212121',
      soft: '#9e9e9e',
      hint: '#ff4081'
    }
  },
  zIndex: {
    loading: 10000
  }
});
