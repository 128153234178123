import { useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Grid, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import moment from 'moment';
import firebase from 'firebase';
import { useDispatch } from 'react-redux';
import Viewer from 'react-viewer';
import Loading from '@I/loading.png';
import type { ImageResult, Study } from '@TS/cashplace/study';
import { useFetch } from '@HOOK/';
import { FIREBASE_CASHPLACE_URL, MODE } from '@/config';
import { actions } from '@/redux/cashplace/inspect/state';
import { userStore } from '@/mobX/user';
import * as S from './styles';

type DetailProps = {
  id?: number;
  data: [Study];
};

function Detail({ id, data }: DetailProps): JSX.Element {
  const detail = useMemo(() => data.find((element) => element.study_log.study_id === id), [id, data]);

  const { isLoading } = useFetch();
  const [images, setImages] = useState<string[]>([]);
  const [imageInfos, setImageInfos] = useState<ImageResult[]>([]);
  const [imageMetadatas, setImageMetadatas] = useState<string[] | null[]>([null, null, null]);
  const [isImageLoaded, setIsImageLoaded] = useState<(boolean | null)[]>([false, false, false]);

  const imageResults = useMemo(() => isImageLoaded.filter((result) => result !== null), [isImageLoaded]);

  const [isLightBoxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  useEffect(() => {
    if (!id) return;
    if (!detail) return;
    (async () => {
      const names = detail.image_names;
      const results = detail.image_results;
      setIsImageLoaded(new Array(names.length).fill(false));
      if (results.length > 0) {
        setImageInfos(results);
      } else {
        setImageInfos(
          new Array(names.length)
            .fill({
              floor: detail.study_log.floor,
              inout: null
            })
            .map((item, index) => ({
              ...item,
              seq: index
            }))
        );
      }

      // NOTE: load images from firebase.
      const imageLength = names.length;
      const newImages = Array(imageLength).fill(null);
      names.forEach((image: string, index: number) => {
        const url = firebase
          .storage()
          .refFromURL(`${FIREBASE_CASHPLACE_URL}/${MODE === 'staging' ? 'images-staging' : 'images'}/${image}`);
        url
          .getDownloadURL()
          .then((responseUrl) => {
            newImages.splice(index, 1, responseUrl);
            const refinedImages = newImages.filter(Boolean);
            setImages(refinedImages);
          })
          .catch(() => setIsImageLoaded((prevState) => prevState.map((item, j) => (j === index ? null : item))));

        url.getMetadata().then((metadata) => {
          setImageMetadatas((prevState) =>
            prevState.map((item, j) => (index === j ? metadata.customMetadata?.createdDateTime : item))
          );
        });
      });
    })();
  }, [id, detail]);

  useEffect(() => {
    // NOTE: 원칙적으로 이미지는 최대 3장이다. (2개는 필수, 1개는 선택)
    // 총 3장인 경우, 마지막 사진의 result를 서버에서 가지고 있지 않으므로, 하나를 추가해준다.
    // 그러나 캐시플레이스 정책이 바뀌기 전에 들어온 건에 대해서는 층수마다 3장, 즉 3n개가 존재할 수 있다. 이 경우는 현재 시점에서 별도로 고려하지 않는다.
    // 참고: MC-1013
    if (!detail) return;
    if (!imageInfos) return;
    if (images.length === 3 && imageInfos.length === 2) {
      const newInfo: ImageResult = {
        floor: detail.study_log.floor,
        inout: 'unknown',
        seq: 3
      };
      setImageInfos((prevState) => [...prevState, newInfo]);
    }
  }, [detail, images, imageInfos]);

  useEffect(() => {
    if (images.length < 2) return;
    const imageLoaders = new Array(images.length).fill(undefined);
    imageLoaders.forEach((_, i) => {
      imageLoaders[i] = new Image();
      imageLoaders[i].src = images[i];

      imageLoaders[i].onload = () => {
        setIsImageLoaded((prevState) => prevState.map((item, j) => (j === i ? true : item)));
      };
      imageLoaders[i].onerror = () => {
        setIsImageLoaded((prevState) => prevState.map((item, j) => (j === i ? null : item)));
      };
    });
  }, [images]);

  function openLightbox(image: string) {
    setIsLightboxOpen(true);
    setLightboxIndex(images.findIndex((item) => item === image));
  }

  const changeInout = (seq: number, value: string) => {
    if (value !== 'in' && value !== 'out' && value !== 'unknown') return;
    setImageInfos(
      (prevState) =>
        prevState &&
        prevState.map((item: ImageResult, index: number) => {
          if (index === seq) {
            return {
              ...item,
              inout: value
            };
          }
          return item;
        })
    );
  };

  const changeAllIn = () => {
    setImageInfos(
      (prevState) =>
        prevState &&
        prevState.map((item: ImageResult) => ({
          ...item,
          inout: 'in'
        }))
    );
  };

  const changeAllOut = () => {
    setImageInfos(
      (prevState) =>
        prevState &&
        prevState.map((item: ImageResult) => ({
          ...item,
          inout: 'out'
        }))
    );
  };

  const classes = S.useStyles();

  const [isForced, setIsForced] = useState(false);
  const IsForcedCheckbox = (
    <FormControlLabel
      control={
        <Checkbox color="primary" checked={isForced} onChange={(): void => setIsForced((prevState) => !prevState)} />
      }
      label="FORCE"
    />
  );
  const isCompleted = useMemo(() => !!(detail && detail.slot), [detail]);
  const IsCompletedCheckbox = (
    <FormControlLabel control={<Checkbox color="primary" disabled checked={isCompleted} />} label="완료여부" />
  );
  const [isClosed, setIsClosed] = useState(false);
  const IsClosedCheckbox = (
    <FormControlLabel
      control={
        <Checkbox color="primary" checked={isClosed} onChange={(): void => setIsClosed((prevState) => !prevState)} />
      }
      label="사이트 닫기"
    />
  );

  const dispatch = useDispatch();
  const user = userStore.getUser();

  const approve = () => {
    if (!detail) return;
    if (!user) return;
    dispatch(
      actions.updateInspect({
        studyId: detail.study_log.study_id,
        siteId: detail.study_log.site_id,
        userId: detail.study_log.user_id,
        images: imageInfos.filter((info, index) => info.inout !== null && isImageLoaded[index]),
        result: 1,
        inspector: typeof user.identity === 'string' && user.identity,
        complete: isClosed
      })
    );
  };
  const reject = () => {
    if (!detail) return;
    if (!user) return;
    dispatch(
      actions.updateInspect({
        studyId: detail.study_log.study_id,
        siteId: detail.study_log.site_id,
        userId: detail.study_log.user_id,
        images: imageInfos.filter((info, index) => info.inout !== null && isImageLoaded[index]),
        result: -1,
        inspector: typeof user.identity === 'string' && user.identity,
        complete: isClosed
      })
    );
  };

  return (
    <>
      <Viewer
        visible={isLightBoxOpen}
        onClose={() => setIsLightboxOpen(false)}
        images={images.map((image) => ({ src: image }))}
        activeIndex={lightboxIndex}
        zIndex={10000}
        showTotal
      />
      <S.StyledDetail>
        <S.Row container className={classes.margin} spacing={1}>
          {imageResults.length > 0 ? (
            <S.ImageSection>
              {imageResults.map((_: unknown, index: number) => (
                <S.ImageContainer key={index}>
                  {imageInfos[index] && (
                    <S.MetadataWrapper>
                      <span>{imageInfos[index].floor ? `${imageInfos[index].floor} 층` : ''}</span>
                      <span>
                        {imageMetadatas[index]
                          ? `${moment(imageMetadatas[index]).format('YYYY-MM-DD HH:mm:ss')}`
                          : 'Metadata 없음'}
                      </span>
                    </S.MetadataWrapper>
                  )}
                  {isImageLoaded[index] ? (
                    <S.ImageWrapper onClick={() => openLightbox(images[index])}>
                      <img key={images[index]} src={images[index]} alt="inspect" />
                    </S.ImageWrapper>
                  ) : (
                    <S.LoadingWrapper>
                      <img src={Loading} alt="loading" />
                    </S.LoadingWrapper>
                  )}
                  {imageInfos[index] && (
                    <RadioGroup
                      row
                      aria-label="inout"
                      name="inout"
                      value={imageInfos ? imageInfos[index].inout : 'unknown'}
                      onChange={(e) => changeInout(index, e.target.value)}
                    >
                      <FormControlLabel value="unknown" control={<Radio />} label="?" disabled={isLoading} />
                      <FormControlLabel value="in" control={<Radio />} label="in" disabled={isLoading} />
                      <FormControlLabel value="out" control={<Radio />} label="out" disabled={isLoading} />
                    </RadioGroup>
                  )}
                </S.ImageContainer>
              ))}
            </S.ImageSection>
          ) : (
            <S.ImageSection>
              <h1>이미지가 없습니다.</h1>
            </S.ImageSection>
          )}
        </S.Row>

        <S.ButtonRow container className={classes.margin} spacing={1}>
          <Grid item xs>
            {IsForcedCheckbox}
          </Grid>
          <Grid item xs>
            {IsCompletedCheckbox}
          </Grid>
          <Grid item xs>
            {IsClosedCheckbox}
          </Grid>
        </S.ButtonRow>

        <S.ButtonRow container className={classes.margin} spacing={1}>
          <Grid item xs>
            <Button variant="outlined" color="primary" onClick={changeAllIn} disabled={isLoading}>
              ALL IN
            </Button>
          </Grid>
          <Grid item xs>
            <Button variant="outlined" color="secondary" onClick={changeAllOut} disabled={isLoading}>
              ALL OUT
            </Button>
          </Grid>

          <Grid item xs={1} />

          <Grid item xs>
            <Button variant="contained" color="primary" onClick={approve} disabled={isLoading}>
              승인
            </Button>
          </Grid>
          <Grid item xs>
            <Button variant="contained" color="secondary" onClick={reject} disabled={isLoading}>
              거부
            </Button>
          </Grid>
        </S.ButtonRow>
      </S.StyledDetail>
    </>
  );
}
export default Detail;
