import { Table, TableContainer, Paper } from '@material-ui/core';
import TableHead from './TableHead';
import TableBody from './TableBody';

const CampaignTable = (): JSX.Element => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead />
      <TableBody />
    </Table>
  </TableContainer>
);
export default CampaignTable;
