import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import DialogModal from '@F/DialogModal';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { calculateDistanceFromLatLng } from '@FUNC/map';
import useDueDate from '@HOOK/useDueDate';
import useAuth from '@HOOK/useAuth';
import { actions } from '@/redux/fingerprint/fpManager/state';
import * as S from './styles';

function InfoSection({ fingerprint }) {
  const validation = useMemo(() => fingerprint.fp_image_validation, [fingerprint]);

  // MC-1096: fingerprint가 생성된 후 4일 뒤 자동 비활성화
  const { isAuthoritative } = useAuth();
  const { leftDaysBeforeDueDate, isOverdue } = useDueDate(moment(fingerprint.fp_created_at).add(3, 'days').toString());

  // 거부, 승인, 확인완료, 승인거부, 승인
  // TODO: enum 으로 분리
  const disableSpotButton = (isOverdue && !isAuthoritative) || [0, 1, 12, 10, 11].includes(validation);
  // 승인, 현장확인, 승인거부, 승인
  const disableRejectButton = (isOverdue && !isAuthoritative) || [1, 2, 10, 11].includes(validation);
  // 거부, 현장확인, 승인거부, 승인
  const disableApproveButton = (isOverdue && !isAuthoritative) || [0, 2, 10, 11].includes(validation);

  // 재확인 모달
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [confirmFunction, setConfirmFunction] = useState(() => () => {});
  useEffect(() => {
    setIsModalOpen(false);
  }, [fingerprint]);

  // 버튼 클릭시 TODO: 함수 하나로 리팩토링
  const dispatch = useDispatch();
  const onClickSpotButton = useCallback(() => {
    setIsModalOpen(true);
    if (validation === 2) {
      // 현장 확인이면
      setModalTitle('현장확인 취소할까요?');
      setConfirmFunction(() => () => dispatch(actions.setImageValidation(fingerprint, -1)));
    } else {
      setModalTitle('현장확인 요청할까요?');
      setConfirmFunction(() => () => dispatch(actions.setImageValidation(fingerprint, 2)));
    }
  }, [dispatch, fingerprint, validation]);
  const onClickApproveButton = useCallback(() => {
    setIsModalOpen(true);
    if (validation === 1) {
      // 승인이면
      setModalTitle('승인 취소할까요?');
      setConfirmFunction(() => () => dispatch(actions.setImageValidation(fingerprint, -1)));
    } else {
      setModalTitle('승인할까요?');
      setConfirmFunction(() => () => dispatch(actions.setImageValidation(fingerprint, 1)));
    }
  }, [dispatch, fingerprint, validation]);
  const onClickRejectButton = useCallback(() => {
    setIsModalOpen(true);
    if (validation === 0) {
      // 거부 이면
      setModalTitle('승인거부 취소할까요?');
      setConfirmFunction(() => () => dispatch(actions.setImageValidation(fingerprint, -1)));
    } else {
      setModalTitle('승인거부 할까요?');
      setConfirmFunction(() => () => dispatch(actions.setImageValidation(fingerprint, 0)));
    }
  }, [dispatch, fingerprint, validation]);

  return (
    <S.StyledInfoSection>
      <div>
        <S.Table>
          <thead>
            <tr>
              <th className="GrayCell">이름</th>
              <th className="GrayCell" colSpan={2}>
                값
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="GrayCell">LUX</td>
              <td colSpan={2}>{fingerprint.fp_lux}</td>
            </tr>
            <tr>
              <td className="GrayCell" rowSpan={3}>
                기기 위치
              </td>
              <td>위도</td>
              <td>{fingerprint.fp_c_lat}</td>
            </tr>
            <tr>
              <td>경도</td>
              <td>{fingerprint.fp_c_lng}</td>
            </tr>
            <tr>
              <td>실제 거리 차이</td>
              <td>
                {Math.round(
                  calculateDistanceFromLatLng(
                    fingerprint.fp_c_lat,
                    fingerprint.fp_c_lng,
                    fingerprint.fp_lat,
                    fingerprint.fp_lng
                  ) * 1000
                )}
                m
              </td>
            </tr>
            <tr>
              <td className="GrayCell">장소(pid)</td>
              <td colSpan={2}>{`${fingerprint.name} ${fingerprint.branch_name ?? ''}(${fingerprint.pid})`}</td>
            </tr>
            <tr>
              <td className="GrayCell">브랜드(bid)</td>
              <td colSpan={2}>{fingerprint.brands?.map((brand) => `${brand.name}(${brand.bid})`).join(', ')}</td>
            </tr>
            <tr>
              <td className="GrayCell">복합몰(cid)</td>
              <td colSpan={2}>
                {fingerprint.complexes
                  ?.map((complex) => `${complex.name} ${complex.branch_name ?? ''}(${complex.cid})`)
                  .join(', ')}
              </td>
            </tr>
          </tbody>
        </S.Table>
        <S.Status>
          이미지 업로드 상태:
          {formatImageUploadStatus(fingerprint.fp_image_upload_status)}
        </S.Status>
      </div>

      <S.Buttons>
        {!isAuthoritative && (
          <Typography color={leftDaysBeforeDueDate === 0 ? 'secondary' : 'default'}>
            D{isOverdue ? '+' : '-'}
            {Math.abs(leftDaysBeforeDueDate)}
          </Typography>
        )}
        <Button variant="outlined" color="default" disabled={disableSpotButton} onClick={onClickSpotButton}>
          {validation === 2 ? '현장확인취소' : '현장확인'}
        </Button>
        <Button variant="outlined" color="secondary" disabled={disableRejectButton} onClick={onClickRejectButton}>
          {validation === 0 ? '승인거부취소' : '승인거부'}
        </Button>
        <Button variant="outlined" color="primary" disabled={disableApproveButton} onClick={onClickApproveButton}>
          {validation === 1 ? '승인취소' : '승인'}
        </Button>
      </S.Buttons>

      <DialogModal
        title={modalTitle}
        description="확인버튼을 누르시면 다시 돌이킬 수 없습니다. 신중히 선택하십시오."
        open={isModalOpen}
        setOpen={setIsModalOpen}
        confirmButtonDidClicked={confirmFunction}
      />
    </S.StyledInfoSection>
  );
}
export default InfoSection;

InfoSection.propTypes = {
  fingerprint: PropTypes.shape({
    fp_fpid: PropTypes.number,
    fp_created_at: PropTypes.string,
    fp_image_validation: PropTypes.number,
    fp_image_upload_status: PropTypes.number,
    fp_lux: PropTypes.number,
    fp_c_lat: PropTypes.number,
    fp_c_lng: PropTypes.number,
    fp_lat: PropTypes.number,
    fp_lng: PropTypes.number,
    pid: PropTypes.number,
    name: PropTypes.string,
    branch_name: PropTypes.string,
    brands: PropTypes.arrayOf(
      PropTypes.shape({
        bid: PropTypes.number,
        name: PropTypes.string
      })
    ),
    complexes: PropTypes.arrayOf(
      PropTypes.shape({
        cid: PropTypes.number,
        name: PropTypes.string,
        branch_name: PropTypes.string
      })
    )
  }).isRequired
};

function formatImageUploadStatus(status) {
  switch (status) {
    case 2:
      return <span style={{ color: 'green' }}>(성공)</span>;
    case -1:
      return <span style={{ color: 'red' }}>(실패)</span>;
    case 1:
      return <span style={{ color: 'gray' }}>(대기)</span>;
    default:
      return (
        <span style={{ color: 'green' }}>
          (성공(
          {status})
        </span>
      );
  }
}
