import { Caption, grayscale800, Body, grayscale100, primary, highlight, styled } from 'loplat-ui';
import type { CampaignReport } from '@P/max/campaigns/utils/type';
import { Section } from '../section';

const Report = ({ report }: { report: CampaignReport }): JSX.Element => (
  <Section title="캠페인 성과">
    <ReportWrapper>
      <ReportModule title="도달" content={report.hitCnt.toLocaleString()} />
      <ReportModule
        title="응답"
        content={report.click.count.toLocaleString()}
        caption={report.click.percent.toFixed(2)}
      />
    </ReportWrapper>
  </Section>
);

export default Report;

type ReportModuleType = {
  title: string;
  content: string;
  caption?: string;
};
const ReportModule = ({ title, content, caption }: ReportModuleType): JSX.Element => (
  <ModuleWrapper>
    <Body tag="span" color={grayscale800} size="base" weight="bold" mb={2}>
      {title}
    </Body>
    <Body tag="span" weight="extraBold" size="4xl" color={primary}>
      {content}
    </Body>
    {caption && (
      <ReportCaption color={grayscale800} mt={2} size="base">
        응답률: {caption}%
      </ReportCaption>
    )}
  </ModuleWrapper>
);

const ReportWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const ModuleWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    text-align: center;
    max-width: 500px;
  }
  & + & {
    border-left: 1px solid ${grayscale100};
  }
`;

export const ReportCaption = styled(Caption)`
  ${highlight()};
`;
