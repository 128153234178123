import { memo } from 'react';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { HeadCell, isSortableHeadCell, SortableId } from '@TS/store/cidTable';
import * as S from './TableHead.styles';

type Props = {
  headCell: HeadCell;
  sortingOrder: (id: SortableId) => void;
};

const TableHeadCell = memo(
  ({ headCell, sortingOrder }: Props): JSX.Element => (
    <S.StyledHeadCell
      onClick={() => isSortableHeadCell(headCell) && sortingOrder(headCell.id)}
      isSortable={'sortOrder' in headCell}
    >
      <>
        {headCell.label}
        {'sortOrder' in headCell && headCell.sortOrder === 'descending' ? <ArrowDownward /> : <ArrowUpward />}
      </>
    </S.StyledHeadCell>
  )
);

export default TableHeadCell;
