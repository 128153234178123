import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const StyledForm = styled.form``;

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2)
  }
}));

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
  padding-right: 20px;
`;
