import { Fragment, useCallback, useRef, useEffect } from 'react';
import { Complex } from '@TS/store/complex';
import { toggleNodeByCid } from './isOpenFunction';
import TreeNode from './TreeNode';
import NoResult from './NoResult';
import { SelectCIDFunction } from '.';
import { TableBodyWrapper } from './index.styles';
import { SelectedCID } from '..';

interface Props {
  complexes: Complex[];
  setComplexes: React.Dispatch<React.SetStateAction<Complex[]>>;
  setUpdate: () => void;
  selectCID: SelectCIDFunction;
  selectedCID: SelectedCID | null;
}

const TableBody = ({ complexes, setComplexes, setUpdate, selectedCID, selectCID }: Props): JSX.Element => {
  const firstRow = useRef<HTMLDivElement | null>(null);
  const executeScrollTop = () => firstRow?.current?.scrollIntoView();

  useEffect(() => {
    executeScrollTop();
  }, [complexes]);

  const toggleTableNode = useCallback(
    (cid: Complex['cid']) => {
      if (!complexes?.length) return;
      const newComplexes = toggleNodeByCid(complexes, cid, false);

      setComplexes(newComplexes);
      setUpdate();
    },
    [complexes, setComplexes, setUpdate]
  );

  const createTree = (complex: Complex, depth: number) =>
    complex.children && (
      <TreeNode
        complex={complex}
        toggleNode={toggleTableNode}
        depth={depth}
        onSelect={selectCID}
        isSelected={selectedCID?.cid === complex.cid}
        aria-label="collapsible table"
      >
        {complex.children.map((child: Complex) => (
          <Fragment key={child.cid}>{createTree(child, depth + 1)}</Fragment>
        ))}
      </TreeNode>
    );

  return (
    <TableBodyWrapper>
      {!complexes && <NoResult />}
      {complexes?.map((complex, index: number) => {
        if (index === 0) {
          return (
            <div key={complex.cid} ref={firstRow}>
              {createTree(complex, 0)}
            </div>
          );
        }
        return <Fragment key={complex.cid}>{createTree(complex, 0)}</Fragment>;
      })}
    </TableBodyWrapper>
  );
};

export default TableBody;
