import { useDispatch } from 'react-redux';
import { Button, Grid } from '@material-ui/core';
import TextInputForm from '@F/materialUI/TextInputForm';
import { toastErrorMessage } from '@FUNC/toast';
import { useInput } from '@HOOK/';
import { actions } from '@/redux/admin/whitelist/state';
import * as S from './styles';

function CreateWhitelist() {
  const classes = S.useStyles();

  const [ip, setIp] = useInput('');
  const IpInput = <TextInputForm name="Ip" label="아이피" onChange={setIp} value={ip} />;
  const [memo, setMemo] = useInput('');
  const MemoInput = <TextInputForm name="Memo" label="메모" onChange={setMemo} value={memo} />;

  const dispatch = useDispatch();
  const createWhitelist = () => {
    if (!ip.trim() || !memo.trim()) {
      toastErrorMessage('정보를 입력해주세요.');
      return;
    }
    dispatch(actions.createList({ ipAddress: ip, comment: memo }));
  };

  return (
    <S.StyledCreateWhitelist>
      <div className={classes.title}>화이트리스트 추가</div>
      <Grid container spacing={2} className={classes.wrapper}>
        <Grid item xs={3}>
          {IpInput}
        </Grid>
        <Grid item xs={9}>
          {MemoInput}
        </Grid>
      </Grid>
      <S.ButtonRow>
        <Button variant="contained" color="primary" onClick={createWhitelist}>
          추가
        </Button>
      </S.ButtonRow>
    </S.StyledCreateWhitelist>
  );
}
export default CreateWhitelist;

CreateWhitelist.propTypes = {};
