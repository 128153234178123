import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Paper } from '@material-ui/core';

import { useTogglers } from '@HOOK/useTogglers';
import Wrapper from '@F/materialUI/Wrapper';
import TableHeadCell from '@F/table/cells/TableHeadCell';
import ToggleHeadCell from '@F/table/cells/ToggleHeadCell';
import type { PrimitiveLog } from '@TS/max/primitiveLog/primitiveLog';
import { usePrimitiveLogBloc } from '../usePrimitiveLogBloc';
import { PRIMITIVE_LOG_TABLE_HEAD_CELLS } from '../data';
import PrimitiveLogTableItem from './PrimitiveLogTableItem';
import TableBodySkeleton from './TableBodySkeleton';

const MORE = 20;

export default function PrimitiveLogsTable(): JSX.Element {
  const { pagedPrimitiveLogs, sortPrimitiveLogs, fetchStatus } = usePrimitiveLogBloc();
  const [inViewPrimitiveLogs, setInViewPrimitiveLogs] = useState<PrimitiveLog[]>([]);
  const collapseTogglers = useTogglers();
  const [orderName, setOrderName] = useState('ts_local');
  const { ref, inView } = useInView();

  const handleClickHeadCell = (
    _: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    _name?: string,
    toggledOrderType?: OrderType
  ): void => {
    if (!_name || !toggledOrderType) return;

    const name = _name as keyof PrimitiveLog;
    setOrderName(name);
    sortPrimitiveLogs(name, toggledOrderType);
  };

  const loadMore = () => {
    setInViewPrimitiveLogs((prev) => {
      const next = prev.concat(pagedPrimitiveLogs.slice(prev.length, prev.length + MORE));
      return next;
    });
  };

  useEffect(() => {
    if (pagedPrimitiveLogs.length) {
      setInViewPrimitiveLogs(pagedPrimitiveLogs.slice(0, MORE));
    }
  }, [pagedPrimitiveLogs]);

  useEffect(() => {
    if (inView) {
      if (inViewPrimitiveLogs.length < pagedPrimitiveLogs.length) {
        loadMore();
      }
    }
  }, [inView]);

  return (
    <Wrapper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <ToggleHeadCell />
              {PRIMITIVE_LOG_TABLE_HEAD_CELLS.map(({ name, label, defaultOrderType }, i) => (
                <TableHeadCell
                  key={i}
                  name={name}
                  label={label}
                  defaultOrderType={defaultOrderType}
                  onClick={handleClickHeadCell}
                  isActive={Boolean(name === orderName)}
                />
              ))}
            </TableRow>
          </TableHead>
          {fetchStatus === 'loading' ? <TableBodySkeleton /> : null}
          <TableBody>
            {fetchStatus === 'idle' ? (
              <TableRow>
                <TableCell align="center" colSpan={6}>
                  <span>검색해주세요.</span>
                </TableCell>
              </TableRow>
            ) : null}
            {fetchStatus === 'success' ? (
              inViewPrimitiveLogs.length ? (
                inViewPrimitiveLogs.map((primitiveLog, i: number) => (
                  <PrimitiveLogTableItem
                    key={i}
                    index={i}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onClick={(_) => collapseTogglers.toggle(i)}
                    primitiveLog={primitiveLog}
                    isExpanded={collapseTogglers.state[i]}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <span>검색된 결과가 없습니다.</span>
                  </TableCell>
                </TableRow>
              )
            ) : null}
          </TableBody>
          <div ref={ref} />
        </Table>
      </TableContainer>
    </Wrapper>
  );
}
