import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const StyledComplexModal = styled.div``;

export const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FilterItem = styled.div`
  width: calc(100% / 5 - 1%);
`;

export const SearchButton = styled(Button)`
  width: 100%;
  height: fit-content;
`;

export const Text = styled.p`
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.hint};
`;

export const TableWrapper = styled.div`
  height: 500px;
  margin: 20px 0;
`;
