import { CloseIcon, IconButton, grayscale200 } from 'loplat-ui';
import styled from 'styled-components';

interface Props {
  label: string;
  id: number;
  deleteTag: (id: number) => void;
}

const Tag = ({ label, deleteTag, id }: Props): JSX.Element => (
  <TagWrapper>
    <span>{label}</span>
    <StyledIconButton onClick={() => deleteTag(id)} className="test">
      <CloseIcon size={8} />
    </StyledIconButton>
  </TagWrapper>
);

export default Tag;

const TagWrapper = styled.div`
  cursor: pointer;
  padding: 5px 8px;
  border: 1px solid ${grayscale200};
  border-radius: 4px;
  background-color: white;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    margin-right: 0.6rem;
  }
`;

const StyledIconButton = styled(IconButton)`
  padding: 4px !important;
`;
