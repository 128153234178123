import { cookSitesApi } from '@API/manager/cook';
import { fileNameFromContentDisposition } from '@FUNC/download';

export function fetchClosedSites(page, perPage, params) {
  return cookSitesApi
    .getClosedSites({
      page,
      perPage,
      ...params
    })
    .then((response) => response.data);
}

export function fetchClosedSitesCsv(page, perPage, params) {
  return cookSitesApi
    .getClosedSitesCsv({
      page,
      perPage,
      ...params
    })
    .then((response) => {
      const { data, headers } = response;

      const fileName = fileNameFromContentDisposition(headers['content-disposition']);

      return {
        data,
        fileName
      };
    });
}

export function patchSite({ siteId, action }) {
  return cookSitesApi
    .patchSite({
      siteId,
      action
    })
    .then((response) => response.data);
}
