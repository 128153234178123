import { useSelector } from 'react-redux';

import { Table, TableBody, TableContainer, Paper } from '@material-ui/core';

import RecruitItem from './recruitsTable/RecruitItem';
import TableSkeleton from './recruitsTable/TableSkeleton';
import Pagination from './recruitsTable/Pagination';
import RecruitsTableHead from './recruitsTable/RecruitsTableHead';
import { selectStatus, selectRecruits } from './redux/selector';

export default function RecruitTable(): JSX.Element {
  const { recruits } = useSelector(selectRecruits);
  const status = useSelector(selectStatus);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <RecruitsTableHead />
          {status === 'loading' ? (
            <TableSkeleton />
          ) : (
            <TableBody>
              {recruits.map((recruit) => (
                <RecruitItem key={recruit.id} recruit={recruit} />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Pagination />
    </>
  );
}
