import styled, { css } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const StyledReportSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const useStyles = makeStyles(() => ({
  button: {
    marginLeft: 10
  }
}));

export const Buttons = styled.div`
  align-self: flex-end;
`;

export const Section = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;

  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: none;
      opacity: 0.2;
    `};
`;

export const Title = styled.p`
  margin: 10px 0;
  font-weight: bold;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
`;

export const ContentRow = styled.div`
  display: flex;
  align-items: center;

  & > p:first-of-type {
    margin: 5px 0;
    width: 5rem;
    font-weight: bold;
  }
`;

export const Text = styled.p`
  margin: 0;
  color: ${(props) => props.color || props.theme.palette.text.secondary};
`;
