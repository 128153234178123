import { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from '@F/modal/ModalWrapper';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import IndeterminateCheckBoxRoundedIcon from '@material-ui/icons/IndeterminateCheckBoxRounded';
import { TreeView, TreeItem } from '@material-ui/lab';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useInput } from '@HOOK/';
import TextInputForm from '@F/materialUI/TextInputForm';
import { valuesStore } from '@/mobX/values';
import * as S from './styles';

const CategoryModal = observer(({ open, setOpen, setValue, mode = 'manager' }) => {
  const classes = S.useStyles();

  const [keyword, setKeyword, clearKeyword] = useInput('');
  const [selected, setSelected] = useState(null);
  const [parentNodes, setParentNodes] = useState([]);
  // TODO: Mobx에 타이밍 문제가 있어서 추후 쓰기 좋고, 성능 좋은 Redux로 바꿉시다.
  const categories = useMemo(() => toJS(valuesStore.categories[mode]), [mode]);

  const makeNewParentNodes = useCallback(() => {
    if (!categories) return [];

    let newParentNodes = [];
    const recursiveSearch = (node) => {
      if (node.children) {
        newParentNodes = [...newParentNodes, node.code];
        node.children.forEach((child) => recursiveSearch(child));
      }
    };
    categories.forEach((node) => {
      recursiveSearch(node);
    });
    return newParentNodes;
  }, [categories]);

  const isSearched = useCallback(
    (node) => getNamePlusCodeString(node).indexOf(keyword) > -1 || node.children?.some((child) => isSearched(child)),
    [keyword]
  );

  const selectTreeItem = useCallback((node) => {
    if (node.children) return setSelected(null);

    return setSelected(node);
  }, []);

  const completeSelection = useCallback(
    (node) => {
      if (node.children) return;

      const korName = node.kor_name;
      const { code } = node;
      setValue({ korName, code });
      setOpen(false);
    },
    [setOpen, setValue]
  );

  const recursiveTreeItem = useCallback(
    (node) => (
      <TreeItem
        nodeId={node.code}
        label={getNamePlusCodeString(node)}
        key={node.code}
        onClick={() => selectTreeItem(node)}
        onDoubleClick={() => completeSelection(node)}
        style={{ display: !isSearched(node) && 'none' }}
      >
        {node.children && node.children.map((child) => recursiveTreeItem(child))}
      </TreeItem>
    ),
    [isSearched, selectTreeItem, completeSelection]
  );

  const confirm = useCallback(() => {
    if (selected) {
      const korName = selected.kor_name;
      const { code } = selected;
      setValue({ korName, code });
      setOpen(false);
    }
  }, [selected, setOpen, setValue]);

  useEffect(() => {
    setParentNodes(makeNewParentNodes());
  }, [makeNewParentNodes]);

  return (
    <ModalWrapper open={open} setOpen={setOpen} title="카테고리 선택" confirm={confirm}>
      <S.StyledCategoryModal>
        <TextInputForm
          name="category"
          label="카테고리 검색"
          fullWidth
          value={keyword}
          onChange={setKeyword}
          className={classes.input}
          onClear={clearKeyword}
        />
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<IndeterminateCheckBoxRoundedIcon />}
          defaultExpandIcon={<AddCircleOutlineRoundedIcon />}
          defaultEndIcon="-"
          expanded={parentNodes}
          selected={selected?.code ?? ''}
        >
          {categories?.map((node) => recursiveTreeItem(node))}
        </TreeView>
      </S.StyledCategoryModal>
    </ModalWrapper>
  );
});
export default CategoryModal;

CategoryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['all', 'auto-fp', 'manager'])
};

function getNamePlusCodeString(node) {
  return node ? `${node.kor_name} (${node.code})` : '';
}
