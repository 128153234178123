import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import { Button, Grid } from '@material-ui/core';
import CategoryModal from '@F/modal/CategoryModal';
import { useDispatch, useSelector } from 'react-redux';
import PinBlue from '@I/map/pin-blue.png';
import PinRed from '@I/map/pin-red.png';
import PinGreen from '@I/map/pin-green.png';
import DialogModal from '@F/DialogModal';
import { toastErrorMessage } from '@FUNC/toast';
import { useInput } from '@HOOK/';
import ComplexInput from '@F/input/ComplexInput';
import { actions } from '@/redux/fingerprint/manualMapping/state';
import * as S from './styles';

function InnerDetail({ detail, selectedPlace }) {
  const classes = S.useStyles();

  const [name, setName] = useState('');
  const NameInput = (
    <TextInputForm name="NameInput" label="장소명" onChange={(e) => setName(e.target.value)} value={name} />
  );

  const [branchName, setBranchName] = useState('');
  const BranchNameInput = (
    <TextInputForm
      name="BranchNameInput"
      label="지점명"
      onChange={(e) => setBranchName(e.target.value)}
      value={branchName}
    />
  );

  const [category, setCategory] = useState({
    korName: '',
    code: ''
  });
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
  const CategoryInput = (
    <TextInputForm
      name="CategoryInput"
      label="카테고리"
      value={selectedPlace?.category ? selectedPlace.category.join(',') : '카테고리'}
      disabled
    />
  );
  const CategoryCodeInput = (
    <TextInputForm
      name="CategoryCodeInput"
      label="카테고리 코드"
      onClick={() => setIsCategoryModalVisible(!isCategoryModalVisible)}
      value={`${category.code}`}
    />
  );

  const [complex, setComplex] = useState(null);
  const ComplexIdInput = <ComplexInput onChange={setComplex} />;

  const [section, setSection] = useInput('0');
  const SectionSelect = (
    <SelectForm name="sectionSelect" label="Section" options={['0', '1']} value={section} onSelect={setSection} />
  );

  const [floor, setFloor] = useInput('');
  const FloorInput = <TextInputForm name="FloorInput" label="층" type="number" value={floor} onChange={setFloor} />;

  useEffect(() => {
    setName(selectedPlace?.name || '');
    setCategory((prevState) => ({
      ...prevState,
      korName: selectedPlace?.category?.join(',') ?? null
    }));
  }, [selectedPlace]);

  const dispatch = useDispatch();

  const charge = useSelector((state) => state.manualMapping.charge);
  const updateFp = (state) => {
    dispatch(
      actions.patchFingerprint(detail, {
        name: name || null,
        branchName: branchName || null,
        categoryCode: category.code || null,
        cid: complex ? complex.id : null,
        section,
        floor: Number(floor) || null,
        state,
        lat: selectedPlace?.y || null,
        lng: selectedPlace?.x || null,
        nid: selectedPlace?.id.slice(1) || null,
        addr: selectedPlace?.address || null,
        addrRoad: selectedPlace?.roadAddress || null,
        phone: selectedPlace?.tel || null,
        charge
      })
    );
  };
  const invalidateFp = (state) => {
    dispatch(actions.patchFingerprintValidation(detail, state));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [state, setState] = useState(null);
  function getModalConfig(willBeChangedstate) {
    const defaultConfig = {
      description: '한번 선택하면 되돌릴 수 없습니다.',
      callback: state === 1 || state === 4 ? () => updateFp(willBeChangedstate) : () => invalidateFp(willBeChangedstate)
    };
    switch (state) {
      case 2:
        return {
          ...defaultConfig,
          title: '매장이 없습니까?'
        };
      case 3:
        return {
          ...defaultConfig,
          title: 'SSID가 판단 불가능합니까?'
        };
      case 5:
        return {
          ...defaultConfig,
          title: '매장이 판단 불가능합니까?'
        };
      default:
        return {
          ...defaultConfig,
          title: '위의 정보가 확실합니까?'
        };
    }
  }

  const CompleteButton = (
    <Button
      className={classes.completeButton}
      variant="contained"
      color="primary"
      onClick={() => {
        setState(1);
        name.length > 0 ? setIsModalOpen(true) : toastErrorMessage('매장을 선택해주세요!');
      }}
    >
      확인
    </Button>
  );
  const SsidUnjudgeableButton = (
    <Button
      className={classes.completeButton}
      variant="outlined"
      color="secondary"
      onClick={() => {
        setState(3);
        setIsModalOpen(true);
      }}
    >
      SSID 판단불가
    </Button>
  );
  const StoreUnjudgeableButton = (
    <Button
      className={classes.completeButton}
      variant="outlined"
      color="secondary"
      onClick={() => {
        setState(5);
        setIsModalOpen(true);
      }}
    >
      매장 판단불가
    </Button>
  );
  const StoreDoesNotExistButton = (
    <Button
      className={classes.completeButton}
      variant="outlined"
      color="secondary"
      onClick={() => {
        setState(2);
        setIsModalOpen(true);
      }}
    >
      매장 없음
    </Button>
  );
  const UnnecessaryPlaceButton = (
    <Button
      className={classes.completeButton}
      variant="outlined"
      onClick={() => {
        setState(4);
        name.length > 0 ? setIsModalOpen(true) : toastErrorMessage('매장을 선택해주세요!');
      }}
    >
      불필요 장소
    </Button>
  );

  return (
    <S.StyledComplexInnerDetail>
      <DialogModal
        confirmButtonDidClicked={getModalConfig(state).callback}
        open={isModalOpen}
        setOpen={setIsModalOpen}
        title={getModalConfig(state).title}
        description={getModalConfig(state).description}
      />
      <h3>매장 정보</h3>
      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {NameInput}
        </Grid>
        <Grid item xs>
          {BranchNameInput}
        </Grid>
      </S.Row>
      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {CategoryInput}
        </Grid>
        <Grid item xs>
          {CategoryCodeInput}
          <CategoryModal
            open={isCategoryModalVisible}
            setOpen={setIsCategoryModalVisible}
            setValue={setCategory}
            mode="auto-fp"
          />
        </Grid>
      </S.Row>
      <S.Row container className={classes.margin} spacing={1}>
        <Grid item xs>
          {ComplexIdInput}
        </Grid>
        <Grid item xs>
          {SectionSelect}
        </Grid>
        <Grid item xs>
          {FloorInput}
        </Grid>
      </S.Row>

      <S.ButtonRow>
        {CompleteButton}
        {SsidUnjudgeableButton}
        {StoreUnjudgeableButton}
        {StoreDoesNotExistButton}
        {UnnecessaryPlaceButton}
      </S.ButtonRow>
      <S.InfoRow>
        <div>
          <img src={PinGreen} width={13 * 2.5} height={17 * 2.5} alt="PinGreen" />
          추정 위치
        </div>
        <div>
          <img src={PinBlue} width={13 * 2.5} height={17 * 2.5} alt="PinBlue" />
          검색 매장
        </div>
        <div>
          <img src={PinRed} width={13 * 2.5} height={17 * 2.5} alt="PinRed" />
          선택 매장
        </div>
      </S.InfoRow>
    </S.StyledComplexInnerDetail>
  );
}
export default InnerDetail;

InnerDetail.propTypes = {
  detail: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedPlace: PropTypes.objectOf(PropTypes.any)
};

InnerDetail.defaultProps = {
  selectedPlace: null
};
