import { all, call, put, takeLeading, select } from 'redux-saga/effects';
import { toastErrorMessage, toastSuccessMessage } from '@FUNC/toast';
import { downloadCsv } from '@FUNC/download';
import { actions, types } from './state';
import { fetchFingerprints, fetchFingerprint, patchImageValidation, fetchFingerprintsCsv } from './api';

export function* fetchData() {
  const { page, perPage, params } = yield select((state) => ({
    page: state.fpManager.page,
    perPage: state.fpManager.perPage,
    params: state.fpManager.params
  }));

  yield put(actions.setLoading(true));
  try {
    if (params.fpid) {
      const data = yield call(fetchFingerprint, params.fpid);
      yield put(actions.setNewFingerprints(data ? [data] : []));
      yield put(actions.setValue('count', data ? 1 : 0));
    } else {
      const data = yield call(fetchFingerprints, page, perPage, params);
      yield put(actions.setNewFingerprints(data.items));

      yield put(actions.setValue('count', data.count));
    }
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* fetchCsvData() {
  const { page, perPage, params } = yield select((state) => ({
    page: state.fpManager.page,
    perPage: state.fpManager.perPage,
    params: state.fpManager.params
  }));

  yield put(actions.setLoading(true));
  try {
    const { data, fileName } = yield call(fetchFingerprintsCsv, page, perPage, params);

    downloadCsv(data, fileName);
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* setImageValidation(action) {
  yield put(actions.setLoading(true));
  try {
    yield call(patchImageValidation, action.fingerprint.fp_fpid, action.status);
    yield put(actions.setNewFingerprint({ ...action.fingerprint, fp_image_validation: action.status }));

    toastSuccessMessage();
  } catch (e) {
    toastErrorMessage(e);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export default function* sagaReducer() {
  yield all([
    takeLeading(types.FETCH_FINGERPRINTS, fetchData),
    takeLeading(types.FETCH_FINGERPRINTS_CSV, fetchCsvData),
    takeLeading(types.SET_IMAGE_VALIDATION, setImageValidation)
  ]);
}
