import { useCallback, useEffect, useMemo, useState } from 'react';
import { createHeadCells } from '@F/table/Table/functions';
import PaginationRow from '@F/table/PaginationRow';
import EnhancedTable from '@F/table/Table';
import { Button } from '@material-ui/core';
import ModalWrapper from '@F/modal/ModalWrapper';
import SelectForm from '@F/materialUI/SelectForm';
import { shallowEqual, useDispatch } from 'react-redux';
import StatusLabel from '@F/StatusLabel';

import { theme } from '@S/index';

import Red from '@material-ui/core/colors/red';

import Blue from '@material-ui/core/colors/blue';
import { Reward, RewardTableRow } from '@TS/cashplace/reward';
import { Column } from '@TS/columns';
import { useAppSelector } from '@HOOK/useSelector';
import { actions } from '@/redux/cashplace/reward/state';
import { reasonOptions } from './options';
import * as S from './styles';

const columns: Column[] = [
  { name: 'id', label: 'REWARD ID', isImportant: true },
  { name: 'kinds', label: '종류' },
  { name: 'state', label: '상태' },
  { name: 'userId', label: 'USER ID' },
  { name: 'username', label: '유저명' },
  { name: 'requestedReward', label: '신청 금액' },
  { name: 'accountHolder', label: '예금주' },
  { name: 'bankName', label: '은행명' },
  { name: 'accountNumber', label: '계좌번호' },
  { name: 'phone', label: '전화번호' },
  { name: 'requestedDate', label: '신청일시' }
];

function TableSection(): JSX.Element {
  const { page, perPage, count, rewards } = useAppSelector(
    (state) => ({
      page: state.reward.page,
      perPage: state.reward.perPage,
      count: state.reward.count,
      rewards: state.reward.rewards
    }),
    shallowEqual
  );

  const [selected, setSelected] = useState<RewardTableRow[]>([]);
  const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
  const selectedRewardSum = useCallback(
    () => (selected.length > 0 ? selected.map((value) => value.requestedReward).reduce(reducer, 0) : 0),
    [selected]
  );

  const headCells = columns.map((column) => createHeadCells(column.name, column.label));
  const rows = useMemo(
    () =>
      rewards?.map(
        (item: Reward): RewardTableRow => ({
          id: item.reward_id,
          kinds: {
            value: item.kind,
            component: formatKindLabel(item.kind)
          },
          state: {
            value: item.state,
            component: formatStateLabel(item.state)
          },
          userId: item.user.user_id,
          username: item.user.username,
          requestedReward: item.amount,
          accountHolder: item.name,
          bankName: item.bank,
          accountNumber: item.account,
          phone: item.phone,
          requestedDate: item.created_at_str
        })
      ),
    [rewards]
  );

  const dispatch = useDispatch();
  const setPage = useCallback(
    (newPage) => {
      if (page === newPage) dispatch(actions.fetchRewards());
      else dispatch(actions.setPage(newPage));
    },
    [dispatch, page]
  );
  useEffect(() => {
    dispatch(actions.fetchRewards());
  }, [dispatch, page]);

  const [expectedResult, setExpectedResult] = useState('지급완료');
  const [reason, setReason] = useState<string>('계좌 번호를 찾을 수 없음');
  const giveRewards = useCallback(() => {
    dispatch(
      actions.updateRewards({
        status: expectedResult === '지급완료' ? 1 : -1,
        rewardIds: selected.map((item) => item.id),
        reason: expectedResult === '지급완료' ? 0 : reasonOptions[reason]
      })
    );
    setIsPayModalOpened(false);
  }, [dispatch, selected, expectedResult, reason]);

  const [isPayModalOpened, setIsPayModalOpened] = useState(false);
  const PayButton = (
    <Button
      key="PayButton"
      style={{ marginRight: '10px' }}
      variant="outlined"
      color="primary"
      onClick={() => setIsPayModalOpened((prevState) => !prevState)}
    >
      지급하기
    </Button>
  );
  return (
    <S.StyledTableSection>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} />
      <S.ActionBar>
        {PayButton}
        <h4>
          예상 지급액:
          {selectedRewardSum().toLocaleString()}
        </h4>
      </S.ActionBar>
      <EnhancedTable
        rows={rows || []}
        headCells={headCells}
        selected={selected}
        setSelected={setSelected}
        initialOrder="desc"
        initialOrderBy="carbs"
        isCheckable
      />
      <ModalWrapper open={isPayModalOpened} setOpen={setIsPayModalOpened} confirm={giveRewards} confirmText="지급하기">
        <S.PaymentsContainer>
          <h3>리워드 처리</h3>
          <h5>
            처리할 리워드 신청 목록 (총
            {selected.length}
            개)
          </h5>
          <h5>
            예상 지급액 (총 {selectedRewardSum().toLocaleString()}
            원)
          </h5>
          <S.List>
            {selected.map((item: RewardTableRow) => (
              <span key={item.id}>{`${item.id}, `}</span>
            ))}
          </S.List>
          <S.Row>
            <SelectForm
              label="처리결과"
              options={['지급완료', '미지급']}
              value={expectedResult}
              onSelect={(e): void =>
                setExpectedResult((prevState) => {
                  if (typeof e.target.value === 'string') return e.target.value;
                  return prevState;
                })
              }
              name="ExpectedResultSelect"
            />
          </S.Row>

          {/* NOTE: 추후 가연님이 미지급 사유를 추가 혹은 변경 요구사항이 있을 수 있음. */}
          {expectedResult === '미지급' && (
            <S.Row>
              <SelectForm
                label="미지급 사유"
                options={Object.keys(reasonOptions)}
                value={reason}
                onSelect={(e): void =>
                  setReason((prevState) => {
                    if (typeof e.target.value === 'string') return e.target.value;
                    return prevState;
                  })
                }
                name="ReasonSelect"
              />
            </S.Row>
          )}
        </S.PaymentsContainer>
      </ModalWrapper>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} reverse />
    </S.StyledTableSection>
  );
}
export default TableSection;

function formatStateLabel(result: number): React.ReactNode {
  switch (result) {
    case 0:
      return (
        <StatusLabel color={theme.palette.grey[800]} backgroundColor={theme.palette.grey[200]}>
          대기
        </StatusLabel>
      );
    case -1:
      return (
        <StatusLabel color={Red[800]} backgroundColor={Red[200]}>
          미지급
        </StatusLabel>
      );
    case 1:
      return (
        <StatusLabel color={Blue[800]} backgroundColor={Blue[200]}>
          지급완료
        </StatusLabel>
      );
    default:
      return (
        <StatusLabel color={theme.palette.grey[800]} backgroundColor={theme.palette.grey[200]}>
          오류
        </StatusLabel>
      );
  }
}

function formatKindLabel(kind: number): React.ReactNode {
  return (
    <StatusLabel color={theme.palette.grey[800]} backgroundColor={theme.palette.grey[200]}>
      {kind === 0 ? '현금' : '문화상품'}
    </StatusLabel>
  );
}
