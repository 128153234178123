/* eslint-disable max-classes-per-file */
export class ItemClass<T> {
  name: string;
  label: string;
  value: T;

  constructor(name: string, label: string, value: T) {
    this.name = name;
    this.label = label;
    this.value = value;
  }
}

export class TooltipItemClass<T> extends ItemClass<T> {
  description: string;
  constructor(name: string, label: string, value: T, description: string) {
    super(name, label, value);
    this.description = description;
  }
}

export class OrderItemClass<T> extends ItemClass<T> {
  defaultOrderType: OrderType;
  isInitialActive: boolean;

  constructor(name: string, label: string, value: T, defaultOrderType: OrderType, isInitialActive: boolean) {
    super(name, label, value);
    this.defaultOrderType = defaultOrderType;
    this.isInitialActive = isInitialActive;
  }
}
