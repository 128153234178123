import axios from 'axios';
import { axiosInstancesManager } from '.';

const axiosTokenManager = {
  setAccessToken: (access_token: string): void => {
    axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    axiosInstancesManager.resetAxiosInstances();
  },
  setRefreshToken: (refresh_token: string): void => {
    axios.defaults.headers.common.Authorization = `Bearer ${refresh_token}`;
    axiosInstancesManager.resetAxiosInstances();
  },
  deleteAccessToken: (): void => {
    axios.defaults.headers.common.Authorization = '';
    axiosInstancesManager.resetAxiosInstances();
  }
};

export default axiosTokenManager;
