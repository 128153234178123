import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '@material-ui/core';

import RecruitTable from './RecruitsTable';

export default function ManageRecruits(): JSX.Element {
  return (
    <StyledManageRecruit>
      <Link to="create" style={{ textDecoration: 'none' }}>
        <Button className="add-recruit-info" variant="contained" color="primary">
          포지션 등록
        </Button>
      </Link>
      <RecruitTable />
    </StyledManageRecruit>
  );
}

const StyledManageRecruit = styled.div`
  .add-recruit-info {
    margin-bottom: 20px;
  }
`;

/*
import { useLocation } from 'react-router-dom';
import ManageRecruitContainer from '@P/homepage/manageRecruits/ManageRecruits';

import MainTemplate from '@/Templates/MainTemplate/MainTemplate';

const VIEW_STATE_ENG_TO_KOR = {
  edit: '수정',
  form: '생성',
  preview: '미리보기'
} as any;

export default function ManageRecruits(): JSX.Element {
  const location = useLocation();
  const { pathname } = location;

  const currentViewState = pathname.slice(pathname.lastIndexOf('/') + 1);

  const subTitle = VIEW_STATE_ENG_TO_KOR[currentViewState];

  return (
    <MainTemplate title={`채용정보 관리${subTitle ? ` - ${subTitle}` : ''}`}>
      <ManageRecruitContainer />
    </MainTemplate>
  );
}

*/
