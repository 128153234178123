import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// RTK reducer & saga
import articlesSlice from '@P/homepage/manageArticles/redux/slice';
import articlesSaga from '@P/homepage/manageArticles/redux/saga';
import recruitsSlice from '@P/homepage/manageRecruits/redux/slice';
import recruitsSaga from '@P/homepage/manageRecruits/redux/saga';
import uncollectedComplexPlaceTodosSlice from '@P/poi/UnCollectedComplexPlaceTodosManager/redux/slice';
import uncollectedComplexPlaceTodosSaga from '@P/poi/UnCollectedComplexPlaceTodosManager/redux/saga';

// reducer
import { all } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import maxReducer from '@P/max/campaigns/redux/campaigns/slice';
import maxSaga from '@P/max/campaigns/redux/campaigns/saga';
import companiesReducer, { companiesPersistConfig } from '@P/max/campaigns/redux/companies/slice';
import companiesSaga from '@P/max/campaigns/redux/companies/saga';
import countReducer, { countPersistConfig } from '@/redux/count/state';
import storeManagerReducer from '@/redux/store/storeManager/state';
import storeCombineReducer from '@/redux/store/storeCombine/state';
import storeErrorReportReducer from '@/redux/store/storeErrorReport/state';
import complexManagerReducer from '@/redux/complex/state';
import fpManagerReducer from '@/redux/fingerprint/fpManager/state';
import manualMappingReducer from '@/redux/fingerprint/manualMapping/state';
import cookUserReducer from '@/redux/cook/cookUser/state';
import cookVersionReducer from '@/redux/cook/cookVersion/state';
import closingManagerReducer from '@/redux/closingManager/state';
import userManagerReducer from '@/redux/admin/userManager/state';
import whitelistReducer from '@/redux/admin/whitelist/state';
import reportReducer from '@/redux/cashplace/report/state';
import eventReducer from '@/redux/cashplace/event/state';
import contactReducer from '@/redux/cashplace/contact/state';
import versionReducer from '@/redux/cashplace/version/state';
import rewardReducer from '@/redux/cashplace/reward/state';
import userReducer from '@/redux/cashplace/user/state';
import inspectReducer from '@/redux/cashplace/inspect/state';

// saga
import countSaga from '@/redux/count/saga';
import storeManagerSaga from '@/redux/store/storeManager/saga';
import storeCombineSaga from '@/redux/store/storeCombine/saga';
import storeErrorReportSaga from '@/redux/store/storeErrorReport/saga';
import complexManagerSaga from '@/redux/complex/saga';
import fpManagerSaga from '@/redux/fingerprint/fpManager/saga';
import manualMappingSaga from '@/redux/fingerprint/manualMapping/saga';
import cookUserSaga from '@/redux/cook/cookUser/saga';
import cookVersionSaga from '@/redux/cook/cookVersion/saga';
import closingManagerSaga from '@/redux/closingManager/saga';
import userManagerSaga from '@/redux/admin/userManager/saga';
import whitelistSaga from '@/redux/admin/whitelist/saga';
import reportSaga from '@/redux/cashplace/report/saga';
import eventSaga from '@/redux/cashplace/event/saga';
import contactSaga from '@/redux/cashplace/contact/saga';
import versionSaga from '@/redux/cashplace/version/saga';
import rewardSaga from '@/redux/cashplace/reward/saga';
import userSaga from '@/redux/cashplace/user/saga';
import inspectSaga from '@/redux/cashplace/inspect/saga';

export const reducer = combineReducers({
  count: persistReducer(countPersistConfig, countReducer),

  storeManager: storeManagerReducer,
  storeCombine: storeCombineReducer,
  storeErrorReport: storeErrorReportReducer,

  complexManager: complexManagerReducer,

  fpManager: fpManagerReducer,
  manualMapping: manualMappingReducer,
  cookUser: cookUserReducer,
  cookVersion: cookVersionReducer,
  uncollectedComplexPlaceTodos: uncollectedComplexPlaceTodosSlice.reducer,

  closingManager: closingManagerReducer,

  userManager: userManagerReducer,
  whitelist: whitelistReducer,

  report: reportReducer,
  version: versionReducer,
  reward: rewardReducer,
  event: eventReducer,
  user: userReducer,
  contact: contactReducer,
  inspect: inspectReducer,
  articles: articlesSlice.reducer,
  recruits: recruitsSlice.reducer,

  max: maxReducer,
  companies: persistReducer(companiesPersistConfig, companiesReducer)
});

/**
 * NOTE: rootPersistConfig는 'reducer'를 최소 묶음으로 persist 적용중
 *       reducer 마다 'state'를 최소 묶음으로 nested Persists 를 적용할 수도 있음.
 * */
const rootPersistConfig = {
  key: 'internal-manager/root',
  storage,
  whitelist: []
};
const persistedReducer = persistReducer(rootPersistConfig, reducer);

/**
 * saga 적용
 */
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

function* rootSaga() {
  yield all([
    countSaga(),
    storeCombineSaga(),
    storeErrorReportSaga(),
    fpManagerSaga(),
    cookUserSaga(),
    cookVersionSaga(),
    closingManagerSaga(),
    userManagerSaga(),
    manualMappingSaga(),
    complexManagerSaga(),
    whitelistSaga(),
    versionSaga(),
    rewardSaga(),
    eventSaga(),
    reportSaga(),
    userSaga(),
    contactSaga(),
    inspectSaga(),
    storeManagerSaga(),
    articlesSaga(),
    recruitsSaga(),
    uncollectedComplexPlaceTodosSaga(),
    maxSaga(),
    companiesSaga()
  ]);
}
sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);
export default { store, persistor };
