import { storesApi } from '@API/manager';

export function fetchReports(page, perPage, params) {
  return storesApi.getStoreReports({ page, perPage, ...params }).then((response) => response.data);
}

export function fetchReport(reportId) {
  return storesApi.getStoreReport(reportId).then((response) => response.data);
}

export function putReport(params) {
  return storesApi.putReport(params).then((response) => response.data);
}

export function discardStore(pid) {
  return storesApi.patchStoreDiscard({ pid }).then((response) => response.data);
}

export function deleteStore(pid) {
  return storesApi.deleteStore({ pid }).then((response) => response.data);
}
