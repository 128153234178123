import { css, FlattenSimpleInterpolation } from 'styled-components';
import { bluescale50 } from 'loplat-ui';

export const gridSpread = css`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 6px;
  align-items: center;
`;
export const ulMargin = css`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const listMargin = (margin = 5): FlattenSimpleInterpolation => css`
  &:not(:first-of-type) {
    margin-top: ${margin}px;
  }
`;

export const blueBackground = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  background: ${bluescale50};
  padding: 20px;
  border-radius: 6px;
`;
