import styled from 'styled-components';
import { Body } from 'loplat-ui';
// NOTE: 아래의 svg에서 target, user은 loplat ui에 있지만,
// svg의 패딩? box 가 기존과 달라서 기존의 다른 svg와 같이 있을 때 위화감이 느껴져서 UI의 아이콘으로 적용안함 :(
import { ReactComponent as BrandChip } from '@ICON/campaign/brand.svg';
import { ReactComponent as CategoryChip } from '@ICON/campaign/category.svg';
import { ReactComponent as StoreChip } from '@ICON/campaign/store.svg';
import { ReactComponent as TargetChip } from '@ICON/campaign/target.svg';
import { ReactComponent as UserChip } from '@ICON/campaign/user.svg';
import { CHIP_DETAIL } from '@P/max/campaigns/utils/data';
import { gridSpread } from '@P/max/campaigns/utils/styles';
import type { SegmentOptionType } from '../utils/type';
import LevelTwoModule from './LevelTwoModule';

type Props = {
  type: 'area' | 'user' | keyof SegmentOptionType['selection'];
} & Partial<Omit<SegmentOptionType, 'selection'>>;
const SegmentModule = ({ type, lv1, lv2 }: Props): JSX.Element => (
  <ChipWrapper>
    <Chip>
      {type === 'area' && <TargetChip />}
      {type === 'user' && <UserChip />}
      {type === 'store' && <StoreChip />}
      {type === 'brand' && <BrandChip />}
      {type === 'category' && <CategoryChip />}
      {type === 'complex' && <StoreChip />}
      <Body tag="span" weight="extraBold" ml={4}>
        {CHIP_DETAIL[type].label}
      </Body>
    </Chip>

    {lv1 && (
      <div className="region">
        <Body tag="span" color={CHIP_DETAIL[type].color} className="desc" weight="bold">
          {lv1}
        </Body>
        {lv2 && <LevelTwoModule lv2={lv2} />}
      </div>
    )}
  </ChipWrapper>
);

export default SegmentModule;

const ChipWrapper = styled.div`
  ${gridSpread};
  width: 100%;
  justify-content: flex-start;

  div.region {
    text-align: start;
    word-break: keep-all;
    line-height: 1.6rem;
    width: 100%;
    grid-column: 4/-1;
    > * + * {
      margin-top: 4px;
    }
  }

  & + & {
    margin-top: 8px;
  }
`;

const Chip = styled.div`
  width: 100%;
  grid-column: 1/4;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    text-align: left;
  }

  svg {
    margin-right: 8px;
  }
`;
