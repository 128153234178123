import type { AxiosResponse } from 'axios';
import type { GetCampaignsResponse, GetCampaignsParameters } from '@TS/max/campaigns/api';
import type { FetchCampaignStatus, FilterStatus } from '@TS/max/campaigns/status';
import type { State } from '@P/max/campaigns/redux/campaigns/slice';
import type { Campaign } from '@TS/max/campaigns/campaign';
import { camelToSnake } from '@FUNC/word';
import { skipEmptyStringValueFromObj } from '@FUNC/filterObject';
import { axiosInstancesManager } from '@API/axios';

const CAMPAIGN_STATUS: FetchCampaignStatus[] = ['complete', 'test', 'active', 'inactive'];
const stringifyStatus = (rule: (key: FetchCampaignStatus) => boolean): string => CAMPAIGN_STATUS.filter(rule).join(',');

const defineStatus = (status: FilterStatus): string => {
  if (status === 'all') return CAMPAIGN_STATUS.join(',');
  if (status === 'proceeding') return stringifyStatus((item) => item === 'test' || item === 'active');
  return stringifyStatus((key) => status === key);
};

class CampaignApi {
  getCampaigns(parameter: GetCampaignsParameters): Promise<AxiosResponse<GetCampaignsResponse>> {
    const notEmptyParams = skipEmptyStringValueFromObj<State['params']>(parameter);
    const stringifiedStatus = defineStatus(parameter.status);
    const params = {
      ...notEmptyParams,
      status: stringifiedStatus,
      orderby: notEmptyParams.orderby ? camelToSnake(notEmptyParams.orderby) : null
    };

    return axiosInstancesManager.max.get('/campaign', { params });
  }

  getCampaign({ cid }: { cid: number }): Promise<AxiosResponse<Campaign>> {
    return axiosInstancesManager.max.get(`/campaign/${cid}`);
  }

  getTwoCampaign({ cidA, cidB }: Record<'cidA' | 'cidB', number>): Promise<AxiosResponse<Campaign>[]> {
    return Promise.all([cidA, cidB].map((cid) => axiosInstancesManager.max.get(`/campaign/${cid}`)));
  }
}

export default new CampaignApi();
