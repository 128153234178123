import { useCallback, useMemo } from 'react';
import EnhancedTable from '@F/table/Table';
import ComplexInnerContainer from '@P/complex/complexManager/tableSection/detail/Detail';
import { useDispatch, useSelector } from 'react-redux';
import { createHeadCells } from '@F/table/Table/functions';
import PaginationRow from '@F/table/PaginationRow';
import { actions } from '@/redux/complex/state';
import * as S from './styles';

function TableSection() {
  const { complexData, page, perPage, count } = useSelector((state) => state.complexManager);
  const dispatch = useDispatch();

  // pagination
  const setPage = useCallback(
    (newPage) => {
      dispatch(actions.setValue('page', newPage));
      dispatch(actions.fetchComplexes());
    },
    [dispatch]
  );

  const headCells = columns.map((column) => createHeadCells(column.name, column.label));
  const rows = useMemo(
    () =>
      complexData?.map((item) => ({
        id: item.cid,
        storeName: item.name,
        branchName: item.branch_name,
        category: item.category_all_kor,
        brands: formatBrands(item.brands),
        parentComplex: formatParentComplex(item.parent),
        service: item.service ? 'O' : 'X',
        createDate: item.created_at,
        collector: item.collector
      })),
    [complexData]
  );

  // expand
  const openedComplexIds = useSelector((state) => state.complexManager.openedComplexIds);
  const setOpenedComplexIds = useCallback(
    (newOpenedComplexIds) => {
      dispatch(actions.setValue('openedComplexIds', newOpenedComplexIds));
    },
    [dispatch]
  );

  return (
    <S.StyledComplexTable>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} />
      <EnhancedTable
        rows={rows || []}
        headCells={headCells}
        initialOrder="desc"
        initialOrderBy="carbs"
        isCheckable={false}
        isCollapsible
        opened={openedComplexIds}
        setOpened={setOpenedComplexIds}
      >
        <ComplexInnerContainer data={complexData} />
      </EnhancedTable>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} reverse />
    </S.StyledComplexTable>
  );
}
export default TableSection;

const columns = [
  { name: 'id', label: 'Complex ID (CID)', isImportant: true },
  { name: 'storeName', label: '장소명' },
  { name: 'branchName', label: '지점명' },
  { name: 'category', label: '카테고리' },
  { name: 'brands', label: '브랜드' },
  { name: 'parentComplex', label: '부모 복합몰' },
  { name: 'service', label: '로플랫 X/i 노출여부' },
  { name: 'createdDate', label: '생성 시각' },
  { name: 'collector', label: '수집자' }
];

function formatBrands(brands) {
  return brands !== null && brands.length > 0 ? brands.map((brand) => `${brand.name}(${brand.bid})`).join(', ') : null;
}

function formatParentComplex(parentComplex) {
  return parentComplex === null ? '' : `${parentComplex.cid} | ${parentComplex.name}`;
}
