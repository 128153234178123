import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import {
  TextField,
  Checkbox,
  Button,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  FormControlLabel
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { PlaceTodoParams } from '@TS/cook/api/placetodos';
import DatePickers from '@F/materialUI/datePicker/DatePickers/index';
import { toastErrorMessage } from '@FUNC/toast';
import TextInputForm from '@F/materialUI/TextInputForm';
import ComplexInput from '@F/input/ComplexInput';
import { valuesStore } from '@/mobX/values';
import { actions } from './redux/slice';
import { selectParams } from './redux/selector';

type FetchComplexPlaceTodoForm = {
  targetSiteId?: string;
  cid?: string;
  floor?: string;
  charge?: string;
  startDate?: string;
  endDate?: string;
};

const FIELDS: {
  label?: string;
  name: Extract<keyof PlaceTodoParams, 'targetSiteId' | 'cid' | 'floor' | 'charge'>;
  type?: HTMLInputElement['type'];
  isNumber?: boolean;
}[] = [
  {
    type: 'text',
    label: 'Site ID',
    name: 'targetSiteId',
    isNumber: true
  },
  {
    type: 'complex',
    name: 'cid'
  },
  {
    type: 'text',
    label: 'floor',
    name: 'floor',
    isNumber: true
  },
  {
    type: 'autoComplete',
    label: '수집자',
    name: 'charge'
  }
];

export default function SearchFilter(): JSX.Element {
  const dispatch = useDispatch();
  const params = useSelector(selectParams);

  const [fields, setFields] = useState<Omit<FetchComplexPlaceTodoForm, 'startDate' | 'endDate'>>({
    targetSiteId: '',
    floor: '',
    charge: ''
  });
  const [date, setDate] = useState<Pick<FetchComplexPlaceTodoForm, 'startDate' | 'endDate'>>({
    startDate: new Date().toISOString().slice(0, 10),
    endDate: new Date().toISOString().slice(0, 10)
  });
  const [hasDateParams, setHasDateParams] = useState(true);

  const handleChangeFields = (fieldName: string, value: string, isNumber?: boolean) => {
    if (isNumber) {
      const formattedValue = value === '' || value === '-' ? 0 : Number(value);

      if (Number.isNaN(formattedValue)) {
        toastErrorMessage(`올바르지 않은 형식입니다. ${fieldName}은 숫자로 입력해주세요.`);
        return;
      }
    }

    setFields((prev) => ({ ...prev, [fieldName]: value }));
  };
  const handleChangeDate = useCallback(
    ([startDate, endDate]: string[]) =>
      setDate({
        startDate,
        endDate
      }),
    []
  );
  const handleChangeEmptyDateCheckbox = () => setHasDateParams((prev) => !prev);
  const handleChangeHasDuplicateStoresCheckbox = () =>
    dispatch(actions.setParams({ ...params, chargeResult: hasDuplicateStores ? [1, 2] : [1, 2, 3] }));
  const handleChangePerPageSelect = (
    e: any // TODO : mui 이벤트로 바꾸기 (ts 지원이 되는지 의문)
  ) => dispatch(actions.setParams({ ...params, perPage: Number(e.target.value) }));

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    // 형태가 계속 변하는 값이어서 any로 선언했습니다.
    // 좋은 방법이 있다면 알려주세요!
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newParams: any = {
      ...params,
      ...fields,
      ...date,
      cid: complex ? complex.id : null,
      page: 1
    };

    // 1. value가 ''인 key는 삭제
    Object.entries(newParams).forEach((entry) => {
      const [key, value] = entry as [keyof PlaceTodoParams, string];

      if (value === '') {
        delete newParams[key];
      }
    });

    // 2. 숫자인 경우 number로 변환
    FIELDS.filter(({ isNumber }) => isNumber).forEach(({ name }) => {
      if (fields[name] !== '') {
        newParams[name] = Number(fields[name]);
      }
    });

    // 3. date가 disabled일 경우 삭제
    if (!hasDateParams) {
      delete newParams.startDate;
      delete newParams.endDate;
    }

    dispatch(actions.fetchUnCollectedComplexPlaceTodos(newParams));
  };

  useEffect(() => {
    dispatch(actions.fetchUnCollectedComplexPlaceTodos());
  }, [dispatch]);

  // charge(수집자)
  const chargeOptions = valuesStore.collectors.map((user) => user.username);
  const hasDuplicateStores = params.chargeResult.includes(3);
  const perPageOptions = ['20', '30', '50', '100'];

  const [complex, setComplex] = useState<{ name: string; id: string } | null>(null);

  return (
    <form onSubmit={handleSubmit}>
      <StyledFields>
        {FIELDS.map((field) =>
          field.type === 'autoComplete' ? (
            <StyledAutoComplete key={field.name}>
              <Autocomplete
                fullWidth
                key={field.name}
                options={chargeOptions}
                noOptionsText="수집자를 입력해주세요"
                value={fields[field.name]}
                onInputChange={(_, value) => handleChangeFields('charge', value, field.isNumber)}
                renderInput={(params) => <TextField {...params} label={field.label} variant="outlined" />}
              />
            </StyledAutoComplete>
          ) : field.type === 'complex' ? (
            <ComplexInput onChange={setComplex} />
          ) : field.type === 'text' ? (
            <StyledTextFieldWrapper key={field.name}>
              <TextInputForm
                name={field.name}
                label={field.label}
                type={field.type}
                value={fields[field.name]}
                onChange={(e) => handleChangeFields(field.name, e.target.value, field.isNumber)}
                onClear={() => handleChangeFields(field.name, '', field.isNumber)}
              />
            </StyledTextFieldWrapper>
          ) : null
        )}
        <StyledTextFieldWrapper>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>개수</InputLabel>
            <Select value={String(params.perPage)} onChange={handleChangePerPageSelect} name="perPage" label="개수">
              {perPageOptions.map((perPageOption) => (
                <MenuItem value={perPageOption} key={perPageOption}>
                  {perPageOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </StyledTextFieldWrapper>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={hasDuplicateStores}
                onChange={handleChangeHasDuplicateStoresCheckbox}
              />
            }
            label="중복매장 포함"
          />
        </div>
      </StyledFields>
      <DatePickers
        initialStartDate={params.startDate}
        initialEndDate={params.endDate}
        onEmitDate={handleChangeDate}
        disabled={!hasDateParams}
      />
      <StyledToolbar>
        <FormControlLabel
          control={<Checkbox color="primary" checked={hasDateParams} onChange={handleChangeEmptyDateCheckbox} />}
          label="기간 검색"
        />
        <Button type="submit" variant="contained" color="primary">
          불러오기
        </Button>
      </StyledToolbar>
    </form>
  );
}

const StyledFields = styled.div`
  display: flex;

  > div {
    margin-right: 10px;
  }
`;

const StyledToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: -10px;
`;

const StyledTextFieldWrapper = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

const StyledAutoComplete = styled.div`
  width: 200px;
`;
