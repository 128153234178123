import { createReducer, createSetValueAction, setValueReducer } from '@/redux/common/helper-functions';

/** prefix */
const PREFIX = 'COMPLEX_MANAGER';

/** inital state */
const INITIAL_STATE = {
  complexData: [],
  count: 0,
  openedComplexIds: [],
  page: 1,
  perPage: 15,
  params: {},
  isLoading: false
};

/** type */
export const types = {
  RESET: `${PREFIX}/RESET`,
  SET_VALUE: `${PREFIX}/SET_VALUE`,
  SET_LOADING: `${PREFIX}/SET_LOADING`,
  SET_PAGE: `${PREFIX}/SET_PAGE`,
  SET_NEW_COMPLEXES: `${PREFIX}/SET_NEW_COMPLEXES`,
  SET_NEW_COMPLEX: `${PREFIX}/SET_NEW_COMPLEX`,
  FETCH_COMPLEXES: `${PREFIX}/FETCH_COMPLEXES`,
  FETCH_COMPLEX: `${PREFIX}/FETCH_COMPLEX`,
  UPDATE_COMPLEX: `${PREFIX}/UPDATE_COMPLEX`
};

/** action */
export const actions = {
  reset: () => ({ type: types.RESET }),
  setValue: createSetValueAction(types.SET_VALUE),
  setLoading: (isLoading) => ({ type: types.SET_LOADING, isLoading }),
  setPage: (page) => ({ type: types.SET_PAGE, page }),
  setNewComplexes: (complexData) => ({ type: types.SET_NEW_COMPLEXES, complexData }),
  setNewComplex: (complexData) => ({ type: types.SET_NEW_COMPLEX, complexData }),
  fetchComplexes: () => ({ type: types.FETCH_COMPLEXES }),
  fetchComplex: (cid) => ({ type: types.FETCH_COMPLEX, cid }),
  updateComplex: (complex, params) => ({ type: types.UPDATE_COMPLEX, complex, params })
};

/** reducer */
const reducer = createReducer(INITIAL_STATE, {
  [types.RESET]: (draft) => {
    draft.complexData = [];
    draft.openedComplexIds = [];
  },
  [types.SET_VALUE]: setValueReducer,
  [types.SET_NEW_COMPLEXES]: (draft, action) => {
    draft.complexData = action.complexData;
    draft.openedComplexIds = [];
  },
  [types.SET_NEW_COMPLEX]: (draft, action) => {
    const index = draft.complexData.findIndex((complex) => complex.cid === action.complexData.cid);
    if (index >= 0) {
      draft.complexData[index] = action.complexData;
    }
  },
  [types.SET_LOADING]: (draft, action) => {
    draft.isLoading = action.isLoading;
  },
  [types.SET_PAGE]: (draft, action) => {
    draft.page = action.page;
  }
});
export default reducer;
