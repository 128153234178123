import styled from 'styled-components';

export const SearchForm = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

export const Alert = styled.small`
  font-size: 12px;
  color: ${(props) => props.theme.palette.text.hint};
`;
