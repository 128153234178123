import styled, { css } from 'styled-components';

export const StyledComplexTable = styled.div`
  height: 100%;
`;

export const TablePaginationRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem 0;
`;

export const IdText = styled.span`
  ${(props) =>
    props.emphasize &&
    css`
      color: ${props.theme.palette.text.primary};
    `};
`;
