import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: 10
  },
  button: {
    backgroundColor: theme.palette.primary.dark,
    color: 'white'
  }
}));

export const StyledLoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  border-radius: 20px;
  background-color: white;
`;

export const Title = styled.h1`
  font-size: 40px;
  font-weight: 900;
  padding: 1.5vh 0;
  margin-bottom: 10px;

  @media (min-width: 800px) {
    font-size: 50px;
  }

  color: ${(props) => props.theme.palette.primary.deepDark};
`;
