import dayjs from 'dayjs';

import DatePickers from '@F/materialUI/datePicker/DatePickers/refactor';
import { useFormikContext } from 'formik';
import { PrimitiveLogFilterValues } from '../data';

export default function Dates(): JSX.Element {
  const { values, setValues } = useFormikContext<PrimitiveLogFilterValues>();

  const handleChange = (e: any) => {
    if (!values.dates) return;

    const { name, value } = e.target;
    const newDates = [...values.dates] as typeof values.dates;
    newDates[name] = value;

    const [startAt, endAt] = newDates;

    if (startAt > endAt) {
      // 시작일 변경시
      if (name === '0') {
        // eslint-disable-next-line prefer-destructuring
        newDates[1] = newDates[0];
        // 종료일 변경시
      } else {
        // eslint-disable-next-line prefer-destructuring
        newDates[0] = newDates[1];
      }
    }

    setValues((prev) => ({
      ...prev,
      dates: newDates
    }));
  };

  return (
    <DatePickers
      error={false}
      onChange={handleChange}
      disableds={[false, false]}
      values={values.dates}
      labels={['부터', '까지']}
      names={['0', '1']}
      helperTexts={['0', '1']}
      maxs={[dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]}
      requireds={[true, true]}
    />
  );
}
