import Filter from './filter/Filter';
import TableSection from './tableSection/TableSection';
import FilterWrapper from './filter/Wrapper';
import * as S from './styles';

function ClosingManagerContainer() {
  return (
    <S.StyledClosingManagerContainer>
      <FilterWrapper>
        <Filter />
      </FilterWrapper>
      <TableSection />
    </S.StyledClosingManagerContainer>
  );
}
export default ClosingManagerContainer;

ClosingManagerContainer.propTypes = {};
