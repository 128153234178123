import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { AreaChart } from '@toast-ui/chart';
import * as S from './styles';

const options = {
  chart: { width: '100%', height: 400 },
  legend: { visible: false },
  series: { showDot: true, spline: true }
};

function PlaceChart({ dates, counts }) {
  const [chart, setChart] = useState(null);
  const data = useMemo(() => ({ categories: dates, series: [{ name: 'Places', data: counts }] }), [dates, counts]);

  useEffect(() => {
    if (data.categories.length) {
      const el = document.getElementById('chart');
      setChart(new AreaChart({ el, data, options }));
    }
    return () => chart?.destroy();
  }, [data]);

  return (
    <S.StyledPlaceChart>
      Collected Places Chart
      <div id="chart" />
    </S.StyledPlaceChart>
  );
}
export default PlaceChart;

PlaceChart.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string),
  counts: PropTypes.arrayOf(PropTypes.number)
};

PlaceChart.defaultProps = {
  dates: [],
  counts: []
};
