import ArticlesForm from './ArticleForm';
import ArticlesTable from './ArticlesTable';

export default function ManageArticles(): JSX.Element {
  return (
    <div>
      <ArticlesForm />
      <ArticlesTable />
    </div>
  );
}
