import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { white } from 'loplat-ui';

const ClearTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
    color: white,
    boxShadow: theme.shadows[1],
    fontSize: 15
  }
}))(Tooltip);

export default ClearTooltip;
