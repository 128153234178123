import PropTypes from 'prop-types';
import * as S from './styles';

function StatusLabel({ color, backgroundColor, children }) {
  return (
    <S.StyledStatusLabel color={color} backgroundColor={backgroundColor}>
      {children}
    </S.StyledStatusLabel>
  );
}
export default StatusLabel;

StatusLabel.propTypes = {
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired
};
