import { Box, Paper } from '@material-ui/core';
import * as S from './styles';

function InputsWrapper(props) {
  const { children } = props;
  const classes = S.useStyles;
  return (
    <Box className={classes.box}>
      <Paper className={classes.paper}>{children}</Paper>
    </Box>
  );
}
export default InputsWrapper;

InputsWrapper.propTypes = {};
