import Badge from '@F/Badge';
import { bluescale300, grayscale800, green300 } from 'loplat-ui';
import type { FetchCampaignStatusKorean } from '@TS/max/campaigns/status';

const CampaignState = ({
  status,
  full = false
}: {
  status: FetchCampaignStatusKorean;
  full?: boolean;
}): JSX.Element => <Badge full={full} text={status} color={CampaignStateColor[status]} />;

export default CampaignState;

export const CampaignStateColor: Record<FetchCampaignStatusKorean, string> = {
  대기: grayscale800,
  진행: green300,
  종료: bluescale300,
  테스트: bluescale300
};
