import { action, observable, makeObservable } from 'mobx';

class ToastStore {
  @observable isOpen = false;

  @observable message = '';

  /** type: PropTypes.oneOf(['error', 'warning', 'info', 'success']) */
  @observable type = 'info';

  constructor() {
    makeObservable(this);
  }

  @action setIsOpen(newIsOpen) {
    this.isOpen = newIsOpen;
  }

  @action setMessageInfo({ message: newMessage, type: newType }) {
    this.message = newMessage;
    this.type = newType;
  }
}
export const toastStore = new ToastStore();
