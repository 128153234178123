import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { DatePickers } from '@F/materialUI/datePicker/DatePickers/refactor';
import { DatePickersProps } from '@F/materialUI/datePicker/DatePickers/types';
import CheckForm, { Props as CheckProps } from '@F/materialUI/CheckForm';
import styled from 'styled-components';
import type { Duration as DurationType } from '@TS/max/campaigns/operation';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

type Props = Omit<DatePickersProps, 'error' | 'disableds'> &
  PrefixKey<Omit<CheckProps, 'checked'>, 'check'> &
  Pick<CheckProps, 'checked'>;
const Duration = ({
  values,
  checked,
  checkLabel,
  checkName,
  checkOnChange,
  checkDisabled,
  ...props
}: Props): JSX.Element => {
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if (checked) setDisabled(false);
    else setDisabled(true);
  }, [checked]);

  useEffect(() => {
    if (values.some((value) => value === '')) {
      return setError(true);
    }
    const [start, end] = values;
    setError(dayjs(end).isBefore(start));
  }, [values]);

  return (
    <Wrapper>
      <legend>
        <CheckForm
          checked={checked}
          label={checkLabel}
          name={checkName}
          onChange={checkOnChange}
          disabled={checkDisabled}
        />
      </legend>

      <DatePickers {...props} error={error} values={values} disableds={[disabled, disabled]} />
    </Wrapper>
  );
};

export default Duration;

const Wrapper = styled.fieldset`
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  padding: 0 1rem;
  width: 100%;

  > label {
    margin-bottom: 1rem;
  }
`;

export const isValidDurationValue = (duration: DurationType): boolean => {
  const { start, end } = duration;
  if (start === '' || end === '') return false;
  return dayjs(start).isSameOrBefore(end);
};
