import React, { createContext, useCallback, useContext, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import jwtDecode from 'jwt-decode';

import { authApi } from '@API/manager';
import { User, decodedRefreshToken } from '@TS/user';
import axiosTokenManager from '@API/axios/helper/tokenManager';
import { managerLocalStorage } from '@/utils/localStorageParser';

export type Login = (username: string, password: string) => Promise<void>;
export type Logout = () => void;
export type Refresh = () => Promise<void> | never;

type AuthContextType = {
  login: Login;
  logout: Logout;
  refresh: Refresh;
  user: User | null;
  isAuthoritative: boolean;
};

type AuthContextProviderProps = {
  children: React.ReactNode;
};

const getInitialUser = () => {
  const accessToken = managerLocalStorage.get<string>(managerLocalStorage.keys.access_token);

  if (accessToken) {
    const user = jwtDecode<User>(accessToken);

    const isTokenExpired = user.exp <= new Date().getTime() / 1000;

    if (isTokenExpired) return null;

    axiosTokenManager.setAccessToken(accessToken);

    return user;
  }

  return null;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthContextProvider({ children }: AuthContextProviderProps): JSX.Element {
  const [user, setUser] = useState<User | null>(getInitialUser());

  const login: Login = useCallback(async (username, password) => {
    const {
      data: { access_token, refresh_token }
    } = await authApi.login({ username, password });

    axiosTokenManager.setAccessToken(access_token);

    managerLocalStorage.set(managerLocalStorage.keys.access_token, access_token);
    managerLocalStorage.set(managerLocalStorage.keys.refresh_token, refresh_token);
    const user = jwtDecode<User>(access_token);

    setUser(user);
  }, []);

  const logout: Logout = useCallback(() => {
    axiosTokenManager.deleteAccessToken();
    managerLocalStorage.remove(managerLocalStorage.keys.refresh_token);
    managerLocalStorage.remove(managerLocalStorage.keys.access_token);

    setUser(null);
  }, []);

  const refresh: Refresh = useCallback(async () => {
    const $refreshTokenModal = showRefreshTokenModal();

    const refreshToken = managerLocalStorage.get<string>(managerLocalStorage.keys.refresh_token);

    if (refreshToken) {
      const { exp } = jwtDecode<decodedRefreshToken>(refreshToken);
      const isFreshRefreshToken = exp >= new Date().getTime() / 1000;

      if (!isFreshRefreshToken) {
        $refreshTokenModal.remove();
        throw new Error('REFRESH_TOKEN_IS_EXPIRED');
      }

      axiosTokenManager.setRefreshToken(refreshToken);

      const {
        data: { access_token, refresh_token }
      } = await authApi.refresh();

      managerLocalStorage.set(managerLocalStorage.keys.access_token, access_token);
      managerLocalStorage.set(managerLocalStorage.keys.refresh_token, refresh_token);
      axiosTokenManager.setAccessToken(access_token);

      const user = jwtDecode<User>(access_token);

      setUser(user);
      $refreshTokenModal.remove();
    } else {
      $refreshTokenModal.remove();
      throw new Error('REFRESH_TOKEN_DOES_NOT_EXIST');
    }
  }, []);

  useIdleTimer({
    onActive: () => {
      const accessToken = managerLocalStorage.get<string>(managerLocalStorage.keys.access_token);

      if (accessToken) {
        const user = jwtDecode<User>(accessToken);

        const isTokenExpired = user.exp <= new Date().getTime() / 1000;

        if (isTokenExpired) {
          refresh();
        }
      }
    },
    timeout: 1000 * 60 * 10
  });

  return (
    <AuthContext.Provider value={{ login, logout, refresh, user, isAuthoritative: true }}>
      {children}
    </AuthContext.Provider>
  );
}

const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('auth context must be used in auth context provider');
  }

  return context;
};

export default useAuth;

export const showRefreshTokenModal = () => {
  const $refreshTokenModal = document.createElement('div');
  $refreshTokenModal.className = 'refresh-token-modal';
  $refreshTokenModal.innerHTML = `
        <div class="refresh-token-modal-box">
            <div>토큰 갱신 중입니다...</div>
            <div class="refresh-token-modal-loading">
                <div class="lds-spinner"/>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    `;

  document.body.append($refreshTokenModal);

  return $refreshTokenModal;
};
