import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import TextInputForm from '@F/materialUI/TextInputForm';
import SelectForm from '@F/materialUI/SelectForm';
import { toastErrorMessage } from '@FUNC/toast';
import { useInput } from '@HOOK/';
import { actions } from '@/redux/admin/whitelist/state';
import { statusOptions } from '../../options';

import * as S from './styles';

function Detail({ id, data }) {
  const whitelist = useMemo(() => data.find((element) => element.whitelist_id === Number(id)), [id, data]);

  const classes = S.useStyles();

  const [ip, setIp] = useInput(whitelist.ip_address);
  const IpInput = <TextInputForm name="Ip" label="아이피" onChange={setIp} value={ip} />;
  const [memo, setMemo] = useInput(whitelist.comment);
  const MemoInput = <TextInputForm name="Memo" label="메모" onChange={setMemo} value={memo} />;
  const [status, setStatus] = useInput(whitelist.status ? '활성' : '비활성');
  const StatusSelect = (
    <SelectForm
      label="상태"
      options={Object.keys(statusOptions).slice(1)}
      value={status}
      onSelect={setStatus}
      name="StatusSelect"
    />
  );

  const dispatch = useDispatch();
  const deleteWhitelist = () => {
    dispatch(actions.deleteList(whitelist));
  };

  const updateWhitelist = () => {
    if (!ip.trim() || !memo.trim()) {
      toastErrorMessage('정보를 입력해주세요.');
      return;
    }

    dispatch(
      actions.updateList({
        whitelistId: whitelist.whitelist_id,
        ipAddress: ip,
        status: statusOptions[status],
        comment: memo
      })
    );
  };

  return (
    <S.StyledDetail>
      <Grid container spacing={2} className={classes.wrapper}>
        <Grid item xs={3}>
          {IpInput}
        </Grid>
        <Grid item xs={6}>
          {MemoInput}
        </Grid>
        <Grid item xs={3}>
          {StatusSelect}
        </Grid>
      </Grid>
      <S.ButtonRow>
        <Button variant="contained" color="secondary" onClick={deleteWhitelist}>
          삭제
        </Button>
        <Button variant="contained" color="primary" onClick={updateWhitelist}>
          수정
        </Button>
      </S.ButtonRow>
    </S.StyledDetail>
  );
}
export default Detail;

Detail.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.arrayOf(PropTypes.any).isRequired
};

Detail.defaultProps = {
  id: 0
};
