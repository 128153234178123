import styled from 'styled-components';
import { IsOpenedAllType } from '@TS/store/cidTable';

type IsSortableHeadCell = { isSortable: boolean };
interface SortButton {
  isOpenedAll: IsOpenedAllType;
}

export const SortButton = styled.button<SortButton>`
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid darkgray;
  border-radius: 4px;
  background-color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 0.875rem;
    path {
      fill: darkgray;
    }
  }

  &:hover {
    border-color: blue;

    svg > path {
      fill: blue;
    }
  }
`;

export const StyledHeadCell = styled.div<IsSortableHeadCell>`
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: transparent;

    ${({ isSortable }) =>
      isSortable &&
      `
      &:hover {
        color: darkgray;
        svg {
          opacity: 1;
        }
      }`}

    > svg {
      margin-left: 0.5rem;
      opacity: 0;
      transition: opacity 0.3s ease;
      font-size: 0.875rem;
    }
  }
`;

export const StyledHead = styled.div`
  display: flex;
  flex-direction: row;

  background: #fafafa;
  border-bottom: 1px solid lightgray;
  padding: 1rem;
  font-size: 0.875rem;

  ${StyledHeadCell} {
    margin-right: 0.5rem;
    &:nth-of-type(1) {
      width: 80%;
      margin-left: 1rem;
      padding-left: 1rem;
    }

    &:nth-of-type(4) {
      width: 200%;
      margin-right: 0;
    }
  }
`;
