import { useParams } from 'react-router-dom';
import LinkButton from '@F/LinkButton';
import CampaignDetail from './detail/index';

const Detail = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  if (Number.isNaN(Number(id))) throw new Error('정확한 캠페인 id를 입력해주세요.');

  return (
    <>
      <LinkButton mb={4} go="/x/campaigns">
        뒤로가기
      </LinkButton>
      <CampaignDetail id={Number(id)} />
    </>
  );
};

export default Detail;
