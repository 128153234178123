import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  max-height: 500px;
  overflow-y: scroll;
  margin-bottom: 16px;

  &,
  td,
  th {
    border: 1px solid black;
    border-collapse: collapse;
  }

  tr > td,
  tr > th {
    &:nth-of-type(1),
    &:nth-of-type(3) {
      width: 25%;
    }
    &:nth-of-type(2) {
      width: 50%;
    }
  }
`;

export const IdText = styled.span<{ charge: string | null }>`
  color: ${(props) => (props.charge ? props.theme.palette.text.hint : props.theme.palette.primary.deepDark)};
`;
