import Loading from '@F/Loading';
import { useAppSelector } from '@HOOK/useSelector';
import Filter from './Filter';
import AddVersion from './AddVersion';
import TableSection from './tableSection/TableSection';
import * as S from './styles';

function Version(): JSX.Element {
  const isLoading = useAppSelector((state) => state.version.isLoading);
  return (
    <S.StyledVersion>
      {isLoading && <Loading />}
      <AddVersion />
      <br />
      <Filter />
      <br />
      <TableSection />
    </S.StyledVersion>
  );
}
export default Version;
