import ModalWrapper from '@F/modal/ModalWrapper';
import * as S from './styles';

// TODO: camelCase로 된 interface로 분리
interface Site {
  site_id: number;
  origin_name: string;
  last_category: string;
  charge: string;
  floor: number;
}

interface FloorSite extends Site {
  sites: Site[];
}

interface Props {
  floorSite: FloorSite | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function FloorSiteModal({ floorSite, open, setOpen }: Props): JSX.Element | null {
  if (!floorSite) return null;
  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      title={`${floorSite.origin_name} ${floorSite.floor}층 수집 매장`}
      withCancelButton={false}
      confirm={() => setOpen(false)}
    >
      <S.Table>
        <thead>
          <tr>
            <th>site id</th>
            <th>매장명</th>
            <th>카테고리</th>
          </tr>
        </thead>
        <tbody>
          {floorSite.sites.map((site) => (
            <tr key={site.site_id}>
              <td>
                <S.IdText charge={site.charge}>{site.site_id}</S.IdText>
              </td>
              <td>{site.origin_name}</td>
              <td>{site.last_category}</td>
            </tr>
          ))}
        </tbody>
      </S.Table>
    </ModalWrapper>
  );
}
export default FloorSiteModal;
