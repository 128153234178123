import { useEffect, useState } from 'react';
import Wrapper from '@F/materialUI/Wrapper';
import { Grid } from '@material-ui/core';
import { fingerprintsApi } from '@API/manager';
import { useFetch } from '@HOOK/';
import PlaceChart from './PlaceChart';
import CountBox from './CountBox';
import * as S from './styles';

function Dashboard() {
  const [stat, setStat] = useState({});
  const { callApi } = useFetch();
  useEffect(() => {
    (async () => {
      const response = await callApi(fingerprintsApi.getStats);
      if (response) setStat(response.data);
    })();
  }, [callApi]);

  return (
    <S.StyledDashboard>
      <Wrapper>
        <PlaceChart counts={stat.monthly?.count} dates={stat.monthly?.month} />
      </Wrapper>
      <br />
      <Grid container spacing={2}>
        <Grid item xs>
          <Wrapper>
            <CountBox label="Totally" description="Totally places" backgroundColor="red" data={stat.totally} />
          </Wrapper>
        </Grid>
        <Grid item xs>
          <Wrapper>
            <CountBox
              label="Monthly"
              description="Monthly places / Last Monthly places"
              backgroundColor="blue"
              data={stat.monthly?.count[5]}
              lastData={stat.monthly?.count[4]}
            />
          </Wrapper>
        </Grid>
        <Grid item xs>
          <Wrapper>
            <CountBox label="Daily" description="Daily places" backgroundColor="skyblue" data={stat.daily} />
          </Wrapper>
        </Grid>
      </Grid>
    </S.StyledDashboard>
  );
}
export default Dashboard;
