import styled from 'styled-components';
import { Button, Grid, GridSize } from '@material-ui/core';

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;

  .MuiFormControl-root,
  .MuiInputBase-root {
    width: 100%;
  }
`;

export const Row = styled(Grid)`
  display: flex;
  flex-wrap: wrap;

  &:nth-child(2) {
    margin-top: 2px;
  }

  &:not(:nth-child(1), :nth-child(2)) {
    margin-top: 24px;
  }
`;

const Item = styled(Grid)`
  width: 100%;
  display: flex;
  > div {
    width: 100%;
  }
`;

type Props = {
  children: React.ReactNode;
  xs?: GridSize;
};
export const GridItem = ({ children, xs = 3 }: Props): JSX.Element => (
  <Item item xs={xs}>
    {children}
  </Item>
);

export const SearchButton = styled(Button)`
  height: fit-content;
`;

export const Footer = styled(Row)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 16px;
  margin: 0;
`;
