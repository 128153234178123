import { Complex } from '@TS/store/complex';

export const appendIsOpenFieldsToComplexes = (complexes: Complex[], isOpen: boolean): Complex[] => {
  const complexMallList = complexes;
  if (!complexMallList.length) return complexMallList;

  for (let i = 0; i < complexMallList.length; i++) {
    if (complexMallList[i].children.length) {
      complexMallList[i].isOpen = isOpen;
      appendIsOpenFieldsToComplexes(complexMallList[i].children, isOpen);
    }
  }

  return complexMallList;
};

export const toggleNodeByCid = (complexes: Complex[], targetCid: number, isDone: boolean): Complex[] => {
  const complexList = complexes;

  if (isDone || !complexList.length) return complexList;
  let doneToggle: boolean = isDone;

  for (let i = 0; i < complexList.length; i++) {
    if (complexList[i].cid === targetCid) {
      const toggledIsOpen = complexList[i].isOpen;
      complexList[i].isOpen = !toggledIsOpen;
      doneToggle = true;
      return complexList;
    }

    toggleNodeByCid(complexList[i].children, targetCid, doneToggle);
  }

  return complexList;
};
