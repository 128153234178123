import type { Campaign } from '@TS/max/campaigns/campaign';
import type { RepeatType } from '@TS/max/campaigns/message';
import type { TargetApp } from '@TS/max/campaigns/target';
import type { MessageCondition, DeepLink } from './type';
import { REPEAT_TYPE, MESSAGE_METHOD } from './data';

export const schemeMessageCondition = (campaign: SnakeToCamelObj<Campaign>): MessageCondition[] => [
  {
    title: '발송 횟수',
    content: getSendType(campaign)
  },
  {
    title: '알림 방식',
    content: MESSAGE_METHOD[campaign.method],
    get children() {
      if (campaign.method === 'none') return [];

      const messageDetail: MessageCondition[] = [
        { title: '메시지 제목', content: campaign.title },
        { title: '메시지 내용', content: campaign.body }
      ];

      if (campaign.method === 'noti') {
        messageDetail.push({
          title: '딥링크 URL',
          content: null,
          children: getDeepLinks(campaign)
        });
        return messageDetail;
      }

      messageDetail.push(
        { title: '이미지 URL', content: campaign.img },
        { title: '딥링크 URL', content: null, children: getDeepLinks(campaign) }
      );
      return messageDetail;
    }
  }
];

function getSendType(campaign: SnakeToCamelObj<Campaign>): Exclude<RepeatType, '사용자 설정'> | string {
  const messageSendType = REPEAT_TYPE[campaign.repeatType];
  if (messageSendType !== '사용자 설정') return messageSendType;

  const { repeatResetMins } = campaign;
  const { day, hour, min } = calculateTimeByMinutes(repeatResetMins);
  return `발송 후 ${day}일 ${hour}시간 ${min === 0 ? '' : `${min}분 `}경과 후 재발송 활성화`;
}

function getDeepLinks(campaign: SnakeToCamelObj<Campaign>): DeepLink[] {
  const { uri, targets } = campaign;
  const imageForTarget: { [k: string]: string } = uri ? JSON.parse(uri) : {};
  return Object.entries(imageForTarget).map(([key, url]) => {
    const targetApp = targets.find(({ target }) => target === key.substring(2)) as TargetApp;
    return {
      title: targetApp?.name,
      type: targetApp?.target_type,
      content: url
    };
  });
}

function calculateTimeByMinutes(minutes: number): Record<'day' | 'hour' | 'min', number> {
  const ONE_HOUR_PER_MINS = 60;
  const ONE_DAY_PER_HOUR = 24;
  const hours = minutes / ONE_HOUR_PER_MINS;
  const min = minutes % ONE_HOUR_PER_MINS;
  return {
    day: Math.floor(hours / ONE_DAY_PER_HOUR),
    hour: Math.floor(hours) % ONE_DAY_PER_HOUR,
    min
  };
}
