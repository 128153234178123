import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import EnhancedTable from '@F/table/Table';
import { Button } from '@material-ui/core';
import AssignModal from '@F/modal/assignModal/AssignModal';
import moment from 'moment';
import PaginationRow from '@F/table/PaginationRow';
import ResetAssignedModal from '@F/modal/assignModal/ResetAssignedsModal';
import * as S from './styles';

const headCells = [
  {
    id: 'siteId',
    numeric: false,
    disablePadding: false,
    label: 'Site ID'
  },
  {
    id: 'cid',
    numeric: false,
    disablePadding: false,
    label: 'Complex ID'
  },
  { id: 'floor', numeric: false, disablePadding: false, label: '층수' },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: '장소명'
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: '카테고리'
  },
  {
    id: 'chargeResult',
    numeric: false,
    disablePadding: false,
    label: '상태'
  },
  {
    id: 'charge',
    numeric: false,
    disablePadding: false,
    label: '수집자'
  },
  {
    id: 'chargedAt',
    numeric: false,
    disablePadding: false,
    label: '할당일시'
  }
];

function Table({ sites, totalCount, perPage, page, setPage, onConfirmCharge, isLoading }) {
  const classes = S.useStyles();
  const [selectedSites, setSelectedSites] = useState([]);
  const [isAssignModalOpened, setIsAssignModalOpened] = useState(false);
  const [isResetModalOpened, setIsResetModalOpened] = useState(false);

  const rows = useMemo(
    () =>
      sites.map((site) => ({
        id: site.site_id,
        cid: site.cid,
        floor: site.floor,
        name: `${site.name} ${site.branch_name ?? ''}`,
        category: site.category_code,
        chargeResult: `${site.charge_result} (${getKoreanChargeResult(site.charge_result)})`,
        charge: site.charge,
        chargedAt: moment(site.charge_result_at).utc().format('YYYY-MM-DD HH:mm:ss')
      })),
    [sites]
  );

  const onConfirm = useCallback(() => {
    onConfirmCharge();
    setSelectedSites([]);
  }, [onConfirmCharge]);

  return (
    <S.Row container className={classes.margin} spacing={1}>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={totalCount} />
      <S.ButtonRow>
        <Button
          className={classes.button}
          variant="outlined"
          color="primary"
          onClick={() => setIsAssignModalOpened(true)}
        >
          재할당
        </Button>
        <Button
          className={classes.button}
          variant="outlined"
          color="secondary"
          onClick={() => setIsResetModalOpened(true)}
        >
          할당취소
        </Button>
      </S.ButtonRow>
      <S.Table disabled={isLoading}>
        <EnhancedTable
          headCells={headCells}
          rows={rows}
          initialOrder="desc"
          initialOrderBy="carbs"
          selected={selectedSites}
          setSelected={setSelectedSites}
          isCollapsible={false}
          isCheckable
        />
      </S.Table>
      <PaginationRow perPage={perPage} page={Number(page)} setPage={setPage} count={totalCount} reverse />

      <AssignModal
        open={isAssignModalOpened}
        sites={selectedSites}
        setOpen={setIsAssignModalOpened}
        onConfirm={onConfirm}
      />

      <ResetAssignedModal
        open={isResetModalOpened}
        setOpen={setIsResetModalOpened}
        sites={selectedSites}
        onConfirm={onConfirm}
      />
    </S.Row>
  );
}
export default Table;

Table.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalCount: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  onConfirmCharge: PropTypes.func,
  isLoading: PropTypes.bool
};

Table.defaultProps = {
  onConfirmCharge: () => {},
  isLoading: false
};

function getKoreanChargeResult(chargeResult) {
  switch (chargeResult) {
    case 0:
      return '아직';
    case 1:
      return '미방문';
    case 2:
      return '폐점';
    case 3:
      return '기타';
    case 5:
      return '완료';
    default:
      return '';
  }
}
