import styled from 'styled-components';
import Loading from '@F/Loading';
import DetailReport from '@P/max/campaigns/detail/DetailReport';
import type { CampaignDetail } from '@P/max/campaigns/redux/campaigns/slice';

type Props = Record<'cidA' | 'cidB', CampaignDetail | null>;
const CompareReport = ({ cidA, cidB }: Props): JSX.Element => {
  if (!cidA) return <Loading />;
  if (!cidB) return <Loading />;

  return (
    <Wrapper>
      <DetailReport campaign={cidA} />
      <DetailReport campaign={cidB} />
    </Wrapper>
  );
};

export default CompareReport;

const Wrapper = styled.div`
  width: 100%;
  padding: 0 48px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;

  > div {
    height: fit-content;
  }
`;
