import { useState } from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import dayjs from 'dayjs';
import { Formik, Form } from 'formik';

import ConfirmModal from '@F/modal/ConfirmModal';
import { toastWarningMessage } from '@FUNC/toast';
import { PrimitiveLogFilterValues, INITIAL_PRIMITIVE_LOG_FILTER_VALUES } from '../data';
import { usePrimitiveLogBloc } from '../usePrimitiveLogBloc';
import MaxCompanies from './MaxCompanies';
import Log from './Log';
import Dates from './Dates';

const DATE_WARNING_MESSAGE = '조회 기간이 7일을 초과할 경우 big query 비용이 크게 발생할 수 있습니다.';

export default function PrimitiveLogFilter(): JSX.Element {
  const { fetchPrimitiveLogs } = usePrimitiveLogBloc();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCsvConfirmModal, setShowCsvConfirmModal] = useState(false);
  const { fetchStatus, downloadStatus, downloadPrimitiveLogs } = usePrimitiveLogBloc();
  const [shouldWarnDates, setShouldWarnDates] = useState(false);

  const validateFilterValues = (values: PrimitiveLogFilterValues) => {
    const errors: { client_id?: string; log?: { value: string }; dates?: string } = {};

    const { log, client_id, dates } = values;

    if (client_id === '') {
      errors.client_id = '회사명을 입력해주세요.';
    }

    if (log.value === '') {
      errors.log = { value: `${values.log.type} 조회 값을 입력해주세요.` };
    }

    const [startAt, endAt] = dates;

    const dayDiff = dayjs(endAt).diff(dayjs(startAt), 'day');
    if (dayDiff > 7) {
      toastWarningMessage(DATE_WARNING_MESSAGE);
      setShouldWarnDates(true);
    } else {
      setShouldWarnDates(false);
    }

    return errors;
  };

  const submit = (values: PrimitiveLogFilterValues) => {
    const {
      client_id,
      log,
      dates: [startAt, endAt]
    } = values;

    fetchPrimitiveLogs({
      client_id,
      timestamp_from: startAt,
      timestamp_to: endAt,
      adid: log.type === 'adid' ? log.value : undefined,
      campaign_id: log.type === 'campaign_id' ? Number(log.value) : undefined,
      echo_code: log.type === 'echo_code' ? log.value : undefined
    });
  };

  const submitCsv = (values: PrimitiveLogFilterValues) => {
    const {
      client_id,
      log,
      dates: [startAt, endAt]
    } = values;

    downloadPrimitiveLogs({
      client_id,
      timestamp_from: startAt,
      timestamp_to: endAt,
      adid: log.type === 'adid' ? log.value : undefined,
      campaign_id: log.type === 'campaign_id' ? Number(log.value) : undefined,
      echo_code: log.type === 'echo_code' ? log.value : undefined
    });
  };

  const handleSubmit = (values: PrimitiveLogFilterValues) =>
    shouldWarnDates ? setShowConfirmModal(true) : submit(values);

  const handleSubmitCsv = (values: PrimitiveLogFilterValues) =>
    shouldWarnDates ? setShowCsvConfirmModal(true) : submitCsv(values);

  const handlePermitConfirmModal = (values: PrimitiveLogFilterValues) => {
    setShowConfirmModal(false);
    submit(values);
  };
  const handlePermitCsvConfirmModal = (values: PrimitiveLogFilterValues) => {
    setShowCsvConfirmModal(false);
    submitCsv(values);
  };

  const handleCancelConfirmModal = () => setShowConfirmModal(false);
  const handleCancelCsvConfirmModal = () => setShowCsvConfirmModal(false);

  return (
    <Formik initialValues={INITIAL_PRIMITIVE_LOG_FILTER_VALUES} onSubmit={handleSubmit} validate={validateFilterValues}>
      {({ values, touched, errors }) => {
        const isValidPrimitiveLogFilterValues =
          !touched.client_id || !touched.log?.value || Boolean(errors.client_id) || Boolean(errors.log);

        const { dates, log } = values;

        const confirmModalContent = `\n
            기간: ${dates?.[0]} ~ ${dates?.[1]}\n
            조회 유형 : ${log?.type}\n
            조회 값 : ${log?.value}
        `;

        return (
          <Form>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <MaxCompanies />
              </Grid>
              <Log />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Dates />
              </Grid>
              <Grid item xs={4}>
                <Button
                  type="submit"
                  style={{ height: '55px', marginTop: '9px' }}
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={isValidPrimitiveLogFilterValues || fetchStatus === 'loading'}
                >
                  검색
                </Button>
              </Grid>
              {shouldWarnDates ? (
                <ConfirmModal
                  isOpen={showConfirmModal}
                  title="big query 요청 조회 기간 확인"
                  subTitle="아래와 같이 인식 로그 조회를 진행하시겠습니까?"
                  content={confirmModalContent}
                  onPermit={() => handlePermitConfirmModal(values)}
                  onCancel={handleCancelConfirmModal}
                />
              ) : null}
            </Grid>
            <Grid container direction="row-reverse">
              <Grid item xs={2}>
                <Button
                  onClick={() => handleSubmitCsv(values)}
                  style={{ height: '55px' }}
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={isValidPrimitiveLogFilterValues || downloadStatus === 'loading'}
                >
                  CSV 다운로드
                </Button>
                {shouldWarnDates ? (
                  <ConfirmModal
                    isOpen={showCsvConfirmModal}
                    title="big query 요청 조회 기간 확인"
                    subTitle="아래와 같이 인식 로그 조회를 진행하시겠습니까?"
                    content={confirmModalContent}
                    onPermit={() => handlePermitCsvConfirmModal(values)}
                    onCancel={handleCancelCsvConfirmModal}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
