import { useCallback, useEffect, useMemo } from 'react';
import { createHeadCells } from '@F/table/Table/functions';
import PaginationRow from '@F/table/PaginationRow';
import EnhancedTable from '@F/table/Table';
import { shallowEqual, useDispatch } from 'react-redux';
import Loading from '@F/Loading';
import StatusLabel from '@F/StatusLabel';
import { theme } from '@S/index';
import Red from '@material-ui/core/colors/red';
import Blue from '@material-ui/core/colors/blue';
import { Report } from '@TS/cashplace/report';
import { Column } from '@TS/columns';
import { useAppSelector } from '@HOOK/useSelector';
import { actions } from '@/redux/cashplace/report/state';
import Detail from '../Detail';
import * as S from './styles';

const columns: Column[] = [
  { name: 'status', label: '상태' },
  { name: 'kind', label: '종류' },
  { name: 'id', label: 'Report ID', isImportant: true },
  { name: 'userId', label: 'User ID' },
  { name: 'siteId', label: 'Site ID' },
  { name: 'name', label: '장소명' },
  { name: 'location', label: '수정된 위치' },
  { name: 'createdAt', label: '신고일시' },
  { name: 'comment', label: '비고' }
];

function TableSection(): JSX.Element {
  const { isLoading, page, perPage, count, reports, openedReportIds } = useAppSelector(
    (state) => ({
      isLoading: state.report.isLoading,
      page: state.report.page,
      perPage: state.report.perPage,
      count: state.report.count,
      reports: state.report.reports,
      openedReportIds: state.report.openedReportIds
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const setPage = useCallback(
    (newPage) => {
      if (page === newPage) dispatch(actions.fetchReports());
      else dispatch(actions.setPage(newPage));
    },
    [dispatch, page]
  );
  useEffect(() => {
    dispatch(actions.fetchReports());
  }, [dispatch, page]);

  const setOpenedReportIds = useCallback(
    (newOpenedReportIds) => {
      dispatch(actions.setValue('openedReportIds', newOpenedReportIds));
    },
    [dispatch]
  );

  const headCells = columns.map((column) => createHeadCells(column.name, column.label));
  const rows = useMemo(
    () =>
      reports?.map((report: Report) => ({
        result: {
          value: report.result,
          component: formatResultLabel(report.result)
        },
        kind: {
          value: report.kind,
          component: formatKindLabel(report.kind)
        },
        id: report.report_id,
        userId: report.user_id,
        siteId: report.site.site_id,
        name: report.site.name,
        location: report.lat ? `${report.lat}, ${report.lng}` : '',
        createdAt: report.created_at_str,
        comment: report.comment
      })) ?? [],
    [reports]
  );

  return (
    <S.StyledTableSection>
      {isLoading && <Loading />}
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} />
      <EnhancedTable
        rows={rows}
        headCells={headCells}
        isCheckable={false}
        isCollapsible
        opened={openedReportIds}
        setOpened={setOpenedReportIds}
      >
        <Detail reports={reports} />
      </EnhancedTable>
      <PaginationRow perPage={perPage} page={page} setPage={setPage} count={count} reverse />
    </S.StyledTableSection>
  );
}
export default TableSection;

function formatResultLabel(result: number): React.ReactNode {
  switch (result) {
    case 0:
      return (
        <StatusLabel color={theme.palette.grey[800]} backgroundColor={theme.palette.grey[200]}>
          미검수
        </StatusLabel>
      );
    case -1:
      return (
        <StatusLabel color={Red[800]} backgroundColor={Red[200]}>
          틀림
        </StatusLabel>
      );
    case 1:
      return (
        <StatusLabel color={Blue[800]} backgroundColor={Blue[200]}>
          올바름
        </StatusLabel>
      );
    default:
      return (
        <StatusLabel color={theme.palette.grey[800]} backgroundColor={theme.palette.grey[200]}>
          미검수
        </StatusLabel>
      );
  }
}

function formatKindLabel(kind: Report['kind']): React.ReactNode {
  return (
    <StatusLabel color={theme.palette.grey[800]} backgroundColor={theme.palette.grey[200]}>
      {kind === 0 ? '폐점' : '위치 수정'}
    </StatusLabel>
  );
}
