import styled from 'styled-components';

export const StyledStatusLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  width: 3.2rem;
  white-space: nowrap;

  padding: 0.2rem 0.5rem;
  margin-left: -15px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  border-radius: 5px;
`;
